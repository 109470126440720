import { Component, OnInit } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatTableDataSource} from '@angular/material/table';

import { NotesService} from '../notes/notes.service'

import { GlobalService } from '../../services/global/global.service'
import { BackendService } from '../../services/backend/backend.service'
import { EventsService } from '../../services/events/events.service'
import { ConfirmService } from '../../modal/confirm/confirm.service';
import { EditEtatService } from '../../modal/edit-etat/edit-etat.service';
import { EditLocationService } from '../../modal/edit-location/edit-location.service'
import { Router } from '@angular/router';


@Component({
  selector: 'app-expedition-terminee',
  templateUrl: './expedition-terminee.component.html',
  styleUrls: ['./expedition-terminee.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ExpeditionTermineeComponent implements OnInit {

  expandedElement
  userConnected
  dataSourceTermineeLenght = 0
  dataSourceTerminee = [];
  errorMessage = null;

  dataSourceTermineeList = [];

  limite = 0;
  notOther = false
  totalParPage = 2;
  resultatListeSendbox;
  offset = 2;

  constructor( 
    private notesService: NotesService ,
    private eventsService: EventsService,
    private globalService: GlobalService,
    private backendService: BackendService,
    private router: Router
  ) { }

  ngOnInit() {

    if( !this.globalService.getConnectedUser() ) {
      this.router.navigate(['/home']);
      window.scroll(0,0);
    }
    this.userConnected = this.globalService.getConnectedUser()
    this.displayPage( 0 )
  }

  async displayPage( status ){

    if ( status === 0 ) {
      this.limite = 0;
      if ( !!this.resultatListeSendbox && this.resultatListeSendbox.array_sendbox_encours_user.length >= this.totalParPage ) {
        this.offset = this.resultatListeSendbox.array_sendbox_encours_user.length
      } else if ( !!this.resultatListeSendbox && this.resultatListeSendbox.array_sendbox_no_user_encours.length >= this.totalParPage ) {
        this.offset = this.resultatListeSendbox.array_sendbox_no_user_encours.length
      } else {
        this.offset = 2
      }
    }

    let annonce = {
      limite: this.limite,
      status_end: 1,
      offset: this.offset
    }

    this.dataSourceTerminee = [];
    this.backendService.post( `/senbox/list-sendbox-user/${this.globalService.getConnectedUser().id}`, annonce )
    .then( async(resultat) => {
      console.log(' resultatv zz :: ', resultat )
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.dataSourceTerminee = [];
      } else {
        this.dataSourceTerminee = await this.initNote(resultat.array_sendbox_encours) ;
        
        this.resultatListeSendbox = resultat;
        this.notOther = resultat.array_sendbox_encours.length < this.totalParPage ? true : false
        console.log( ' this.initNote(resultat.array_sendbox_encours) ', resultat );

        this.dataSourceTerminee.sort((a: any, b: any) => {
          return this.globalService.getDateStringToDate(b.datecreate).getTime() - this.globalService.getDateStringToDate(a.datecreate).getTime()
        });

        this.dataSourceTermineeList = this.dataSourceTermineeList.concat( this.dataSourceTerminee );
        this.dataSourceTermineeLenght = this.dataSourceTermineeList.length;
      }

    })
    .catch( error => {
      this.dataSourceTerminee = [];
    })

  }

  expandedElements(element) {
    this.expandedElement = this.expandedElement === element ? null : element;
  }

  nextAffiche() {
    if ( this.resultatListeSendbox.array_sendbox_encours_user.length >= this.totalParPage ) {
      this.limite = this.resultatListeSendbox.array_sendbox_encours_user.length
    } else if ( this.resultatListeSendbox.array_sendbox_no_user_encours.length >= this.totalParPage ) {
      this.limite = this.resultatListeSendbox.array_sendbox_no_user_encours.length
    } else {
      console.log(' eslse if vraiment UOI ')
    }
    //this.limite = this.dataSourceTermineeList.length;
    this.displayPage(1)
  }

  initNote( arrayBoject ){
    for (var i = 0; i < arrayBoject.length; i++) {
      arrayBoject[i].notestrans = JSON.parse( arrayBoject[i].notestrans );

      if ( !!arrayBoject[i].prep_remise_exped ) {

        if ( !!arrayBoject[i].prep_remise_exped.prep_remise_exped[0] && !!arrayBoject[i].prep_remise_exped.prep_remise_exped[0].image_prep ) {
          arrayBoject[i].prep_remise_exped.prep_remise_exped[0].image_prep = JSON.parse( arrayBoject[i].prep_remise_exped.prep_remise_exped[0].image_prep );
        }
        if ( !!arrayBoject[i].prep_remise_exped.prep_remise_exped[0] && !!arrayBoject[i].prep_remise_exped.prep_remise_exped[0].image_recup ) {
          arrayBoject[i].prep_remise_exped.prep_remise_exped[0].image_recup = JSON.parse( arrayBoject[i].prep_remise_exped.prep_remise_exped[0].image_recup );
        }
        if ( !!arrayBoject[i].prep_remise_exped.prep_remise_exped[0] && !!arrayBoject[i].prep_remise_exped.prep_remise_exped[0].photo_livr ) {
          arrayBoject[i].prep_remise_exped.prep_remise_exped[0].photo_livr = JSON.parse( arrayBoject[i].prep_remise_exped.prep_remise_exped[0].photo_livr );
        }
        if ( !!arrayBoject[i].prep_remise_exped.prep_remise_exped[0] && !!arrayBoject[i].prep_remise_exped.prep_remise_exped[0].sign_livr ) {
          arrayBoject[i].prep_remise_exped.prep_remise_exped[0].sign_livr = JSON.parse( arrayBoject[i].prep_remise_exped.prep_remise_exped[0].sign_livr );
        }
        if ( !!arrayBoject[i].prep_remise_exped.prep_remise_exped[0] && !!arrayBoject[i].prep_remise_exped.prep_remise_exped[0].sign_recup ) {
          arrayBoject[i].prep_remise_exped.prep_remise_exped[0].sign_recup = JSON.parse( arrayBoject[i].prep_remise_exped.prep_remise_exped[0].sign_recup );
        }
      }
      
    }
    return arrayBoject;
  }

  canNote( element ) {
    return (element.infos_suggest.user_suggest.id != this.userConnected.id && element.etattrans != "Terminée et notée" && element.etattrans != "Réfusée par le transporteur" && element.etattrans != "Annulée" ) ? true : false
  }


  ajouterUnAvis( event: Event, element ){
    event.stopPropagation();
    this.errorMessage = null;

    if( element.etattrans == "Réfusée par le transporteur" ){
      this.eventsService.errorsmsg("Vous ne pouvez pas noter cette expédition. Elle a été réfusée par le transporteur.")
    } else if( element.etattrans == "Annulée" ){
      this.eventsService.errorsmsg("Vous ne pouvez pas noter cette expédition. Elle a été annulée.")
    } else {
      if( element.infos_suggest.user_suggest.id == this.userConnected.id ) {
        this.eventsService.errorsmsg("Vous ne pouvez pas vous noter ;) ")
      } else {

        if( element.etattrans == "Terminée et notée"  ) {
          this.eventsService.errorsmsg("Expédition notée et terminée. ")
        } else {

          //Note
          this.notesService.confirmService( element , 'avis')
          .then( (confirmed : any ) => {
            
            if( confirmed.status ) {
              this.eventsService.successmsg( "Note ajoutée avec succès." )
              setTimeout(() => {
                //On met à jour l'état de l'expédition
                this.limite = 0;
                this.displayPage(0)
              }, 2000);
            }
          })
          .catch( (error) => {
          })
          
        }

      }

    }

  }


  downloadFile( event, element , name) {
    event.stopPropagation();
    const extensionFile = this.globalService.getExtentionBase64( element );
    const ladate = new Date();

    this.globalService.downloadFile(
      `${element}` ,
      `${name}-${this.globalService.makeid(10)}.${extensionFile}`
    )
  }




}
