import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmComponent } from './confirm/confirm.component';

import { ConfirmService } from './confirm/confirm.service';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { EditEtatComponent } from './edit-etat/edit-etat.component';


import { BackendService } from '../services/backend/backend.service'
import { GlobalService } from '../services/global/global.service'
import { EventsService } from '../services/events/events.service'
import { MaterialAngModule } from '../matmodule/materialang.module';
import { EditLocationComponent } from './edit-location/edit-location.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfoDetailComponent } from './info-detail/info-detail.component';


@NgModule({
  declarations: [ConfirmComponent, EditEtatComponent, EditLocationComponent, InfoDetailComponent],
  imports: [
    CommonModule,
    NgbModule,
    MaterialAngModule,
    FormsModule, 
    ReactiveFormsModule
  ],
  entryComponents: [ ConfirmComponent , EditEtatComponent , EditLocationComponent , InfoDetailComponent ],
  providers: [ConfirmService , EventsService , GlobalService , BackendService]
})
export class ModalModule { }
