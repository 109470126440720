<div class="download-text">
    Télécharger et installer l'application Msahilisho Group. 
</div>

<div class="container-application">

    <div class="cont-div-application">

        <div class="row">
            <div class="col-md-6 text-center-div">
                <img src="assets/imgs/app-download-ms.png" class="img-pub-app" />
            </div>
            <div class="col-md-3 text-center-div app-log">
                <img src="assets/imgs/app-store.png" class="img-store-app" (click)="openApp('ios')" />
            </div>
            <div class="col-md-3 text-center-div app-log">
                <img src="assets/imgs/google-store.png" class="img-store-app" (click)="openApp('android')" />
            </div>
        </div>

    </div>

</div>
