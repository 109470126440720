import { Component, OnInit , Input  } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators, FormBuilder, ValidationErrors } from '@angular/forms';


import { BackendService } from '../../services/backend/backend.service'
import { GlobalService } from '../../services/global/global.service'
import { EventsService } from '../../services/events/events.service'



@Component({
  selector: 'app-edit-location',
  templateUrl: './edit-location.component.html',
  styleUrls: ['./edit-location.component.scss']
})
export class EditLocationComponent implements OnInit {

  @Input() element
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  title : string = "Modifier la localisation de l'expédition"


  messageRetour
  infosFormGroup: FormGroup;
  submitted = false
  listeLocalisation = [];

  constructor(
    private activeModal: NgbActiveModal ,
    private backendService: BackendService ,
    private globalService: GlobalService,
    private eventsService: EventsService,
    private _formBuilder: FormBuilder,
  ) { }

  ngOnInit() {

    this.listeLocalisation = this.globalService.getListEmplacement();
    this.infosFormGroup = this._formBuilder.group({
      locationCtrl: ['', Validators.required ]
    });

  }


  ngAfterContentInit() {

    this.title = `Modifier l'emplacement de l'expédition n° ${this.element.numtrans}`
    
  }

  get f() { 
    return this.infosFormGroup.controls; 
  }


  public decline() {
    this.activeModal.close({status: false});
  }

  public valider() {

    this.submitted = true
    this.messageRetour = null;
    // stop here if form is invalid
    if (this.infosFormGroup.invalid) {
      return;
    }


    let dateAct = new Date()
    let note = {
      note : `Localisation :: "${this.f.locationCtrl.value}"`,
      create_at: `${dateAct.getDate()}/${dateAct.getMonth() + 1}/${dateAct.getFullYear()}`
    }
    let lesNotes = this.element.notestrans
    lesNotes.push( note )

    let box = {
      iduserexp: this.element.user_sender.id,
      numtrans: this.element.numtrans,
      notestrans: JSON.stringify( lesNotes ),
      localisation: this.f.locationCtrl.value,
      user_direct: !!this.element.user_sender.user_direct,
      email_user: !!this.element.user_sender.email ? this.element.user_sender.email : null
    }

    this.backendService.put(`/senbox/location/${this.element.id}`, box )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        //this.eventsService.errorsmsg( resultat.error.error.text )
        this.messageRetour = {ko: resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
      } else {
        this.messageRetour = {ok: resultat.text}
        setTimeout( () => {
          this.activeModal.close({status: true});
        }, 1000)
      }

    })
    .catch( error => {
      this.messageRetour = {ko: "Une erreur lors de l'enregistrement des données"}
    })

  }


  public dismiss() {
    this.decline();
  }

}
