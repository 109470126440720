import { Component, OnInit } from '@angular/core';

import { BackendService } from '../services/backend/backend.service'
import { GlobalService } from '../services/global/global.service'
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {

  theTeams = []
  aboutAs = []

  president
  equipes = []
  directeurGeneral

  urlImage = environment.urlImage
  
  constructor(
    private backendService: BackendService ,
    private globalService: GlobalService,
  ) { }

  ngOnInit() {
    this.displayPage()
    this.getNous();
  }


  
  displayPage(){
    this.backendService.get('/cguteams/teams' )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
      } else {
        this.theTeams = resultat
        if ( this.theTeams.length <= 6 ) {
          this.president = resultat[0]
          this.directeurGeneral = resultat[5]
          this.theTeams.shift()
          this.theTeams.pop()
          this.equipes = this.theTeams
          this.theTeams = resultat
        } else {
          this.president = resultat[0]
          this.theTeams.shift()
          this.equipes = this.theTeams
          this.theTeams = resultat
        }
      }
    })
    .catch( error => {
      this.theTeams = []
    })
  }


  getNous() {
    this.backendService.get('/contact/footer' )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
      } else {
        this.aboutAs = resultat
      }
    })
    .catch( error => {
    })
  }



}
