import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global/global.service';
import { BackendService } from 'src/app/services/backend/backend.service';

@Component({
  selector: 'app-fidelite',
  templateUrl: './fidelite.component.html',
  styleUrls: ['./fidelite.component.scss']
})
export class FideliteComponent implements OnInit {

  panelOpenState = false;
  userConnected
  numeroFidelite
  paramsContent: any = [];

  constructor( private globalService: GlobalService, private backendService: BackendService ) { 
    this.userConnected = this.globalService.getConnectedUser();
  }

  ngOnInit(): void {
    this.initPage();
    this.getParamsContent();
  }

  initPage() {
    const msahilishoStr = 'MSG';
    this.numeroFidelite = `${this.userConnected.id.padStart(4, '0')} ${msahilishoStr} 021`;
  }


  getPoints() {
    const recommandation = parseFloat(this.userConnected.pointclient[0].recommandation);
    const expedition = parseFloat(this.userConnected.pointclient[0].expedition);
    return (expedition + recommandation).toString().padStart(4, '0');
  }

  getParamsContent() {
    this.backendService.get('/contact/footer' )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.paramsContent = [];
      } else {
        this.paramsContent = resultat[0]
      }
    })
    .catch( error => {
      this.paramsContent = [];
    })
  }

}
