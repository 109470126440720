<div *ngIf="title != ''" class="modal-header">
    <h4 class="modal-title"> {{title}} </h4>
</div>
<div *ngIf="message != ''" class="modal-body">
    {{ message }}
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="decline()">{{ btnCancelText }}</button>
    <button *ngIf="btnRefuserText != ''" type="button" class="btn btn_refuser" (click)="refuser()">{{ btnRefuserText }}</button>
    <button type="button" class="btn btn_default" (click)="accept()">{{ btnOkText }}</button>
</div>