import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {

  // isLoading: Subject<boolean> = this.loaderService.isLoading;
  isLoading: boolean;
  constructor(private loaderService: LoaderService) {
    this.loaderService.isLoading.subscribe((v) => {
      this.isLoading = v;
      console.log(' Loading server afficher avec status ', v )
    });
  }

  ngOnInit() {}

}
