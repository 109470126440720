import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators, FormBuilder, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { GlobalService } from '../../services/global/global.service'

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { InfoDetailService } from '../../modal/info-detail/info-detail.service';

@Component({
  selector: 'app-slider-home',
  templateUrl: './slider-home.component.html',
  styleUrls: ['./slider-home.component.scss']
})
export class SliderHomeComponent implements OnInit {

  searchFormGroup: FormGroup;
  submitted= false

  textToolTip = [
    {
      img: "disponibilite.png",
      title: "Disponibilité",
      text: "<b>On a tous le besoin d’envoyer et/ou recevoir</b> tout type de colis (enveloppe, téléphone, paquet …) depuis la France vers l’Océan Indien et inversement.<br /><br /><b>Vous voulez envoyer ou recevoir un colis ?</b><br /><br />Pas besoin de vous déplacer dans une agence, ni même de supplier un voyageur de l’amener. Msahilisho Import & Export vous propose h24 et 7/7 des disponibilités sur des voyageurs.<br /><br />Il suffit simplement de vous connecter et cliquer sur Expédier un colis."
    },
    {
      img: "handshake.svg",
      title: "Fiabilité",
      text: "<b>Nous disons, l’humain avant tout</b>.<br /><br />Toute une équipe d’experts à vos services et un suivi de votre expédition en temps réel sur votre espace personnel <b><i>Msahilisho Import & Export</i></b> jusqu’à destination.<br /><br /><span>Aucun frais supplémentaire derrière celui négocié ni de frais de Douane (Aéroport) également</span>."
    },
    {
      img: "time.svg",
      title: "Rapidité",
      text: "<b>Nous, on est pas comme les agences traditionnelles</b>:<br /><br />&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;Attendre au moins deux semaines au mieux.<br />&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;Ou pire, colis perdu sans explication.<br /><br />Nous garantissons une livraison en 48h chrono maximum à compter de la date de départ."
    },
    {
      img: "shield2.svg",
      title: "Sécurité",
      text: "<b>La sécurité avant tout</b>.<br /><br />Parce que pour Msahilisho <strong>Tout ce qui est sécurisé à 99% n'est pas sécurisé.</strong><br /><br />Expédiez vos colis en toute simplicité et sérénité.<br /><br />Bien sûr, on n'est jamais à l'abri d'un problème, avec l’option tout risque vous pouvez recevoir la totalité de la valeur de votre colis que vous avez déclarez lors de l’envoi."
    }
  ]

  backgroundImage // = {'background': 'url(assets/imgs/bg/bg-home5.png'};
  
  constructor( 
    private router: Router ,
    private _formBuilder: FormBuilder,
    private globalService: GlobalService,
    private infoDetailService: InfoDetailService
  ) { 

    this.searchFormGroup = this._formBuilder.group({
      villeDepCtrl: ['', Validators.required],
      villeArrCtrl: ['', Validators.required]
    });

  }

  ngOnInit() {
    this.backgroundImage = this.globalService.backgroundImage()
  }


  get f() { 
    return this.searchFormGroup.controls; 
  }


  onSubmit(){
    this.submitted = true

    // stop here if form is invalid
    if (this.searchFormGroup.invalid) {
      return;
    }
    this.globalService.setSearch( {trajet_de: this.f.villeDepCtrl.value , trajet_a: this.f.villeArrCtrl.value} )

    this.router.navigate(['/expedier-ou-recevoir-un-colis']);
    window.scroll(0,0);

  }



  reverseInput(){
    let deBase = {trajet_de: this.f.villeDepCtrl.value , trajet_a: this.f.villeArrCtrl.value}

    this.searchFormGroup.patchValue({
      villeDepCtrl: deBase.trajet_a,
      villeArrCtrl: deBase.trajet_de,
    });

  }


  openDetail( element ) {

    //  this.textToolTip[id]
    this.infoDetailService.confirm( element )
    .then( (confirmed : any ) => {

    })
    .catch( () => {

    })
  }



}
