import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  public urlImage = environment.urlImage
  search = {
    trajet_de: "",
    trajet_a: ""
  }

  pourcentageMS = "20"
  pourcentageGarantie = 0.15;
  sansGarantie = 0.5;

  annoneEnModification = null
  @Output() maskNumTelChanged = new EventEmitter();


  infosProducts = [
    {id: 1, nomproduct: "Téléphone portable", image: "smartphone.svg", prixproduct: "15", partype: "", poids: "0"},
    {id: 2, nomproduct: "Tablette", image: "tablet.svg", prixproduct: "15", partype: "", poids: "0"},
    {id: 3, nomproduct: "Montre", image: "wristwatch.svg", prixproduct: "10", partype: "", poids: "0"},
    {id: 4, nomproduct: "Usb + SD Disk", image: "sdcard.svg", prixproduct: "5", partype: "", poids: "0"},
    {id: 5, nomproduct: "Appareil photo", image: "camera.svg", prixproduct: "18", partype: "", poids: "0"},
    {id: 6, nomproduct: "Ordinateur portable", image: "laptop.svg", prixproduct: "30", partype: "", poids: "0"},
    {id: 7, nomproduct: "Batterie (Externe/Ordi)", image: "battery.svg", prixproduct: "15", partype: "", poids: "0"},
    {id: 8, nomproduct: "Chargeur", image: "charging.svg", prixproduct: "5", partype: "", poids: "0"},
    {id: 9, nomproduct: "Écouteurs", image: "airpods.svg", prixproduct: "5", partype: "", poids: "0"},
    {id: 10, nomproduct: "Disque dur", image: "disk.svg", prixproduct: "10", partype: "", poids: "0"},
    {id: 11, nomproduct: "Parfum (et/ou Coffret)", image: "perfume.svg", prixproduct: "15", partype: "", poids: "0"},
    {id: 12, nomproduct: "Lunette", image: "sunglasses.svg", prixproduct: "10", partype: "", poids: "0"},
    {id: 13, nomproduct: "Or", image: "gold.svg", prixproduct: "2", partype: "", poids: "0"},
    {id: 14, nomproduct: "Argent", image: "money.svg", prixproduct: "2", partype: "100€", poids: "0"},
    {id: 15, nomproduct: "Passeport", image: "passport.svg", prixproduct: "15", partype: "", poids: "0"},
    {id: 16, nomproduct: "Petit format", image: "envelopebig.svg", prixproduct: "10", partype: "", poids: "0"},
    {id: 17, nomproduct: "Grand format", image: "envelope.svg", prixproduct: "15", partype: "", poids: "0"},
    {id: 18, nomproduct: "Kofia", image: "kofia.jpg", prixproduct: "10", partype: "", poids: "0"},
    {id: 19, nomproduct: "Nkandou", image: "nkandou.png", prixproduct: "10", partype: "", poids: "0" },
    {id: 20, nomproduct: "Sandale artisanale", image: "sandales.jpg", prixproduct: "10", partype: "", poids: "0"},
    {id: "autre", nomproduct: "Autre", image: "other.svg", prixproduct: "", partype: "", poids: "0" }
  ];
  
  constructor() { }

  getURLApp() {
    return environment.urlApp
  }


  getConnectedUser(){
    return localStorage.getItem('mshlish_curUser') ? JSON.parse(localStorage.getItem('mshlish_curUser')) : null
  }


  setConnectedUser( user ){
    return localStorage.setItem('mshlish_curUser', JSON.stringify( user ) );
  }

  setSeSouvenir( user ){
    user ? localStorage.setItem('mshlish_seSouvenirUser', JSON.stringify( user ) ) : localStorage.removeItem('mshlish_seSouvenirUser');
  }

  getSeSouvenir(){
    return localStorage.getItem('mshlish_seSouvenirUser') ? JSON.parse(localStorage.getItem('mshlish_seSouvenirUser')) : null
  }


  logOut( ) {
    
    localStorage.removeItem('mshlish_curUser')
    localStorage.removeItem('mshlish_annonceEncours')
  }
  

  setAnnoncdSelected( annone ) {
    localStorage.setItem('mshlish_annonceEncours', JSON.stringify( annone ))
  }

  getAnnoncdSelected() {
    return localStorage.getItem('mshlish_annonceEncours') ? JSON.parse(localStorage.getItem('mshlish_annonceEncours')) : null
  }

  removeAnnoncdSelected() {
    localStorage.removeItem('mshlish_annonceEncours')
  }


  setRemisecentPoints( remise ) {
    localStorage.setItem('mshlish_remisecentPoints', remise)
  }

  getRemisecentPoints() {
    return localStorage.getItem('mshlish_remisecentPoints') ? parseFloat(localStorage.getItem('mshlish_remisecentPoints')): 0;
  }

  setCookiesBrowserMs(){
    let cookies_ = {
      productSub: navigator.productSub,
      userAgent: navigator.userAgent,
      platform: navigator.platform,
      appVersion: navigator.appVersion,
      appName: navigator.appName
    }
    localStorage.setItem('mshlish_cookiesAcceptUser', JSON.stringify( cookies_ ))
  }

  getCookiesBrowserMs(){
    return localStorage.getItem('mshlish_cookiesAcceptUser') ? JSON.parse(localStorage.getItem('mshlish_cookiesAcceptUser')) : null
  }


  setSearch( srch ){
    this.search = srch
  }

  getSearch(){
    return this.search
  }


  setPourcentage( pourcentage ) {
    this.pourcentageMS = pourcentage
  }

  getPourcentage() {
    return this.pourcentageMS
  }


  setAnnoneEnModification(annone) {
    this.annoneEnModification = annone
  }

  getAnnoneEnModification(){
    return this.annoneEnModification
  }


  dateDiff(date1, date2){
      var diff = {sec: null, min: null, hour: null, day: null}                           // Initialisation du retour
      var tmp = date2 - date1;
  
      tmp = Math.floor(tmp/1000);             // Nombre de secondes entre les 2 dates
      diff.sec = tmp % 60;                    // Extraction du nombre de secondes
  
      tmp = Math.floor((tmp-diff.sec)/60);    // Nombre de minutes (partie entière)
      diff.min = tmp % 60;                    // Extraction du nombre de minutes
  
      tmp = Math.floor((tmp-diff.min)/60);    // Nombre d'heures (entières)
      diff.hour = tmp % 24;                   // Extraction du nombre d'heures
      
      tmp = Math.floor((tmp-diff.hour)/24);   // Nombre de jours restants
      diff.day = tmp;
      
      return diff;
  }



  setVisitor( visitor ){
    if( visitor !== undefined && visitor !== null)
      localStorage.setItem('mshlish_visitoXztrP', JSON.stringify( visitor ))
  }

  getVisitor(){
    //
   //  if( localStorage.getItem('mshlish_visitoXztrP') && localStorage.getItem('mshlish_visitoXztrP') !== "undefined" )
    return localStorage.getItem('mshlish_visitoXztrP') && localStorage.getItem('mshlish_visitoXztrP') !== "undefined" ? JSON.parse(localStorage.getItem('mshlish_visitoXztrP')) :  null 
  }


  randomString(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKL.MNOPQRSTUVWXYZabcde-fghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  makeid(length) {
    var result           = '';
    var characters       = '123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }


  getErrorCode(){
    return [
      0, 401, 403, 404, 422, 500, 501, 503, 301, 302 
    ];
  }


  getAeroports(){
    return [
      {name: "CDG Paris", pays: "France", drapeau: "france_.jpg"},
      {name: "ORY Paris", pays: "France", drapeau: "france_.jpg"},
      {name: "MRS Marseille", pays: "France", drapeau: "france_.jpg"},
      {name: "NCE Nice", pays: "France", drapeau: "france_.jpg"},
      {name: "BES Brest-Bretagne", pays: "France", drapeau: "france_.jpg"},
      {name: "RNS Rennes-Bretagne", pays: "France", drapeau: "france_.jpg"},
      {name: "RUN Saint-Denis", pays: "La Réunion", drapeau: "lareunion.png"},
      {name: "DZA Mamoudzou", pays: "Mayotte", drapeau: "mayotte.png"},
      {name: "HAH Moroni", pays: "Comores", drapeau: "comores_.png"},
      {name: "DSS Dakar", pays: "Sénégal", drapeau: "senegal_.png"},
      {name: "IST Istanbul", pays: "Turquie", drapeau: "turquie_.png"},
      {name: "SAW Istanbul", pays: "Turquie", drapeau: "turquie_.png"},
      {name: "ESB Ankara", pays: "Turquie", drapeau: "turquie_.png"},
      {name: "AYT Antalya", pays: "Turquie", drapeau: "turquie_.png"},
      {name: "TUN Tunis", pays: "Tunisie", drapeau: "tunisie_.png"},
      {name: "CMN Nouaceur", pays: "Maroc", drapeau: "maroc_.png"},
      {name: "RAK Marrakech", pays: "Maroc", drapeau: "maroc_.png"},
      {name: "AGA Agadir", pays: "Maroc", drapeau: "maroc_.png"},
      {name: "FEZ Fès", pays: "Maroc", drapeau: "maroc_.png"},
      {name: "TNG Tanger", pays: "Maroc", drapeau: "maroc_.png"}
    ];
  }

  getFrequenceVoyage(){
    return [
      {name: "Occasionnellement", value: "occasionnellement"},
      {name: "Toutes les semaines", value: "toutes_les_semaines"},
      {name: "Tous les mois", value: "tous_les_mois"}
    ]
  }

  getMoyenPaiement(){
    return [
      {name: "Virement", value: "virement"},
      {name: "Espèce", value: "espece"}
    ]
  }

  getTypeObjet(){
    return [
      {name: "Enveloppe", value: "enveloppe"},
      {name: "Colis", value: "colis"}
    ]
  }

  getInfoPlaceholder(){
    return {
      txtEnv: {
        taille: "La taille de votre enveloppe. Grand ou petit format",
        poids: "Le poids approximatif de votre enveloppe. Attention ! Sauf indication, le prix est le même qu'en KG.",
        infos: "Informations sur le l'enveloppe. Décrivez le contenu exact de votre enveloppe. Attention ! L'agent Msahilisho vérifiera son contenu avant de le récupérer."
      },
      txtColi: {
        taille: "La taille de l'objet envoyé en longueur et en largeur: Exemple 25cmX15cm. Si vous ne savez pas la taille, indiquez (Petit colis ou Grand colis)",
        poids: "Poids en KG de votre objet.",
        infos: "Informations sur le colis. Décrivez le contenu exact de votre colis, la valeur de son contenu et la quantité. Attention ! L'agent Msahilisho vérifiera son contenu avant de le récupérer."
      }
    }
  }

  getTypeEnveloppe(){
    return [
      {name: "Enveloppe grand format", value: "Enveloppe grand format"},
      {name: "Enveloppe standard", value: "Enveloppe standard"},
    ]
  }


  backgroundImage(){
    let bgImage = [
      {'background': 'url(assets/imgs/bg/bg-home.jpg)'},
      {'background': 'url(assets/imgs/bg/bg-home1.jpg)'},
      {'background': 'url(assets/imgs/bg/bg-home2.jpg)'},
      {'background': 'url(assets/imgs/bg/bg-home3.jpg)'},
      {'background': 'url(assets/imgs/bg/bg-home4.jpg)'},
      {'background': 'url(assets/imgs/bg/bg-home5.png)'},
    ]

    return bgImage[Math.floor(Math.random() * bgImage.length)];
  }


  getInfosProducts() {
    return this.infosProducts;
  }


  checkInterdi(value : string) {
    return ["msahilisho", "admin", "administrateur", "agent", "voyage", "msahil"].includes( value.toLowerCase() )
  }

  checkIsMsahilisho( value ) {
    return value === "annonce@msahilishogroup.com"
  }



  maskSaisi( tel ) {
    if( tel.substring(0, 1) !== "+" ) {
      tel = '+' + tel;
    }
    console.log( ' telteltel ==== ', tel)
    let maskTel = {mask: "(+00) 0 00 00 00 00", value: "france_.jpg"};
    if( tel.substring(0, 3) === "+33" ) {
      //France
      maskTel.mask = "(+00) 0 00 00 00 00"
      maskTel.value = "france_.jpg"
    } else if( tel.substring(0, 4) === "+269" ) {
      //Comores
      maskTel.mask = "(+000) 000 00 00"
      maskTel.value = "comores_.png"
    } else if( tel.substring(0, 4) === "+221" ){
      //Sénégal
      maskTel.mask = "(+000) 00 000 00 00"
      maskTel.value = "senegal_.png"
    } else if( tel.substring(0, 4) === "+262" ){
      //Mayotte - La réunion
      maskTel.mask = "(+000) 0 00 00 00 00"
      maskTel.value = "france_.jpg"
    } else if( tel.substring(0, 3) === "+90" ){
      // Turquie
      maskTel.mask = "(+00) 00 00 00 00"
      maskTel.value = "turquie_.png"
    } else if( tel.substring(0, 4) === "+212" ){
      // Maroc
      maskTel.mask = "(+000) 00 00 00 00 00"
      maskTel.value = "maroc_.png"
    } else if( tel.substring(0, 4) === "+216" ){
      // Tunisie
      maskTel.mask = "(+000) 00 000 00"
      maskTel.value = "tunisie_.png"
    } else {
      maskTel.mask = "(+00) 0 00 00 00 00"
      maskTel.value = "france_.jpg"
    }

    this.maskNumTelChanged.emit( maskTel );
    return maskTel;

  }

  getListeIndicatifTelephone() {
    return  [
      { pays: 'fr', text: '(+33)', image: 'france_.jpg', mask: "(+00) 0 00 00 00 00" },
      { pays: 'km', text: '(+269)', image: 'comores_.png', mask: "(+000) 000 00 00" },
      { pays: 're', text: '(+262)', image: 'france_.jpg', mask: "(+000) 0 00 00 00 00" },
      { pays: 'sn', text: '(+221)', image: 'senegal_.png', mask: "(+000) 00 000 00 00" },
      { pays: 'ma', text: '(+212)', image: 'maroc_.png', mask: "(+000) 00 00 00 00 00" },
      { pays: 'tr', text: '(+90)', image: 'turquie_.png', mask: "(+00) 00 00 00 00" },
      { pays: 'tn', text: '(+216)', image: 'tunisie_.png', mask: "(+000) 00 000 00" }
    ];
  }

  getIndicatifTelephoneByMask( mask ) {
    const list = this.getListeIndicatifTelephone();
    return list.find(elt => elt.mask === mask);
  }


  getAdresse( utilisateur ) {
    let adresse = '';
    adresse += utilisateur.address ? utilisateur.address + ' ' : '';
    adresse += utilisateur.zipecode ? utilisateur.zipecode + ' ' : '';
    adresse += utilisateur.city ? utilisateur.city + ' ' : '';
    return adresse;
  }

  getDateStringToDate( dateSend ) {
    const dateTrajet = new Date( dateSend );
    dateTrajet.setHours(0,0,0);
    return dateTrajet;
  }

  getListEmplacement() {
    return [
      { id: 1, libelle: 'Chez l\'expéditeur' },
      { id: 2, libelle: 'Remis à Msahilisho'},
      { id: 3, libelle: 'En cours d\'acheminement'},
      { id: 4, libelle: 'Retourner à l\'expéditeur'},
      { id: 5, libelle: 'En point de retrait'},
      { id: 6, libelle: 'Livré'},
    ]
  }

  getExtentionBase64( base64file ) {
    const mimeType = base64file.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
    const extensionFile = mimeType.split('/')
    return extensionFile[1]
  }

  downloadFile(base64String, filename) {
    const source = `${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = filename
    link.click();
  }



}
