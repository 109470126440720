import { Component, OnInit } from '@angular/core';

import { GlobalService } from '../../services/global/global.service'
import { BackendService } from '../../services/backend/backend.service'
import { Router } from '@angular/router';

@Component({
  selector: 'app-mesavis',
  templateUrl: './mesavis.component.html',
  styleUrls: ['./mesavis.component.scss']
})
export class MesavisComponent implements OnInit {

  urlImage
  lesNotes = []
  limite = 0
  avisYes = false
  notOther = false

  constructor(
    private globalService: GlobalService,
    private backendService: BackendService,
    private router: Router
  ) { }

  ngOnInit() {

    if( !this.globalService.getConnectedUser() ) {
      this.router.navigate(['/home']);
      window.scroll(0,0);
    }

    this.urlImage = this.globalService.urlImage
    this.getNotes()
  }


  getNotes() {

    let annonce = {
      idexped: this.globalService.getConnectedUser().id,
      idtrans: "",
      limite: this.limite
    }

    this.backendService.post(`/notes/list/user` , annonce )
    .then( resultat => {

      console.log('------LLLL ', resultat)

      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.lesNotes = []
      } else {

        this.lesNotes = this.lesNotes.concat( resultat.array_notes )
        this.notOther = resultat.array_notes.length < 10 ? true : false

        if( this.lesNotes.length > 0 ) {
          this.avisYes = true
        } else {
          this.avisYes = false
        }
      
      }
      
    })
    .catch( error => {
      this.lesNotes = []
    })

  }


  voirPlus(){
    this.limite = this.lesNotes.length
    this.getNotes()
  }





}
