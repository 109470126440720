import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { HomeModule} from '../home/home.module'
import { SendboxComponent } from './sendbox.component';

import { TopheaderModule} from '../topheader/topheader.module'
import { HeaderModule} from '../header/header.module';
import { FooterModule } from '../footer/footer.module';
import { DetailsAdComponent } from './details-ad/details-ad.component';
import { ChooseAdComponent } from './choose-ad/choose-ad.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialAngModule } from '../matmodule/materialang.module'


import { GlobalService } from '../services/global/global.service'
import { BackendService } from '../services/backend/backend.service'

import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [SendboxComponent, DetailsAdComponent, ChooseAdComponent],
  imports: [
    CommonModule,
    NgbModule,
    HomeModule,
    TopheaderModule,
    HeaderModule,
    FooterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialAngModule,

    NgxMaskModule.forRoot(),
    SharedModule

  ],
  exports:[SendboxComponent],
  providers:  [ 
    BackendService, 
    GlobalService,
    
  ]
})
export class SendboxModule { }

