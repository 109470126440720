<div class="content-app">

    <app-topheader></app-topheader>
 
    <app-header></app-header>


    <!-- Section bar de recherche -->
    <!-- Section bar de recherche -->
    <div class="search-bar-top">
        <img src="assets/imgs/tracking.svg" class="airport-img" /> Éxpédier un colis
    </div>

    <div class="container-app">

        <div class="go-back">
            <button class="" mat-button (click)="goBack()" matTooltip="Retourner à la liste des trajets" > 
                <mat-icon>arrow_back_ios</mat-icon> Retourner à la liste des trajets
            </button>
        </div>


        <span class="title-text"> {{nextTravels.aerodep}} &nbsp;&nbsp;&nbsp;&nbsp; <img src="assets/imgs/double-chevron.svg" class="double-chevron" /> &nbsp;&nbsp;&nbsp;&nbsp; {{nextTravels.aeroarr}} </span>
        <br /><br />
        
        <div class="row">

            <div class="col-md-2 zone-notif">
                <span class="personnes-reg">
                    <i class="material-icons">search</i> <span>{{randomNumber}} personnes</span> sont en train de consulter l'annonce, en ce moment.
                </span>

                <br /><br /><br /><br />
                <!--<label> Partager l'annonce </label>

                <div class="social-links">
                    <a href="#" class="twitter"><i class="fa fa-twitter"></i></a>
                    <a href="#" class="facebook"><i class="fa fa-facebook"></i></a>
                    <a href="#" class="instagram"><i class="fa fa-instagram"></i></a>
                    <a href="#" class="google-plus"><i class="fa fa-google-plus"></i></a>
                    <a href="#" class="linkedin"><i class="fa fa-linkedin"></i></a>
                </div> -->


                <br /><br />
                <label> Entrer en contact </label>
                <button *ngIf="nextTravels.kgdispo > 0" class="btn selectionner" (click)="openPage()" > {{ userConnected ? 'Sélectionner & Continuer' : 'Connexion ou Inscription'}} </button>


                <div *ngIf="nextTravels.kgdispo <= 0" >
                    <span class="plus-de-kg">COMPLET !</span> <br />
                    <img class="img-nkgdispo" src="assets/imgs/sad.svg" />
                </div>

            </div>

            <div class="col-md-8">
             
                <div class="box wow bounceInUp" [ngClass]="nextTravels.kgdispo > 0 ? '' : 'ligh-bg-no-kilos' " >
                    
                    <div class="detail-user">
                        <div class="row">
                            <div class="col-12 date-dep">
                                <i class="fa fa-calendar" aria-hidden="true"></i> <span>{{nextTravels.datedepot | date: 'EE dd MMMM yyyy'}} </span>
                            </div>
                            <div class="col-6 icon-transport">
                                <img src="assets/imgs/plane.svg" class="travel-img" />
                            </div>
                            <div class="col-6 col-lg-3 col-md-3 col-sm-6 ">
                                <!--<ngb-rating [(rate)]="currentRate" max="5" [readonly]="true"></ngb-rating>-->
                                <span *ngFor="let strt of currentRate" class="" >
                                    <span class="material-icons start-rate-icon">star_rate</span>
                                  </span>
                            </div>
                        </div>
                    </div>
    
                    <div class="colis-user">
    
                        
                        <div class="row">
                            <div class="col-md-6">
                                <div class="txt-mute-petit">Date de départ prévue</div>
                                <span class="text-colis"><img src="assets/imgs/boarding.svg" class="airport-img" /> ({{ nextTravels.datedepot | date: 'EE dd MMMM yyyy'}})</span><br /><br />
                                <img src="assets/imgs/airport.svg" class="airport-img" />
                                <span class="airport-text">{{nextTravels.aerodep}}</span>
                            </div>
                            <div class="col-md-6">
                                <div class="txt-mute-petit">Date d’arrivée prévue</div>
                                <span class="text-colis"><img src="assets/imgs/waiting-room.svg" class="airport-img" /> ({{ nextTravels.daterecup | date: 'EE dd MMMM yyyy'}})</span><br /><br />
                                <img src="assets/imgs/airport.svg" class="airport-img" />
                                <span class="airport-text">{{nextTravels.aeroarr}}</span>
                            </div>
                        </div>

                        <div class="row paddingrow">

                        </div>
    
                    
                        <div class="row">
                            <div class="col-6 col-md-6 ">
                                <span class="text-colis">Prix /kg</span><br />
                                <img src="assets/imgs/euro.svg" class="airport-img" />
                                <span class="airport-text">{{nextTravels.prixkg}} {{nextTravels.devise}}</span>
                            </div>
                            <div class="col-6 col-md-6">
                                <span class="text-colis">Nombre de kilos disponibles</span><br />
                                <img src="assets/imgs/box.svg" class="airport-img" />
                                <span class="airport-text">{{nextTravels.kgdispo}} KG</span>
                                <span *ngIf="nextTravels.kgdispo <= 0" class="plus-de-kg">COMPLET !</span>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-12 col-md-12 grille-tarifaire">
                                Grille tarifaire de Msahilisho Group sur les produits non pesables
                            </div>
                        </div>

                        <div class="row msahilisho-div">
                            <div *ngFor="let pdt of infosProducts" class="col-6 col-md-3 col-lg-3 col-sm-12 col-div">
                                <div class="nom-produit">{{pdt.nomproduct}}</div>
                                <div class="icon-produit"> <img src="assets/imgs/icon-produit/{{pdt.image}}" ></div>
                                <div class="prix-produit" *ngIf="pdt.prixproduct != ''" > 
                                    {{pdt.nomproduct === 'Argent' ? pdt.prixproduct + '€' : '15% v.'}}
                                    <span *ngIf="pdt.partype != ''" class="parType" >/{{pdt.partype}} </span>
                                </div>
                            </div>
                        </div>
    
                    </div>
    
    
    
                </div>

            </div>

            <div class="col-md-2 zone-user">

                <div class="agent-msahilisho" *ngIf="nextTravels.msahilisho != '0'" >
                    <div class="titre-agent">Agent Msahilisho Group</div>
                    <div class="image-logo">
                        <img src="assets/imgs/msahilisho.png" />
                    </div>
                </div>

                <img [src]="nextTravels.user_suggest.image64 | safeHtml" > 
                <br /><br /><br />
                <h4 class="title">
                    <a href="">{{nextTravels.user_suggest.username}} !</a>
                </h4>

                <div class="info-user">
                    <div class="row">
                        <div class="col-6 col-md-6 icon-user-right" [ngClass]="nextTravels.user_suggest.email != '' ? 'check-icon' : 'uncheck-icon'" >
                            <i class="material-icons">check_circle</i> <span class="material-icons">contact_mail</span> 
                        </div>
                        <div class="col-6 col-md-6 icon-user-right" [ngClass]="nextTravels.user_suggest.telephone != '' ? 'check-icon' : 'uncheck-icon'">
                            <i class="material-icons">check_circle</i><span class="material-icons icon-user-right">contact_phone</span> 
                        </div>
                        <div class="col-6 col-md-6 icon-user-right" [ngClass]="nextTravels.user_suggest.address != '' ? 'check-icon' : 'uncheck-icon'">
                            <i class="material-icons">check_circle</i><span class="material-icons">location_city</span> 
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
    



    <app-whychoose-home></app-whychoose-home> 
     
    
    <app-footer></app-footer>

    
</div>