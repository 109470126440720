import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { BackendService } from '../services/backend/backend.service'
import { GlobalService } from '../services/global/global.service'
import { ValidatorrService } from '../services/validatorr/validatorr.service'
@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {


  loginFormGroup: FormGroup;
  submitted
  resultMessage = {ok: null, ko: null}


  constructor( 
    private backendService: BackendService ,
    private _formBuilder: FormBuilder,
    private router: Router ,
    private globalService: GlobalService,
  ) { }

  ngOnInit() {

    if( this.globalService.getConnectedUser() ) {
      this.router.navigate(['/home']);
      window.scroll(0,0);
    }

    this.loginFormGroup = this._formBuilder.group({
      emailCtrl: ['', [Validators.required , ValidatorrService.emailValidator] ]
    });

  }


  get f() { 
    return this.loginFormGroup.controls; 
  }


  onSubmit(){

    this.submitted = true;
    this.resultMessage = {ok: null, ko: null}

    // stop here if form is invalid
    if (this.loginFormGroup.invalid) {
      return;
    }

    let user = {
      email: this.loginFormGroup.controls['emailCtrl'].value
    }

    this.backendService.put('/users/reinit-password', user )
    .then( resultat => {

      
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.resultMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      } else {
        this.resultMessage.ok = resultat.text
      }

    })
    .catch( error => {
      this.resultMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      
    })
    
  }


  seConnecter() {
    this.router.navigate(['/login']);
    window.scroll(0,0);
  }

}
