<div class="content-app je-veux-voyager">
    <app-topheader></app-topheader>
 
    <app-header></app-header>

    <div class="search-bar-top">
        <img src="assets/imgs/tourist.png" class="airport-img" /> Je veux voyager
    </div>


    <div class="je-veux-voyager-content">


        <div class="information">
            <i class="material-icons">warning</i> <i class="material-icons">warning</i> <i class="material-icons">warning</i>
            Pour offrir une meilleure qualité du service <span>Msahilsho Import & Export</span>, soyez le plus concis et le plus précis possible sur les informations renseignées.
            
            <div class="text-center" *ngIf="isIE() " >
                <b> Attention ! </b> Certains paramètres de votre navigateur internet ne sont pas compatible avec cette version de Msahilisho Import & Export.
            </div>
        </div>


        <mat-horizontal-stepper [linear]="isLinear" #stepper class="stepPage">
            <mat-step [stepControl]="infosPersoFormGroup" errorMessage="(*) obligatoires." >
                <form [formGroup]="infosPersoFormGroup">
                    <ng-template matStepLabel>Informations personnelles </ng-template>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group" >
                                <label for="nomCtrl">Nom  <span>(*)</span></label>
                                <legend>Nom de famille au complet tel qu'indiqué sur votre passeport</legend>
                                <input id="nomCtrl" type="text" class="form-control" formControlName="nomCtrl" placeholder="Veuillez renseigner votre nom de famille" [ngClass]="{ 'is-invalid': submitted && f1.nomCtrl.errors }">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group" >
                                <label for="prenomCtrl">Prénom  <span>(*)</span></label>
                                <legend>Prénom au complet tel qu'indiqué sur votre passeport</legend>
                                <input id="prenomCtrl" type="text" class="form-control" formControlName="prenomCtrl" placeholder="Veuillez renseigner votre prénom" [ngClass]="{ 'is-invalid': submitted && f1.prenomCtrl.errors }">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group" >
                                <label for="numTelCtrl">Numéro de téléphone  <span>(*)</span></label>
                                <legend>Numéro de téléphone sur lequel vous êtes joignable jusqu'à votre départ. Au format {{maskTel.mask}} </legend>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <button class="btn btn-outline-secondary dropdown-toggle" type="button"  
                                            aria-haspopup="true" aria-expanded="false" [matMenuTriggerFor]="menuVoy" > 
                                            <img src="assets/imgs/{{maskTel.value}}" width="20" >
                                        </button>
                                        <mat-menu #menuVoy="matMenu" class="img-select-menu" >
                                          <button *ngFor="let ntl of listTelephone" mat-menu-item (click)="telFormat( ntl )" >
                                              <img src="assets/imgs/{{ntl.image}}" width="30" > &nbsp; {{ntl.text}}
                                          </button>
                                        </mat-menu>
                                    </div>

                                    <input [validation]="true" [showMaskTyped]="true" mask="{{maskTel.mask}}" id="numTelCtrl" 
                                    type="text" class="form-control" formControlName="numTelCtrl" 
                                    placeholder="Votre numéro de téléphone" [ngClass]="{ 'is-invalid': submitted && f1.numTelCtrl.errors }" >
                                </div>

                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group" >
                                <label for="emailCtrl">E-mail  <span>(*)</span></label>
                                <legend>Adresse mail à laquelle vous avez accès.</legend>
                                <input id="emailCtrl" type="text" class="form-control" formControlName="emailCtrl" placeholder="Veuillez renseigner une adresse mail" [ngClass]="{ 'is-invalid': submitted && f1.emailCtrl.errors }">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group" >
                                <label for="importFile" #labelImport class="cursor" > <i class="material-icons">  search </i> {{urlSelectJustif.msg}} (facultatif)  <span>Téléchargez votre pièce d'identité</span></label>
                                <legend>Pour lutter contre les fraudes, un justificatif prouvant votre identité est nécessaire. 
                                    Rassure-vous, ce document ne sera pas publié sur notre plateforme. Il permet de valider la prise en charge de votre demande par notre équipe commerciale.
                                 </legend>
                                 <input type="file" class="custom-file-input" formControlName="importFile" id="importFile" (change)="onFileChange($event)" [ngClass]="{ 'is-invalid': submitted && f1.importFile.errors }">
                            </div>
                        </div>
                    </div>

                    <div>
                        <button (click)="changeBTN('infosPerso')" class="nextLevel" mat-button matStepperNext>Étape suivante</button>
                        <br/><br /><span class="champObligatoire">Attention ! Les champs avec (*) sont obligatoires </span>
                    </div>
                </form>
            </mat-step>

            <mat-step [stepControl]="infosVoyageFormGroup" errorMessage="(*) obligatoires."  >
                <form [formGroup]="infosVoyageFormGroup" >
                    <ng-template matStepLabel>Informations sur le voyage </ng-template>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group" >
                                <label for="aeroportDepCtrl">Départ de  <span>(*)</span></label>
                                <legend>Aéroport de départ souhaité</legend>
                                <mat-select id="aeroportDepCtrl" formControlName="aeroportDepCtrl" class="form-control" placeholder="Départ à " [ngClass]="{ 'is-invalid': submitted && f2.aeroportDepCtrl.errors }" >
                                    <mat-option *ngFor="let aero of aeroportList" [value]="aero" class="optionList">
                                    {{aero.name}} <img src="assets/imgs/{{aero.drapeau}}" width="30"  >
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
    
                        <div class="col-md-6">
                            <div class="form-group" >
                                <label for="aeroportArrCtrl">Arrivée à  <span>(*)</span></label>
                                <legend>Aéroport de destination souhaité</legend>
                                <mat-select id="aeroportArrCtrl" formControlName="aeroportArrCtrl" class="form-control" placeholder="Arrivée à " [ngClass]="{ 'is-invalid': submitted && f2.aeroportArrCtrl.errors }" >
                                    <mat-option *ngFor="let aero of aeroportList" [value]="aero" class="optionList">
                                    {{aero.name}} <img src="assets/imgs/{{aero.drapeau}}" width="30"  >
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group" >
                                <div class="input-group">
                                    <label for="dateDepartCtrl">Date de départ  <span>(*)</span></label>
                                    <legend>
                                        La date de départ de votre voyage.
                                        <div *ngIf="submitted && infosVoyageFormGroup.errors" class="div-error" >
                                            {{ infosVoyageFormGroup.errors?.dates }}
                                        </div>
                                    </legend>
                                    <input [showMaskTyped]="true" mask="00/00/0000" id="dateDepartCtrl" type="text" class="form-control" formControlName="dateDepartCtrl" placeholder="dd/mm/yyyy"  [ngClass]="{ 'is-invalid': submitted && f2.dateDepartCtrl.errors }"  >
                                </div>
                            </div>
                        </div>
    
                        <div class="col-md-6">
                            <div class="form-group" >
                                <div class="input-group">
                                    <label for="dateArriveeCtrl">Date de retour  <span>(*)</span></label>
                                    <legend>
                                        La date de votre retour.
                                        <div *ngIf="submitted && infosVoyageFormGroup.errors" class="div-error" >
                                            {{ infosVoyageFormGroup.errors?.dates }}
                                        </div>
                                    </legend>
                                    <input [showMaskTyped]="true" mask="00/00/0000" id="dateArriveeCtrl" type="text" class="form-control" formControlName="dateArriveeCtrl" placeholder="dd/mm/yyyy"  [ngClass]="{ 'is-invalid': submitted && f2.dateArriveeCtrl.errors }"  >
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <br /><br />
                        <button class="prevLevel" mat-button matStepperPrevious>Étape précédente</button>
                        <button (click)="changeBTN('infosVoyage')" class="nextLevel" mat-button matStepperNext>Étape suivante</button>
                        <br/><br /><span class="champObligatoire">Attention ! Les champs avec (*) sont obligatoires </span>
                    </div>

                </form>
            </mat-step>

            <mat-step errorMessage="(*) obligatoires."  >
                <form [formGroup]="confiramtionFormGroup" >
                    <ng-template matStepLabel>Confirmation et validation des CGU </ng-template>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group" >
                                <label for="commentCtrl">Complément d’informations </label>
                                <legend>Toutes informations que vous jugerez utiles au traitement de votre dossier. Merci de nous préciser votre flexibilité sur les dates proposées. </legend>
                                <textarea class="form-control" id="commentCtrl" rows="3" formControlName="commentCtrl" placeholder="Complément d’informations" [ngClass]="{ 'is-invalid': submitted && f3.commentCtrl.errors }"></textarea>
                            </div>
    
                        </div>
                    </div>

                    <div class="condition">
                        <div class="div-progress" *ngIf="progressMessage.progress">
                            <span> {{progressMessage.progress}} %</span> effectués
                        </div>
                        <div class="col-md-12 div-success" *ngIf="progressMessage.ok" >
                            {{progressMessage.ok}}
                            <p class="redirection_auto">
                                <br />
                                Vous allez être redirigé automatiquement dans quelques secondes.
                            </p>
                        </div>
    
                        <div class="col-md-12 div-error" *ngIf="progressMessage.ko" >
                            {{progressMessage.ko}}
                        </div>
                        <br /><br />

                        <div *ngIf="!progressMessage.ok">
                            <mat-checkbox formControlName="confirmationCtrl" [ngClass]="{ 'is-invalid': submitted && f3.confirmationCtrl.errors }"  > </mat-checkbox> <span>En cliquant sur le bouton Valider, vous indiquez avoir pris connaissance et accepté</span>
                            <a class="cursor" (click)="openCGU()">les Conditions Générales d'Utilisation.</a>

                            <br />
                            <button class="validBtn" mat-button (click)="publier()" >Valider et envoyer votre demande</button>
                        </div>
                        
                    </div>

                    <div>
                        <button class="prevLevel" mat-button matStepperPrevious>Étape précédente</button>
                    </div>
                </form>
            </mat-step>
        </mat-horizontal-stepper>


    </div>



    <app-whychoose-home></app-whychoose-home> 
     
    
    <app-footer></app-footer>

</div>
