<div class="titre" mat-dialog-title> {{data.titre}} </div>
<div mat-dialog-content>
  <p> {{data.message }} </p>
  <p *ngIf="data.autreMessage" > {{data.autreMessage }} </p>
  <p *ngIf="data.autreMessage2" > {{data.autreMessage2 }} </p>
  <p *ngIf="data.autreMessage3" > {{data.autreMessage3 }} </p>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Annuler</button>
  <button class="selectionner" mat-button [mat-dialog-close]="'oui'" cdkFocusInitial>Valider</button>
</div>