import { Injectable } from '@angular/core';
import { Output , EventEmitter } from '@angular/core';

import { ToastrService } from 'ngx-toastr'; 

import { Subject, Observable , BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class EventsService {

  @Output() pageEmitter = new EventEmitter();
  @Output() userConnectEmitter = new EventEmitter();
  @Output() majTokenUser = new EventEmitter();
  @Output() autoLogout = new EventEmitter();

  private userLoggedIn = new Subject<boolean>();


  constructor(
    private toastr: ToastrService 
  ) { 
    this.userLoggedIn.next(false);
  }

  onPageSelected( page ){
    this.pageEmitter.emit( page )
  }

  onUserConnected( user ){
    this.userConnectEmitter.emit( user )
  }

  onUserDeconnected() {
    this.userConnectEmitter.emit( null )
  }

  onMajToken(){
    this.majTokenUser.emit()
  }

  onAutoLogout( message ){
    this.autoLogout.emit( message )
  }




  successmsg(message){  
      this.toastr.success(message,'Success')  
  }  

  errorsmsg(message){  
    this.toastr.error(message,'Error')  
  }  

  infotoastr(message){  
    this.toastr.info(message, 'Information');  
  }

  toastrwaring(message){  
    this.toastr.warning(message, 'Warning');  
  }



  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }



}
