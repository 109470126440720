import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global/global.service'
import { Router } from '@angular/router';

import { EventsService } from '../../services/events/events.service'

@Component({
  selector: 'app-comment-home',
  templateUrl: './comment-home.component.html',
  styleUrls: ['./comment-home.component.scss']
})
export class CommentHomeComponent implements OnInit {

  userConnected
  constructor(
    private globalService: GlobalService,
    private router: Router,
    private eventsService: EventsService
  ) { 
    this.eventsService.userConnectEmitter.subscribe( user => {
      this.isConnectedUser()
    })
  }

  ngOnInit() {
    this.isConnectedUser()
  }

  isConnectedUser() {
    this.userConnected = this.globalService.getConnectedUser()
  }

  openPage(page) {
    this.router.navigate([page]);
    window.scroll(0,0);
  }

}
