<div class="row dashbord-right">

    <div class="col-12 col-md-3 profile-user upload ">


        <label class="hoverable" for="fileInput">
            <img [src]="urlSelectPhoto.view ? urlSelectPhoto.view : getAvatarUrlBase()" > 
            <div class="hover-text">Choisir photo</div>
            <div class="background"></div>
        </label>
        
        <input id="fileInput" type='file' (change)="onSelectFile($event)">

        <button *ngIf="urlSelectPhoto.view" type="button" class="btn btn-valider" (click)="uploadFile()" > Enregistrer </button> &nbsp; &nbsp; &nbsp;
        <button *ngIf="urlSelectPhoto.view" (click)="deleteSelectFile()" type="button" class="btn btn-warning" aria-label="Supprimer le fichier " >
            Annuler
        </button>


        <div class="div-progress" *ngIf="progressMessage.progress">
            <span> {{progressMessage.progress}} %</span> effectués
        </div>

        <div class="col-md-12 div-success" *ngIf="progressMessage.ok" >
            {{progressMessage.ok}}
        </div>

        <div class="col-md-12 div-error" *ngIf="progressMessage.ko" >
            {{progressMessage.ko}}
        </div>
        



        <br />
        <div class="legend-image">
            Pour des raisons de confidentialité, les photos représentant votre propre visage ne sont pas admises sur Msahilisho Import & export.
        </div>
    </div>


    <div class="col-12 col-md-9">

        <mat-tab-group mat-align-tabs="center" >

            <mat-tab label="Données personnelles"> 

                <form [formGroup]="infosFormGroup" (ngSubmit)="onSubmit()" >
                    <span class="infoPersonnelle">Données personnelles  </span>
        
        
                    <div class="row">
                        <div class="col-md-6">
        
                            <div class="form-group" >
                                <label for="nomCtrl">Civilité  <span>(*)</span></label>
                                <legend>Seul votre sexe est visible sur votre profil public</legend>
        
                                <mat-select id="sexeCtrl" formControlName="sexeCtrl" class="form-control" placeholder="Vous êtes ? " [ngClass]="{ 'is-invalid': submitted && f.sexeCtrl.errors }" >
                                    <mat-option *ngFor="let cvl of civilites" [value]="cvl.name">
                                    {{cvl.name}}
                                    </mat-option>
                                </mat-select>
        
                            </div>
        
                        </div>
        
                        <div class="col-md-6">
        
                            <div class="form-group" >
        
                                <div class="input-group">
                                    <label for="datenaissCtrl">Date de naissance  <span>(*)</span></label>
                                    <legend>Votre date de naissance est privée et n'est utilisée qu'à des fins d'analyse.</legend>
                                    <input [validation]="true" [showMaskTyped]="true" mask="00/00/0000" class="form-control" placeholder="dd/mm/yyyy"  id="datenaissCtrl" formControlName="datenaissCtrl"  type="text" [ngClass]="{ 'is-invalid': submitted && f.datenaissCtrl.errors }" >
                                </div>
                                
                            </div>
        
                        </div>
                    </div>
        
        
                    <div class="row">
                        <div class="col-md-12">
                            <legend>Seuls votre prénom et la première lettre de votre nom sont visibles sur votre profil public. </legend>
                        </div>
                        <div class="col-md-6">
        
                            <div class="form-group" >
                                <label for="nomCtrl">Nom  <span>(*)</span></label>
                                <legend>Votre nom de famille tel qu'écrit dans votre pièce d'identité.</legend>
                                <input id="nomCtrl" type="text" class="form-control" formControlName="nomCtrl" placeholder="Votre nom de famille" required [ngClass]="{ 'is-invalid': submitted && f.nomCtrl.errors || nameError }"  >
                            </div>
        
                        </div>
        
                        <div class="col-md-6">
        
                            <div class="form-group" >
                                <label for="prenomCtrl">Prénom  <span>(*)</span></label>
                                <legend>Votre prénom tel qu'écrit dans votre pièce d'identité.</legend>
                                <input id="prenomCtrl" type="text" class="form-control" formControlName="prenomCtrl" placeholder="Votre prénom" required [ngClass]="{ 'is-invalid': submitted && f.prenomCtrl.errors }"  >
                            </div>
        
                        </div>
                    </div>
        
        
                    <div class="row">
        
                        <div class="col-md-12">
                            <legend><!--Votre numéro de téléphone. Rassurez-vous, ils n'est ni visbile ni sera communiqué aux autres utilisateurs. Il est pour les agents Msahilisho pour qu'ils puissent prendre contact avec vous.-->
                                votre numéro de téléphone, votre adresse e-mail ainsi que l'adresse de votre lieu de résidence sont des informations confidentielles. Rassurez-vous, elles ne seront ni affichées publiquement, ni communiquées aux autres utilisateurs.
                            </legend>
                        </div>
                        <div class="col-md-6">
        
                            <div class="form-group" >
                                <label for="telephoneCtrl">Téléphone  <span>(*)</span></label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <button class="btn btn-outline-secondary dropdown-toggle" type="button"  
                                            aria-haspopup="true" aria-expanded="false" [matMenuTriggerFor]="menuProfil" > 
                                            <img src="assets/imgs/{{maskTel.value}}" width="20" >
                                        </button>
                                        <mat-menu #menuProfil="matMenu" class="img-select-menu" >
                                          <button *ngFor="let ntl of listTelephone" mat-menu-item (click)="telFormat( ntl )" >
                                              <img src="assets/imgs/{{ntl.image}}" width="30" > &nbsp; {{ntl.text}}
                                          </button>
                                        </mat-menu>
                                    </div>

                                    <input [validation]="true" [showMaskTyped]="true" mask="{{maskTel.mask}}" 
                                    id="telephoneCtrl" type="text" class="form-control" formControlName="telephoneCtrl" 
                                    placeholder="Votre numéro de téléphone" required 
                                    [ngClass]="{ 'is-invalid': submitted && f.telephoneCtrl.errors }" >
                                </div>
                            </div>
        
                        </div>
        
                        <div class="col-md-6">
        
                            <div class="form-group" >
                                <label for="emailCtrl">E-mail  <span>(*)</span></label>
                                <!--<legend>Rassurez-vous, il n'est ni visible ni sera communiqué aux autres utilisateurs.</legend>-->
                                <input id="emailCtrl" type="email" class="form-control" formControlName="emailCtrl" placeholder="Votre e-mail" required [ngClass]="{ 'is-invalid': submitted && f.emailCtrl.errors }"  >
                            </div>
        
                        </div>
                    </div>
        
        
                    <div class="row">
                        <div class="col-md-6">
        
                            <div class="form-group" >
                                <label for="adresseCtrl">Adresse  <span>(*)</span></label>
                                <!--<legend>Votre adresse de votre lieu de résidence. Rassure-vous, elle ne sera pas affichée publiquement</legend>-->
                                <input id="adresseCtrl" type="text" class="form-control" formControlName="adresseCtrl" placeholder="Votre adresse de votre lieu de résidence." required [ngClass]="{ 'is-invalid': submitted && f.adresseCtrl.errors }"  >
                            </div>
        
                        </div>

                        <div class="col-md-2">
                            <div class="form-group" >
                                <label for="codePCtrl">Code P </label>
                                <input id="codePCtrl" type="text" class="form-control" formControlName="codePCtrl" placeholder="CP - BP" >
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group" >
                                <label for="villeCtrl">Ville  </label>
                                <input id="villeCtrl" type="text" class="form-control" formControlName="villeCtrl" placeholder="Ville de résidence" required [ngClass]="{ 'is-invalid': submitted && f.villeCtrl.errors }"  >
                            </div>
                        </div>
        
                    </div>
                    
        
                    <div class="row margin-bottom-20" *ngIf="messageRetour" >
        
                        <div class="col-md-12 div-error" *ngIf="messageRetour.ko" >
                            {{messageRetour.ko}}
                        </div>
        
                        <div class="col-md-12 div-success" *ngIf="messageRetour.ok" >
                            {{messageRetour.ok}}
                        </div>
        
                    </div>
        
        
                    <div>
                      <button class="nextLevel" type="submit" mat-button >Valider et enregistrer</button>
                    </div>
        
                </form>

            </mat-tab>

            <mat-tab label="Mot de passe "> 

                <form [formGroup]="passwordFormGroup" (ngSubmit)="onSubmitPassword()" >
                    <span class="infoPersonnelle">Modifier votre mot de passe   </span>
        
                    <div class="row">
                        <div class="col-md-6">
        
                            <div class="form-group" >
                                <label for="telephoneCtrl">Mot de passe actuel  <span>(*)</span></label>
                                <legend>Votre mot de passe actuel</legend>
                                <input id="mdpActuCtrl" type="password" class="form-control" formControlName="mdpActuCtrl" placeholder="Votre mot de passe actuel" required [ngClass]="{ 'is-invalid': submittedPasse && f1.mdpActuCtrl.errors }"  >
                            </div>
        
                        </div>

                    </div>

                    <div class="row">

                        <div class="col-md-6">
        
                            <div class="form-group" >
                                <label for="mdpNewCtrl">Nouveau mot de passe  <span>(*)</span></label>
                                <legend>Votre nouveau mot de passe </legend>
                                <input id="mdpNewCtrl" type="password" class="form-control" formControlName="mdpNewCtrl" placeholder="Votre nouveau mot de passe" required [ngClass]="{ 'is-invalid': submittedPasse && f1.mdpNewCtrl.errors }"  >
                            </div>
        
                        </div>
        
                        <div class="col-md-6">
        
                            <div class="form-group" >
                                <label for="mdpNewConfCtrl">Confirmer votre mot de passe  <span>(*)</span></label>
                                <legend>Confirmer votre mot de passe</legend>
                                <input id="mdpNewConfCtrl" type="password" class="form-control" formControlName="mdpNewConfCtrl" placeholder="Confirmer votre nouveau mot de passe" required [ngClass]="{ 'is-invalid': submittedPasse && f1.mdpNewConfCtrl.errors }"  >
                            </div>
        
                        </div>
                    </div>
        
        
                    <div class="row margin-bottom-20" *ngIf="_messageRetour" >
        
                        <div class="col-md-12 div-error" *ngIf="_messageRetour.ko" >
                            {{_messageRetour.ko}}
                        </div>
        
                        <div class="col-md-12 div-success" *ngIf="_messageRetour.ok" >
                            {{_messageRetour.ok}}
                        </div>
        
                    </div>
        
        
                    <div>
                      <button class="nextLevel" type="submit" mat-button >Valider et enregistrer</button>
                    </div>
        
                </form>
                 
            </mat-tab>

        </mat-tab-group>

    </div>


</div>