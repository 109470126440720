import { Component, OnInit } from '@angular/core';

import { BackendService } from '../services/backend/backend.service'
import { GlobalService } from '../services/global/global.service'

@Component({
  selector: 'app-confidentialite',
  templateUrl: './confidentialite.component.html',
  styleUrls: ['./confidentialite.component.scss']
})
export class ConfidentialiteComponent implements OnInit {

  cguInfos = []
  constructor(
    private backendService: BackendService ,
    private globalService: GlobalService,
  ) { }

  ngOnInit() {
    this.displayPage()
  }


  displayPage(){

    this.backendService.get('/cguteams/poconf/1' )
    .then( resultat => {

      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.cguInfos = []
      } else {
        this.cguInfos = resultat
      }
      
    })
    .catch( error => {
      this.cguInfos = []
    })

  }

}
