import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators, FormBuilder} from '@angular/forms';


import { BackendService } from '../services/backend/backend.service'
import { GlobalService } from '../services/global/global.service'
import { EventsService } from '../services/events/events.service'
import { ValidatorrService } from '../services/validatorr/validatorr.service'

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  submitted = false

  contactFormGroup: FormGroup;
  retourMessage = { ok: null, ko: null}

  footerInfos = {
    nom: "Msahilisho Group",
    email: "contact@msahilishogroup.com",
    telephone: "(+33) 06 58 74 50 24",
    adresse: "24 rue de Verdun, 91800 Brunoy",
    logo: "logo.png",
    telcomores: "",
    adressecomores: "",
    maps: ""
  }


  constructor(
    private _formBuilder: FormBuilder,
    private backendService: BackendService ,
    private globalService: GlobalService,
    private eventsService: EventsService
  ) { }

  ngOnInit() {

    this.contactFormGroup = this._formBuilder.group({
      nameCtrl: [ this.globalService.getConnectedUser() ? `${this.globalService.getConnectedUser().firstname} ${this.globalService.getConnectedUser().lastname[0]}` : '', Validators.required],
      emailCtrl: [this.globalService.getConnectedUser() ? this.globalService.getConnectedUser().email : '', [Validators.required , ValidatorrService.emailValidator] ],
      sujetCtrl: ['', Validators.required],
      messageCtrl: ['', Validators.required]
    });
    this.getFooterContent()

  }


  getFooterContent() {

    this.backendService.get('/contact/footer' )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
      } else {

        
        this.footerInfos = resultat[0]
      }
    })
    .catch( error => {
    })

  }



  get f() { 
    return this.contactFormGroup.controls; 
  }


  onSubmit(){
    this.submitted = true;
    this.retourMessage.ko = null;
    this.retourMessage.ok = null;

    if (this.contactFormGroup.invalid) {
      return;
    }

    let contact = {
      name: this.f.nameCtrl.value,
      email: this.f.emailCtrl.value,
      subject: this.f.sujetCtrl.value,
      message: this.f.messageCtrl.value,
    }

    this.backendService.post('/contact/contact-us', contact )
    .then( resultat => {

      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        //this.eventsService.errorsmsg( "Erreur lors de l'envoi de votre demande." )
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      } else {

        this.retourMessage.ok = resultat.text
        this.eventsService.successmsg("Votre message a été bien reçu")

        setTimeout( () => {
          
          this.contactFormGroup.patchValue( {
            messageCtrl: "",
            sujetCtrl: ""
          } )

        }, 2000)
          
      }

    })
    .catch( error => {
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      
    })



  }

}
