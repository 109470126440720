import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators, FormBuilder, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';

import { DateformatService } from '../matmodule/dateformat.service'
import { GlobalService } from '../services/global/global.service'
import { BackendService } from '../services/backend/backend.service'
import { EventsService } from '../services/events/events.service'
import { ValidatorrService } from '../services/validatorr/validatorr.service'

import {
  NgbCalendar,
  NgbDateAdapter,
  NgbDateStruct,
  NgbDateParserFormatter,
  NgbDate
} from '@ng-bootstrap/ng-bootstrap';
import { from } from 'rxjs';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],

  providers: [
    {provide: NgbDateParserFormatter, useClass: DateformatService}
  ]
  
})
export class ProfileComponent implements OnInit {

  infosFormGroup: FormGroup;
  passwordFormGroup: FormGroup;

  userConnected
  civilites = [
    {name: "Mr"}, {name: "Mme"}, {name: "Autre"}
  ]
  submitted = false
  submittedPasse = false

  messageRetour = {ok: null, ko: null}
  _messageRetour = {ok: null, ko: null}
  nameError = false


  urlSelectPhoto = { url: '', msg: 'Photo de profil' , view: ""}
  progressMessage = {progress: null, ok: null, ko: null}
  maskTel = {mask: "(+00) 0 00 00 00 00" , value: "france.png"}
  listTelephone = [];


  constructor(
    private _formBuilder: FormBuilder,
    private eventsService: EventsService,
    private globalService: GlobalService,
    private backendService: BackendService,
    private router: Router
  ) {}


  ngOnInit() {
    this.listTelephone = this.globalService.getListeIndicatifTelephone();
    this.displayPage()
  }


  displayPage() {

    this.userConnected = this.globalService.getConnectedUser()

    this.infosFormGroup = this._formBuilder.group({
      nomCtrl: [this.userConnected.lastname, Validators.required],
      prenomCtrl: [this.userConnected.firstname, Validators.required],
      telephoneCtrl: [this.userConnected.telephone, Validators.required],
      emailCtrl: [this.userConnected.email, [Validators.required , ValidatorrService.emailValidator] ],
      adresseCtrl: [this.userConnected.address, Validators.required],
      codePCtrl: [this.userConnected.zipecode], // Validators.required
      villeCtrl: [this.userConnected.city, Validators.required],
      passwordCtrl: [''],
      sexeCtrl: [this.userConnected.civilite, Validators.required],
      datenaissCtrl: [ this.unFormateDate() ]
    });

    this.passwordFormGroup = this._formBuilder.group({
      mdpActuCtrl: ['', Validators.required],
      mdpNewCtrl: ['', Validators.required],
      mdpNewConfCtrl: ['', Validators.required]
    });
    
  }


  get f() { 
    return this.infosFormGroup.controls; 
  }

  get f1() { 
    return this.passwordFormGroup.controls; 
  }

  profilIncomplet() {
    if (this.userConnected.address === '' || this.userConnected.telephone === '' || this.userConnected.city === '') {
      return true;
    }
    return false;
  }

  onSubmit() {

    this.submitted = true
    this.nameError = false
    this.messageRetour = {ok: null, ko: null}

    console.log( this.infosFormGroup)
    console.log( this. maskTel )

    console.log( this.maskTel.mask.length , this.f.telephoneCtrl.value.length)
    
    // stop here if form is invalid
    if (this.infosFormGroup.invalid) {
      return;
    }

    if( this.globalService.checkInterdi( this.f.nomCtrl.value ) ) {
      this.messageRetour.ko = " Le nom utilisé n'est pas autorisé"
      this.nameError = true
      return;
    }

    let user = {
      lastname: this.f.nomCtrl.value,
      firstname: this.f.prenomCtrl.value,
      telephone: this.f.telephoneCtrl.value,
      email: this.f.emailCtrl.value,
      address: this.f.adresseCtrl.value,
      zipecode: this.f.codePCtrl.value,
      city: this.f.villeCtrl.value,
      civilite: this.f.sexeCtrl.value,
      datenais: this.formaterDate(),
      incomplet: this.profilIncomplet() ? 'oui' : 'non'
    }

    console.log('user', user)
    this.backendService.post(`/users/update/${this.userConnected.id}`, user )
    .then( resultat => {
      
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.messageRetour.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
        this.eventsService.errorsmsg( "Erreur lors de la modification de l'utilisateur." )
      } else {

        this.messageRetour.ok = resultat.text
        this.eventsService.successmsg(resultat.text)
        this.submitted = false
        setTimeout(() => {
          this.getUser()
          this.eventsService.onPageSelected( "dashboard" )
          window.scroll(0,0);
        }, 2000);
      
      }

    })
    .catch( error => {
      
    })
  }


  formaterDate(){
    let la_date = this.f.datenaissCtrl.value
    return `${la_date.substring(0, 2)}/${la_date.substring(2, 4)}/${la_date.substring(4, 8)}`
  }

  unFormateDate(){
    let la_date = this.userConnected.datenais
    return la_date.replaceAll('/','')
  }


  onSubmitPassword() {
    this.submittedPasse = true
    
    // stop here if form is invalid
    if (this.passwordFormGroup.invalid) {
      return;
    }
    if( this.f1.mdpActuCtrl.value != this.userConnected.password ) {
      this._messageRetour.ko = "Le mot de passe actuel saisi ne correspond pas à votre mot de passe."
      return;
    }
    if( this.f1.mdpNewCtrl.value != this.f1.mdpNewConfCtrl.value ) {
      this._messageRetour.ko = " Les mots de passe saisis ne sont pas identiques"
      return;
    }
    
    let user = {
      password: this.f1.mdpNewCtrl.value,
      id_user: this.userConnected.id
    }

    this.backendService.put('/users/update-password', user )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this._messageRetour.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
        this.eventsService.errorsmsg( "Erreur lors de la modification du mot de passe." )
      } else {

        this._messageRetour.ok = resultat.text
        this.eventsService.successmsg(resultat.text)
        this.userConnected.password = this.f1.mdpNewCtrl.value
        this.globalService.setConnectedUser( this.userConnected )
        this.submitted = false
        this.submittedPasse = false
        this.passwordFormGroup.patchValue( {
          mdpActuCtrl: "",
          mdpNewCtrl: "",
          mdpNewConfCtrl: ""
        } )
      }

    })
    .catch( error => {
      
    })


  }

  onSelectFile( event ) { 
    
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      this.urlSelectPhoto.url = event.target.files[0]
      reader.readAsDataURL(event.target.files[0]);
      this.urlSelectPhoto.msg = event.target.files[0].name
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.urlSelectPhoto.view = this.getSelectResult( event )
      }
    }
     
  }

  getSelectResult( event ) {
    return event.target.result 
  }


  getAvatarUrlBase(){
    return `${this.globalService.urlImage}${this.userConnected.photo}`
  }


  deleteSelectFile(){
    this.urlSelectPhoto.view = null;
    this.urlSelectPhoto.url = null;
  }


  uploadFile(){

    const formData = new FormData();
    formData.append('photo', this.urlSelectPhoto.url);
    formData.append('lastname', this.globalService.getConnectedUser().lastname);
    formData.append('firstname', this.globalService.getConnectedUser().firstname );
    formData.append('telephone', this.globalService.getConnectedUser().telephone );
    formData.append('email', this.globalService.getConnectedUser().email );
    formData.append('address', this.globalService.getConnectedUser().address );
    formData.append('zipecode', this.globalService.getConnectedUser().zipecode );
    formData.append('city', this.globalService.getConnectedUser().city );
    formData.append('civilite', this.globalService.getConnectedUser().civilite );
    formData.append('datenais', this.globalService.getConnectedUser().datenais );
    formData.append('incomplet', this.profilIncomplet() ? 'oui' : 'non' );

    this.backendService.upload( formData, `/users/update/${this.userConnected.id}` )
    .subscribe (
      (res) => {
        let uploadResponse = res
        if( uploadResponse.status === 'error' ) {
          this.eventsService.errorsmsg( uploadResponse.message )
        } else {
          if( uploadResponse.status == 'progress' ) {
            this.progressMessage.progress = uploadResponse.message
          }
          if( uploadResponse.text ) {
            this.progressMessage.ok = uploadResponse.text
            setTimeout(() => {
              this.getUser()
              this.eventsService.onPageSelected( "dashboard" )
              window.scroll(0,0);
            }, 2000);
          }
        }
      },
      (err) => this.eventsService.errorsmsg( err )
    )
  }



  getUser() {

    this.backendService.get(`/users/user/${this.userConnected.id}` )
    .then( resultat => {
      

      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        
      } else {

        if( resultat[0].id ) {

          resultat[0].password = this.userConnected.password
          this.globalService.setConnectedUser( resultat[0] )
          this.eventsService.onUserConnected( resultat[0] )
          //this.displayPage()
          this.deleteSelectFile()
  
          setTimeout(() => {
            this.progressMessage.ko = null,
            this.progressMessage.ok = null
            this.progressMessage.progress = null
          }, 1000);
  
        }
      
      }
      
    })
    .catch( error => {
    })


  }


  telFormat( ntl ) {
    console.log('ntel ' , ntl)
    this.maskTel.value = ntl.image;
    this.maskTel.mask = ntl.mask;
    this.infosFormGroup.patchValue( {
      telephoneCtrl: ntl.text
    } );
  }



}
