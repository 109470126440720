


    <div class="container-app2">
        <div class="row">

            
            <div class="col-sm-6">

                <ngb-carousel #carousel  [pauseOnHover]="pauseOnHover" (slide)="onSlide($event)" *ngIf="avisClient.length > 0">

                    <ng-template ngbSlide *ngFor="let avis of avisClient; index as i" >
                        
               
                            <div class="picsum-img-wrapper" id="testimonials">

                                <blockquote>

                                    <div class="row justify-content-center">
                                        <div class="col-lg-8">
    
                              
                                          <div class="owl-carousel testimonials-carousel wow fadeInUp">
                                  
                                            <div class="testimonial-item">
                                              <img [src]="avis.user_sender.image64 | safeHtml" class="testimonial-img" alt="">
                                              <h3>{{avis.user_sender.name}} </h3>
                                              <h4><ngb-rating [(rate)]="avis.nbrstar" max="5" [readonly]="true" ></ngb-rating></h4>
                                              <p class="text-note-avis">
                                                <i class="material-icons rotate_left">format_quote</i>
                                                {{ avis.note }}
                                                <i class="material-icons">format_quote</i>
                                              </p>
                                            </div>
                              
                                          </div>
                              
                                        </div>
                                    </div>

                                </blockquote>

                                
                                
                            </div>
                          

                    </ng-template>

                </ngb-carousel>

                <div class="img_not_found" *ngIf="avisClient.length <= 0" >
                  <img src="assets/imgs/about-img.svg" alt="" class="img-fluid">
                </div>
               
                    
                    

            </div>


            <div class="col-sm-6">
                <div class="div-avis" >


                </div>


                <section id="intro" class="clearfix">
                    <div class="container ">
                
                      <div class="intro-img">
                        <img src="assets/imgs/intro-img.svg" alt="" class="img-fluid">
                      </div>
                
                      <div class="intro-info">
                        <h2>Des professionnels <br><span>à</span><br>votre écoute!</h2>
                        <div class="text-center" *ngIf="!userConnected" >
                          <a class="btn-connexion cursor" (click)="openPage('/login')" >Connexion</a>
                          <a class="btn-inscription cursor" (click)="openPage('/signup')">Inscription</a>
                        </div>
                      </div>
                
                    </div>
                </section><!-- #intro -->

                
            </div>



        </div>
    </div>
