<div class="modal-header">
    <h4 class="modal-title"> {{title}} </h4>
    <button type="button" class="close" aria-label="Close" (click)="decline()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>


<div class="modal-body">


    <form  >
        <div class="example-container">

            
            <div class="row">

                <div class="col-md-12">

                    <div class="form-group" >
                        <label for="etatSelec">État de l'expédition<span>(*)</span></label>
                        <legend>Mettre à jour l'état de l'expédition n° {{element.numtrans}} ? </legend>
                        
                        <mat-select required class="form-control" placeholder="Type d'objet " [(value)]="etatSelected">
                            <mat-option *ngFor="let etep of etatExpedition" [value]="etep.name">
                                {{etep.name}}
                            </mat-option>
                        </mat-select>

                    </div>

                </div>

            </div>

            <div class="row" *ngIf="messageRetour" >

                <div class="col-md-12 div-error" *ngIf="messageRetour.ko" >
                    {{messageRetour.ko}}
                </div>

                <div class="col-md-12 div-success" *ngIf="messageRetour.ok" >
                    {{messageRetour.ok}}
                </div>

            </div>


        </div>
    </form>


</div>


<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="decline()">{{ btnCancelText }}</button>
    <button type="button" class="btn btn_default" (click)="valider()">{{ btnOkText }}</button>
</div>