import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GestTelComponent } from './gest-tel/gest-tel.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { MaterialAngModule } from '../matmodule/materialang.module';
import { NgxMaskModule } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GlobalService } from '../services/global/global.service';
import { DialogComponent } from './dialog/dialog.component';
import { LoaderComponent } from './loader/loader.component';
@NgModule({
  declarations: [
    GestTelComponent,
    DialogComponent,
    LoaderComponent
  ],
  imports: [
    CommonModule,
    MaterialAngModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot()
  ],
  exports: [
    GestTelComponent,
    DialogComponent,
    LoaderComponent
  ],
  providers:  [ 
    GlobalService,
  ]
})
export class SharedModule { }
