<div class="row dashbord-right">

    <div class="go-back">
        <button class="" mat-button (click)="goBack()" matTooltip="Retourner à la liste des Expéditions / Transports" > 
            <mat-icon>arrow_back_ios</mat-icon> Retourner à la liste des Expéditions / Transports
        </button>
    </div>

    <label for="" class="label2"> Modifier les données de votre colis. Attention ! chaque modification entraîne une demande de validation du transporteur. </label>

    <div class="col-12">

        <form [formGroup]="infosFormGroup" (ngSubmit)="onSubmit()" >

            <div class="info-colis">Informations colis </div>

            <div class="row">
                <div class="col-md-6">

                    <div class="form-group" >
                        <label for="nomPrenomExpediteurCtrl">Expéditeur  <span>(*)</span></label>
                        <legend>Votre nom et prénom tel que écrit sur votre pièce d’identité officiel.</legend>
                        <input id="nomPrenomExpediteurCtrl" type="text" class="form-control" formControlName="nomPrenomExpediteurCtrl" placeholder="Votre nom et prénom" readonly="readonly" >
                    </div>

                </div>

                <div class="col-md-6">

                    <div class="form-group" >
                        <label for="telExpediteurCtrl">Téléphone expéditeur  <span>(*)</span></label>
                        <legend>Votre numéro de téléphone tel que défini dans votre compte</legend>
                        <input mask="00 00 00 00 00" id="telExpediteurCtrl" type="text" class="form-control" formControlName="telExpediteurCtrl" placeholder="Téléphone expéditeur" >
                    </div>

                </div>
            </div>


            <div class="row">

                <div class="col-md-6">

                    <div class="form-group" >
                        <label for="nomPrenomDestinataireCtrl">Nom et prénom du destinataire  <span>(*)</span></label>
                        <legend>Attention! Nos agents vérifieront les noms et prénoms avant de remettre le colis</legend>
                        <input id="nomPrenomDestinataireCtrl" type="text" class="form-control" formControlName="nomPrenomDestinataireCtrl" placeholder="Nom et prénom du destinataire" required [ngClass]="{ 'is-invalid': submitted && f.nomPrenomDestinataireCtrl.errors }"  >
                    </div>

                </div>

                <div class="col-md-6">

                    <div class="form-group" >
                        <label for="telephoneDestinataireCtrl">Téléphone destinataire <span>(*)</span></label>
                        <legend>Numéro de téléphone sur lequel le destinataire est joignable</legend>

                        <div class="input-group mb-3">
                            <input mask="{{masktelArr.mask}}" id="telephoneDestinataireCtrl" type="text" 
                            class="form-control" formControlName="telephoneDestinataireCtrl" 
                            placeholder="Veuillez renseigner le numéro de téléphone du destinataire" 
                            required [ngClass]="{ 'is-invalid': submitted && f.telephoneDestinataireCtrl.errors }" >
                        </div>

                        <app-gest-tel
                            [idTelephone]="'telephoneDestinataireCtrl'"
                            [placeholderInput]="'Téléphone du lieu d\'arrivé'"
                            [numTelCtrl]="f.telephoneDestinataireCtrl.value"
                            (numTelChanged)="changeTelephone($event, 'arr')"
                            [errors]="submitted && f.telephoneDestinataireCtrl.errors"
                        ></app-gest-tel>


                    </div>

                </div>

            </div>


            <div class="row">
                
                <div class="col-md-12">

                    <div class="form-group" >
                        <label for="adresseLivraisonCtrl">Adresse de résidence du destinataire  <span>(*)</span></label>
                        <legend>Adresse précise du destinataire. Ces informations seront utiles aux agents Msahilisho pour des fins de vérification. </legend>
                        <input id="adresseLivraisonCtrl" type="text" class="form-control" formControlName="adresseLivraisonCtrl" placeholder="Adresse du destinataire" required [ngClass]="{ 'is-invalid': submitted && f.adresseLivraisonCtrl.errors }" >
                    </div>

                </div>

            </div>

            <!--  Start ici si msahilisho -->
            <!--  Cas normal : envoi exterieur -->
            <div *ngIf="nextTravels.msahilisho == '0'" >

                <div class="row">

                    <div class="col-md-12">
    
                        <div class="form-group" >
                            <label for="telephoneDestinatairetrl">Type d'objet<span>(*)</span></label>
                            <legend>Le type de colis que vous voulez éxpédier: Envéloppe ou Colis ? </legend>
                            
                            <mat-select required class="form-control" placeholder="Type d'objet " (selectionChange)="onTypeSelection($event)" [(value)]="typeSelected">
                                <mat-option *ngFor="let tpe of typeObjet" [value]="tpe.value">
                                {{tpe.name}}
                                </mat-option>
                            </mat-select>
    
                        </div>
    
                    </div>
    
                </div>
    
    
                <div class="row">
                    <div class="col-md-6">
    
                        <div class="form-group" >
                            <label for="tailleCtrl">Taille de l'objet  <span>(*)</span></label>
                            <legend>{{typeSelected == 'colis' ? infoPlaceholder.txtColi.taille : infoPlaceholder.txtEnv.taille }}</legend>
                            <!--<input id="tailleCtrl" type="text" class="form-control" formControlName="tailleCtrl" placeholder="Taille de l'objet envoyé" required [ngClass]="{ 'is-invalid': submitted && f.tailleCtrl.errors }" >-->
    
                            <input *ngIf="typeSelected == 'colis'"  id="tailleCtrl" type="text" class="form-control" formControlName="tailleCtrl" placeholder="Taille de l'objet envoyé" required [ngClass]="{ 'is-invalid': submitted && f.tailleCtrl.errors }" >
    
                            <mat-select *ngIf="typeSelected == 'enveloppe'" formControlName="tailleCtrl" required class="form-control" placeholder="Taille de l'objet envoyé" >
                                <mat-option *ngFor="let evp of typeEnveloppe" [value]="evp.value">
                                {{evp.name}}
                                </mat-option>
                            </mat-select>
    
                        </div>
    
                    </div>
    
                    <div class="col-md-6">
    
                        <div class="form-group" >
                            <label for="poidsCtrl">Poids  <span>(*)</span></label>
                            <legend>{{typeSelected == 'colis' ? infoPlaceholder.txtColi.poids : infoPlaceholder.txtEnv.poids }} </legend>
                            <input id="poidsCtrl" step="0.01" type="number" class="form-control" formControlName="poidsCtrl" placeholder="Poid de votre objet" required [ngClass]="{ 'is-invalid': submitted && f.poidsCtrl.errors }" >
                        </div>
    
                    </div>
                </div>

            </div>

            <!--  Cas msahilisho : envoi msahilisho -->
            <div *ngIf="nextTravels.msahilisho != '0'" >

                <div class="row">
                    <div class="col-md-12"> 
                        <div class="form-group" >
                            <label for="envoiConcerne">Votre envoi concerne <span>(*)</span></label>
                        </div>
                    </div>

                </div>

                <div class="row msahilisho-div">
                    <div *ngFor="let pdt of infosProducts" class="col-6 col-md-3 col-lg-3 col-sm-12 col-div" [ngClass]= "produiSelect && produiSelect.id === pdt.id ? 'select-div': ''"   (click)="chooseEnvoieMsahilisho(pdt)">
                        <div class="nom-produit">{{pdt.nomproduct}}</div>
                        <div class="icon-produit"> <img src="assets/imgs/icon-produit/{{pdt.image}}" ></div>
                        <div class="prix-produit" *ngIf="pdt.prixproduct != ''" > {{pdt.prixproduct}} € <span *ngIf="pdt.partype != ''" class="parType" >/{{pdt.partype}} </span> </div>
                    </div>
                </div>

                <div class="row" *ngIf="produiSelect && produiSelect.id == 'autre'" >
                    <div class="col-md-12">

                        <div class="form-group" >
                            <label for="poidsMshCtrl">Poids  <span>(*)</span></label>
                            <legend>Poids en KG de votre objet. </legend>
                            <input id="poidsMshCtrl" step="0.01" type="number" class="form-control" formControlName="poidsMshCtrl" placeholder="Poid de votre objet" required [ngClass]="{ 'is-invalid': submitted && f.poidsMshCtrl.errors }" >
                        </div>

                    </div>
                </div>

            </div>

            <!--  End ici si msahilisho -->


            


            <div class="row" *ngIf="messageRetour" >

                <div class="col-md-12 div-error" *ngIf="messageRetour.ko" >
                    {{messageRetour.ko}}
                </div>

                <div class="col-md-12 div-success" *ngIf="messageRetour.ok" >
                    {{messageRetour.ok}}
                </div>

            </div>


            <!--<div class="row">-->
            <div class="row" *ngIf="nextTravels.msahilisho == '0' || (nextTravels.msahilisho != '0' && (produiSelect && produiSelect.id == 'autre') ) " >
                <div class="col-md-12">

                    <div class="form-group" >
                        <label for="infoObjetCtrl">Informations sur le colis  <span>(*)</span></label>
                        <legend>{{typeSelected == 'colis' ? infoPlaceholder.txtColi.infos : infoPlaceholder.txtEnv.infos }}</legend>
                        <textarea class="form-control" id="infoObjetCtrl" rows="3" formControlName="infoObjetCtrl" placeholder="Information sur le colis" [ngClass]="{ 'is-invalid': submitted && f.infoObjetCtrl.errors }"></textarea>
                    </div>

                </div>

            </div>
        
            <div>
              <button class="btn selectionner" type="submit" >Valider votre demande </button>
            </div>

            <div class="row" *ngIf="messageRetour" >
                <div class="col-md-12 div-error" *ngIf="messageRetour.ko" >
                    {{messageRetour.ko}}
                </div>
                <div class="col-md-12 div-success" *ngIf="messageRetour.ok" >
                    {{messageRetour.ok}}
                </div>
                <br />
            </div>

        </form>

    </div>


</div>
