import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DateformatService } from './dateformat.service'

import { 

  MatButtonModule , 
  MatIconModule, 
  MatInputModule ,
  MatAutocompleteModule,
  MatFormFieldModule,
  MatSortModule,
  MatTooltipModule,
  MatListModule,
  MatExpansionModule,
  MatBottomSheetModule,
  MatSelectModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatBadgeModule,
  MatMenuModule,
  MatCardModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSidenavModule,
  MatSnackBarModule,
  MatTableModule,
  MatPaginatorModule,
  MatChipsModule,
  MatCheckboxModule,
  MatStepperModule,
  MatTabsModule,
  MatDialogModule,

} from '@angular/material';

import { MatPaginatorIntl } from '@angular/material';
import {MatButtonToggleModule} from '@angular/material/button-toggle';

export function getRangeLabel (page: number, pageSize: number, length: number) {
  if (length == 0 || pageSize == 0) { return `0 sur ${length}`; }

  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;

  return `${startIndex + 1} – ${endIndex} sur ${length}`;
}

export function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();
  customPaginatorIntl.itemsPerPageLabel = 'Élément par page';
  customPaginatorIntl.nextPageLabel = 'Suivant';
  customPaginatorIntl.previousPageLabel = 'Précédent';
  customPaginatorIntl.firstPageLabel = 'Début';
  customPaginatorIntl.lastPageLabel = 'Fin';
  customPaginatorIntl.getRangeLabel = getRangeLabel;
  return customPaginatorIntl;
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,

    MatButtonModule , 
    MatIconModule, 
    MatInputModule ,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatSortModule,
    MatTooltipModule,
    MatListModule,
    MatExpansionModule,
    MatBottomSheetModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatBadgeModule,
    MatMenuModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatChipsModule,
    MatCheckboxModule,
    MatStepperModule,
    MatTabsModule,
    MatDialogModule,
    MatButtonToggleModule

  ],
  exports: [
    CommonModule,

    MatButtonModule , 
    MatIconModule, 
    MatInputModule ,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatSortModule,
    MatTooltipModule,
    MatListModule,
    MatExpansionModule,
    MatBottomSheetModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatBadgeModule,
    MatMenuModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatChipsModule,
    MatCheckboxModule,
    MatStepperModule,
    MatTabsModule,
    MatDialogModule,
    MatButtonToggleModule

  ],
  providers: [
    DateformatService,
    { provide: MatPaginatorIntl, useValue: CustomPaginator() }
  ]
})
export class MaterialAngModule { 
  
}
