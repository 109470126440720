import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { EventsService } from '../services/events/events.service'
import { GlobalService } from '../services/global/global.service'
@Component({
  selector: 'app-topheader',
  templateUrl: './topheader.component.html',
  styleUrls: ['./topheader.component.scss']
})
export class TopheaderComponent implements OnInit {

  isCollapsed = false
  userConnected = null
  urlImage
  //urlConnect = ['/dashboard', '/mon-compte', '/mes-expedition-ou-transports', '/mes-trajets', '/mes-avis' ]


  constructor( 
    private router: Router ,
    private globalService: GlobalService,
    private eventsService: EventsService
  ) { 
    this.eventsService.userConnectEmitter.subscribe( user => {

      
      this.isConnectedUser()
    })
  }

  ngOnInit() {
    this.urlImage = this.globalService.urlImage
    this.isConnectedUser()
  }

  isConnectedUser() {
    this.userConnected = this.globalService.getConnectedUser()

    
    /*if(!this.userConnected && this.urlConnect.includes( this.router.url ) ){
      
      this.router.navigate(['/home']);
      window.scroll(0,0);
    }*/
  }

  openPage(page) {
    this.router.navigate([page]);
    window.scroll(0,0);
  }


  logOut(){
    this.globalService.logOut()
    this.eventsService.setUserLoggedIn(false);
    this.eventsService.onUserDeconnected()
    this.router.navigate(['/home']);
    window.scroll(0,0);
  }

}
