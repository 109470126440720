import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators, FormBuilder, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import {Location} from '@angular/common';

import { BackendService } from '../services/backend/backend.service'
import { GlobalService } from '../services/global/global.service'
import { EventsService } from '../services/events/events.service'
import { ValidatorrService } from '../services/validatorr/validatorr.service'

import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


  loginFormGroup: FormGroup;
  submitted;
  resultMessage;

  publish = environment.publish


  constructor( 
    private backendService: BackendService ,
    private _formBuilder: FormBuilder,
    private router: Router ,
    private globalService: GlobalService,
    private eventsService: EventsService,
    private _location: Location
  ) { }

  ngOnInit() {

    this.loginFormGroup = this._formBuilder.group({
      emailCtrl: [ this.globalService.getSeSouvenir() ? this.globalService.getSeSouvenir().email: '', [Validators.required , ValidatorrService.emailValidator] ],
      passwordCtrl: [ this.globalService.getSeSouvenir() ? this.globalService.getSeSouvenir().password: '', Validators.required ],
      seSouvenirCtrl: [this.globalService.getSeSouvenir() ? true : false]
    });

    if( this.globalService.getConnectedUser() ) {
      this.router.navigate(['/home']);
      window.scroll(0,0);
    }

  }


  get f() { 
    return this.loginFormGroup.controls; 
  }


  onSubmit(){

    this.submitted = true;
    this.resultMessage = null
    

    // stop here if form is invalid
    if (this.loginFormGroup.invalid) {
      return;
    }

    let user = {
      email: this.loginFormGroup.controls['emailCtrl'].value,
      password: this.loginFormGroup.controls['passwordCtrl'].value
    }

    this.backendService.post('/users/login', user )
    .then( resultat => {

      
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.resultMessage = resultat.status != 0 ? resultat.error.error : {text: "Utilisateur ou Mot de passe incorrect !"}
      } else {

        
        let userResturned = resultat[0]
        
        userResturned.password = user.password

        //resultat[0].password = user.password
        this.globalService.setConnectedUser( userResturned ) // resultat[0]
        this.eventsService.onUserConnected( userResturned )
        this.eventsService.setUserLoggedIn(true);

        if( this.f.seSouvenirCtrl.value ) {
          this.globalService.setSeSouvenir( user );
        } else {
          this.globalService.setSeSouvenir( null );
        }

        this._location.back();
      
      }

    })
    .catch( error => {
      
      this.resultMessage = error 
    })
 
  }


  passwordForget() {
    this.router.navigate(['/mot-de-passe-oublier']);
    window.scroll(0,0);
  }

}
