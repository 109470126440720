import { Component, OnInit , ViewChild } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';

import { NotesService} from '../notes/notes.service'

import { GlobalService } from '../../services/global/global.service'
import { BackendService } from '../../services/backend/backend.service'
import { EventsService } from '../../services/events/events.service'
import { ConfirmService } from '../../modal/confirm/confirm.service';
import { EditEtatService } from '../../modal/edit-etat/edit-etat.service';
import { EditLocationService } from '../../modal/edit-location/edit-location.service'
import { Router } from '@angular/router';


@Component({
  selector: 'app-expedition',
  templateUrl: './expedition.component.html',
  styleUrls: ['./expedition.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ExpeditionComponent implements OnInit {

  expandedElement

  userConnected
  dataSourceEncoursLength = 0
  dataSourceTermineeLenght = 0
  dataSourceEncours = new MatTableDataSource<any>([]);
  dataSourceTerminee = new MatTableDataSource<any>([]);
  columnsToDisplay = ['detail', 'datecreate', 'numtrans', 'nature_trans', 'localisation', 'etattrans', 'prixtrans', 'note', 'action'];
  elementsDisplay = ['#', 'Date de la transaction', "N° de suivi", 'Nature transaction', 'Localisation', 'État', '€', 'Note', 'Action'];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  errorMessage = null;

  constructor( 
    private notesService: NotesService ,
    private eventsService: EventsService,
    private globalService: GlobalService,
    private backendService: BackendService,
    private confirmService: ConfirmService,
    private editEtatService: EditEtatService,
    private editLocationService: EditLocationService,
    private router: Router
  ) { }

  ngOnInit() {

    if( !this.globalService.getConnectedUser() ) {
      this.router.navigate(['/home']);
      window.scroll(0,0);
    }

    this.userConnected = this.globalService.getConnectedUser()
    this.displayPage()
    this.dataSourceTerminee.paginator = this.paginator;
  }




  displayPage(){

    this.backendService.get( `/senbox/list/${this.globalService.getConnectedUser().id}` )
    .then( resultat => {
      console.log( 'resultatresultat ', resultat)

      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.dataSourceEncours = new MatTableDataSource([]);
        this.dataSourceTerminee = new MatTableDataSource([]);
      } else {
        this.dataSourceEncours = new MatTableDataSource( this.initNote(resultat.array_sendbox_encours) );
        this.dataSourceTerminee = new MatTableDataSource( this.initNote(resultat.array_sendbox_terminee) );
        this.dataSourceEncoursLength = resultat.array_sendbox_encours.length
        this.dataSourceTermineeLenght = resultat.array_sendbox_terminee.length
      }

    })
    .catch( error => {
      this.dataSourceEncours = new MatTableDataSource([]);
      this.dataSourceTerminee = new MatTableDataSource([]);
    })

  }


  initNote( arrayBoject ){

    for (var i = 0; i < arrayBoject.length; i++) {
      arrayBoject[i].notestrans = JSON.parse( arrayBoject[i].notestrans )
    }

    return arrayBoject

  }



  applyFilterEncours(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceEncours.filter = filterValue.trim().toLowerCase();
  }

  applyFilteTerminee(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceTerminee.filter = filterValue.trim().toLowerCase();
  }



  ajouterUneNote( event: Event, element ){
    event.stopPropagation();
    this.errorMessage = null;

    this.notesService.confirmService( element , 'note')
    .then( (confirmed : any ) => {
      
      if( confirmed.status ) {
        this.eventsService.successmsg( "Note ajoutée avec succès." )
        setTimeout(() => {
          this.displayPage()
        }, 2000);
      }
    })
    .catch( (error) => {
      
    })

  }


  noterTransporteur( event: Event, element ){
    event.stopPropagation();
    this.errorMessage = null;

    this.notesService.confirmService( element , 'notetrans')
    .then( (confirmed) => {
      
      if( confirmed.status ) {
        this.eventsService.successmsg( "Note ajoutée avec succès." )
        setTimeout(() => {
          this.displayPage()
        }, 2000);
      }
    })
    .catch( (error) => {
      
    })

  }


  btnEncoursAction(event: Event, element, action ){
    event.stopPropagation();


    
    
    this.errorMessage = null;


    if( action == "edit" ) {

      if( element.localisation == "Chez l'expéditeur" && ( element.etattrans != "En cours" && element.etattrans != "Validée par le transporteur" )   ) {
        this.globalService.setAnnoneEnModification( element )
        this.eventsService.onPageSelected( "edit-sendbox" )
      } else {
        this.eventsService.errorsmsg("Vous ne pouvez plus modifier cette expédition.")
      }
      
    } else if( action == "valider" ) {

      if( element.infos_suggest.user_suggest.id != this.userConnected.id ) {
        this.eventsService.errorsmsg("Seul le transporteur peut valider la transaction.")
      } else {
        this.validerExpedition( element )
      }

    } else if( action == "etat" ) {

      //this.editEtat( element )
      if( element.infos_suggest.user_suggest.id != this.userConnected.id ) {
        this.eventsService.errorsmsg("Seul le transporteur peut valider la transaction.")
      } else {
        this.editEtat( element )
      }

    } else if( action == "location" ) {

      // this.editLocation( element )
      if( element.etattrans == "En attente de validation" ) {
        this.eventsService.errorsmsg("Colis chez l'expéditeur et en attente de validation.")
      } else {

        if( element.infos_suggest.user_suggest.id != this.userConnected.id ) {
          this.eventsService.errorsmsg("Seul le transporteur peut changer la localisation.")
        } else {
          this.editLocation( element )
        }

      }
      
    } else if( action == "delete" ) {

      if( element.user_sender.id != this.userConnected.id ) {
        this.eventsService.errorsmsg("Seul l'expéditeur peut annuler l'expédition.")
      } else {
        this.deleteExpe( element )
      }

    }

  }


  canEdit( element ) {
    return element.user_sender.id == this.userConnected.id ? true : false
  }


  isValidate( element ) {

    if( element.etattrans == "En cours" || element.etattrans == "Validée par le transporteur" ){
      return false
    } else {
      return true
    }
  }

  canValidate( element ) {
    return element.infos_suggest.user_suggest.id == this.userConnected.id ? true : false 
  }



  validerExpedition(element) {
    this.confirmService.confirm(`Traitement de la demande - ${element.numtrans}`, 'Êtes-vous sûr de vouloir valider cette demande ?', 'Valider', 'Annuler' , 'Refuser' )
    .then( (confirmed : any) => {

      if( confirmed.etat != "cancel" ) {

        let box = {
          iduserexp: this.userConnected.id,
          numtrans: element.numtrans,
          notestrans: null,
          etattrans: null,
          idtrajet: element.infos_suggest.id,
          kgdispo: element.infos_suggest.kgdispo,  //parseFloat( element.infos_suggest.kgdispo ) - parseFloat(element.poidsobj)
          poidsobj: element.poidsobj,
          user_direct: !!element.user_sender.user_direct,
          email_user: !!element.user_sender.email ? element.user_sender.email : null
        }

        if( confirmed.etat == "accept") {

          let dateAct = new Date()
          let note = {
            note : "Prise en charge validée par le transporteur" ,
            create_at: `${dateAct.getDate()}/${dateAct.getMonth() + 1}/${dateAct.getFullYear()}`
          }
          let lesNotes = element.notestrans
          lesNotes.push( note )
          box.notestrans = JSON.stringify( lesNotes )
          box.etattrans = "Validée par le transporteur"

        } else if( confirmed.etat == "refuse") {

          let dateAct = new Date()
          let note = {
            note : "Prise en charge réfusée par le transporteur" ,
            create_at: `${dateAct.getDate()}/${dateAct.getMonth() + 1}/${dateAct.getFullYear()}`
          }
          let lesNotes = element.notestrans
          lesNotes.push( note )
          box.notestrans = JSON.stringify( lesNotes )
          box.etattrans = "Réfusée par le transporteur"
  
        }


        

        this.backendService.put(`/senbox/validate/${element.id}`, box )
        .then( resultat => {

          

          if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
            this.eventsService.errorsmsg( resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement." )
            this.errorMessage = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement.";
          } else {

            this.eventsService.successmsg( resultat.text )
            setTimeout( () => {
              this.displayPage();
            }, 2000)
          
          }

        })
        .catch( error => {
          
        })

      }
      
    })
    .catch( () => {

    })
  } 



  editEtat(element){

    this.editEtatService.confirm(element, 'Valider', 'Anuler')
    .then( (confirmed : any ) => {

      if( confirmed.status ) {
        this.eventsService.successmsg( "Votre demande a été bien prise en compte" )
        setTimeout( () => {
          this.displayPage();
        }, 2000)
      }

    })
    .catch( () => {

    })

  }


  editLocation(element){

    this.editLocationService.confirmer(element, 'Valider', 'Annuler')
    .then( (confirmed : any ) => {

      if( confirmed.status ) {
        this.eventsService.successmsg( "Votre demande a été bien prise en compte" )
        setTimeout( () => {
          this.displayPage();
        }, 2000)
      }

    })
    .catch( () => {

    })

  }



  deleteExpe(element) {

    if( element.etattrans == "En cours" ){
      this.eventsService.errorsmsg("Vous ne pouvez plus annuler cette expédition.")
    } /*else if( element.etattrans == "En attente de validation" ){
       this.confirmDelete(element)
    }*/ else {
      
      this.confirmService.confirm(`Anulation de la demande - ${element.numtrans}`, 'Êtes-vous sûr de vouloir annuler cette demande ?', 'Oui', 'Non' , '' )
      .then( (confirmed : any) => {   

        if( confirmed.etat == "accept" ) {
          this.confirmDelete(element)
        }

      })
      .catch( () => {

      })
      
    }


  }



  confirmDelete(element){

    this.backendService.delete(`/senbox/delete/${element.id}`, )
    .then( resultat => {

      

      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.eventsService.errorsmsg( resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement." )
      } else {

        this.eventsService.successmsg( resultat.text )
        setTimeout( () => {
          this.displayPage();
        }, 2000)
      
      }

    })
    .catch( error => {
      
    })


  }


  canNote( element ) {
    return (element.infos_suggest.user_suggest.id != this.userConnected.id && element.etattrans != "Terminée et notée" && element.etattrans != "Réfusée par le transporteur" ) ? true : false
  }


  ajouterUnAvis( event: Event, element ){
    event.stopPropagation();
    this.errorMessage = null;

    if( element.etattrans == "Réfusée par le transporteur" ){
      this.eventsService.errorsmsg("Vous ne pouvez pas noter cette expédition. Elle a été réfusée par le transporteur.")
    } else {
      if( element.infos_suggest.user_suggest.id == this.userConnected.id ) {
        this.eventsService.errorsmsg("Vous ne pouvez pas vous noter ;) ")
      } else {

        if( element.etattrans == "Terminée et notée"  ) {
          this.eventsService.errorsmsg("expédition notée et terminée. ")
        } else {

          //Note
          this.notesService.confirmService( element , 'avis')
          .then( (confirmed : any ) => {
            
            if( confirmed.status ) {
              this.eventsService.successmsg( "Note ajoutée avec succès." )
              setTimeout(() => {
                //On met à jour l'état de l'expédition
                this.displayPage()
              }, 2000);
            }
          })
          .catch( (error) => {
            
          })
          
          
        }

      }

    }
    

  }



}

