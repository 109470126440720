import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopheaderComponent } from './topheader.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { MaterialAngModule } from '../matmodule/materialang.module'

import { GlobalService } from '../services/global/global.service'
import { EventsService } from '../services/events/events.service'

import { SafeHtml2 } from '../pipes/safeHtml.pipe';
@NgModule({
  declarations: [TopheaderComponent, SafeHtml2],
  imports: [
    CommonModule,
    NgbModule,
    MaterialAngModule
  ],
  exports: [TopheaderComponent],
  providers: [ GlobalService , EventsService ]
})
export class TopheaderModule { }
