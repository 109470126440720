import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators, FormBuilder} from '@angular/forms';
import { BackendService } from '../services/backend/backend.service'
import { GlobalService } from '../services/global/global.service'
import { EventsService } from '../services/events/events.service'
import { DateformatService } from '../matmodule/dateformat.service'
import { Router } from '@angular/router';
import { ValidatorrService } from '../services/validatorr/validatorr.service'

import {
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';

@Component({
  selector: 'app-suggestroute',
  templateUrl: './suggestroute.component.html',
  styleUrls: ['./suggestroute.component.scss'],
  providers: [
    {provide: NgbDateParserFormatter, useClass: DateformatService},
    {
      provide: STEPPER_GLOBAL_OPTIONS, useValue: {showError: true}
    }
  ]
})
export class SuggestrouteComponent implements OnInit {

  isLinear = true;
  submitted = false

  infosFormGroup: FormGroup;
  offreFormGroup: FormGroup;
  paiementFormGroup: FormGroup;
  confiramtionFormGroup: FormGroup;

  aeroportList = []
  frequenceVoyage = []
  moyenPayment = []
  isPaiementReadOnly = true
  aeroportSelet = {depart: null, arrivee: null }

  @ViewChild('labelImport')
  labelImport: ElementRef;

  formImport: FormGroup;
  fileToUpload: File = null;
  urlSelectJustif = { url: '', msg: 'Justificatif de voyage' }
  progressMessage = {progress: null, ok: null, ko: null}

  maskTeldep = {mask: "(+00) 0 00 00 00 00" , value: "france_.jpg"}
  masktelArr = {mask: "(+00) 0 00 00 00 00" , value: "france.png"}

  pourcentage
  msgPrice

  envoiEncours = false;
  listTelephone = [];


  constructor(
    private _formBuilder: FormBuilder,
    private backendService: BackendService ,
    private globalService: GlobalService,
    private eventsService: EventsService,
    private router: Router ,
    private validatorServ: ValidatorrService
  ) {}

  ngOnInit() {

    this.listTelephone = this.globalService.getListeIndicatifTelephone();
    this.aeroportList = this.globalService.getAeroports()
    this.frequenceVoyage = this.globalService.getFrequenceVoyage();
    this.moyenPayment = this.globalService.getMoyenPaiement();

    this.infosFormGroup = this._formBuilder.group({
      aeroportDepCtrl: ['', Validators.required],
      aeroportArrCtrl: ['', Validators.required],
      adresseDepotCtrl: ['', Validators.required],
      adresseRecupCtrl: ['', Validators.required],
      dateDepartCtrl: ['', Validators.required],
      dateArriveeCtrl: ['', Validators.required],
      numDepartCtrl: ['', Validators.required],
      numArriveCtrl: ['', Validators.required]
    }, {validator: this.validatorServ.dateLessThan('dateDepartCtrl', 'dateArriveeCtrl')} );

    this.offreFormGroup = this._formBuilder.group({
      deviseCtrl: [''],
      frequenceCtrl: ['', Validators.required],
      prixCtrl: ['', Validators.required],
      nbrKilosCtrl: ['', Validators.required]
    });

    this.paiementFormGroup = this._formBuilder.group({
      paiementCtrl: ['', Validators.required],
      ibanCtrl: ['']
    });

    this.confiramtionFormGroup = this._formBuilder.group({
      confirmationCtrl: ['', Validators.required],
      importFile: [''] // , Validators.required
    });

    this.offreFormGroup.patchValue( {
      deviseCtrl: "€"
    } );

    this.infosFormGroup.patchValue( {
      numDepartCtrl: "33",
      numArriveCtrl: "33"
    } );

    this.pourcentage = this.globalService.getPourcentage()
    this.msgPrice = `Msahilisho Import & Export se réserve ${this.pourcentage}% de commission sur votre annonce.`



  }



  get f1() { 
    return this.infosFormGroup.controls; 
  }
  get f2() { 
    return this.offreFormGroup.controls; 
  }
  get f3() { 
    return this.paiementFormGroup.controls; 
  }
  get f4() { 
    return this.confiramtionFormGroup.controls; 
  }


  onAeroDepSelection(event) {
    this.aeroportSelet.depart = this.infosFormGroup.controls['aeroportDepCtrl'].value 
  }

  onAeroArrSelection(event) {
    this.aeroportSelet.arrivee = this.infosFormGroup.controls['aeroportArrCtrl'].value 
  }


  onFreqSelection(event) {

  }

  onPaymentSelection(event) {
    if( this.paiementFormGroup.controls['paiementCtrl'].value == this.moyenPayment[0].value ) {
      this.isPaiementReadOnly = false
    } else {
      this.isPaiementReadOnly = true
    }
  }


  onFileChange( event ) {  //files: FileList   :::: $event.target.files 
    
   if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      this.urlSelectJustif.url = event.target.files[0]
      reader.readAsDataURL(event.target.files[0]);
      this.urlSelectJustif.msg = event.target.files[0].name
    }
    
  }


  formaterDate( la_date ){
    return `${la_date.substring(0, 2)}-${la_date.substring(2, 4)}-${la_date.substring(4, 8)}`
  }

  unFormateDate( la_date ){
    return la_date.replaceAll('/','')
  }



  publier(){

    this.submitted = true
    this.progressMessage.ko = null;
    this.progressMessage.ok = null;
    this.progressMessage.progress = null;
    
    if (this.infosFormGroup.invalid) {
      this.progressMessage.ko = "Une erreur a été détectée dans l'onglet [Informations du voyage] "
      return;
    }
    if (this.offreFormGroup.invalid) {
      this.progressMessage.ko = "Une erreur a été détectée dans l'onglet [Offre et service] "
      return;
    }
    if (this.paiementFormGroup.invalid) {
      this.progressMessage.ko = "Une erreur a été détectée dans l'onglet [Facturation et rémunération] "
      return;
    }
    if (this.confiramtionFormGroup.invalid) {
      this.progressMessage.ko = "Une erreur a été détectée dans l'onglet [Confirmation et validation des CGU]. Le champs CGU est obligatoire."
      return;
    }
    if ( this.urlSelectJustif.url === '' ) {
      this.progressMessage.ko = "Une erreur a été détectée dans l'onglet [Confirmation et validation des CGU]. Le justificatif de voyage est obligatoire."
      return;
    }
    if ( this.infosFormGroup.controls['numDepartCtrl'].value === '' || this.infosFormGroup.controls['numArriveCtrl'].value === '' ) {
      this.progressMessage.ko = "Une erreur a été détectée dans l'onglet [Informations du voyage]. Votre navigateur internet n'est peut être pas compatible avec cette version de Msahilisho Import & Export."
      return;
    }

    const formData = new FormData();
    formData.append('justificatif', this.urlSelectJustif.url);

    formData.append('iduser', this.globalService.getConnectedUser().id);

    formData.append('aerodep', this.aeroportSelet.depart.name );
    formData.append('aeroarr', this.aeroportSelet.arrivee.name );
    formData.append('adddepot', this.infosFormGroup.controls['adresseDepotCtrl'].value );
    formData.append('addrecup', this.infosFormGroup.controls['adresseRecupCtrl'].value );
    formData.append('datedepot', this.formaterDate( this.infosFormGroup.controls['dateDepartCtrl'].value ) );
    formData.append('daterecup', this.formaterDate(this.infosFormGroup.controls['dateArriveeCtrl'].value ) );
    formData.append('teldepot', `${this.infosFormGroup.controls['numDepartCtrl'].value}` );
    formData.append('telrecup', `${this.infosFormGroup.controls['numArriveCtrl'].value}` );

    formData.append('devise', this.offreFormGroup.controls['deviseCtrl'].value );
    formData.append('freqtrajet', this.offreFormGroup.controls['frequenceCtrl'].value );
    formData.append('prixkg', this.offreFormGroup.controls['prixCtrl'].value );
    formData.append('kgdispo', this.offreFormGroup.controls['nbrKilosCtrl'].value );

    formData.append('moyenpaie', this.paiementFormGroup.controls['paiementCtrl'].value );
    formData.append('iban', this.paiementFormGroup.controls['ibanCtrl'].value );

    formData.append('paysdep', this.aeroportSelet.depart.pays );
    formData.append('paysarr', this.aeroportSelet.arrivee.pays );

    formData.append('msahilisho', this.globalService.checkIsMsahilisho( this.globalService.getConnectedUser().email ) ? "1" : "0" );

    this.envoiEncours = true;

    this.backendService.upload( formData, `/suggest/add`)
    .subscribe (
      (res) => {
        let uploadResponse = res

        if( uploadResponse.status === 'error' ) {
          
          this.eventsService.errorsmsg( uploadResponse.message )
        } else {
          
          if( uploadResponse.status == 'progress' ) {
            this.progressMessage.progress = uploadResponse.message
          }

          if( uploadResponse.text ) {
            this.envoiEncours = false;
            this.progressMessage.ok = "Félicitations ! Votre trajet a été enregistré avec succès et est en attente de validation ! Msahilisho Import & Export s'emploie à le traiter dans les plus brefs délais. "
            setTimeout(() => {
              // this.router.navigate(['/expedier-ou-recevoir-un-colis']);
              this.router.navigate(['/home']);
              window.scroll(0,0);
            }, 2000);
          }

        }
      },
      (err) => {
        
        if( typeof err == "string")
          this.eventsService.errorsmsg( err )
        this.progressMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
        this.envoiEncours = false;
      }
    )

  }

  openCGU(){
    //this.router.navigate(["/conditions-generales-utilisations"]);
    window.open( `${this.globalService.getURLApp()}#/conditions-generales-utilisations`  , "_blank")
  }

  /**
   * Le prix maximum autorisé est de 7.99
   */
  OnChangePrice(){
    

    if( parseFloat( this.offreFormGroup.controls['prixCtrl'].value ) > 15 ) {
      this.offreFormGroup.patchValue( {
        prixCtrl: ""
      } )
      this.eventsService.toastrwaring("Le prix par kilo est limité à 14.99 €");
    } else {
      let prc  = parseFloat( this.offreFormGroup.controls['prixCtrl'].value ) * ( parseFloat(this.pourcentage) / 100 );
      let rst = parseFloat( this.offreFormGroup.controls['prixCtrl'].value ) - prc
      this.msgPrice = `Msahilisho Import & Export se réserve ${this.pourcentage}% de commission sur votre annonce. Soit ${prc.toFixed(2)}€ pour Msahilisho Import & Export et ${rst.toFixed(2)}€ pour vous, pour chaque proposition de transport.`
    }
    
  }


  checkMask( type , value ) {
    if( type == "date" ) {
      return value.length < 8 ? false: true
    }
  }

  
  changeBTN( action ) {

   this.submitted = false

   if( action == "infos" ) {

    if (this.infosFormGroup.invalid) {
      this.submitted = true
      return;
    }

   }

   if( action == "offre" ) {

    if (this.offreFormGroup.invalid) {
      this.submitted = true
      return;
    }

   }

   if( action == "facturation" ) {

    if (this.paiementFormGroup.invalid) {
      this.submitted = true
      return;
    }

   }


   if( action == "cgu" ) {

    if (this.confiramtionFormGroup.invalid) {
      this.submitted = true
      this.progressMessage.ko = null
      return;
    }

   }


  }

  isIE(){
    return /Trident\/|MSIE/.test(window.navigator.userAgent);
  }


  changeTelephone(value, type) {
    if (type === 'dep') {
      this.infosFormGroup.patchValue( {
        numDepartCtrl: value.tel
      } );
      this.maskTeldep = value.mask;
    } else {
      this.infosFormGroup.patchValue( {
        numArriveCtrl: value.tel
      } );
      this.masktelArr = value.mask;
    }
  }

  telFormat( ntl , type ) {
    console.log('ntel ' , ntl)
    if (type === 'dep') {
      this.maskTeldep.value = ntl.image;
      this.maskTeldep.mask = ntl.mask;
      this.infosFormGroup.patchValue( {
        numDepartCtrl: ntl.text
      } );
    } else {
      this.masktelArr.value = ntl.image;
      this.masktelArr.mask = ntl.mask;
      this.infosFormGroup.patchValue( {
        numArriveCtrl: ntl.text
      } );
    }
  }



}
