<div class="content-app">

    <app-topheader></app-topheader>
 
    <app-header></app-header>


    <!-- Section bar de recherche -->
    <div class="search-bar-top">
        <img src="assets/imgs/password.svg" class="airport-img" /> Réinitialiser votre mot de passe !
    </div>



    <div class="login-page ">

        <div class="row logo-authentification">
            <div class="col-12 text-center">
                <img src="assets/imgs/password_.svg" class="img-login" /> 
            </div>
        </div>

        <div class="row " *ngIf="resultMessage.ko != null ">
            <div class="col-12 div-error">
                {{resultMessage.ko}}
            </div>
        </div>

        <div class="row " *ngIf="resultMessage.ok != null ">
            <div class="col-12 div-success">
                {{resultMessage.ok}}
            </div>
        </div>


        <form [formGroup]="loginFormGroup" (ngSubmit)="onSubmit()"  >

            <div class="form-group">
                <label for="emailCtrl">Votre e-mail</label>
                <div class="input-group mb-2 mr-sm-2">
                    <div class="input-group-prepend">
                      <div class="input-group-text"><i class="material-icons">email</i></div>
                    </div>
                    <input type="email" class="form-control" id="emailCtrl" placeholder="Votre e-mail" formControlName="emailCtrl" [ngClass]="{ 'is-invalid': submitted && f.emailCtrl.errors }" >
                  </div>
            </div>

            <div class="row txt-connexion">
                <div class="col-md-6">
                    <button type="submit" class="btn-connexion">Réinitialiser</button>
                </div>
                
                <div class="col-md-6">
                    <button (click)="seConnecter()" type="button" class="btn-password"  >Se connecter</button>
                </div>


            </div>

          </form>


    </div>

    <div class="marg-bot"></div>


    
 
    <app-whychoose-home></app-whychoose-home> 
     
    
    <app-footer></app-footer>

    
</div>
