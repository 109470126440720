import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {FormControl, FormGroup, Validators, FormBuilder} from '@angular/forms';
import { BackendService } from '../services/backend/backend.service'
import { GlobalService } from '../services/global/global.service'
import { EventsService } from '../services/events/events.service'
import { ValidatorrService } from '../services/validatorr/validatorr.service'
import { DateformatService } from '../matmodule/dateformat.service'
import {
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import { Router } from '@angular/router';

@Component({
  selector: 'app-jeveuxvoyager',
  templateUrl: './jeveuxvoyager.component.html',
  styleUrls: ['./jeveuxvoyager.component.scss'],
  providers: [
    {provide: NgbDateParserFormatter, useClass: DateformatService},
    {
      provide: STEPPER_GLOBAL_OPTIONS, useValue: {showError: true}
    }
  ]
})
export class JeveuxvoyagerComponent implements OnInit {

  isLinear = true;
  submitted = false
  infosPersoFormGroup: FormGroup;
  infosVoyageFormGroup: FormGroup;
  confiramtionFormGroup: FormGroup;
  aeroportList = [];
  @ViewChild('labelImport')
  labelImport: ElementRef;

  fileToUpload: File = null;
  urlSelectJustif = { url: '', msg: 'Justificatif d\'identité' };
  progressMessage = {progress: null, ok: null, ko: null};
  maskTel = {mask: "(+00) 0 00 00 00 00" , value: "france_.jpg"};
  listTelephone = [];

  constructor(
    private _formBuilder: FormBuilder,
    private backendService: BackendService ,
    private globalService: GlobalService,
    private eventsService: EventsService,
    private router: Router,
    private validService: ValidatorrService
  ) { }

  ngOnInit(): void {
    this.listTelephone = this.globalService.getListeIndicatifTelephone();
    this.aeroportList = this.globalService.getAeroports();
    this.initFormGroups();
  }


  initFormGroups() {
    this.infosPersoFormGroup = this._formBuilder.group({
      nomCtrl: ['', Validators.required],
      prenomCtrl: ['', Validators.required],
      numTelCtrl: ['', Validators.required],
      emailCtrl: ['', [Validators.required, ValidatorrService.emailValidator]],
      importFile: ['']
    });

    this.infosVoyageFormGroup = this._formBuilder.group({
      aeroportDepCtrl: ['', Validators.required],
      aeroportArrCtrl: ['', Validators.required],
      dateDepartCtrl: ['', Validators.required],
      dateArriveeCtrl: ['', Validators.required]
    }, {validator: this.validService.dateLessThan('dateDepartCtrl', 'dateArriveeCtrl')} );

    this.confiramtionFormGroup = this._formBuilder.group({
      confirmationCtrl: ['', Validators.required],
      commentCtrl: ['']
    });

    this.infosPersoFormGroup.patchValue( {
      numTelCtrl: "33"
    } )
  }

  isIE(){
    return /Trident\/|MSIE/.test(window.navigator.userAgent);
  }

  get f1() { 
    return this.infosPersoFormGroup.controls; 
  }
  get f2() { 
    return this.infosVoyageFormGroup.controls; 
  }
  get f3() { 
    return this.confiramtionFormGroup.controls; 
  }

  onFileChange( event ) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      this.urlSelectJustif.url = event.target.files[0]
      reader.readAsDataURL(event.target.files[0]);
      this.urlSelectJustif.msg = event.target.files[0].name
    }
  }

  publier(){
    this.submitted = true
    this.progressMessage.ko = null;
    this.progressMessage.ok = null;
    this.progressMessage.progress = null;

    if (this.infosPersoFormGroup.invalid) {
      this.progressMessage.ko = "Une erreur a été détectée dans l'onglet [Informations personnelles] "
      return;
    }
    if (this.infosVoyageFormGroup.invalid) {
      this.progressMessage.ko = "Une erreur a été détectée dans l'onglet [Informations du voyage] "
      return;
    }
    if (this.confiramtionFormGroup.invalid) {
      this.progressMessage.ko = "Une erreur a été détectée dans l'onglet [Confirmation et validation des CGU]. Le champs CGU est obligatoire."
      return;
    }
    /*if ( this.urlSelectJustif.url === '' ) {
      this.progressMessage.ko = "Une erreur a été détectée dans l'onglet [Informations personnelles]. Le justificatif d'identité est obligatoire."
      return;
    }*/

    const formData = new FormData();
    // Info voyage
    const aerortDepart = this.infosVoyageFormGroup.controls['aeroportDepCtrl'].value ;
    const aerortArrivee = this.infosVoyageFormGroup.controls['aeroportArrCtrl'].value ;
    formData.append('datedepart', this.formaterDate( this.infosVoyageFormGroup.controls['dateDepartCtrl'].value));
    formData.append('datearrivee', this.formaterDate(this.infosVoyageFormGroup.controls['dateArriveeCtrl'].value));
    formData.append('aerodep', aerortDepart.name);
    formData.append('aeroarr', aerortArrivee.name);
    formData.append('paysdep', aerortDepart.pays);
    formData.append('paysarr', aerortArrivee.pays);
    // Info personnelle
    formData.append('nom', this.infosPersoFormGroup.controls['nomCtrl'].value);
    formData.append('prenom', this.infosPersoFormGroup.controls['prenomCtrl'].value);
    formData.append('numTel', this.infosPersoFormGroup.controls['numTelCtrl'].value);
    formData.append('email', this.infosPersoFormGroup.controls['emailCtrl'].value);
    formData.append('justificatif', this.urlSelectJustif.url);
    // Confirmation
    formData.append('comment', this.confiramtionFormGroup.controls['commentCtrl'].value);

    console.log( formData );

    this.backendService.upload( formData, `/voyageur/add`)
    .subscribe (
      (res) => {
        let uploadResponse = res;
        if( uploadResponse.status === 'error' ) {
          this.eventsService.errorsmsg( uploadResponse.message )
        } else {
          if( uploadResponse.status == 'progress' ) {
            this.progressMessage.progress = uploadResponse.message;
          }
          if( uploadResponse.text ) {
            this.progressMessage.ok = "Félicitations ! Votre demande a été enregistrée avec succès et est en attente de validation ! Msahilisho Import & Export s'emploie à la traiter dans les plus brefs délais.";
            setTimeout(() => {
              this.router.navigate(['/home']);
              window.scroll(0,0);
            }, 6000);
          }
        }
      }, (err) => {
        if( typeof err === "string")
          this.eventsService.errorsmsg( err )
          this.progressMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      }
    );

  }

  formaterDate( la_date ){
    return `${la_date.substring(0, 2)}-${la_date.substring(2, 4)}-${la_date.substring(4, 8)}`
  }

  openCGU(){
    window.open( `${this.globalService.getURLApp()}#/conditions-generales-utilisations`  , "_blank")
  }


  changeBTN( action ) {
    this.submitted = false
    if( action == "infosPerso" ) {
      if (this.infosPersoFormGroup.invalid) {
        this.submitted = true
        return;
      }
    }
    if( action == "infosVoyage" ) {
      if (this.infosVoyageFormGroup.invalid) {
        this.submitted = true
        return;
      }
    }
    if( action == "cgu" ) {
      if (this.confiramtionFormGroup.invalid) {
        this.submitted = true
        this.progressMessage.ko = null
        return;
      }
    }
  }

  changeTelephone(value) {
    this.infosPersoFormGroup.patchValue( {
      numTelCtrl: value.tel
    } );
    this.maskTel = value.mask;
  }

  telFormat( ntl , type ) {
    console.log('ntel ' , ntl)
    this.maskTel.value = ntl.image;
    this.maskTel.mask = ntl.mask;
    this.infosPersoFormGroup.patchValue( {
      numTelCtrl: ntl.text
    } );
  }

}
