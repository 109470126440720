import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmtmarcheComponent } from './cmtmarche.component';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { HomeModule} from '../home/home.module'
import { TopheaderModule} from '../topheader/topheader.module'
import { HeaderModule} from '../header/header.module';
import { FooterModule } from '../footer/footer.module'
import { MaterialAngModule } from '../matmodule/materialang.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [CmtmarcheComponent],
  imports: [
    CommonModule,

    NgbModule,
    HomeModule,
    TopheaderModule,
    HeaderModule,
    FooterModule,
    MaterialAngModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class CmtmarcheModule { }
