import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JeveuxvoyagerComponent } from './jeveuxvoyager.component';


import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { HomeModule} from '../home/home.module'
import { TopheaderModule} from '../topheader/topheader.module'
import { HeaderModule} from '../header/header.module';
import { FooterModule } from '../footer/footer.module'
import { MaterialAngModule } from '../matmodule/materialang.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { NgxMaskModule } from 'ngx-mask';


import { GlobalService } from '../services/global/global.service'
import { BackendService } from '../services/backend/backend.service'
import { EventsService } from '../services/events/events.service'
import { ValidatorrService } from '../services/validatorr/validatorr.service'

@NgModule({
  declarations: [
    JeveuxvoyagerComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    HomeModule,
    TopheaderModule,
    HeaderModule,
    FooterModule,
    MaterialAngModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgxMaskModule.forRoot()
  ],
  providers:  [ 
    BackendService, 
    GlobalService,
    EventsService,
    ValidatorrService
  ]
})
export class JeveuxvoyagerModule { }
