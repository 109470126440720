 <!--==========================
      Why Us Section
    ============================-->
    <section id="why-us" class="wow fadeIn">
        <div class="container">
          <header class="section-header">
            <h3>L’humain avant tout <img src="assets/imgs/handshake.svg" class="svg-img" /> </h3>
            <p><span>98%</span> de clients satisfaits. 
              C’est aussi <span>7/7</span> jours d’envoi de colis !
              <!--C'est aussi plus de <span>10 000</span> demandes d'envoi de colis !--></p>
          </header>
  
          <div class="row row-eq-height justify-content-center">
  
            <div class="col-lg-4 mb-4">
              <div class="card wow bounceInUp">
                <img src="assets/imgs/people.svg" class="svg-img" />
                <div class="card-body">
                  <h5 class="card-title">convivial</h5>
                  <p class="card-text">Le plaisir de se faire plaisir et de se rendre service entre particuliers.</p>
                </div>
              </div>
            </div>
  
            <div class="col-lg-4 mb-4">
              <div class="card wow bounceInUp">
                <img src="assets/imgs/wallet.svg" class="svg-img" />
                <div class="card-body">
                  <h5 class="card-title">économique</h5>
                  <p class="card-text">Envoyez vos colis depuis et/ou vers l'Océan Indien en moins de 48H et 30 fois moins cher que les prix du marché.</p>
                </div>
              </div>
            </div>
  
            <div class="col-lg-4 mb-4">
              <div class="card wow bounceInUp">
                <img src="assets/imgs/time.svg" class="svg-img" />
                <div class="card-body">
                  <h5 class="card-title">Rapide</h5>
                  <p class="card-text">Pour vos envois urgents, faites appel à des voyageurs dont les trajets ont lieu le jour même ou le lendemain</p>
                </div>
              </div>
            </div>
  
          </div>
  
          <!--
          <div class="row counters">
  
            <div class="col-lg-3 col-6 text-center">
              <span data-toggle="counter-up">274</span>
              <p>Clients</p>
            </div>
  
            <div class="col-lg-3 col-6 text-center">
              <span data-toggle="counter-up">421</span>
              <p>Projects</p>
            </div>
  
            <div class="col-lg-3 col-6 text-center">
              <span data-toggle="counter-up">1,364</span>
              <p>Hours Of Support</p>
            </div>
  
            <div class="col-lg-3 col-6 text-center">
              <span data-toggle="counter-up">18</span>
              <p>Hard Workers</p>
            </div>
    
          </div>

        -->
  
        </div>
    </section>