<div class="content-app">

    <app-topheader></app-topheader>
 
    <app-header></app-header>


    <!-- Section bar de recherche -->
    <div class="search-bar-top">
        <img src="assets/imgs/customer.svg" class="airport-img" /> Activation de votre compte utilisateur Msahilisho Import & Export
    </div>



    <div class="login-page ">

        <div class="row logo-authentification">
            <div class="col-12 text-center">
                <img *ngIf="resultMessage.ok" src="assets/imgs/customer.svg" class="img-login" /> 
                <img *ngIf="resultMessage.ko" src="assets/imgs/delete.svg" class="img-login" /> 
            </div>
        </div>

        <div class="row" *ngIf="resultMessage.ok || resultMessage.ko ">
            <div class="col-md-12 text-center">
                <div class="alert" [ngClass]="resultMessage.ok != null ? 'alert-success' : 'alert-danger'" role="alert">
                    {{resultMessage.ok != null ? resultMessage.ok : resultMessage.ko }}
                </div>
            </div>
        </div>

        <div class="row" *ngIf="reessayer" >
            <div class="col-4">&nbsp;</div>
            <div class="col-4 text-center">
              <button class="nextLevel" type="button" mat-button (click)="reessayerEncore()" >Réessayer</button>
            </div>
            <div class="col-4">&nbsp;</div>
        </div>


    </div>

    <div class="marg-bot"></div>


    
 
    <app-whychoose-home></app-whychoose-home> 
     
    
    <app-footer></app-footer>

    
</div>
