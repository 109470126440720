import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoDetailComponent } from './info-detail.component'

@Injectable({
  providedIn: 'root'
})
export class InfoDetailService {

  constructor(private modalService: NgbModal) { }

  public confirm(
    infoDetail,
    dialogSize: 'sm'|'lg' = 'lg'): Promise<boolean> {
    const modalRef = this.modalService.open(InfoDetailComponent, { size: dialogSize });
    modalRef.componentInstance.infoDetail = infoDetail;
    return modalRef.result;
  }



}
