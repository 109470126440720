<div class="content-app">

    <app-topheader></app-topheader>
 
    <app-header></app-header>


    <!-- Section bar de recherche -->
    <div class="search-bar-top">

        <form [formGroup]="searchFormGroup" (ngSubmit)="onSubmit()" >
      
            <div class="inner-form row">
      
            <div *ngIf="closeBtn"  class="input-field col-12 col-md-1 clos-btn">
                <button (click)="resetSearch()" class="btn-clos" type="button"><i class="material-icons">close</i></button>
            </div>
              <div class="input-field col-12  text-bold-input " [ngClass]="closeBtn ? 'col-md-2' : 'col-md-3'" >
                <input class="form-control-plaintext" type="text" value="Je cherche un trajet de" />
              </div>
      
              <div class="input-field col-12 col-md-3 text-city-input">
                  <input id="villeDepCtrl" type="text"class="form-control"  placeholder="Indiquer ville ou pays" formControlName="villeDepCtrl" [ngClass]="{ 'is-invalid': submitted && f.villeDepCtrl.errors }" />
              </div>
      
              <div class="input-field text-three col-12 col-md-1 text-bold-input text-center">
                  <!--<input class="form-control-plaintext" type="text" value="à" />-->
                  <img src="assets/imgs/double-arrow.svg" class="arrow_double cursor" (click)="reverseInput()" />
              </div>
      
              <div class="input-field col-12 col-md-3 text-city-input">
                  <input id="villeArrCtrl" type="text" class="form-control" placeholder="Indiquer ville ou pays" formControlName="villeArrCtrl" [ngClass]="{ 'is-invalid': submitted && f.villeArrCtrl.errors }" />
              </div>
      
              <div class="input-field col-12 col-md-2 text-btn-input">
                <button class="btn-search" type="submit">C'est parti !</button>
              </div>
      
            </div>
        </form>


    </div>
    


    <section id="services" class="section-bg">
        <div class="container-service">

            <app-flashinfo-home *ngIf="!publish" ></app-flashinfo-home>
  
          <header class="section-header">
            <h3> 
                <img src="assets/imgs/travel_loc_bl.svg" class="travel_loc_bl" />
                Les prochains départs 
            </h3>
            
          </header>
  
          <div class="row" >


            <div *ngFor="let ntravl of nextTravels; let i = index" [ngClass]="i%2 == 0 ? 'offset-lg-1' : ''" class="col-md-6 col-lg-5 wow bounceInUp cursor" data-wow-duration="1.4s"  (click)="openAnnonce(ntravl)">
                <div class="box " [ngClass]="ntravl.kgdispo > 0 ? '' : 'ligh-bg-no-kilos' " >
                  <div class="icon"><i> <img class="avatar" [src]="ntravl.user_suggest.image64 | safeHtml" /> </i></div>
  
                  
                  <div class="row">
                      <div class="col-md-8">
                          <h4 class="title">
                              <a >{{ntravl.user_suggest.username}} !</a>
                          </h4>
                      </div>
                      <div class="col-md-4 duree-voyage">
                        Durée prévue du trajet : {{ntravl.nombre_jours}} jour(s)
                      </div>
                  </div>
  
                  <div class="detail-user">
                      <div class="row">
                          <div class="col-12 col-lg-6 col-md-6 col-sm-12 date-dep">
                              <i class="fa fa-calendar" aria-hidden="true"></i> <span>{{ ntravl.datedepot | date: 'EE dd MMMM yyyy'}}</span>
                          </div>
                          <div class="col-6 col-lg-2 col-md-2 col-sm-6 icon-transport">
                              <img src="assets/imgs/plane.svg" class="travel-img" />
                          </div>
                          <div class="col-6 col-lg-4 col-md-4 col-sm-6 note-user">
                              <!-- <ngb-rating [(rate)]="currentRate" max="5" [readonly]="true"></ngb-rating> -->
                              <span *ngFor="let strt of currentRate" class="" >
                                <span class="material-icons start-rate-icon">star_rate</span>
                              </span>
                          </div>
                      </div>
                  </div>
  
                  <div class="colis-user">
  
                      <!-- Airport -->
                      <div class="row">
                        <div class="col-md-6">
                            <div class="txt-mute-petit">Date de départ prévue</div>
                            <span class="text-colis"> <img src="assets/imgs/boarding.svg" class="airport-img" /> ({{ ntravl.datedepot | date: 'EE dd MMMM yyyy'}})</span><br /><br />
                            
                            <img src="assets/imgs/airport.svg" class="airport-img" />
                            <span class="airport-text">{{ntravl.aerodep}}</span>
                        </div>
                        <div class="col-md-6">
                            <div class="txt-mute-petit">Date d’arrivée prévue</div>
                            <span class="text-colis"><img src="assets/imgs/waiting-room.svg" class="airport-img" /> ({{ ntravl.daterecup | date: 'EE dd MMMM yyyy'}})</span><br /><br />
                            <img src="assets/imgs/airport.svg" class="airport-img" />
                            <span class="airport-text">{{ntravl.aeroarr}}</span>
                        </div>
                      </div>
  
                      <!-- Colis -->
                      <div class="row prix-aukilo">
                          <div class="col-6 col-md-6 ">
                              <span class="text-colis">Prix /kg</span><br />
                              <img src="assets/imgs/euro.svg" class="airport-img" />
                              <span class="airport-text">{{ntravl.prixkg}} {{ntravl.devise}}</span>
                          </div>
                          <div class="col-6 col-md-6">
                            <span class="text-colis">Nombre de kilos disponibles</span><br />
                              <img src="assets/imgs/box.svg" class="airport-img" />
                              <span class="airport-text">{{ntravl.kgdispo}} KG</span>
                              <span *ngIf="ntravl.kgdispo <= 0" class="plus-de-kg">COMPLET !</span>
                          </div>
                      </div>
  
                  </div>
  
  
                  <!-- <p class="description">Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>-->
  
  
                  <div class="info-user">
                      <div class="row">
                          <div class="col-6 col-md-4 " [ngClass]="ntravl.user_suggest.email != '' ? 'check-icon' : 'uncheck-icon'" >
                              <i class="material-icons">check_circle</i><span>Email</span> 
                          </div>
                          <div class="col-6 col-md-4" [ngClass]="ntravl.user_suggest.telephone != '' ? 'check-icon' : 'uncheck-icon'">
                              <i class="material-icons">check_circle</i><span>Téléphone</span> 
                          </div>
                          <div class="col-6 col-md-4" [ngClass]="ntravl.user_suggest.address != '' ? 'check-icon' : 'uncheck-icon'">
                              <i class="material-icons">check_circle</i><span>Adresse</span> 
                          </div>
                          <!--<div class="col-6 col-md-3" [ngClass]="ntravl.user_suggest.email != '' ? 'check-icon' : 'uncheck-icon'">
                              <i class="material-icons">check_circle</i><span>P. identité</span> 
                          </div>-->
                      </div>
                  </div>
  
  
                </div>

                <span class="slides" *ngIf="ntravl.msahilisho != '0'" ><img src="assets/imgs/mbanner.png" alt=""></span>
                
            </div>

  
          </div>


            <div class="text-center" *ngIf="nextTravels.length > 0 && !notOther " >
                <a (click)="voirPlus()" class="btn-showannonces cursor"> <img src="assets/imgs/search.svg" class="airport-img" /> Voir plus d'annonces (+10)</a>
            </div>

<!--  -->
            <div class="text-center" *ngIf="nextTravels.length <= 0 && loadFinished " >
                <br />
                <img src="assets/imgs/at-the-office-amico.png" class="sad_img" />
                <div class="alert alert-info">
                    {{(trajet_a != "" || trajet_de != "") ? "Aucun résultat ne correspond à votre recherche. ": "Aucun départ n'est programmé pour le moment. Toute notre équipe se mobilise pour vous proposer un trajet dans les plus brefs délais." }}
                </div>
            </div>


  
        </div>
    </section>

    
    
    
    
    <app-whychoose-home></app-whychoose-home> 
     
    
    <app-footer></app-footer>

    
</div>