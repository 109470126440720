<div class="content-app">

    <app-topheader></app-topheader>
 
    <app-header></app-header>


    <!-- Section bar de recherche -->
    <div class="search-bar-top">
        <img src="assets/imgs/sign-up.svg" class="airport-img" /> Créer un compte Msahilisho Import & Export
    </div>


    <app-flashinfo-home *ngIf="!publish" ></app-flashinfo-home>


    <div class="login-page ">

        <div class="row logo-authentification">
            <div class="col-12 text-center">
                <img src="assets/imgs/sign-up.svg" class="img-login" /> 
            </div>
        </div>

        <div class="row" *ngIf="resultMessage.ok || resultMessage.ko ">
            <div class="col-md-12">
                <div class="alert" [ngClass]="resultMessage.ok != null ? 'alert-success' : 'alert-danger'" role="alert">
                    {{resultMessage.ok != null ? resultMessage.ok : resultMessage.ko }}
                </div>
            </div>
        </div>

        <form [formGroup]="signupFormGroup" (ngSubmit)="onSubmit()" >

            <div class="row margin-top-form">
                <div class="col-12 col-md-6">

                    <div class="form-group">
                        <label for="exampleInputEmail1">Nom</label>
                        <div class="input-group mb-2 mr-sm-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text"><i class="material-icons">person</i></div>
                            </div>
                            <input type="text" class="form-control" id="nomCtrl" placeholder="Votre nom" formControlName="nomCtrl" [ngClass]="{ 'is-invalid': submitted && f.nomCtrl.errors || nameError  }" >
                          </div>
                    </div>

                </div>
                <div class="col-12 col-md-6">

                    <div class="form-group">
                        <label for="exampleInputEmail1">Prénom</label>
                        <div class="input-group mb-2 mr-sm-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text"><i class="material-icons">person</i></div>
                            </div>
                            <input type="text" class="form-control" id="prenomCtrl" placeholder="Votre prénom" formControlName="prenomCtrl" [ngClass]="{ 'is-invalid': submitted && f.prenomCtrl.errors }" >
                          </div>
                    </div>

                </div>
            </div>


            <div class="row">
                <div class="col-12 col-md-6">

                    <div class="form-group">
                        <label for="exampleInputEmail1">Votre e-mail</label>
                        <div class="input-group mb-2 mr-sm-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text"><i class="material-icons">email</i></div>
                            </div>
                            <input type="email" class="form-control" id="emailCtrl" placeholder="Votre e-mail" formControlName="emailCtrl" [ngClass]="{ 'is-invalid': submitted && f.emailCtrl.errors }" >
                          </div>
                    </div>

                </div>
                <div class="col-12 col-md-6">

                    <div class="form-group">
                        <label for="emailConfirmCtrl">Confirmer votre e-mail</label>
                        <div class="input-group mb-2 mr-sm-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text"><i class="material-icons">email</i></div>
                            </div>
                            <input (paste)="onPaste($event)" (change)="formulaireChange()" type="email" class="form-control" id="emailConfirmCtrl" placeholder="Confirmer votre e-mail" formControlName="emailConfirmCtrl" [ngClass]="{ 'is-invalid': submitted && f.emailConfirmCtrl.errors || emailError }" >
                          </div>
                    </div>

                </div>
            </div>


            <div class="row">
                <div class="col-12 col-md-6">

                    <div class="form-group">
                        <label for="exampleInputEmail1">Mot de passe</label>
                        <div class="input-group mb-2 mr-sm-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text"><i class="material-icons">lock</i></div>
                            </div>
                            <input type="password" class="form-control" id="passwordCtrl" placeholder="Votre mot de passe" formControlName="passwordCtrl" [ngClass]="{ 'is-invalid': submitted && f.passwordCtrl.errors }" >
                          </div>
                    </div>

                </div>
                <div class="col-12 col-md-6">

                    <div class="form-group">
                        <label for="passwordConfirmCtrl">Confirmer mot de passe</label>
                        <div class="input-group mb-2 mr-sm-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text"><i class="material-icons">lock</i></div>
                            </div>
                            <input (paste)="onPaste($event)" (change)="formulaireChange()" type="password" class="form-control" id="passwordConfirmCtrl" placeholder="Confirmer votre mot de passe" formControlName="passwordConfirmCtrl" [ngClass]="{ 'is-invalid': submitted && f.passwordConfirmCtrl.errors || passwordError }" >
                          </div>
                    </div>

                </div>
            </div>

            

            <div class="row txt-connexion">
                <div class="col-md-6">
                    <button *ngIf="publish" type="submit" class="btn-connexion">M'inscrire</button>
                </div>
                
                <div class="col-md-6">
                    <button type="button" class="btn-cgu" (click)="openCGU()" >En cliquant sur "M'inscrire" vous acceptez nos <span>CGU</span> </button>
                </div>

            </div>

            <div class="dtcgu"> *CGU :  Conditions Générales d'Utilisation </div>

          </form>


    </div>

    <div class="marg-bot"></div>


    
 
    <app-whychoose-home></app-whychoose-home> 
     
    
    <app-footer></app-footer>

    
</div>
