
<div class="publicite-div">

    <div class="title-pub"> 
        <span> Covid-19</span> Dépôt , récupéreration et livraison sans contact
    </div>

    <div class="row">
        <div class="col-12 col-md-6 col-lg-6">

            <div class="image-pub">
                <img src="assets/imgs/CoronavirusDeliveryPreventions.png" class="pub-svg" />
            </div>

        </div>

        <div class="col-12 col-md-6 col-lg-6">

            <div class="text-pub">
                Nos équipes sont entièrement dédiées à affronter cette crise et à maintenir les récupérations, les dépôts et les livraisons lorsque cela est possible. 
                La situation que nous vivons actuellement évolue tous les jours et nous mesurons l’évolution des événements de très près. 
                Si la situation venait à changer de manière significative, nous publierons de nouvelles dispositions. <br /><br />
        
                Avec la propagation du Covid-19 dans le monde, <span>Msahilisho Group</span> a un rôle à jouer, en tant qu’acteur majeur des transports de colis vers l'Océan Indien, afin de ralentir la diffusion. <br /><br />
        
                Chez <span>Msahilisho Group</span>, nous suivons de près la situation liée au Coronavirus (Covid-19) et mettons en œuvre les recommandations émises par l’Organisation Mondiale de la Santé et le gouvernement. <br /><br />
        
                Nous nous assurons que les mesures de précautions sont en place pour protéger à la fois nos salariés et le service que nous fournissons à nos clients. <br />
                
            </div>

        </div>
    </div>

</div>
