    <!--==========================
      About Us Section
    ============================-->
    <section id="about">
        <div class="container">
  
          <header class="section-header">
            <h3>D'accord ! Mais comment ça marche <img src="assets/imgs/brainstorming.svg" class="brainstorming-img" /> ? </h3>
            <p>Faites livrer vos colis et vos achats par des particuliers</p>
          </header>
  
          <div class="row about-container">
  
            <div class="col-lg-6 content order-lg-1 order-2">

              <div class="icon-box wow fadeInUp">
                <div class="icon">
                    <svg viewBox="0 0 24 24" color="#0069D8" height="30" width="30" class="css-v1lamc"><g><path d="M12 24a.72.72 0 0 1-.225-.037.426.426 0 0 1-.054-.017l-11.25-4.5A.745.745 0 0 1 0 18.75V5.25a.748.748 0 0 1 .244-.55 1.504 1.504 0 0 1 .086-.071.571.571 0 0 1 .087-.049.662.662 0 0 1 .053-.026l11.251-4.5a.74.74 0 0 1 .558 0l11.249 4.5a.489.489 0 0 1 .052.025.641.641 0 0 1 .134.084l.036.031a.722.722 0 0 1 .094.102c.016.022.031.042.044.064a.798.798 0 0 1 .112.39v13.5a.747.747 0 0 1-.472.697l-11.25 4.5A.761.761 0 0 1 12 24zm.75-1.858l9.75-3.9V6.358l-9.75 3.9v11.884zm-1.5 0V10.258l-9.75-3.9v11.885l9.75 3.899zm.75-13.2l4.168-1.667-9.23-3.692L2.77 5.25 12 8.942zm6.187-2.475L21.23 5.25 12 1.558 8.957 2.775l9.23 3.692z"></path><path d="M18.75 18a.744.744 0 0 1-.696-.472.747.747 0 0 1 .418-.975l1.875-.75a.744.744 0 0 1 .974.418.747.747 0 0 1-.418.975l-1.875.75a.732.732 0 0 1-.278.054z"></path></g></svg>
                </div>
                <h4 class="title"><a href="">C’est très facile</a></h4>
                <p class="description">Vous êtes voyageur, déposez une annonce décrivant votre trajet et le nombre de kilos que vous pouvez transporter.</p>
              </div>
  
              <div class="icon-box wow fadeInUp" data-wow-delay="0.2s">
                <div class="icon">
                    <svg viewBox="0 0 24 24" color="#0069D8" height="30" width="30" class="css-40wtpc"><g><path d="M5.25 18a3.754 3.754 0 0 1-3.75-3.75c0-2.068 1.682-3.75 3.75-3.75S9 12.182 9 14.25A3.754 3.754 0 0 1 5.25 18zm0-6C4.009 12 3 13.009 3 14.25s1.009 2.25 2.25 2.25 2.25-1.009 2.25-2.25S6.491 12 5.25 12zm4.5 12a.75.75 0 0 1-.75-.75c0-2.068-1.682-3.75-3.75-3.75S1.5 21.182 1.5 23.25a.75.75 0 0 1-1.5 0C0 20.355 2.355 18 5.25 18s5.25 2.355 5.25 5.25a.75.75 0 0 1-.75.75zm9.005-6.004a3.766 3.766 0 0 1-3.256-1.879 3.743 3.743 0 0 1-.5-1.867.75.75 0 1 1 1.5-.002c0 .393.104.78.3 1.12a2.253 2.253 0 0 0 3.073.828 2.254 2.254 0 0 0 .828-3.073.75.75 0 0 1 1.3-.748 3.753 3.753 0 0 1-3.245 5.621zM23.25 24a.75.75 0 0 1-.75-.75c0-2.068-1.682-3.75-3.75-3.75S15 21.182 15 23.25a.75.75 0 0 1-1.5 0c0-2.895 2.355-5.25 5.25-5.25S24 20.355 24 23.25a.75.75 0 0 1-.75.75zm-12-9a.747.747 0 0 1-.75-.75V10.5H8.25a.75.75 0 0 1-.75-.75v-9A.75.75 0 0 1 8.25 0h12a.75.75 0 0 1 .75.75v9a.75.75 0 0 1-.75.75h-4.189l-4.28 4.28a.747.747 0 0 1-.531.22zm0-6a.75.75 0 0 1 .75.75v2.689l3.22-3.22A.754.754 0 0 1 15.75 9h3.75V1.5H9V9h2.25z"></path><path d="M11.25 4.5a.75.75 0 0 1 0-1.5h6a.75.75 0 0 1 0 1.5h-6zm0 3a.75.75 0 0 1 0-1.5h6a.75.75 0 0 1 0 1.5h-6z"></path></g></svg>
                </div>
                <h4 class="title"><a href="">En temps réel</a></h4>
                <p class="description">Msahilisho connecte les expéditeurs et les transporteurs qui font le trajet. Mettez-vous d’accord sur les modalités du transport.</p>
              </div>
  
              <div class="icon-box wow fadeInUp" data-wow-delay="0.4s">
                <div class="icon">
                    <svg viewBox="0 0 24 24" color="#0069D8" height="30" width="30" class="css-11xk8ch"><g><path d="M8.25 18A2.252 2.252 0 0 1 6 15.75v-4.5c0-.96.615-1.808 1.5-2.121V7.5C7.5 5.019 9.519 3 12 3s4.5 2.019 4.5 4.5v1.629A2.258 2.258 0 0 1 18 11.25v4.5A2.252 2.252 0 0 1 15.75 18h-7.5zm0-7.5a.75.75 0 0 0-.75.75v4.5c0 .414.336.75.75.75h7.5a.75.75 0 0 0 .75-.75v-4.5a.75.75 0 0 0-.75-.75h-7.5zM15 9V7.5c0-1.654-1.346-3-3-3s-3 1.346-3 3V9h6z"></path><path d="M12 14.625c-.62 0-1.125-.505-1.125-1.125a1.127 1.127 0 0 1 1.923-.794A1.126 1.126 0 0 1 12 14.625z"></path><path d="M12 24a.744.744 0 0 1-.194-.026C4.083 21.914 0 17.255 0 10.5V.75A.75.75 0 0 1 .75 0h22.5a.75.75 0 0 1 .75.75v9.75c0 6.755-4.083 11.414-11.807 13.475A.774.774 0 0 1 12 24zM1.5 10.5c0 6.031 3.532 10.059 10.5 11.973 6.968-1.915 10.5-5.942 10.5-11.973v-9h-21v9z"></path></g></svg>
                </div>
                <h4 class="title"><a href="">100% sécurisé</a></h4>
                <p class="description">Un accord est trouvé ? Bloquez la réservation sur Msahilisho Import & Export. L’argent sera versé au transporteur une fois le bien réceptionné par le destinataire.</p>
              </div>
  
            </div>
  
            <div class="col-lg-6 background order-lg-2 order-1 wow fadeInUp">

              <img src="assets/imgs/CoronavirusDelivery.svg" class="img-fluid" alt="">
              <!-- <img src="assets/imgs/Delivery-pana.svg" class="img-fluid" alt=""> -->
            </div>
          </div>
  
        </div>
    </section><!-- #about -->
