import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';

import { GlobalService } from '../../services/global/global.service'
import { BackendService } from '../../services/backend/backend.service'
import { EventsService } from '../../services/events/events.service'

import {FormControl, FormGroup, Validators, FormBuilder} from '@angular/forms';
import { Router } from '@angular/router';
@Component({
  selector: 'app-trajets',
  templateUrl: './trajets.component.html',
  styleUrls: ['./trajets.component.scss']
})
export class TrajetsComponent implements OnInit {

  nextTravels = []
  urlImage
  currentRate = 5
  limite = 0
  userConnected
  editAction = false
  loadFinished = false
  notOther = false

  constructor(
    private globalService: GlobalService,
    private backendService: BackendService,
    private _formBuilder: FormBuilder,
    private eventsService: EventsService,
    private router: Router
  ) { }

  ngOnInit() {

    if( !this.globalService.getConnectedUser() ) {
      this.router.navigate(['/home']);
      window.scroll(0,0);
    }

    this.userConnected = this.globalService.getConnectedUser()
    this.urlImage = this.globalService.urlImage
    this.getAnnonces()
  }


  getAnnonces(){

    let annonce = {
      limite: this.limite
    }
    this.loadFinished = false;
    this.backendService.post(`/suggest/list/${this.userConnected.id}` , annonce )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.nextTravels = []
      } else {
        this.nextTravels = this.nextTravels.concat( resultat )
        this.notOther = resultat.length < 10 ? true : false
        console.log('mes trajet ', resultat)
      }
      this.loadFinished = true
    })
    .catch( error => {
      this.nextTravels = []
    })
  }


  voirPlus(){
    this.limite = this.nextTravels.length
    this.getAnnonces()
  }


  editAnnonce(element) {

    console.log(element )
    const dateJour = new Date();
    const dateTrajet = new Date( element.daterecup );
    dateJour.setHours(0,0,0);
    dateTrajet.setHours(0,0,0);

    if (dateJour.getTime() > dateTrajet.getTime()) {
      this.eventsService.toastrwaring("Trajet terminé. Vous ne pouvez plus le modifier.");
    } else {
      this.globalService.setAnnoneEnModification( element )
      this.eventsService.onPageSelected( "edit-trajet" );
      window.scroll(0,0);
    }
    
  }



}
