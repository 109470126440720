<div class="content-app">

    <app-topheader></app-topheader>
 
    <app-header></app-header>


    <!-- Section bar de recherche -->
    <div class="search-bar-top">
        <img src="assets/imgs/shield.svg" class="airport-img" /> Politique de confidentialité
    </div>


    <div class="row content-cgu-term" *ngFor="let cgu of cguInfos; let i = index" >

        <div class=" col-12" [innerHTML]="cgu.contenu"  > 
            Msahilisho Group, immatriculée sous le numero de SIRET XXXXXXXXXXXXXXXXX au Régistre du Commerce et des Sociétés (RCS) de Paris, 
            accorde une importance à la protection et au respect de votre vie privée. 
            La présente politique vise à vous présenter la manière dont nous traitons les données personnelles que nous recueillons et que 
            vous nous fournissez par le biais de notre plateforme www.msahilishogroup.com
        </div>

        <div class="col-12 test-normal">Éditer le {{cgu.create_at | date: 'EEEE dd MMMM yyyy' }} </div>

    </div>


    <div class="marg-bot"></div>


    
 
    <app-whychoose-home></app-whychoose-home> 
     
    
    <app-footer></app-footer>

    
</div>
