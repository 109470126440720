<div class="content-app">

    <app-topheader></app-topheader>
 
    <app-header></app-header>


    <!-- Section bar de recherche -->
    <div class="search-bar-top">
        <img src="assets/imgs/ui.svg" class="airport-img" /> Réinitialiser mon mot de passe utilisateur Msahilisho Import & Export
    </div>



    <div class="login-page ">

        <div class="row logo-authentification">
            <div class="col-12 text-center">
                <img *ngIf="resultMessage.ok" src="assets/imgs/customer.svg" class="img-login" /> 
                <img *ngIf="resultMessage.ko" src="assets/imgs/delete.svg" class="img-login" /> 
            </div>
        </div>

        <div class="row" *ngIf="resultMessage.ok || resultMessage.ko ">
            <div class="col-md-12 text-center">
                <div class="alert" [ngClass]="resultMessage.ok != null ? 'alert-success' : 'alert-danger'" role="alert">
                    {{resultMessage.ok != null ? resultMessage.ok : resultMessage.ko }}
                </div>
            </div>
        </div>


        <form [formGroup]="passwordFormGroup" (ngSubmit)="onSubmitPassword()" *ngIf="!resultMessage.ok && canReinitialise " >
            <span class="infoPersonnelle">Modifier votre mot de passe   </span>

            <div class="row">

                <div class="col-md-6">

                    <div class="form-group" >
                        <label for="mdpNewCtrl">Nouveau mot de passe  <span>(*)</span></label>
                        <legend>Votre nouveau mot de passe </legend>
                        <input id="mdpNewCtrl" type="password" class="form-control" formControlName="mdpNewCtrl" placeholder="Votre nouveau mot de passe" required [ngClass]="{ 'is-invalid': submitted && f1.mdpNewCtrl.errors }"  >
                    </div>

                </div>

                <div class="col-md-6">

                    <div class="form-group" >
                        <label for="mdpNewConfCtrl">Confirmer votre mot de passe  <span>(*)</span></label>
                        <legend>Confirmer votre mot de passe</legend>
                        <input id="mdpNewConfCtrl" type="password" class="form-control" formControlName="mdpNewConfCtrl" placeholder="Confirmer votre nouveau mot de passe" required [ngClass]="{ 'is-invalid': submitted && f1.mdpNewConfCtrl.errors }"  >
                    </div>

                </div>
            </div>


            <div class="row">
              <div class="col-12">
                <button class="nextLevel" type="submit" mat-button >Valider et enregistrer</button>
              </div>
            </div>

        </form>


    </div>

    <div class="marg-bot"></div>


    
 
    <app-whychoose-home></app-whychoose-home> 
     
    
    <app-footer></app-footer>

    
</div>
