<div class="content-app">

    <app-topheader></app-topheader>
 
    <app-header></app-header>


    <!-- Section bar de recherche -->
    <div class="search-bar-top">
        <img src="assets/imgs/terms-and-conditions.svg" class="airport-img" /> Conditions générales d'utilisation
    </div>


    <div class="row content-cgu-term" *ngFor="let cgu of cguInfos; let i = index" >

        <div class="test-normal col-12"> 
            Les conditions Générales d'utilisation des applications Msahilisho Import & Export, 
            service d’envoi de colis entre particuliers édité parMsahilisho Group. 
            Version mise à jour ligne {{cgu.create_at | date: 'EEEE dd MMMM yyyy' }}
        </div>

        <div class="col-12" [innerHTML]="cgu.contenu" ></div>

    </div>


    <div class="marg-bot"></div>


    
 
    <app-whychoose-home></app-whychoose-home> 
     
    
    <app-footer></app-footer>

    
</div>
