import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { GlobalService } from '../../services/global/global.service'
import { BackendService } from '../../services/backend/backend.service'


@Component({
  selector: 'app-details-ad',
  templateUrl: './details-ad.component.html',
  styleUrls: ['./details-ad.component.scss']
})
export class DetailsAdComponent implements OnInit {

  nextTravels
  urlImage
  randomNumber = 0
  currentRate
  userConnected

  infosProducts = []

  constructor( 
    private router: Router,
    private globalService: GlobalService,
    private backendService: BackendService
  ) {
  }


  ngOnInit() {

    this.currentRate = Array(5).fill(5)
    this.infosProducts = this.globalService.getInfosProducts();
    //this.infosProducts.pop();

    if( !this.globalService.getAnnoncdSelected() ) {
      this.router.navigate(["/expedier-ou-recevoir-un-colis"]);
      window.scroll(0,0);
    }
    this.randomNumber = Math.floor(Math.random() * Math.floor(10)) + 1 ;
    this.nextTravels = this.globalService.getAnnoncdSelected();
    this.urlImage = this.globalService.urlImage
    this.isConnectedUser()
  }


  isConnectedUser() {
    this.userConnected = this.globalService.getConnectedUser()
  }



  openPage( ){
    console.log('open annonce')

    if( this.userConnected ) {
      this.router.navigate(["/choisir-annonce"]);
    } else {
      this.router.navigate(["/login"]);
    }
    window.scroll(0,0);
    
  }

  goBack() {
    this.router.navigate(["/expedier-ou-recevoir-un-colis"]);
    window.scroll(0,0);
  }

}
