import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReinitpassComponent } from './reinitpass.component';


import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { HomeModule} from '../home/home.module'
import { TopheaderModule} from '../topheader/topheader.module'
import { HeaderModule} from '../header/header.module';
import { FooterModule } from '../footer/footer.module'
import { MaterialAngModule } from '../matmodule/materialang.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BackendService } from '../services/backend/backend.service'

@NgModule({
  declarations: [ReinitpassComponent],
  imports: [
    CommonModule,
  
    NgbModule,
    HomeModule,
    TopheaderModule,
    HeaderModule,
    FooterModule,
    MaterialAngModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [BackendService]

})
export class ReinitpassModule { }
