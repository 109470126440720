<div class="modal-header">
    <h4 class="modal-title note-text "> {{ expeReceive.nature_trans }} - Transaction N° {{ expeReceive.numtrans }} </h4>
    <button type="button" class="close" aria-label="Close" (click)="decline()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    
    <form [formGroup]="noteInputs" >
        <div class="example-container">

            <div class="row">
                <div class="col-md-12">

                    <div class="form-group" >
                        <label for="noteCtrl">{{msgContenu.label}}  <span>(*)</span></label>
                        <legend> {{msgContenu.legend}} </legend>
                        <textarea class="form-control" id="noteCtrl" rows="3" formControlName="noteCtrl" placeholder="{{msgContenu.placeholder}}"></textarea>
                    </div>

                </div>
            </div>


            <div class="row" *ngIf="typeOuverture != 'note' " >
                <div class="col-md-12">

                    <div class="form-group" >
                        <label for="currentRateCrtl">Expéditeur  <span>(*)</span></label>
                        <legend>Votre note de 1 à 5</legend>
                        <ngb-rating [(rate)]="currentRate" max="5"></ngb-rating>
                    </div>

                </div>
            </div>


        </div>
    </form>

</div>


<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="decline()">Annuler</button>
    <button type="button"  class="btn selectionner" (click)="noter()" > Noter </button>
</div>