import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {FormControl, FormGroup, Validators, FormBuilder} from '@angular/forms';

import { GlobalService } from '../../services/global/global.service'
import { BackendService } from '../../services/backend/backend.service'
import { EventsService } from '../../services/events/events.service'
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { not } from '@angular/compiler/src/output/output_ast';
@Component({
  selector: 'app-choose-ad',
  templateUrl: './choose-ad.component.html',
  styleUrls: ['./choose-ad.component.scss']
})
export class ChooseAdComponent implements OnInit {

  infosFormGroup: FormGroup;
  nextTravels
  urlImage
  randomNumber = 0
  currentRate
  userConnected
  submitted
  messageRetour

  typeObjet = []
  typeSelected = "colis"
  infoPlaceholder : any = {}
  typeEnveloppe = []
  infosProducts = []

  produiSelect = null

  masktelArr = {mask: "(+00) 0 00 00 00 00" , value: "france.png"}
  maskTelExp = {mask: "(+00) 0 00 00 00 00" , value: "france.png"}

  listTelephone = [];


  constructor( 
    private router: Router ,
    private _formBuilder: FormBuilder,
    private globalService: GlobalService,
    private backendService: BackendService,
    private eventsService: EventsService,
    public dialog: MatDialog
  ) { }


  ngOnInit() {

    this.listTelephone = this.globalService.getListeIndicatifTelephone();
    this.currentRate = Array(5).fill(5)
    this.infosProducts = this.globalService.getInfosProducts();
    //this.infosProducts.push({id: "autre", nomproduct: "Autre", image: "other.svg", prixproduct: "", partype: "", poids: "0" })

    if( !this.globalService.getAnnoncdSelected() ) {
      this.router.navigate(["/expedier-ou-recevoir-un-colis"]);
      window.scroll(0,0);
    }

    this.typeObjet = this.globalService.getTypeObjet();
    this.infoPlaceholder = this.globalService.getInfoPlaceholder();
    this.typeEnveloppe = this.globalService.getTypeEnveloppe();

    this.userConnected = this.globalService.getConnectedUser();
    this.maskTelExp = this.globalService.maskSaisi( this.userConnected.telephone );

    this.infosFormGroup = this._formBuilder.group({
      nomPrenomExpediteurCtrl: [`${this.userConnected.firstname} ${this.userConnected.lastname[0]}`],
      telExpediteurCtrl: [ this.userConnected.telephone , Validators.required ],
      adresseLivraisonCtrl: ['', Validators.required],
      nomPrenomDestinataireCtrl: ['', Validators.required],
      telephoneDestinataireCtrl: ['33 ', Validators.required],
      infoObjetCtrl: ['', Validators.required],
      poidsCtrl: ['', Validators.required],
      tailleCtrl: ['normal'],
      typeObjetCtrl: ['colis', Validators.required ],

      adresseExpediteurCtrl: [this.globalService.getAdresse(this.userConnected), Validators.required],
      garantieColisCtrl: ['non', Validators.required ],
      valeurCtrl: ['', [Validators.required, Validators.max(3000)] ],
      confirmationCtrl: ['', Validators.required]
    });

    

    this.randomNumber = Math.floor(Math.random() * Math.floor(10)) + 1 ;
    this.nextTravels = this.globalService.getAnnoncdSelected();
    this.urlImage = this.globalService.urlImage;
    console.log( this.nextTravels )
  }

  ngOnDestroy() {
    this.globalService.removeAnnoncdSelected()
  }


  get f() { 
    return this.infosFormGroup.controls; 
  }


  onTypeSelection( event ){
    this.typeSelected = this.f.typeObjetCtrl.value
    if( this.typeSelected == "enveloppe" ) {
      this.infosFormGroup.patchValue({
        tailleCtrl: this.typeEnveloppe[1].value
      })
    }
  }

  calculRemiseFidelite() {
    if (!!this.userConnected.pointclient && this.userConnected.pointclient[0] ) {
      const recommandation = parseFloat(this.userConnected.pointclient[0].recommandation);
      const expedition = parseFloat(this.userConnected.pointclient[0].expedition);
      const totalPoints = recommandation + expedition;
      return totalPoints;
    } else {
      return 0;
    }
  }

  onSubmit(){
    this.messageRetour = null
    this.submitted = true
    let msahilisho = 0
    console.log( this.userConnected )

    if( !this.produiSelect ) {
      this.messageRetour = {ko: "Veuillez sélectionner un produit dans la liste." }
      return;
    }

    if( this.produiSelect && this.produiSelect.id == 'autre' && this.f.poidsCtrl.value == "" ) {
      this.messageRetour = {ko: "Le poids de votre colis est obligatoire." }
      return;
    }

    if ( this.produiSelect.id !== 'autre' ) {
      this.infosFormGroup.patchValue({
        typeObjetCtrl: "colis",
        poidsCtrl: this.produiSelect.poids,
        tailleCtrl: this.produiSelect.nomproduct
      })
    }

    console.log( this.infosFormGroup )
    // stop here if form is invalid
    if (this.infosFormGroup.invalid) {
      this.messageRetour = {ko: "Veuillez vérifier les informations saisies." }
      return;
    }
    if ( parseFloat( this.f.valeurCtrl.value ) < 0  ) {
      this.messageRetour = {ko: "Veuillez vérifier les informations saisies. La valeur de votre colis doit être un nombre positif." }
    }

    //Calculer le prix
    let prixTotal;
    let lePoidsAukg;
    const valeurColis = parseFloat( this.f.valeurCtrl.value );

    if( this.produiSelect.id === 'autre' ) {
      lePoidsAukg = parseFloat( this.f.poidsCtrl.value ) 
      if( parseInt( this.f.poidsCtrl.value ) < 1 ) {
        lePoidsAukg = 1
      }
      prixTotal = ( lePoidsAukg * parseFloat( this.nextTravels.prixkg ) ).toFixed(2)
      msahilisho = 0
    } else {
      // Produit dans la liste des produits non pesés de msahilisho
      prixTotal = (valeurColis * this.globalService.pourcentageGarantie).toFixed(2);
      lePoidsAukg = parseFloat( this.produiSelect.poids ); // produits non pesable donc zero kg
      msahilisho = this.produiSelect.id
    }
    

    let prixavecgarantie;
    if ( this.f.garantieColisCtrl.value === 'non' ) {
      prixavecgarantie = prixTotal;
    } else {
      prixavecgarantie = (parseFloat(prixTotal) + (valeurColis * this.globalService.pourcentageGarantie)).toFixed(2);
    }

    if ( lePoidsAukg > parseFloat( this.nextTravels.kgdispo ) ) {
      this.messageRetour = {ko: `Le nombre de kilos demandés ${lePoidsAukg} kg est suppérieur au nombre de kilos disponibles (${this.nextTravels.kgdispo} kg)` }
      return;
    }
    
    let laDate = new Date()
    let note = [ {"note": "Demande de prise en charge", "create_at": `${laDate.getDate()}/${laDate.getMonth() +1}/${laDate.getFullYear()}` } ]
    const totalPoints = this.calculRemiseFidelite();
    const laRemisecent = this.globalService.getRemisecentPoints();

    let prixTotalAEnregistrer = prixavecgarantie;
    if ( totalPoints >= 100 ) {
      const prixavecgarantieremise = prixavecgarantie;
      note.push(
        {
          "note": `Vous avez cumulé ${totalPoints} points de fidélité. Une remise de ${laRemisecent}% est appliquée à cette expédition. Montant sans la remise (${prixavecgarantieremise})€.`,
          "create_at": `${laDate.getDate()}/${laDate.getMonth() +1}/${laDate.getFullYear()}` 
        }
      )
      prixTotalAEnregistrer = ( parseFloat( prixTotalAEnregistrer ) * (laRemisecent / 100) ).toFixed(2)
    }

    // Valeur et garantie
    let box = {
      iduserexp: this.userConnected.id,
      nomdest: this.f.nomPrenomDestinataireCtrl.value,
      addrliv: this.f.adresseLivraisonCtrl.value,
      teldest: `${this.f.telephoneDestinataireCtrl.value}`,
      typeobj: this.f.typeObjetCtrl.value,
      tailobj: this.f.tailleCtrl.value,
      telrecup: this.f.telExpediteurCtrl.value,
      poidsobj: lePoidsAukg,
      infosobj: this.f.infoObjetCtrl.value,
      numtrans: `TR-${(Math.random().toString(36).substring(2, 7) + Math.random().toString(36).substring(2, 7) ).toString().toUpperCase()}-${this.userConnected.id}-${(Math.random().toString(36).substring(2, 12) ).toString().toUpperCase()}`,
      etattrans: "En attente de validation",
      prixtrans: prixTotal,
      notestrans: JSON.stringify( note ),
      idtrajet: this.nextTravels.id,
      idusertra: this.nextTravels.user_suggest.id,
      msahilisho: msahilisho,

      addrexp: this.f.adresseExpediteurCtrl.value,
      valeur: valeurColis,
      garantie: this.f.garantieColisCtrl.value,
      prixtotal: prixTotalAEnregistrer,

      totalPoints: totalPoints
    }
    console.log('La box avant envoi ', box);
    
    if ( this.f.garantieColisCtrl.value === 'non' ) {
      const prixenvoisansg = (valeurColis * this.globalService.sansGarantie).toFixed(2)
      let autreMessage3Point = '';
      if ( totalPoints >= 100 ) {
        autreMessage3Point = `L'expédition vous coûtera (sans la remise Msahilisho) ${prixTotal}€ d'envoi + 0€ de garantie, soit ${prixTotal}€.
        Grâce aux systèmes de fidélisation Msahilisho Group, vous avez accumulé ${totalPoints} points de fidélité.
        Vous allez donc avoir une remise de ${laRemisecent}% sur cette expédition pour vous remercier de votre confiance et votre fidélité.`
      } else {
        autreMessage3Point = `L'expédition vous coûtera ${prixTotal}€ d'envoi + 0€ de garantie, soit ${prixTotal}€.`;
      }
      const message = "La garantie Msahilisho Group vous permet de vous faire rembourser ou de remplacer un colis perdu ou détérioré.";
      const autreMessage = "Nous vous conseillons fortement de l'ajouter à votre envoi et de bénéficier la garantie 100% prise en charge.";
      const autreMessage2 = "Sans cette garantie, vous ne toucherez que " + (this.globalService.sansGarantie * 100) + "% de " + valeurColis + "€ soit " + prixenvoisansg + "€ en cas de perte ou détérioration de colis.";
      const autreMessage3 = autreMessage3Point;
      this.openDialog( box, message, autreMessage, autreMessage2, autreMessage3 , totalPoints );
    } else {
      const valeurPourcentage = this.globalService.pourcentageGarantie * 100;
      const prixenvois = (valeurColis * this.globalService.pourcentageGarantie).toFixed(2)
      let autreMessage3Point = '';
      if ( totalPoints >= 100 ) {
        autreMessage3Point = `L'expédition vous coûtera (sans la remise Msahilisho) ${prixTotal}€ d'envoi + ${prixenvois}€ de garantie, soit ${ prixavecgarantie }€..
        Grâce aux systèmes de fidélisation Msahilisho Group, vous avez accumulé ${totalPoints} points de fidélité.
        Vous allez donc avoir une remise de ${laRemisecent}% sur cette expédition pour vous remercier de votre confiance et votre fidélité.`
      } else {
        autreMessage3Point = `L'expédition vous coûtera ${prixTotal}€ d'envoi + ${prixenvois}€ de garantie, soit ${ prixavecgarantie }€.`;
      }
      const message = "La garantie Msahilisho Group vous permet de vous faire rembourser ou de remplacer un colis perdu ou détérioré.";
      const autreMessage = "Vous avez choisi la garantie 100% prise en charge Msahilisho Group. \
      Avec cette garantie, vous toucherez la totalité de la valeur de votre colis soit " + valeurColis + "€ en cas de perte ou détérioration de colis."
      const autreMessage2 = "Cette protection, prise en charge à 100% vous coutera " + prixenvois + "€ soit " + valeurPourcentage + "% de la valeur de votre colis.";
      const autreMessage3 = autreMessage3Point;
      this.openDialog( box, message, autreMessage, autreMessage2, autreMessage3, totalPoints);
    }

  }

  openDialog( box, message, autreMessage, autreMessage2, autreMessage3, totalPoints ): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '550px',
      data: {
        titre: 'Confirmez votre demande', 
        message: message,
        autreMessage: autreMessage,
        autreMessage2: autreMessage2,
        autreMessage3: autreMessage3
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if( !!result ) {
        this.backendService.post('/senbox/add', box )
        .then( resultat => {
          if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
            this.eventsService.errorsmsg( "Erreur lors de la mise en rélation avec le transporteur" )
            this.messageRetour = {ko: resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement." }
          } else {
            this.messageRetour = {ok: resultat.text}
            this.eventsService.successmsg("Votre demande a été transmise au transporteur")

            // Mise à jour des points fidélité
            if ( totalPoints >= 100 ) {
              const expedition = parseFloat(this.userConnected.pointclient[0].expedition) - 100;
              this.userConnected.pointclient[0].expedition = expedition;
              this.globalService.setConnectedUser( this.userConnected ) // resultat[0]
              this.eventsService.onUserConnected( this.userConnected)
            }
          
            setTimeout( () => {
              this.router.navigate(["/dashboard"] );
              window.scroll(0,0);
            }, 2000)
          }
        })
        .catch( error => {
        })
      }
    });
  }


  openPage( annonce ){
    this.router.navigate(["/choisir-annonce"] );
    window.scroll(0,0);
  }


  chooseEnvoieMsahilisho(envoie) {
    this.produiSelect = envoie
    this.messageRetour = null
  }

  goBack() {
    this.router.navigate(["/detail-annonces"]);
    window.scroll(0,0);
  }


  changeTelephone(value, type, id) {
    console.log('a values change ', value)
    if (type === 'exp') {
      this.infosFormGroup.patchValue( {
        telExpediteurCtrl: value.tel
      } );
      this.maskTelExp = value.mask;
    } else {
      this.infosFormGroup.patchValue( {
        telephoneDestinataireCtrl: value.tel
      } );
      this.masktelArr = value.mask;
    }
  }

  telFormat( ntl , type ) {
    console.log('ntel ' , ntl)
    if (type === 'exp') {
      this.maskTelExp.value = ntl.image;
      this.maskTelExp.mask = ntl.mask;
      this.infosFormGroup.patchValue( {
        telExpediteurCtrl: ntl.text
      } );
    } else {
      this.masktelArr.value = ntl.image;
      this.masktelArr.mask = ntl.mask;
      this.infosFormGroup.patchValue( {
        telephoneDestinataireCtrl: ntl.text
      } );
    }
  }


  toggleValeurChange(event) {
    console.log(event)
  }

  openCGU(){
    window.open( `${this.globalService.getURLApp()}#/conditions-generales-utilisations`  , "_blank")
  }




}
