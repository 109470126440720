


<div class="point-fidelite">
    <div class="row">
        <div class="col-md-6 text-left padding-inner-div-fidelite">
            <div class="inner_div">
                <div class="_title_inner"> Numéro Ms F </div>
                <div class="numero-fidelite"> {{numeroFidelite}} </div>
            </div>
        </div>
        <div class="col-md-6 text-right padding-inner-div-fidelite ">
            <div class="inner_div">
                <div class="_title_inner"> Points disponibles </div>
                <div class="points-fidelite">
                    [<span class="material-icons">emoji_events</span> {{getPoints()}}]
                </div>
            </div>
        </div>
    </div>
</div>


<div class="fidelite">


    <div class="accordian-fidelite">
        <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Votre fidélité récompensée
                    </mat-panel-title>
                    <mat-panel-description>
                        En savoir plus
                    </mat-panel-description>
                </mat-expansion-panel-header>

            <div class="content-fidelite">
                <div class="text-normal">
                    Notre programme de fidélité vous permet de bénéficier de réductions dès votre deuxième expédition. <br /><br />

                    Sur votre prochaine expédition, votre solde de points de fidélité est automatiquement utilisé : vous bénéficierez de {{paramsContent.remisecentpoints}}% de réduction par tranche de 100 points de fidélité.
                    Il vous restera alors des points de fidélité, auxquels s'ajouteront les {{paramsContent.colisenvoye}} nouveaux points acquis sur cette nouvelle expédition.
                </div>

                <div class=" margin-top-20" *ngIf="paramsContent">
                    <div class="text-titre-2">
                        Simple et automatique :
                    </div>

                    <div class="row margin-top-10">
                        <div class="col-md-6 text-titre-3">
                            Ouverture de compte client Msahilisho Group
                        </div>
                        <div class="col-md-6">
                            = {{paramsContent.ouverturecompte}} points de fidélité
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 text-titre-3">
                            1 colis envoyé
                        </div>
                        <div class="col-md-6">
                            = {{paramsContent.colisenvoye}} points de fidélité
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 text-titre-3">
                            Recommandation des services Msahilisho Group
                        </div>
                        <div class="col-md-6">
                            = {{paramsContent.recommandation}} points de fidélité
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 text-titre-3">
                            Ajouter un avis à chaque expédition
                        </div>
                        <div class="col-md-6">
                            = {{paramsContent.ajouavis}} points de fidélité
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 text-titre-3">
                            100 points de fidélité
                        </div>
                        <div class="col-md-6">
                            = {{paramsContent.remisecentpoints}}% de remise automatique fidélité sur votre prochaine expédition. <!-- sur une limite de 100€ par expédition -->
                        </div>
                    </div>
                </div>


                <div class=" margin-top-40">

                    <div class="row">
                        <div class="col-md-12 fidelite-royalty">
                            Le cumul de vos points de fidélité : 
                        </div>
                    </div>

                    <div class="row margin-top-10">
                        <div class="col-md-6  fidelitePoints">
                            <span class="point">
                                &nbsp;
                            </span>
                        </div>
                        <div class="col-md-6 fidelitePoints">
                            <span class="point">
                                [<i class="material-icons">emoji_events</i> {{getPoints()}}]
                            </span>
                        </div>
                    </div>

                </div>

            </div>
        </mat-expansion-panel>
        </mat-accordion>
    </div>
    
</div>



