import { Component, OnInit } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatTableDataSource} from '@angular/material/table';

import { NotesService} from '../notes/notes.service'

import { GlobalService } from '../../services/global/global.service'
import { BackendService } from '../../services/backend/backend.service'
import { EventsService } from '../../services/events/events.service'
import { ConfirmService } from '../../modal/confirm/confirm.service';
import { EditEtatService } from '../../modal/edit-etat/edit-etat.service';
import { EditLocationService } from '../../modal/edit-location/edit-location.service'
import { Router } from '@angular/router';


@Component({
  selector: 'app-expedition-encours',
  templateUrl: './expedition-encours.component.html',
  styleUrls: ['./expedition-encours.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ExpeditionEncoursComponent implements OnInit {

  expandedElement

  userConnected
  dataSourceEncoursLength = 0
  dataSourceEncours: [];
  errorMessage = null;

  dataSourceEncoursList = [];
  preparationLivraison: any;


  limite = 0;
  notOther = false
  totalParPage = 2;
  resultatListeSendbox;
  offset = 2;

  constructor( 
    private notesService: NotesService ,
    private eventsService: EventsService,
    private globalService: GlobalService,
    private backendService: BackendService,
    private confirmService: ConfirmService,
    private editEtatService: EditEtatService,
    private editLocationService: EditLocationService,
    private router: Router
  ) { }

  ngOnInit() {

    if( !this.globalService.getConnectedUser() ) {
      this.router.navigate(['/home']);
      window.scroll(0,0);
    }

    this.userConnected = this.globalService.getConnectedUser();
    this.displayPageSuiv( 0 );
  }


  async displayPageSuiv( status ){
    if ( status === 0 ) {
      this.limite = 0;
      if ( !!this.resultatListeSendbox && this.resultatListeSendbox.array_sendbox_encours_user.length >= this.totalParPage ) {
        this.offset = this.resultatListeSendbox.array_sendbox_encours_user.length
      } else if ( !!this.resultatListeSendbox && this.resultatListeSendbox.array_sendbox_no_user_encours.length >= this.totalParPage ) {
        this.offset = this.resultatListeSendbox.array_sendbox_no_user_encours.length
      } else {
        this.offset = 2
      }
    }


    let annonce = {
      limite: this.limite,
      status_end: 0,
      offset : this.offset
    }

    this.dataSourceEncours = [];
    this.backendService.post( `/senbox/list-sendbox-user/${this.globalService.getConnectedUser().id}` , annonce )
    .then( async (resultat) => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.dataSourceEncours = [];
      } else {
        console.log( ' this.initNote(resultat.array_sendbox_encours) ', resultat )
        this.dataSourceEncours = await this.initNote(resultat.array_sendbox_encours) ;
        this.resultatListeSendbox = resultat;

        this.notOther = resultat.array_sendbox_encours.length < this.totalParPage ? true : false
        this.dataSourceEncours.sort((a: any, b: any) => {
          return this.globalService.getDateStringToDate(b.datecreate).getTime() - this.globalService.getDateStringToDate(a.datecreate).getTime()
        });

        this.dataSourceEncoursList = this.dataSourceEncoursList.concat( this.dataSourceEncours );
        this.dataSourceEncoursLength = this.dataSourceEncoursList.length;

        console.log( ' this.dataSourceEncours ', this.dataSourceEncours )

      }
    })
    .catch( error => {
      this.dataSourceEncours = [];
    })
  }

  nextAffiche() {
    if ( this.resultatListeSendbox.array_sendbox_encours_user.length >= this.totalParPage ) {
      this.limite = this.resultatListeSendbox.array_sendbox_encours_user.length
    } else if ( this.resultatListeSendbox.array_sendbox_no_user_encours.length >= this.totalParPage ) {
      this.limite = this.resultatListeSendbox.array_sendbox_no_user_encours.length
    } else {
      console.log(' eslse if vraiment UOI ')
    }
    //this.limite = this.dataSourceTermineeList.length;
    this.displayPageSuiv(1)
  }

  expandedElements(element) {
    this.expandedElement = this.expandedElement === element ? null : element;
  }

  initNote( arrayBoject ){
    for (var i = 0; i < arrayBoject.length; i++) {
      arrayBoject[i].notestrans = JSON.parse( arrayBoject[i].notestrans );

      if ( !!arrayBoject[i].prep_remise_exped ) {

        if ( !!arrayBoject[i].prep_remise_exped.prep_remise_exped[0] && !!arrayBoject[i].prep_remise_exped.prep_remise_exped[0].image_prep ) {
          arrayBoject[i].prep_remise_exped.prep_remise_exped[0].image_prep = JSON.parse( arrayBoject[i].prep_remise_exped.prep_remise_exped[0].image_prep );
        }
        if ( !!arrayBoject[i].prep_remise_exped.prep_remise_exped[0] && !!arrayBoject[i].prep_remise_exped.prep_remise_exped[0].image_recup ) {
          arrayBoject[i].prep_remise_exped.prep_remise_exped[0].image_recup = JSON.parse( arrayBoject[i].prep_remise_exped.prep_remise_exped[0].image_recup );
        }
        if ( !!arrayBoject[i].prep_remise_exped.prep_remise_exped[0] && !!arrayBoject[i].prep_remise_exped.prep_remise_exped[0].photo_livr ) {
          arrayBoject[i].prep_remise_exped.prep_remise_exped[0].photo_livr = JSON.parse( arrayBoject[i].prep_remise_exped.prep_remise_exped[0].photo_livr );
        }
        if ( !!arrayBoject[i].prep_remise_exped.prep_remise_exped[0] && !!arrayBoject[i].prep_remise_exped.prep_remise_exped[0].sign_livr ) {
          arrayBoject[i].prep_remise_exped.prep_remise_exped[0].sign_livr = JSON.parse( arrayBoject[i].prep_remise_exped.prep_remise_exped[0].sign_livr );
        }
        if ( !!arrayBoject[i].prep_remise_exped.prep_remise_exped[0] && !!arrayBoject[i].prep_remise_exped.prep_remise_exped[0].sign_recup ) {
          arrayBoject[i].prep_remise_exped.prep_remise_exped[0].sign_recup = JSON.parse( arrayBoject[i].prep_remise_exped.prep_remise_exped[0].sign_recup );
        }
      }

    }
    return arrayBoject;
  }


  ajouterUneNote( event: Event, element ){
    event.stopPropagation();
    this.errorMessage = null;

    if ( element.user_sender.user_direct ) {
      this.pasTraitementSiMsahilisho();
      return;
    }

    this.notesService.confirmService( element , 'note')
    .then( (confirmed : any ) => {
      
      if( confirmed.status ) {
        this.eventsService.successmsg( "Note ajoutée avec succès." )
        setTimeout(() => {
          this.displayPageSuiv(0)
        }, 2000);
      }
    })
    .catch( (error) => {
      
    })

  }


  btnEncoursAction(event: Event, element, action ){
    event.stopPropagation();
    this.errorMessage = null;

    if ( element.user_sender.user_direct ) {
      this.pasTraitementSiMsahilisho();
      return;
    }

    /*if( action == "edit" ) {
      if( element.localisation === "Chez l'expéditeur" && ( element.etattrans !== "En cours" && element.etattrans !== "Validée par le transporteur" )   ) {
        this.globalService.setAnnoneEnModification( element )
        this.eventsService.onPageSelected( "edit-sendbox" )
      } else {
        this.eventsService.errorsmsg("Vous ne pouvez plus modifier cette expédition")
      }
    } else */if( action == "valider" ) {
      if( element.infos_suggest.user_suggest.id != this.userConnected.id ) {
        this.eventsService.errorsmsg("Seul le transporteur peut valider la transaction.")
      } else {
        this.validerExpedition( element )
      }
    } else if( action == "etat" ) {
      //this.editEtat( element )
      if( element.infos_suggest.user_suggest.id != this.userConnected.id ) {
        this.eventsService.errorsmsg("Seul le transporteur peut valider la transaction.")
      } else {
        this.editEtat( element )
      }
    } else if( action == "location" ) {

      // this.editLocation( element )
      if( element.etattrans == "En attente de validation" ) {
        this.eventsService.errorsmsg("Colis chez l'expéditeur et en attente de validation.")
      } else {

        if( element.infos_suggest.user_suggest.id != this.userConnected.id ) {
          this.eventsService.errorsmsg("Seul le transporteur peut changer la localisation.")
        } else {
          this.editLocation( element )
        }

      }
      
    } else if( action == "delete" ) {

      if( element.etattrans === "En attente de validation" || element.etattrans === "Validée par le transporteur") {
        if( element.user_sender.id != this.userConnected.id ) {
          this.eventsService.errorsmsg("Seul l'expéditeur peut annuler l'expédition.")
        } else {
          this.deleteExpe( element )
        }
      } else {
        this.eventsService.errorsmsg("Vous ne pouvez plus annuler l'expédition.")
      }

    }

  }


  canEdit( element ) {
    return element.user_sender.id == this.userConnected.id ? true : false
  }


  isValidate( element ) {

    if( element.etattrans == "En cours" || element.etattrans == "Validée par le transporteur" ){
      return false
    } else {
      return true
    }
  }

  canValidate( element ) {
    return element.infos_suggest.user_suggest.id == this.userConnected.id ? true : false 
  }

  validerExpedition(element) {
    this.confirmService.confirm(`Traitement de la demande - ${element.numtrans}`, 'Êtes-vous sûr de vouloir valider cette demande ?', 'Valider', 'Annuler' , 'Refuser' )
    .then( (confirmed : any) => {

      if( confirmed.etat != "cancel" ) {

        let box = {
          iduserexp: this.userConnected.id,
          numtrans: element.numtrans,
          notestrans: null,
          etattrans: null,
          idtrajet: element.infos_suggest.id,
          kgdispo: element.infos_suggest.kgdispo,  //parseFloat( element.infos_suggest.kgdispo ) - parseFloat(element.poidsobj)
          poidsobj: element.poidsobj,
          user_direct: !!element.user_sender.user_direct,
          email_user: !!element.user_sender.email ? element.user_sender.email : null
        }

        if( confirmed.etat == "accept") {

          let dateAct = new Date()
          let note = {
            note : "Prise en charge validée par le transporteur" ,
            create_at: `${dateAct.getDate()}/${dateAct.getMonth() + 1}/${dateAct.getFullYear()}`
          }
          let lesNotes = element.notestrans
          lesNotes.push( note )
          box.notestrans = JSON.stringify( lesNotes )
          box.etattrans = "Validée par le transporteur"

        } else if( confirmed.etat == "refuse") {

          let dateAct = new Date()
          let note = {
            note : "Prise en charge réfusée par le transporteur" ,
            create_at: `${dateAct.getDate()}/${dateAct.getMonth() + 1}/${dateAct.getFullYear()}`
          }
          let lesNotes = element.notestrans
          lesNotes.push( note )
          box.notestrans = JSON.stringify( lesNotes )
          box.etattrans = "Réfusée par le transporteur"
  
        }

        this.backendService.put(`/senbox/validate/${element.id}`, box )
        .then( resultat => {

          if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
            this.eventsService.errorsmsg( resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement." )
            this.errorMessage = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement.";
          } else {
            this.eventsService.successmsg( resultat.text )
            setTimeout( () => {
              this.displayPageSuiv(0);
            }, 2000)
          }

        })
        .catch( error => {
          
        })

      }
      
    })
    .catch( () => {
    })

  } 

  editEtat(element){

    this.editEtatService.confirm(element, 'Valider', 'Annuler')
    .then( (confirmed : any ) => {
      if( confirmed.status ) {
        this.eventsService.successmsg( "Votre demande a été bien prise en compte" )
        setTimeout( () => {
          this.displayPageSuiv(0);
        }, 2000)
      }
    })
    .catch( () => {
    })

  }


  editLocation(element){

    this.editLocationService.confirmer(element, 'Valider', 'Annuler')
    .then( (confirmed : any ) => {
      if( confirmed.status ) {
        this.eventsService.successmsg( "Votre demande a été bien prise en compte" )
        setTimeout( () => {
          this.displayPageSuiv(0);
        }, 2000)
      }
    })
    .catch( () => {
    })

  }


  deleteExpe(element) {

    if ( element.etattrans == "En cours" ){
      this.eventsService.errorsmsg("Vous ne pouvez plus annuler cette expédition.")
    } else {
      
      this.confirmService.confirm(`Anulation de la demande - ${element.numtrans}`, 'Êtes-vous sûr de vouloir annuler cette demande ?', 'Oui', 'Non' , '' )
      .then( (confirmed : any) => {   
        if( confirmed.etat == "accept" ) {
          this.confirmDelete(element)
        }
      })
      .catch( () => {

      })
      
    }

  }


  confirmDelete(element){

    let dateAct = new Date()
    let note = {
      note : "Envoi annulé par l'expéditeur" ,
      create_at: `${dateAct.getDate()}/${dateAct.getMonth() + 1}/${dateAct.getFullYear()}`
    }
    let lesNotes = element.notestrans
    lesNotes.push( note )
    
    const box = {
      idtrajet: element.infos_suggest.id,
      poidsobj: element.poidsobj,
      numtrans: element.numtrans,
      notestrans: null,
      user_direct: !!element.user_sender.user_direct,
      email_user: !!element.user_sender.email ? element.user_sender.email : null
    };
    box.notestrans = JSON.stringify( lesNotes )

    console.log( 'box' , box , element )
    this.backendService.post(`/senbox/annuler/${element.id}`, box)
    //this.backendService.delete(`/senbox/delete/${element.id}`, )
    .then( resultat => {

      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.eventsService.errorsmsg( resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement." )
      } else {

        this.eventsService.successmsg( resultat.text )
        setTimeout( () => {
          this.displayPageSuiv(0);
        }, 2000)
      
      }

    })
    .catch( error => {
      
    })

  }

  pasTraitementSiMsahilisho() {
    // if ( element.user_sender.user_direct )
    this.eventsService.toastrwaring("Vous ne pouvez pas éditer cette expédition. Merci de bien vouloir contcater Msahilisho Group par téléphone, e-mail ou via le formulaire de contact. ");
  }

  downloadFile( event, element , name) {
    event.stopPropagation();
    const extensionFile = this.globalService.getExtentionBase64( element );
    const ladate = new Date();

    this.globalService.downloadFile(
      `${element}` ,
      `${name}-${this.globalService.makeid(10)}.${extensionFile}`
    )
  }

}
