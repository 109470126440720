import { Component, OnInit } from '@angular/core';

import { EventsService } from '../services/events/events.service'
import { BackendService } from '../services/backend/backend.service'
import { GlobalService } from '../services/global/global.service'

import { interval, Subscription } from 'rxjs';
import { Router} from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  /**
   * Le token viteur est valable pendant 24h
   * Pour des raisons de sécurité on renforce la mise à jour automatque du token tous les 5 heurs
   *
   * @memberof HomeComponent
   */
  source = interval(18000000); // 5h   // 3600000 === 1h 
  subscription: Subscription;

  publish = environment.publish

  constructor(
    private eventsService: EventsService,
    private backendService: BackendService,
    private globalService: GlobalService,
    private router: Router ,
  ) { 

    this.eventsService.majTokenUser.subscribe( () => {
      
      this.majTokenApp()
    })

  }


  ngOnInit() {
    //this.eventsService.setUserLoggedIn(true);
    localStorage.removeItem('mshlish_annonceEncours');
    this.backendService.onConnexion()

    if ( this.subscription )
      this.subscription.unsubscribe()

    this.autoMajToken()
    
  }

  /**
   * Mise à jour automatique du token
   * @memberof HomeComponent
  */
  majTokenApp(){
    this.backendService.load()
    .then( () => {
      
      this.router.navigate(['/home']);
      window.scroll(0,0);
    })
  }

  autoMajToken(){
    this.subscription = this.source.subscribe(val => {
      
      this.backendService.load()
    })
  }

}
