import { Component, OnInit } from '@angular/core';
import { BackendService } from '../services/backend/backend.service'
import { GlobalService } from '../services/global/global.service'
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmService } from 'src/app/modal/confirm/confirm.service';

@Component({
  selector: 'app-signercontrat',
  templateUrl: './signercontrat.component.html',
  styleUrls: ['./signercontrat.component.scss']
})
export class SignercontratComponent implements OnInit {

  resultMessage = null;
  selectedId = ""
  selectedVoyage
  reessayer = false
  dataLocalUrl

  constructor(
    private backendService: BackendService ,
    private route: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    private confirmService: ConfirmService
  ) { }

  ngOnInit() {

    this.route.params.subscribe(routeParams => {
      console.log( routeParams )
      this.selectedId = routeParams.id;
      this.getContrat();
    });

  }

  reessayerEncore() {
    this.getContrat();
  }


  getContrat() {
    this.backendService.get(`/voyageur/contrat/${ encodeURIComponent(this.selectedId) }` )
    .then( resultat => {
      console.log('result recuperation ' , resultat )
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.resultMessage = {ko: resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
        this.reessayer = true
      } else {
        this.selectedVoyage = resultat[0]
        let nbJour = this.globalService.dateDiff( new Date( this.selectedVoyage.create_at ), new Date())
        if( nbJour.day < 30 ) {
          const byteArray = new Uint8Array(atob( this.selectedVoyage.suivivoyage[0].contratenvoye ).split('').map(char => char.charCodeAt(0)));
          return new Blob([byteArray], {type: 'application/pdf'});
        } else {
          this.reessayer = true
          this.resultMessage = {ko: "Erreur lors de la récupération de votre contrat voyageur Msahilisho Import & Export. Le lien de signature de votre contrat a expiré. Veuillez prendre contact avec le service Msahlisho Group (contact@msahilishogroup.com)."}
        }
      }
    })
    .then(blob => {
      console.log('iiiii ci ', blob )
      if( !!blob ) {
        const url = window.URL.createObjectURL(blob);
        this.dataLocalUrl = url;
      }
    })
    .catch( error => {
      console.log('Error : ', error)
      this.reessayer = true
      this.resultMessage = {ko: "Erreur lors de la récupération de votre contrat voyageur Msahilisho Import & Export."}
    })
  }


  signContrat() {
    if (!!this.selectedVoyage && !!this.selectedVoyage.vayage ){
      const voyage = this.selectedVoyage.vayage[0];
      this.confirmService.confirm(`Signature contrat voyageur ${voyage.aerodep} <=> ${voyage.aeroarr}`, 'Voulez-vous signer ce contrat voyageur Msahilisho Group ?', 'Signer', 'Annuler' )
      .then( (confirmed : any) => {
        if( confirmed.etat == "accept") {
          this.validateSignature();
        }
      })
      .catch( () => {
      })
    }
  }

  validateSignature() {
    const voyageurSelected = this.selectedVoyage.vayage[0];
    const suiviVoyage = this.selectedVoyage.suivivoyage[0];

    console.log( 'on y est' , voyageurSelected , suiviVoyage )

    const fileName = `Contrat-Voyageur-${voyageurSelected.nom}-${voyageurSelected.prenom}-signature-electronique.pdf`
    const voyage = {
      bonjourDemandeurVoyageur: `Bonjour ${voyageurSelected.nom} ${voyageurSelected.prenom}`,
      trajetAllerRetour: `${voyageurSelected.aerodep} (${voyageurSelected.paysdep}) <=> ${voyageurSelected.aeroarr} (${voyageurSelected.paysarr})`,
      attachment: suiviVoyage.contratenvoye,
      fileName: fileName,
      email: voyageurSelected.email,
      idvoyageur: suiviVoyage.idvoyageur,
      idsuivi: suiviVoyage.id,
      agent: 1
    }
    console.log( ' ----> ', voyage )
    this.backendService.post(`/emails/signature-contrat-voyageur`, voyage )
    .then( resultat => {
      console.log('resultatresultatresultat ', resultat)
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.resultMessage = {ko: resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement." }
      } else {
        this.resultMessage = {ok: "Félicitations ! Le contrat voyageur a bien été signé avec succès. Une copie vous a été envoyée par mail."}
      }
  })
  .catch( error => {
    this.resultMessage = {ko: "Une erreur lors de l'enregistrement des données"}
  })
  }


}
