import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class LogsService {

  constructor() { }

  log( message : any){

    if( !environment.production )
      console.log( `[${new Date()}] : ${message} ` )
  }


}
