import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { GlobalService } from '../services/global/global.service'
import { BackendService } from '../services/backend/backend.service'

@Injectable({ providedIn: 'root' })
export class VisitorGuard implements CanActivate {
    constructor(
        private globalService: GlobalService,
        private backendService: BackendService
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if ( !this.globalService.getVisitor() ) {
            
           await this.backendService.load()
            .then( () => {
                
            })
        }
    
        return true;

    }
    
}