import { Component, OnInit , HostListener ,  ElementRef, ViewChild , AfterViewInit } from '@angular/core';

import {Location} from '@angular/common'
import { Router } from '@angular/router';

import { GlobalService } from '../services/global/global.service'
import { BackendService } from '../services/backend/backend.service'
import { EventsService } from '../services/events/events.service'
import { MatDrawer } from '@angular/material';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  pageSelected = "dashboard"
  userConnected
  urlImage

  opened = true
  txtBadge = "Afficher le menu"

  @ViewChild("drawer") drawer: MatDrawer ;

  constructor( 
    private eventsService: EventsService,
    private location: Location ,
    private globalService: GlobalService,
    private backendService: BackendService,
    private router: Router
  ) { 

  }

  ngOnInit() {

    this.eventsService.userConnectEmitter.subscribe( user => {
      this.isConnectedUser()
    })

    if( !this.globalService.getConnectedUser() ) {
      this.router.navigate(['/home']);
      window.scroll(0,0);
    }

    this.eventsService.pageEmitter.subscribe( (page) => {
      this.pageSelected = page
      this.mediaQuery()
      this.location.replaceState("/" + page )
    })

    this.urlImage = this.globalService.urlImage
    this.isConnectedUser()

  }


  ngAfterViewInit() {
    setTimeout(() => {
      this.mediaQuery()
    }, 1000);
  }


  isConnectedUser() {
    this.userConnected = this.globalService.getConnectedUser();

    console.log('User connected ---', this.userConnected);
  }


  mediaQuery() {
    this.drawer.opened  = window.innerWidth > 1500 ? true : false
    
    this.txtBadge = window.innerWidth > 1500 ? "Cacher le menu" : "Afficher le menu"
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mediaQuery()
  }

  eventToggle( drawer_ ){
    
    this.txtBadge = drawer_._opened ?  "Cacher le menu" : "Afficher le menu"
  }

}
