import { Component , Inject, HostListener , OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

import { EventsService } from './services/events/events.service'
import { GlobalService } from './services/global/global.service'
import { BackendService } from './services/backend/backend.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  idleState = '';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';
  dialogRef = false
  autoLogout = false
  messageAutoLogout = ""

  windowScrolled: boolean;
  coockiesAccept = false

  constructor(
    private idle: Idle, 
    private keepalive: Keepalive, 
    private router: Router ,
    private eventsService: EventsService ,
    private globalService: GlobalService,
    private backendService: BackendService,

  ) {

    this.eventsService.autoLogout.subscribe(( message ) => {
      this.autoLogout = true
      this.messageAutoLogout = message
      setTimeout(() => {
        this.autoLogout = false
      }, 3000);
    })


    // On déconnecte l'utilisateur après 1h d'incativité. Sachant que son token est pour 2h
    // sets an idle timeout of 5 seconds, for testing purposes.
    this.idle.setIdle(3600);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(30);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => { 
      this.idleState = 'Continuer ....? '
      
      this.reset();
    });
    

    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Le temps qui vous était imparti pour vous reconnecter est écoulé !';
      this.timedOut = true;
      
      
      setTimeout(() => {
        this.logout()
      }, 2000);

    });
    
    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'Vous êtes devenu inactif!'
      
      this.openLogout()
    });
    
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'Votre session expire dans ' + countdown + ' secondes !'
      
    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);

    this.keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.eventsService.getUserLoggedIn().subscribe(userLoggedIn => {
      if (userLoggedIn) {
        this.idle.watch()
        this.timedOut = false;
      } else {
        this.idle.stop();
      }
    })

    // this.reset();
  }


  ngOnInit(){
    this.coockiesAccept = this.globalService.getCookiesBrowserMs() != null ? true : false
  }

  acceptCookies(){
    this.globalService.setCookiesBrowserMs()
    this.coockiesAccept = true
  }

  openDetailCookies(){
    window.open( `${this.globalService.getURLApp()}#/politique-de-confidentialite`  , "_blank")
  }


  @HostListener("window:scroll", [])
  onWindowScroll() {
      if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
          this.windowScrolled = true;
      } 
     else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
          this.windowScrolled = false;
      }

      //
  }

  scrollToTop() {
      (function smoothscroll() {
          var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
          if (currentScroll > 0) {
              window.requestAnimationFrame(smoothscroll);
              window.scrollTo(0, currentScroll - (currentScroll / 8));
          }
      })();
  }




  reset() {
    this.idle.watch();
    this.timedOut = false;
  }


  hideLogout(): void {
    this.dialogRef = false
  }


  stay() {
    this.hideLogout()
    this.reset();
  }


  logout() {
    this.hideLogout()
    this.eventsService.setUserLoggedIn(false);
    
    this.globalService.logOut()
    this.eventsService.onUserDeconnected()
    this.router.navigate(['/home']);
    window.scroll(0,0);
  }


  openLogout(): void {
    this.dialogRef = true
  }

  upPage(){
    window.scroll(0,0);
  }

  
}
