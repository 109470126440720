import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GlobalService } from '../services/global/global.service'
import { BackendService } from '../services/backend/backend.service'
import { EventsService } from '../services/events/events.service'
import { ValidatorrService } from '../services/validatorr/validatorr.service'
import { SafeHtml } from '../pipes/safeHtml.pipe'
@NgModule({
  declarations: [FooterComponent, SafeHtml],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [FooterComponent, SafeHtml],
  providers:  [ 
    BackendService, 
    GlobalService,
    EventsService,
    ValidatorrService
  ]
})
export class FooterModule { }
