<div class="logout-modal" *ngIf="dialogRef" > <!--    -->
    <div class="row">
        <div class="col-md-8 text-logout">
            <span> Vous avez été inactif pendant trop longtemps essayez de vous reconnecter </span> <br />
            {{idleState}}
        </div>
        <div class="col-md-4">
            <div class="row">
                <div class="col">
                    <button type="button" class="btn btn-danger" (click)="logout()" >Déconnecter</button>
                </div>
                <div class="col">
                    <button type="button" class="btn btn-continuer" (click)="stay()" >Continuer</button>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="autologout-modal" *ngIf="autoLogout" > <!--    -->
    <div class="row">
        <div class="col-md-12">
            <span> {{messageAutoLogout}} </span> <br />
        </div>
    </div>
</div>

<div class="fab-container" *ngIf="windowScrolled" >
    <button mat-fab class="fab-toggler" (click)="scrollToTop()" >
      <i class="material-icons">publish</i>
    </button>
  </div>


  <div class="coockies-consent" *ngIf="!coockiesAccept" > <!--    -->
    <div class="row">
        <div class="col-md-10 text-justify">
            <span> 
                Nous utilisons des technologies telles que les cookies et traitons les données personnelles comme les informations du navigateur pour personnaliser ce que vous voyez. Cela nous aide à améliorer votre expérience sur Msahilisho Import & Export. Parce que nous apprécions votre vie privée, nous vous demandons par la présente votre permission d'utiliser les technologies suivantes. Vous pouvez toujours modifier / retirer votre consentement plus tard en cliquant sur le bouton des paramètres dans le coin inférieur gauche de la page.
                &nbsp; 
            </span> <br />
        </div>
        <div class="col-md-2">
            <a class="btn-consent cursor" (click)="acceptCookies()" >
                J'accepte
            </a> <br /><br />
            <a class="cursor unser-line" (click)="openDetailCookies()" >Plus d'informations</a>
        </div>
    </div>
    <br />
</div>

<app-loader></app-loader>
<router-outlet></router-outlet>


