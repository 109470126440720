import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { BackendService } from '../services/backend/backend.service'
import { GlobalService } from '../services/global/global.service'
import { EventsService } from '../services/events/events.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isCollapsed = false
  userConnected


  //0,107,216
  constructor( 
    private router: Router ,
    private globalService: GlobalService,
    private eventsService: EventsService,
    private backendService: BackendService
  ) { 
    this.eventsService.userConnectEmitter.subscribe( user => {
      this.isConnectedUser()
    })
  }

  ngOnInit() {
    this.isConnectedUser()
  }


  isConnectedUser() {
    this.userConnected = this.globalService.getConnectedUser()
  }


  openPage(page) {

    if( page == '/proposer-un-trajet' && !this.userConnected ) {
      this.router.navigate(['/login']);
    } else {
      this.router.navigate([page]);
    }
    window.scroll(0,0);

  }

}
