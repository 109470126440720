import { Component, OnInit } from '@angular/core';
import { BackendService } from '../services/backend/backend.service'
import { GlobalService } from '../services/global/global.service'

import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-activeuser',
  templateUrl: './activeuser.component.html',
  styleUrls: ['./activeuser.component.scss']
})
export class ActiveuserComponent implements OnInit {

  resultMessage = {ok: null, ko: null}
  selectedId = ""
  selecttedUser
  reessayer = false

  constructor(
    private backendService: BackendService ,
    private route: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService
  ) { }

  ngOnInit() {

    
    
    if( this.globalService.getConnectedUser() ) {
      this.router.navigate(['/home']);
      window.scroll(0,0);
    }

    this.route.params.subscribe(routeParams => {
      this.selectedId = routeParams.id;
      this.getUser();
    });

  }


  reessayerEncore() {
    this.getUser();
  }


  getUser() {
  
    this.backendService.get(`/users/crypte/${ encodeURIComponent(this.selectedId) }` )
    .then( resultat => {

      

      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.resultMessage.ko = resultat.status != 0 ? resultat.error.error.text + " Votre compte n'est pas activé." : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
        this.reessayer = true
      } else {

        this.selecttedUser = resultat[0]
        let nbJour = this.globalService.dateDiff( new Date( this.selecttedUser.create_at ), new Date())

        

        if( nbJour.day < 30 ) {

          let user = {
            email : this.selecttedUser.email,
            actif : 1,
            username : this.selecttedUser.firstname + " " + this.selecttedUser.lastname
          }

          

          this.backendService.put('/users/activer-user', user )
          .then( result => {
  
              
            if( result.hasOwnProperty('status') && this.globalService.getErrorCode().includes( result.status ) ){
              this.resultMessage.ko = result.status != 0 ? result.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement." 
              this.reessayer = true 
            } else {
              this.resultMessage.ok = "Félicitations ! Votre compte utilisateur a bien été activé avec succès !"
            }

          })
          .catch( error => {
            this.reessayer = true
            this.resultMessage.ko = "Erreur lors de l'activation de votre compte Msahilisho Import & Export."
          })


        } else {
          this.reessayer = true
          this.resultMessage.ko = "Erreur lors de l'activation de votre compte Msahilisho Import & Export. Le lien d'activation de votre compte a expiré. Veuillez prendre contact avec le service Msahlisho Group (contact@msahilishogroup.com)."
        }
          
      }


    })
    .catch( error => {
      this.reessayer = true
      
      this.resultMessage.ko = "Erreur lors de l'activation de votre compte Msahilisho Import & Export."
    })

  }

}
