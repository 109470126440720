import { Component, OnInit , Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators } from '@angular/forms';

import { GlobalService } from '../../services/global/global.service'
import { BackendService } from '../../services/backend/backend.service'
import { EventsService } from '../../services/events/events.service'
import { Router } from '@angular/router';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {

  @Input() expeReceive
  @Input() typeOuverture
  currentRate = 2.5;
  noteInputs: FormGroup

  msgContenu = {label: "", legend: "", placeholder: ""}

  constructor(
    private _formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private globalService: GlobalService,
    private backendService: BackendService,
    private eventsService: EventsService,
    private router: Router
  ) { }

  ngOnInit() {

    if( !this.globalService.getConnectedUser() ) {
      this.router.navigate(['/home']);
      window.scroll(0,0);
    }

    this.noteInputs = this._formBuilder.group({
      noteCtrl: ['', Validators.required ]
    });

  }


  ngAfterContentInit() {

    

    if( this.typeOuverture == "note" ){
      this.msgContenu.label = "Ajouter une note à cette expédition"
      this.msgContenu.legend = "Les notes ou demandes d'informations complémentaires à cette expédition"
      this.msgContenu.placeholder = "Note sur l'expédition"
    }else {
      this.msgContenu.label = "Appréciations"
      this.msgContenu.legend = "Vos appréciations ou les suggestions que vous faites pour ce transporteur. Qu’elles soient très positives ou un peu moins"
      this.msgContenu.placeholder = "Noter le transporteur afin d’informer les autres utilisateurs."
    }
    
  }



  /**
   * Decliner et quitter la fenêtre
  */
  decline() {
    this.activeModal.close({status: false});
  }


  noter() {
    if( this.typeOuverture == "note" ){
      this.noteExpedition()
    } else {
      this.noterTransporteur()
    }
  }


  noteExpedition(){

    if( this.noteInputs.controls['noteCtrl'].value == '' || this.noteInputs.controls['noteCtrl'].value.length < 10 ) {
      this.eventsService.errorsmsg("Le champs note est obligatoire et doit contenir un message qui est suppérieur à 10 caractères. ")
      return
    }

    let dateAct = new Date()
    let note = {
      note : this.noteInputs.controls['noteCtrl'].value ,
      create_at: `${dateAct.getDate()}/${dateAct.getMonth() + 1}/${dateAct.getFullYear()}`
    }

    let lesNotes = this.expeReceive.notestrans
    lesNotes.push( note )

    let notestrans = {
      notestrans: JSON.stringify( lesNotes )
    }

    this.backendService.put(`/senbox/note/${this.expeReceive.id}` , notestrans )
    .then( resultat => {

      

      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.eventsService.errorsmsg( "Erreur lors de l'ajout de votre note" )
      } else {
        this.activeModal.close({status: true});
      }

    })
    .catch( error => {
    })

  }



  noterTransporteur(){

    if( this.noteInputs.controls['noteCtrl'].value == '' || this.noteInputs.controls['noteCtrl'].value.length < 10 ) {
      this.eventsService.errorsmsg("Le champs note est obligatoire et doit contenir un message qui est suppérieur à 10 caractères. ")
      return
    }

    let note = {
      idtrans: this.expeReceive.infos_suggest.user_suggest.id,
      idexped: this.expeReceive.user_sender.id,
      nbrstar: this.currentRate,
      note: this.noteInputs.controls['noteCtrl'].value,
      idsendbox: this.expeReceive.id
    }

    this.backendService.post(`/notes/add` , note )
    .then( resultat => {
      

      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.eventsService.errorsmsg( "Erreur lors de l'ajout de votre note" )
      } else {
        this.activeModal.close({status: true});
      }

    })
    .catch( error => {
    })



  }





}
