
<div class="row dashbord-right">

    <label for="">En cours </label>
    <p class="noinfo" *ngIf="dataSourceEncoursLength <= 0"> Vous n'avez aucun(e) expédition / transport en cours pour le moment. </p>
    <p class="noinfo" *ngIf="dataSourceEncoursLength > 0"> 
        Veuillez trouver ci-après la liste de vos expéditions / transports en cours. Obtenez le détail en cliquant sur une ligne . <br />
    </p>

    <mat-form-field *ngIf="dataSourceEncoursLength > 0">
        <mat-label>Filtrer les transactions en cours </mat-label>
        <input matInput (keyup)="applyFilterEncours($event)" placeholder="Filtrer les données du tabaleau des trsansactions en cours ">
    </mat-form-field>

    <div *ngIf="dataSourceEncoursLength > 0" class="col-12" >

        <span class="astuce_info"> <mat-icon>info</mat-icon>Astuce : Pour voir le détail d'une transaction (Envoi & Transport), cliquez sur une ligne.</span><br />
        <fieldset class="the-fieldset">
            <legend class="the-legend">Description</legend>

            <span class="material-icons check-list">chevron_right</span> <button mat-icon-button class="btn_action" matTooltip="Modifier l'expédition"> <mat-icon>edit</mat-icon> </button> <span>Modifier l'expédition</span><br />
            <span class="material-icons check-list">chevron_right</span> <button mat-icon-button class="btn_action" matTooltip="Valider la prise en charge"> <mat-icon>library_add_check</mat-icon> </button> <span>Valider la prise en charge</span> <br />
            <span class="material-icons check-list">chevron_right</span> <button mat-icon-button class="btn_action" matTooltip="Modifier l'état de l'expédition"> <mat-icon>playlist_add_check</mat-icon> </button> <span>Modifier l'état de l'expédition</span> <br />
            <span class="material-icons check-list">chevron_right</span> <button mat-icon-button class="btn_action" matTooltip="Mettre à jour la localisation du colis"> <mat-icon>edit_location</mat-icon> </button> <span>Mettre à jour la localisation du colis</span> <br />
            <span class="material-icons check-list">chevron_right</span> <button mat-icon-button class="btn_action_delete" matTooltip="Annuler l'expédition"> <mat-icon>delete_forever</mat-icon> </button> <span>Annuler l'expédition</span> <br />
            <span class="material-icons check-list">chevron_right</span> <button mat-icon-button class="rate_review" matTooltip="Ajouter une nouvelle note à la transaction"> <i class="material-icons">rate_review</i> </button> <span>Ajouter une note d'information à la transaction</span> <br />

        </fieldset>
        <br />

        <table mat-table [dataSource]="dataSourceEncours" multiTemplateDataRows class="mat-elevation-z8" >

            <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay; let i = index">
                <th mat-header-cell *matHeaderCellDef> 
                    
                    <mat-icon *ngIf="column == 'detail'"></mat-icon>
                    <span *ngIf="column != 'detail'" > {{ elementsDisplay[i] }}  </span>
                </th>
    
                <td mat-cell *matCellDef="let element">  
                    
                    <span *ngIf="column == 'detail'"> <img src="assets/imgs/details/gif1-arrow.gif" class="arrow-detail" />  </span>

                    <span *ngIf="column == 'datecreate'"> {{element[column] | date: 'dd MMMM yyyy' }}  </span>
    
                    <span *ngIf="column == 'numtrans'" class="numEnvoie" > {{element[column]}}  </span>
    
                    <span *ngIf="column == 'nature_trans'" > {{element[column]}}  </span>
    
                    <span *ngIf="column == 'localisation'" class="badge badge-pill badge-secondary"> {{element[column]}}  </span>
    
                    <span *ngIf="column == 'prixtrans'" class="badge badge-pill badge-danger"> {{element[column]}} € </span>
    
                    <span *ngIf="column == 'etattrans'" class="badge badge-pill " [ngClass]="element.etattrans == 'En cours' ? 'badge-info' : element.etattrans == 'Terminée' ? 'badge-success' : 'badge-secondary' " > {{element[column]}}  </span>
    
                    <span *ngIf="column == 'note'" class="rate_review" (click)="ajouterUneNote($event, element)" > <i class="material-icons">rate_review</i> </span>
    
                    <span *ngIf="column == 'action'"> 
                        <button mat-icon-button *ngIf="canEdit(element)" class="btn_action" (click)="btnEncoursAction($event, element, 'edit')" matTooltip="Modifier l'expédition"> <mat-icon>edit</mat-icon> </button>
                        <button mat-icon-button *ngIf="isValidate(element) && canValidate(element)" class="btn_action" (click)="btnEncoursAction($event, element, 'valider')" matTooltip="Valider la prise en charge"> <mat-icon>library_add_check</mat-icon> </button>
                        <button mat-icon-button class="btn_action" (click)="btnEncoursAction($event, element, 'etat')" matTooltip="Modifier l'état de l'expédition"> <mat-icon>playlist_add_check</mat-icon> </button>
                        <button mat-icon-button class="btn_action" (click)="btnEncoursAction($event, element, 'location')" matTooltip="Mettre à jour la localisation du colis"> <mat-icon>edit_location</mat-icon> </button>
                        <button mat-icon-button class="btn_action_delete" (click)="btnEncoursAction($event, element, 'delete')" matTooltip="Annuler l'expédition"> <mat-icon>delete_forever</mat-icon> </button>
                    </span>
                
                </td>
    
            </ng-container>
    
            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
    
    
                    <!-- Timeline -->
                    <div class="timeline">
                
                        <!-- Line component -->
                        <div class="line text-muted"></div>
    
    
                        <!-- Separator -->
                        <div class="separator text-muted">
                            <span class="text-muted">Le </span> : &nbsp;
                            <time>{{element.datecreate | date: 'dd-MM-yyyy' }}</time>
                        </div>
                        <!-- /Separator -->
    
    
                        <!-- Panel -->
                        <article class="panel" [ngClass]="element.etattrans == 'En cours' ? 'panel-info' : element.etattrans == 'Terminée' ? 'panel-success' : 'panel-primary' " >
                        
                            <!-- Icon -->
                            <div class="panel-heading icon">
                                <i class="material-icons">history</i>
                            </div>
                            <!-- /Icon -->
                    
                            <!-- Heading -->
                            <div class="panel-heading">
                                <span class="badge badge-pill etat-badge" [ngClass]="element.etattrans == 'En cours' ? 'badge-info' : element.etattrans == 'Terminée' ? 'badge-success' : 'badge-info' " >
                                    {{element.etattrans}}
                                </span>
    
                            </div>
                            <!-- /Heading -->
                    
                        </article>
                        <!-- /Panel -->
    
                        
                        <article class="panel">
                            <!-- Heading -->
                            <div class="panel-heading">
                                <h2 class="panel-title"> {{element.nature_trans}} - Transaction N° {{element.numtrans}} </h2>
                            </div>
                            <!-- /Heading -->
                        </article>
    
    
                        <!-- Panel -->
                        <article class="panel panel-primary" >
                        
                            <!-- Icon -->
                            <div class="panel-heading icon">
                                <i class="material-icons">location_on</i>
                            </div>
                            <!-- /Icon -->
                    
                            <!-- Heading -->
                            <div class="panel-heading">
                                <h2 class="panel-title"> </h2>
                                <span class="badge badge-pill badge-secondary">
                                    {{element.localisation}}
                                </span>
                                &nbsp;&nbsp;&nbsp;<span  class="badge badge-pill badge-danger"> {{element.prixtrans}} € </span>
                                &nbsp;&nbsp;&nbsp;<span  class="badge badge-pill badge-info"> {{element.poidsobj}} Kg </span>
                                &nbsp;&nbsp;&nbsp;<span  class="badge badge-pill badge-dark"> {{ element.typeobj[0].toUpperCase() }}{{ element.typeobj.slice(1) }} </span>

                            </div>
                            <!-- /Heading -->
                    
                        </article>
                        <!-- /Panel -->
    
    
                        <br /><br />
                        <!-- Panel -->
                        <article class="panel panel-info">
    
                            <!-- Icon -->
                            <div class="panel-heading icon">
                                <i class="material-icons">description</i>
                            </div>
                            <!-- /Icon -->
    
                            <!-- Body -->
                            <div class="panel-body">
                                    
                                <span class="text-muted">Description du colis</span> : &nbsp;
                                <div class="body-message">
                                        {{element.infosobj}} 
                                </div><br />
    
            
                            </div>
                            <!-- /Body -->
    
    
                            <!-- Body -->
                            <div class="panel-body" *ngIf="element.user_sender.id == userConnected.id" >
                                    
                                <span class="text-muted">Destinataire</span> : &nbsp;
                                <div class="body-message">
                                        {{element.nomdest}} 
                                </div><br />
    
                                <span class="text-muted">Adresse de livraision</span> : &nbsp;
                                <div class="body-message">
                                        {{element.addrliv}} 
                                </div><br />
    
                                <span class="text-muted">Tel. destinataire</span> : &nbsp;
                                <div class="body-message">
                                        {{element.teldest}} 
                                </div><br />
    
                                <span class="text-muted">Type d'objet</span> : &nbsp;
                                <div class="body-message">
                                    {{ element.typeobj[0].toUpperCase() }}{{ element.typeobj.slice(1) }} 
                                </div><br />
    
                                <span class="text-muted">Taille de l'objet</span> : &nbsp;
                                <div class="body-message">
                                        {{element.tailobj}} 
                                </div><br />
    
                                <span class="text-muted">Poids</span> : &nbsp;
                                <div class="body-message">
                                        {{element.poidsobj}} KG
                                </div><br />
            
                            </div>
                            <!-- /Body -->
    
                        </article>
    
    
                        <!-- Separator -->
                        <div class="separator text-muted margin-top-bottom">
                            Expéditeur - Transporteur
                        </div>
                        <!-- /Separator -->
    
    
                        <!-- Panel -->
                        <article class="panel panel-info" >
                        
                            <!-- Icon -->
                            <div class="panel-heading icon">
                                <i class="material-icons">local_airport</i>
                            </div>
                            <!-- /Icon -->
                    
                            <!-- Heading -->
                            <div class="panel-heading">
    
                                <div class="row">
                                    <div class="col-md-6">
                                        <span class="expediteur">Expéditeur : </span> <span class="nomexpediteur"> {{element.user_sender.username}} </span>
                                    </div>
                                    <div class="col-md-6">
                                        <span class="transporteur">Transporteur : </span> <span class="nomtransporteur"> {{element.infos_suggest.user_suggest.username}} </span>
                                    </div>
                                </div>
    
                            </div>
                            <!-- /Heading -->
                    
                        </article>
                        <!-- /Panel -->
    
    
                        <!-- Separator -->
                        <div class="separator text-muted margin-top-bottom">
                            Notes transaction
                        </div>
                        <!-- /Separator -->
    
    
                        <!-- Panel -->
                        <article class="panel panel-warning" >
                        
                            <!-- Icon -->
                            <div class="panel-heading icon">
                                <i class="material-icons">info</i>
                            </div>
                            <!-- /Icon -->
                    
                            <!-- Heading -->
                            <div class="panel-heading">
                
                                <div class="body-message note-bubble" *ngFor="let note of element.notestrans" >
                                
                                    <div class="talk-bubble tri-right round btm-left">
                                        <div class="talktext">
                                            <p>{{note.note}}</p>
                                        </div>
                                    </div>
    
                                    <div class="talk-bubble-date">
                                        {{note.create_at}}
                                    </div>
    
                                </div>
    
                            </div>
                            <!-- /Heading -->
                    
                        </article>
                        <!-- /Panel -->
    
    
                    </div>
        
                </div>
                </td>
            </ng-container>
    
    
    
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
                class="example-element-row"
                [class.example-expanded-row]="expandedElement === element"
                (click)="expandedElement = expandedElement === element ? null : element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    
        </table>

    </div>


    <div class="separator-table">
       
    </div>


    <label for="">Historique </label>
    <p class="noinfo" *ngIf="dataSourceTermineeLenght <= 0"> Vous n'avez aucun historique pour le moment. </p>
    <p class="noinfo" *ngIf="dataSourceTermineeLenght > 0" > Veuillez trouver ci-après la liste de votre historique d'expéditions / transports. Obtenez le détail en cliquant sur une ligne .</p>
    <mat-form-field *ngIf="dataSourceTermineeLenght > 0" >
        <mat-label>Filtrer les transactions terminées </mat-label>
        <input matInput (keyup)="applyFilteTerminee($event)" placeholder="Filtrer les données du tabaleau des trsansactions terminées ">
    </mat-form-field>

    <div class="col-12" *ngIf="dataSourceTermineeLenght > 0" >
        <span  class="astuce_info"> <mat-icon>info</mat-icon>Astuce : Pour voir le détail d'une transaction (Envoi & Transport), cliquez sur une ligne.</span><br />
        <fieldset class="the-fieldset">
            <legend class="the-legend">Description</legend>
            <span class="material-icons check-list">chevron_right</span> <button mat-icon-button class="rate_review" matTooltip="Noter la transaction"> <i class="material-icons">stars</i> </button> <span>Noter la transaction (avis utilisateur) </span> <br />
        </fieldset>
        <br />
    </div>

    <div class="mat-elevation-z8" *ngIf="dataSourceTermineeLenght > 0" >

        <table mat-table [dataSource]="dataSourceTerminee" multiTemplateDataRows >

            <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay; let i = index">
                <th mat-header-cell *matHeaderCellDef>  
                    <mat-icon *ngIf="column == 'detail'"></mat-icon>
                    <span *ngIf="column != 'detail'" > {{elementsDisplay[i] == 'Note' ? 'Votre avis' : elementsDisplay[i] }} </span>
                </th>


                <td mat-cell *matCellDef="let element">  

                    <span *ngIf="column == 'detail'"> <img src="assets/imgs/details/gif1-arrow.gif" class="arrow-detail" />  </span>
                    
                    <span *ngIf="column == 'datecreate'"> {{element[column] | date: 'dd MMMM yyyy' }}  </span>

                    <span *ngIf="column == 'numtrans'" class="numEnvoie" > {{element[column]}}  </span>

                    <span *ngIf="column == 'nature_trans'" > {{element[column]}}  </span>

                    <span *ngIf="column == 'localisation'" class="badge badge-pill badge-secondary"> {{element[column]}}  </span>

                    <span *ngIf="column == 'prixtrans'" class="badge badge-pill badge-danger"> {{element[column]}} € </span>

                    <span *ngIf="column == 'etattrans'" class="badge badge-pill " [ngClass]="element.etattrans == 'Réfusée par le transporteur' ? 'badge-danger' : element.etattrans == 'Terminée et notée' ? 'badge-success' : 'badge-terminee' " > {{element[column]}}  </span>

                    <span *ngIf="column == 'note'" class="rate_review" (click)="ajouterUnAvis($event, element)" > <i class="material-icons">stars</i> </span>

                    <span *ngIf="column == 'action'"> 
                        <span class="trt_terminee"> {{canNote(element) ? 'Action requise' : 'Terminée'}} </span>
                    </span>
                
                </td>

            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">


                    <!-- Timeline -->
                    <div class="timeline">
                
                        <!-- Line component -->
                        <div class="line text-muted"></div>


                        <!-- Separator -->
                        <div class="separator text-muted">
                            <span class="text-muted">Le </span> : &nbsp;
                            <time>{{element.datecreate | date: 'dd-MM-yyyy' }}</time>
                        </div>
                        <!-- /Separator -->


                        <!-- Panel -->
                        <article class="panel" [ngClass]="element.etattrans == 'Réfusée par le transporteur' ? 'panel-danger' : element.etattrans == 'Terminée et notée' ? 'panel-success' : 'panel-terminee' " >
                        
                            <!-- Icon -->
                            <div class="panel-heading icon">
                                <i class="material-icons">history</i>
                            </div>
                            <!-- /Icon -->
                    
                            <!-- Heading -->
                            <div class="panel-heading">
                                <span class="badge badge-pill etat-badge" [ngClass]="element.etattrans == 'En cours' ? 'badge-info' : element.etattrans == 'Terminée' ? 'badge-success' : 'badge-info' " >
                                    {{element.etattrans}}
                                </span>

                            </div>
                            <!-- /Heading -->
                    
                        </article>
                        <!-- /Panel -->

                        
                        <article class="panel">
                            <!-- Heading -->
                            <div class="panel-heading">
                                <h2 class="panel-title"> {{element.nature_trans}} - Transaction N° {{element.numtrans}} </h2>
                            </div>
                            <!-- /Heading -->
                        </article>


                        <!-- Panel -->
                        <article class="panel panel-primary" >
                        
                            <!-- Icon -->
                            <div class="panel-heading icon">
                                <i class="material-icons">location_on</i>
                            </div>
                            <!-- /Icon -->
                    
                            <!-- Heading -->
                            <div class="panel-heading">
                                <h2 class="panel-title"> </h2>
                                <span class="badge badge-pill badge-secondary">
                                    {{element.localisation}}
                                </span>
                                &nbsp;&nbsp;&nbsp;<span  class="badge badge-pill badge-danger"> {{element.prixtrans}} € </span>
                            </div>
                            <!-- /Heading -->
                    
                        </article>
                        <!-- /Panel -->


                        <br /><br />
                        <!-- Panel -->
                        <article class="panel panel-info">

                            <!-- Icon -->
                            <div class="panel-heading icon">
                                <i class="material-icons">description</i>
                            </div>
                            <!-- /Icon -->

                            <!-- Body -->
                            <div class="panel-body">
                                    
                                <span class="text-muted">Description du colis</span> : &nbsp;
                                <div class="body-message">
                                        {{element.infosobj}} 
                                </div><br />

            
                            </div>
                            <!-- /Body -->


                            <!-- Body -->
                            <div class="panel-body" *ngIf="element.user_sender.id == userConnected.id" >
                                    
                                <span class="text-muted">Destinataire</span> : &nbsp;
                                <div class="body-message">
                                        {{element.nomdest}} 
                                </div><br />

                                <span class="text-muted">Adresse de livraision</span> : &nbsp;
                                <div class="body-message">
                                        {{element.addrliv}} 
                                </div><br />

                                <span class="text-muted">Tel. destinataire</span> : &nbsp;
                                <div class="body-message">
                                        {{element.teldest}} 
                                </div><br />

                                <span class="text-muted">Type d'objet</span> : &nbsp;
                                <div class="body-message">
                                    {{ element.typeobj[0].toUpperCase() }}{{ element.typeobj.slice(1) }} 
                                </div><br />

                                <span class="text-muted">Taille de l'objet</span> : &nbsp;
                                <div class="body-message">
                                        {{element.tailobj}} 
                                </div><br />

                                <span class="text-muted">Poids</span> : &nbsp;
                                <div class="body-message">
                                        {{element.poidsobj}} KG
                                </div><br />
            
                            </div>
                            <!-- /Body -->

                        </article>


                        <!-- Separator -->
                        <div class="separator text-muted margin-top-bottom">
                            Expéditeur - Transporteur
                        </div>
                        <!-- /Separator -->


                        <!-- Panel -->
                        <article class="panel panel-info" >
                        
                            <!-- Icon -->
                            <div class="panel-heading icon">
                                <i class="material-icons">local_airport</i>
                            </div>
                            <!-- /Icon -->
                    
                            <!-- Heading -->
                            <div class="panel-heading">

                                <div class="row">
                                    <div class="col-md-6">
                                        <span class="expediteur">Expéditeur : </span> <span class="nomexpediteur"> {{element.user_sender.username}} </span>
                                    </div>
                                    <div class="col-md-6">
                                        <span class="transporteur">Transporteur : </span> <span class="nomtransporteur"> {{element.infos_suggest.user_suggest.username}} </span>
                                    </div>
                                </div>

                            </div>
                            <!-- /Heading -->
                    
                        </article>
                        <!-- /Panel -->


                        <!-- Separator -->
                        <div class="separator text-muted margin-top-bottom">
                            Notes transaction
                        </div>
                        <!-- /Separator -->


                        <!-- Panel -->
                        <article class="panel panel-warning" >
                        
                            <!-- Icon -->
                            <div class="panel-heading icon">
                                <i class="material-icons">info</i>
                            </div>
                            <!-- /Icon -->
                    
                            <!-- Heading -->
                            <div class="panel-heading">
                
                                <div class="body-message note-bubble" *ngFor="let note of element.notestrans" >
                                
                                    <div class="talk-bubble tri-right round btm-left">
                                        <div class="talktext">
                                            <p>{{note.note}}</p>
                                        </div>
                                    </div>

                                    <div class="talk-bubble-date">
                                        {{note.create_at}}
                                    </div>

                                </div>

                            </div>
                            <!-- /Heading -->
                    
                        </article>
                        <!-- /Panel -->


                    </div>
        
                </div>
                </td>
            </ng-container>



            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
                class="example-element-row"
                [class.example-expanded-row]="expandedElement === element"
                (click)="expandedElement = expandedElement === element ? null : element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

        </table>
        <mat-paginator [pageSizeOptions]="[10, 30, 50]" showFirstLastButtons></mat-paginator>
    </div>



</div>
