<div class="flashinfo">

<!--
    <div class="row info-flash">

        <div class="col-md-12">
    
            <div class="img-project">
                <img src="assets/imgs/project.svg" />
            </div>
            <div class="titreInfo">Notre application WEB est actuellement en préparation.</div>
            <div class="ssTitreInfo">Nous vous remercions de votre compréhension et de votre visite.</div>
    
        </div>
    
    </div>

    <div class="row progress-flash">

        <div class="col-md-12">
    
            <ngb-progressbar type="warning" textType="white" [value]="60" [max]="100" [showValue]="true"></ngb-progressbar>
    
        </div>
    
    </div>

-->

<!-- Nouvelle destionation  -->
<!-- 

    <div class="row covid">
        <div class="col-md-12">
            <div class="titleInfos_2">
                <img src="assets/imgs/covid/siren.svg" class="siren-img" />
                <span class="minInfo2">Nouvelle destination :</span> &nbsp;
                <span class="maxInfo2">
                    <span class="couleurUn">Sé</span><span class="couleurDeux">né</span><span class="couleurTrois">gal</span>
                </span> &nbsp; &nbsp;
                <img src="assets/imgs/senegal_.png" class="siren-img" />
            </div>

        </div>
    </div>


    <div class="row covid margin-top-senegal-all">

        <div class="col-md-4 col-12 info-envoie2">
            
            <div class="row">
                <div class="col-3 numeroun">
                    <div class="1-un">1</div>
                </div>
                <div class="col-9">
                    <div class="trajet">Trajet dès</div>
                    <div class="solidaire">OCTOBRE</div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 partie-pub">
                    À partir de 155€ Aller-Retour (*)
                </div>
            </div>

            
            <div class="partie-collecte">
                <div class="row ii-collecte">
                    <div class="col-12">
                        <span class="num">1- </span> <span>Un billet aller-retour </span>
                    </div>
                </div>
                <div class="row ii-collecte">
                    <div class="col-12">
                        <span class="num">2- </span> <span>Selon vos disponibilités </span>
                    </div>
                </div>
                <div class="row ii-collecte">
                    <div class="col-12">
                        <span class="num">3- </span> <span>Un prix défiant toute concurrence </span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="partie-info">
                        Rendre visite à vos proches au Sénégal ou tout simplement partir à la découverte du Sénégal,
                        pays qui regorge de belles plages, idéal pour mixer visites culturelles et détente.
                    </div>
                </div>
            </div>

        </div>

        <div class="col-md-4 col-12 image-milieu-prez">
            <img src="assets/imgs/pubsenegal/img_monument.png" class="cleaner-img" />
        </div>

        <div class="col-md-4 col-12 btn-envoie">
            <div class="row mobilise">
                <div class="col-12">
                    Et ... un étudiant heureux !
                </div>
            </div>

            <div class="row date-dep">
                <div class="col-12">
                    <span class="date">Dès octobre 2021</span> <br />
                    <span>envoyer un colis à vos proches au sénégal</span>
                    <!- - <span>Soyons tous au rendez-vous ! </span>-- >
                </div>
            </div>

            <div class="contact">
                <div class="row dispo">
                    <div class="col-12">
                        Toujours à votre disposition
                    </div>
                </div>

                <div class="row infcont">
                    <div class="col-3">
                        <img src="assets/imgs/customer.svg" width="25" /> &nbsp; <img src="assets/imgs/france.png" width="25" />
                    </div>
                    <div class="col-9">
                        <span> (+33) 07 60 07 78 94 </span> <br />
                        <span> (+33) 07 67 52 80 98 </span>
                    </div>
                </div>

                <div class="row infcont">
                    <div class="col-3">
                        <img src="assets/imgs/customer.svg" width="25" /> &nbsp; <img src="assets/imgs/comoros.png" width="25" />
                    </div>
                    <div class="col-9">
                        <span> (+269) 444 99 32 </span> <br />
                        <span> (+269) 343 99 32 </span>
                    </div>
                </div>

                <div class="row infcont">
                    <div class="col-3">
                        <img src="assets/imgs/support.svg" width="25" />
                    </div>
                    <div class="col-9">
                        <span> contact@msahilishogroup.com</span>
                    </div>
                </div>

            </div>

            <div class="row jeveuaider">
                <div class="col-12">
                    <div class="btn-aider cursor" (click)="openPage('/signup')">
                        <span>CELA M'INTÉRESSE</span><br />
                        Une question ?
                    </div>
                </div>
            </div>

        </div>


    </div> 

-->

<!-- MOHELI -->
<!--
    <div class="row covid">
        <div class="col-md-12">
            <div class="titleInfos_">
                <img src="assets/imgs/covid/siren.svg" class="siren-img" />
                <span class="minInfo">Pour</span> &nbsp; &nbsp;<span class="maxInfo">MOHÉLI</span>
            </div>
            <div class="ssTitleInfos_">
                <span>Msahilisho Group</span> vous a tous appelé à la mobilisation générale des solidarités. <br />
                Vous avez été nombreux à répondre à l’appelle à la solidarité nationale et nous vous en remercions infiniment.
            </div>
        </div>
    </div>

    <div class="row covid">

        <div class="col-md-4 col-12 info-envoie">
            
            <div class="row">
                <div class="col-3 numeroun">
                    <div class="1-un">1</div>
                </div>
                <div class="col-9">
                    <div class="trajet">Trajet</div>
                    <div class="solidaire">SOLIDAIRE</div>
                </div>
            </div>

            
            <div class="partie-collecte">
                <div class="row ii-collecte">
                    <div class="col-12">
                        <span class="num">1- </span> <span>Collecte des dons </span>
                    </div>
                </div>
                <div class="row ii-collecte">
                    <div class="col-12">
                        <span class="num">2- </span> <span>Préparation et expédition </span>
                    </div>
                </div>
                <div class="row ii-collecte">
                    <div class="col-12">
                        <span class="num">3- </span> <span>Remise des dons en main propre </span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="partie-info">
                        Le besoin en masque et en équipements de protection individuelle reste significatif. 
                        C’est pourquoi, <span>Msahilisho Group</span> appelle à votre générosité, votre compassion et à votre soutien pour qu’ensemble, on puisse collecter de manière coordonnée des <span>masques</span>, <span>gants</span>, <span>médicaments</span>, <span>gels</span> et autres <span>équipements de protection individuelle</span> à destination des malades et des personnels soignants de Mohéli.
                    </div>
                </div>
            </div>

        </div>

        <div class="col-md-4 col-12 image-cleaner">
            <img src="assets/imgs/covid/cleaner.svg" class="cleaner-img" />
        </div>

        <div class="col-md-4 col-12 btn-envoie">
            <div class="row mobilise">
                <div class="col-12">
                    Tous concernés, tous mobilisés !
                </div>
            </div>

            <div class="row date-dep">
                <div class="col-12">
                    <span class="date">Le 17 Janvier 2021</span> <br />
                    <span>vous étiez nombreux à vous mobiliser !</span>
                    <!- - <span>Soyons tous au rendez-vous ! </span>-- >
                </div>
            </div>

            <div class="contact">
                <div class="row dispo">
                    <div class="col-12">
                        Toujours à votre disposition
                    </div>
                </div>

                <div class="row infcont">
                    <div class="col-3">
                        <img src="assets/imgs/customer.svg" width="25" /> &nbsp; <img src="assets/imgs/france.png" width="25" />
                    </div>
                    <div class="col-9">
                        <span> (+33) 07 60 07 78 94 </span> <br />
                        <span> (+33) 07 67 52 80 98 </span>
                    </div>
                </div>

                <div class="row infcont">
                    <div class="col-3">
                        <img src="assets/imgs/customer.svg" width="25" /> &nbsp; <img src="assets/imgs/comoros.png" width="25" />
                    </div>
                    <div class="col-9">
                        <span> (+269) 444 99 32 </span> <br />
                        <span> (+269) 343 99 32 </span>
                    </div>
                </div>

                <div class="row infcont">
                    <div class="col-3">
                        <img src="assets/imgs/support.svg" width="25" />
                    </div>
                    <div class="col-9">
                        <span> contact@msahilishogroup.com</span>
                    </div>
                </div>

            </div>

            <div class="row jeveuaider">
                <div class="col-12">
                    <div class="btn-aider cursor" (click)="openPage('/signup')">
                        <!- - <span>JE SUIS SOLIDAIRE</span><br />
                        Je veux aider -- >
                        <span>JE SUIS CONCERNÉ</span><br />
                        Une question ?
                    </div>
                </div>
            </div>

            <div class="row comptrendu">
                <div class="col-12">
                    * Sur place, Msahilisho Group fera parvenir vos dons à Mohéli à travers une association qui agit en faveur de cette cause. 
                    Un compte rendu transparent de la distribution de vos dons sera ensuite publié avec des images à l’appui.
                </div>
            </div>

        </div>


    </div>
-->


</div>