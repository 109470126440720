import { NgModule } from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';


import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';

import { TopheaderModule} from '../topheader/topheader.module'
import { FooterModule } from '../footer/footer.module'
import { HeaderModule} from '../header/header.module';
import { AboutAsHomeComponent } from './about-as-home/about-as-home.component';
import { ClientsHomeComponent } from './clients-home/clients-home.component';
import { CommentHomeComponent } from './comment-home/comment-home.component';
import { WhychooseHomeComponent } from './whychoose-home/whychoose-home.component';
import { AnnoncesHomeComponent } from './annonces-home/annonces-home.component';
import { SliderHomeComponent } from './slider-home/slider-home.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { GlobalService } from '../services/global/global.service'
import { BackendService } from '../services/backend/backend.service'
import { EventsService } from '../services/events/events.service'

import { MaterialAngModule } from '../matmodule/materialang.module'
import { ModalModule } from '../modal/modal.module';
import { FlashinfoHomeComponent } from './flashinfo-home/flashinfo-home.component'

import { PubliciteComponent } from '../publicite/publicite.component';
import { ApplicationComponent } from './application/application.component';
@NgModule({
  declarations: [HomeComponent, AboutAsHomeComponent, ClientsHomeComponent, CommentHomeComponent, WhychooseHomeComponent, AnnoncesHomeComponent, SliderHomeComponent, FlashinfoHomeComponent, PubliciteComponent, ApplicationComponent],
  imports: [
    CommonModule,
    TopheaderModule,
    FooterModule,
    HeaderModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialAngModule,
    ModalModule,
  ],
  exports: [HomeComponent, AboutAsHomeComponent, ClientsHomeComponent, CommentHomeComponent, WhychooseHomeComponent, AnnoncesHomeComponent, SliderHomeComponent, FlashinfoHomeComponent, PubliciteComponent],
  providers:  [ 
    BackendService, 
    GlobalService,
    EventsService
    
  ]
})
export class HomeModule { }
