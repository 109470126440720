<div class="content-app">

    <app-topheader></app-topheader>
 
    <app-header></app-header>


    <!-- Section bar de recherche -->
    <div class="search-bar-top">
        <img src="assets/imgs/info.svg" class="airport-img" />  Besoin d'aide ?
    </div>


    <div class="row container-app">

        <div class="col-12 img-prez-concept">
            <img src="assets/imgs/concept/transporteur.png" class="img-fluid">
        </div>

    </div>


    <div class="row container-app">

        <div class="col-12 img-prez-concept">
            <img src="assets/imgs/concept/expediteur.png" class="img-fluid">
        </div>

        <br /><br />
    </div>



    <div class="marg-bot"></div>

    
 
    <app-whychoose-home></app-whychoose-home> 
     
    
    <app-footer></app-footer>

    
</div>
