import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile.component';

import {NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeModule} from '../home/home.module'
import { TopheaderModule} from '../topheader/topheader.module'
import { HeaderModule} from '../header/header.module';
import { FooterModule } from '../footer/footer.module'
import { MaterialAngModule } from '../matmodule/materialang.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { GlobalService } from '../services/global/global.service'
import { BackendService } from '../services/backend/backend.service'
import { EventsService } from '../services/events/events.service'
import { ValidatorrService } from '../services/validatorr/validatorr.service'
import { SharedModule } from '../shared/shared.module';


import { NgxMaskModule } from 'ngx-mask'


@NgModule({
  declarations: [ProfileComponent],
  imports: [
    CommonModule,
    NgbModule,
    HomeModule,
    TopheaderModule,
    HeaderModule,
    FooterModule,
    MaterialAngModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    SharedModule
    
  ],
  exports: [ProfileComponent],
  bootstrap: [ProfileComponent],
  providers: [EventsService , GlobalService , BackendService, ValidatorrService ],
})
export class ProfileModule { }
