import { Component, OnInit } from '@angular/core';
import { BackendService } from '../services/backend/backend.service'
import { GlobalService } from '../services/global/global.service'

import { Router, ActivatedRoute } from '@angular/router';
import {FormControl, FormGroup, Validators, FormBuilder, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-reinitpass',
  templateUrl: './reinitpass.component.html',
  styleUrls: ['./reinitpass.component.scss']
})
export class ReinitpassComponent implements OnInit {

  resultMessage = {ok: null, ko: null}
  selectedId = ""
  selecttedUser

  passwordFormGroup: FormGroup;
  submitted = false
  canReinitialise = false


  constructor(
    private backendService: BackendService ,
    private route: ActivatedRoute,
    private router: Router,
    private _formBuilder: FormBuilder,
    private globalService: GlobalService
  ) { }

  
  ngOnInit() {

    if( this.globalService.getConnectedUser() ) {
      this.router.navigate(['/home']);
      window.scroll(0,0);
    }

    this.passwordFormGroup = this._formBuilder.group({
      mdpNewCtrl: ['', Validators.required],
      mdpNewConfCtrl: ['', Validators.required]
    });

    this.route.params.subscribe(routeParams => {
      this.selectedId = routeParams.id;
      this.getUser();
    });

  }



  getUser() {

    this.backendService.get(`/users/crypte/${ encodeURIComponent(this.selectedId) }` )
    .then( resultat => {

      

      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.resultMessage.ko = resultat.status != 0 ? resultat.error.error.text + " Votre compte n'est pas activé." : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
        this.canReinitialise = false
      } else {

        this.selecttedUser = resultat[0]

        if( this.selecttedUser.id ) {

          let nbJour = this.globalService.dateDiff( new Date( this.selecttedUser.create_at ), new Date())
          if( nbJour.day < 3 ) {
            this.canReinitialise = true
          } else {
            this.canReinitialise = false
            this.resultMessage.ko = "Erreur lors de la réinitialisation du mot de passe de votre compte Msahilisho Import & Export. Lien de réinitialisation de mot de passe expiré."
          }

        } else {
          this.resultMessage.ko = "Erreur lors de la réinitialisation du mot de passe de votre compte Msahilisho Import & Export."
          this.canReinitialise = false
        }
      
      }

    })
    .catch( error => {
      this.resultMessage.ko = "Erreur lors de la réinitialisation du mot de passe de votre compte Msahilisho Import & Export."
      this.canReinitialise = false
    })

  }


  get f1() { 
    return this.passwordFormGroup.controls; 
  }
  

  onSubmitPassword() {
    this.submitted = true
    
    // stop here if form is invalid
    if (this.passwordFormGroup.invalid) {
      return;
    }

    if( this.f1.mdpNewCtrl.value != this.f1.mdpNewConfCtrl.value ) {
      this.resultMessage.ko = " Les mots de passe saisis ne sont pas identiques"
      return;
    }

    let user = {
      password: this.f1.mdpNewCtrl.value,
      id_user: this.selecttedUser.id
    }

    this.backendService.put('/users/update-password', user )
    .then( resultat => {

      

      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.resultMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      } else {

        this.resultMessage.ok = resultat.text
        this.submitted = false

        this.passwordFormGroup.patchValue( {
          mdpNewCtrl: "",
          mdpNewConfCtrl: ""
        } )
      
      }

    })
    .catch( error => {
      
      this.resultMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
    })


  }



}
