import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {NotesComponent} from './notes.component'

@Injectable({
  providedIn: 'root'
})
export class NotesService {

  constructor( private modalService: NgbModal ) { }

  public confirmService(
    expeReceive,
    typeOuverture,
    dialogSize: 'sm'|'lg' = 'lg') {
    const modalRef = this.modalService.open(NotesComponent, { size: dialogSize });
    modalRef.componentInstance.expeReceive = expeReceive;
    modalRef.componentInstance.typeOuverture = typeOuverture;
    return modalRef.result;
  }


}
