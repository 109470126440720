import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-flashinfo-home',
  templateUrl: './flashinfo-home.component.html',
  styleUrls: ['./flashinfo-home.component.scss']
})
export class FlashinfoHomeComponent implements OnInit {

  constructor(
    private router: Router 
  ) { }

  ngOnInit() {
  }

  openPage(page) {
    this.router.navigate(['contact']);
    window.scroll(0,0);
  }

}
