import { Component, Directive, ElementRef, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators, FormBuilder, ValidationErrors } from '@angular/forms';

import { Router } from '@angular/router';

import { GlobalService } from '../services/global/global.service'
import { BackendService } from '../services/backend/backend.service'

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sendbox',
  templateUrl: './sendbox.component.html',
  styleUrls: ['./sendbox.component.scss']
})
export class SendboxComponent implements OnInit {

  searchFormGroup: FormGroup;
  nextTravels = []
  urlImage
  currentRate // = 5
  limite = 0;
  trajet_de = ""
  trajet_a = ""
  limite_all = 10
  submitted = false
  closeBtn = false
  notOther = false
  serachLast = false
  loadFinished = false
  errorMessage

  publish = environment.publish

  constructor( 
    private router: Router ,
    private globalService: GlobalService,
    private backendService: BackendService,
    private _formBuilder: FormBuilder
  ) { 

    this.currentRate = Array(5).fill(5)

    this.searchFormGroup = this._formBuilder.group({
      villeDepCtrl: ['', Validators.required],
      villeArrCtrl: ['', Validators.required]
    });

  }


  ngOnInit() {
    this.trajet_a = this.globalService.getSearch().trajet_a
    this.trajet_de = this.globalService.getSearch().trajet_de
    this.urlImage = this.globalService.urlImage
    this.getAnnonces()

    if( this.globalService.getSearch().trajet_a != "" || this.globalService.getSearch().trajet_de != "" ){
      this.closeBtn = true
      this.serachLast = true
    }

  }

  ngAfterContentInit(){
    this.searchFormGroup.patchValue( {
      villeDepCtrl: this.trajet_de,
      villeArrCtrl: this.trajet_a
    })
  }



  getAnnonces(){

    let annonce = {
      limite: this.limite,
      trajet_de: this.trajet_de,
      trajet_a: this.trajet_a,
      limite_all: this.limite_all,
      etat: 1
    }
    this.loadFinished = false

    this.backendService.post('/suggest/list' , annonce )
    .then( resultat => {

      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.nextTravels = []
      } else {
        this.nextTravels = this.nextTravels.concat( resultat )
        this.notOther = resultat.length < 10 ? true : false

        if( this.trajet_a != "" || this.trajet_de != "" ){
          this.serachLast = true
        }
      }
      this.loadFinished = true
      
    })
    .catch( error => {
      this.nextTravels = []
      this.loadFinished = true
    })


  }


  voirPlus(){
    this.limite = this.nextTravels.length
    this.limite_all = 10

    if( this.trajet_de != "" || this.trajet_a != "" ) {
      this.rechercheTrajet()
    } else {
      this.getAnnonces()
    }
    
  }



  get f() { 
    return this.searchFormGroup.controls; 
  }


  reverseInput(){
    let deBase = {trajet_de: this.f.villeDepCtrl.value , trajet_a: this.f.villeArrCtrl.value}

    this.searchFormGroup.patchValue({
      villeDepCtrl: deBase.trajet_a,
      villeArrCtrl: deBase.trajet_de,
    });

  }


  onSubmit(){

    this.submitted = true;
    this.loadFinished = false

    // stop here if form is invalid
    if (this.searchFormGroup.invalid) {
      return;
    }

    this.limite = 0
    this.limite_all = 10
    this.trajet_de = this.f.villeDepCtrl.value
    this.trajet_a = this.f.villeArrCtrl.value
    
    this.rechercheTrajet()

  }


  openAnnonce(ntravl) {
    //Ajout de l'annonce selectionné dans service comme variable global 
    this.globalService.setAnnoncdSelected( ntravl )
    this.router.navigate(['/detail-annonces']);
    window.scroll(0,0);
  }


  resetSearch(){
    
    this.globalService.setSearch( { trajet_de: "", trajet_a: ""} );
    this.trajet_de = ""
    this.trajet_a = ""
    this.limite = 0
    this.limite_all = 10
    this.closeBtn = false

    this.searchFormGroup.patchValue( {
      villeDepCtrl: this.trajet_de,
      villeArrCtrl: this.trajet_a
    })

    this.getAnnonces()
  }



  rechercheTrajet() {

    let annonce = {
      limite: this.limite,
      trajet_de: this.trajet_de,
      trajet_a: this.trajet_a,
      limite_all: this.limite_all,
      etat: 1
    }

    this.closeBtn = true

    this.backendService.post('/suggest/list' , annonce )
    .then( resultat => {
      
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
          this.nextTravels = []
      } else {
        if( resultat.length <= 0) {
          if( !this.serachLast ) {
            this.nextTravels = resultat
          }
          this.notOther = true
        } else {
          this.nextTravels = resultat
          this.notOther = resultat.length < 10 ? true : false
        }  
      }

      this.serachLast = true
      this.loadFinished = true
      
    })
    .catch( error => {
      this.nextTravels = []
      this.loadFinished = true
    })

  }

}
