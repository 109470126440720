import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators, FormBuilder, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import {Location} from '@angular/common';

import { BackendService } from '../../../services/backend/backend.service'
import { GlobalService } from '../../../services/global/global.service'
import { EventsService } from '../../../services/events/events.service'


@Component({
  selector: 'app-edit-sendbox',
  templateUrl: './edit-sendbox.component.html',
  styleUrls: ['./edit-sendbox.component.scss']
})
export class EditSendboxComponent implements OnInit {


  infosFormGroup: FormGroup;
  nextTravels
  annonceSelected
  urlImage
  userConnected
  submitted
  messageRetour

  typeObjet = []
  typeSelected = "colis"
  infoPlaceholder : any = {}
  typeEnveloppe = []

  infosProducts = []
  produiSelect = null

  masktelArr = {mask: "(+00) 0 00 00 00 00" , value: "france.png"}

  constructor(
    private _location: Location,
    private backendService: BackendService ,
    private _formBuilder: FormBuilder,
    private router: Router ,
    private globalService: GlobalService,
    private eventsService: EventsService,
  ) { }

  ngOnInit() {

    this.infosProducts = this.globalService.getInfosProducts();
    // this.infosProducts.push({id: "autre", nomproduct: "Autre", image: "other.svg", prixproduct: "", partype: "", poids: "1" })

    if( !this.globalService.getConnectedUser() ) {
      this.router.navigate(['/home']);
      window.scroll(0,0);
    }

    if( !this.globalService.getAnnoneEnModification() ) {
      this.router.navigate(["/dashboard"]);
      window.scroll(0,0);
    }

    this.typeObjet = this.globalService.getTypeObjet()
    this.infoPlaceholder = this.globalService.getInfoPlaceholder()
    this.typeEnveloppe = this.globalService.getTypeEnveloppe()

    this.userConnected = this.globalService.getConnectedUser()
    this.nextTravels = this.globalService.getAnnoneEnModification().infos_suggest
    this.annonceSelected = this.globalService.getAnnoneEnModification()

    this.infosFormGroup = this._formBuilder.group({
      nomPrenomExpediteurCtrl: [`${this.userConnected.firstname} ${this.userConnected.lastname[0]}`],
      telExpediteurCtrl: [ this.userConnected.telephone ],
      adresseLivraisonCtrl: ['', Validators.required],
      nomPrenomDestinataireCtrl: ['', Validators.required],
      telephoneDestinataireCtrl: ['33 ', Validators.required],
      infoObjetCtrl: ['', Validators.required],
      poidsCtrl: ['', Validators.required],
      tailleCtrl: ['', Validators.required],
      typeObjetCtrl: ['', Validators.required ],

      poidsMshCtrl: ['']
    });
    

  }

  ngAfterContentInit(){

    this.typeSelected = this.annonceSelected.typeobj
    this.masktelArr = this.globalService.maskSaisi( this.annonceSelected.teldest );

    this.infosFormGroup.patchValue( {
      nomPrenomExpediteurCtrl: `${this.userConnected.firstname} ${this.userConnected.lastname[0]}`,
      telExpediteurCtrl: this.userConnected.telephone,
      adresseLivraisonCtrl: this.annonceSelected.addrliv,
      nomPrenomDestinataireCtrl: this.annonceSelected.nomdest,
      telephoneDestinataireCtrl: this.annonceSelected.teldest,
      infoObjetCtrl: this.annonceSelected.infosobj,
      poidsCtrl: this.annonceSelected.poidsobj,
      tailleCtrl: this.annonceSelected.tailobj,
      typeObjetCtrl: this.annonceSelected.typeobj,
      poidsMshCtrl: this.annonceSelected.poidsobj
    })

    this.getSelectedProduit()
  }


  goBack() {
    this.globalService.setAnnoneEnModification( null )
    this.eventsService.onPageSelected( "mes-expedition-ou-transports" )
  }

  get f() { 
    return this.infosFormGroup.controls; 
  }

  onTypeSelection( event ){

    this.typeSelected = this.f.typeObjetCtrl.value
    if( this.typeSelected == "enveloppe" ) {
      this.infosFormGroup.patchValue({
        tailleCtrl: this.typeEnveloppe[1].value
      })
    }

  }

  changeTelephone(value, type) {

    this.infosFormGroup.patchValue( {
      telephoneDestinataireCtrl: value.tel
    } );
    this.masktelArr = value.mask;
    
  }


  onSubmit(){


    this.messageRetour = null
    this.submitted = true
    let msahilisho = 0

    if( this.nextTravels.msahilisho != '0' ) {
      
      if( !this.produiSelect ) {
        this.messageRetour = {ko: "Veuillez sélectionner un produit dans la liste." }
        return;
      }

      if( this.produiSelect && this.produiSelect.id == 'autre' && this.f.poidsMshCtrl.value == "" ) {
        this.messageRetour = {ko: "Le poids de votre colis est obligatoire." }
        return;
      }

      if( this.produiSelect && this.produiSelect.id == 'autre' && this.f.infoObjetCtrl.value == "" ) {
        this.messageRetour = {ko: "Les informations sur le colis sont obligatoires." }
        return;
      }

      this.infosFormGroup.patchValue({
        typeObjetCtrl: "colis",
        poidsCtrl: this.produiSelect.poids,
        tailleCtrl: this.produiSelect.nomproduct
      })

      if( this.produiSelect.id !== 'autre' ) {
        this.infosFormGroup.patchValue({
          infoObjetCtrl: this.produiSelect.nomproduct
        })
      }

    }

    // stop here if form is invalid
    if (this.infosFormGroup.invalid) {
      return;
    }


    //Calculer le prix
    let prixTotal
    let lePoidsAukg

    if( this.nextTravels.msahilisho != '0' ) {

      if( this.produiSelect.id == 'autre' ) {
        lePoidsAukg = parseFloat( this.f.poidsMshCtrl.value ) 
        if( parseInt( this.f.poidsMshCtrl.value ) < 1 ) {
          lePoidsAukg = 1
        }
        prixTotal = ( lePoidsAukg * parseFloat( this.nextTravels.prixkg ) ).toFixed(2)
      } else {
        // Produit dans la liste des produits non pesés de msahilisho
        prixTotal = parseFloat(this.produiSelect.prixproduct);
        lePoidsAukg = parseFloat( this.produiSelect.poids ); 
      }
      msahilisho = this.produiSelect.id

    } else {

      msahilisho = 0
      lePoidsAukg = parseFloat( this.f.poidsCtrl.value ) 
      if( parseInt( this.f.poidsCtrl.value ) < 1 ) {
        lePoidsAukg = 1
      }
      prixTotal = ( lePoidsAukg * parseFloat( this.nextTravels.prixkg ) ).toFixed(2)

    }

    if ( lePoidsAukg > parseFloat( this.nextTravels.kgdispo ) ) {
      this.messageRetour = {ko: `Le nombre de kilos demandés ${lePoidsAukg} kg est suppérieur au nombre de kilos disponibles (${this.nextTravels.kgdispo} kg)` }
      return;
    }

    let dateAct = new Date()
    let note = {
      note : "Modification de la demande de prise en charge" ,
      create_at: `${dateAct.getDate()}/${dateAct.getMonth() + 1}/${dateAct.getFullYear()}`
    }
    let lesNotes = this.annonceSelected.notestrans
    lesNotes.push( note )

    let box = {
      iduserexp: this.userConnected.id,
      nomdest: this.f.nomPrenomDestinataireCtrl.value,
      addrliv: this.f.adresseLivraisonCtrl.value,
      teldest: `+${this.f.telephoneDestinataireCtrl.value}`,
      typeobj: this.f.typeObjetCtrl.value,
      tailobj: this.f.tailleCtrl.value,
      telrecup: this.f.telExpediteurCtrl.value,
      poidsobj: lePoidsAukg, //this.f.poidsCtrl.value,
      infosobj: this.f.infoObjetCtrl.value,
      numtrans: this.annonceSelected.numtrans,
      etattrans: "En attente de validation",
      prixtrans: prixTotal,
      notestrans: JSON.stringify( lesNotes ),
      idtrajet: this.nextTravels.id,
      idusertra: this.nextTravels.user_suggest.id,
      localisation: this.annonceSelected.localisation,
      msahilisho: msahilisho,
      ancienpoid: this.annonceSelected.poidsobj
    }
    
    this.backendService.put(`/senbox/update/${this.annonceSelected.id}`, box )
    .then( resultat => {

      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        //this.eventsService.errorsmsg( resultat.error.error.text )
        this.messageRetour = {ko: resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement." }
      } else {

        this.messageRetour = {ok: resultat.text}
        this.eventsService.successmsg("Votre demande a été transmise au transporteur")
        setTimeout( () => {
          this.goBack();
        }, 2000)
      
      }

    })
    .catch( error => {
      
      this.messageRetour = {ko: "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement." }
    })

  }

  chooseEnvoieMsahilisho(envoie) {
    this.produiSelect = envoie
    this.messageRetour = null
  }

  getSelectedProduit() {

    for( var i = 0; i < this.infosProducts.length; i++ ) {
      if( this.infosProducts[i].id == this.annonceSelected.msahilisho ) {
        this.produiSelect = this.infosProducts[i]
        break;
      }
    }

  }



}
