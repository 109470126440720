<div class="content-app">

    <app-topheader></app-topheader>
 
    <app-header></app-header>


    <!-- Section bar de recherche -->
    <div class="search-bar-top">
        <img src="assets/imgs/agreement.png" class="airport-img" /> Signature de votre contrat voyageur Msahilisho Import & Export
    </div>



    <div class="login-page ">

        <div class="row logo-authentification">
            <div class="col-12 text-center">
                <img src="assets/imgs/agreement.png" class="img-login" /> 

                <br /><br />
                <div *ngIf="dataLocalUrl != undefined">
                    <iframe [attr.src]="dataLocalUrl | safeHtml" id="frame" ></iframe>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="!!selectedVoyage">
            <div class="col-4">&nbsp;</div>
            <div class="col-4 text-center">
                <br />
              <button class="signer" type="button" mat-button (click)="signContrat()" > Signer le contrat </button>
              <br />
            </div>
            <div class="col-4">&nbsp;</div>
        </div>

        <div class="row" *ngIf="resultMessage">
            <div class="col-md-12 text-center">
                <div class="alert" [ngClass]="!!resultMessage.ok ? 'alert-success' : 'alert-danger'" role="alert">
                    {{!!resultMessage.ok ? resultMessage.ok : resultMessage.ko }}
                </div>
            </div>
        </div>

        <div class="row" *ngIf="reessayer" >
            <div class="col-4">&nbsp;</div>
            <div class="col-4 text-center">
                <br />
              <button class="ressayer" type="button" mat-button (click)="reessayerEncore()" >Réessayer</button>
              <br />
            </div>
            <div class="col-4">&nbsp;</div>
        </div>


    </div>

    <div class="marg-bot"></div>


    
 
    <app-whychoose-home></app-whychoose-home> 
     
    
    <app-footer></app-footer>

    
</div>
