
<div class="row dashbord-right">

    <label for="">En cours </label>
    <p class="noinfo" *ngIf="dataSourceEncoursLength <= 0"> Vous n'avez aucun(e) expédition / transport en cours pour le moment. </p>
    <p class="noinfo" *ngIf="dataSourceEncoursLength > 0"> 
        Veuillez trouver ci-après la liste de vos expéditions / transports en cours. Obtenez le détail en cliquant sur une ligne . <br />
    </p>


    <div *ngIf="dataSourceEncoursLength > 0" class="col-12" >

        <span class="astuce_info"> <mat-icon>info</mat-icon>Astuce : Pour voir le détail d'une transaction (Envoi & Transport), cliquez sur une ligne.</span><br />
        <fieldset class="the-fieldset">
            <legend class="the-legend">Description</legend>

            <!--<span class="material-icons check-list">chevron_right</span> <button mat-icon-button class="btn_action" matTooltip="Modifier l'expédition"> <mat-icon>edit</mat-icon> </button> <span>Modifier l'expédition</span><br />-->
            <span class="material-icons check-list">chevron_right</span> <button mat-icon-button class="btn_action" matTooltip="Valider la prise en charge"> <mat-icon>library_add_check</mat-icon> </button> <span>Valider la prise en charge</span> <br />
            <span class="material-icons check-list">chevron_right</span> <button mat-icon-button class="btn_action" matTooltip="Modifier l'état de l'expédition"> <mat-icon>playlist_add_check</mat-icon> </button> <span>Modifier l'état de l'expédition</span> <br />
            <span class="material-icons check-list">chevron_right</span> <button mat-icon-button class="btn_action" matTooltip="Mettre à jour la localisation du colis"> <mat-icon>edit_location</mat-icon> </button> <span>Mettre à jour la localisation du colis</span> <br />
            <span class="material-icons check-list">chevron_right</span> <button mat-icon-button class="btn_action_delete" matTooltip="Annuler l'expédition"> <mat-icon>delete_forever</mat-icon> </button> <span>Annuler l'expédition</span> <br />
            <span class="material-icons check-list">chevron_right</span> <button mat-icon-button class="rate_review" matTooltip="Ajouter une nouvelle note à la transaction"> <i class="material-icons">rate_review</i> </button> <span>Ajouter une note d'information à la transaction</span> <br />

        </fieldset>
        <br />

        <div class="table-row" *ngFor="let expen of dataSourceEncoursList" (click)="expandedElements(expen)" >

            <div class="row">
                <div class="col-12 col-md-1 text-center">
                    <div class="top-header">&nbsp;</div>
                    <div class="content-col"> <img src="assets/imgs/details/gif1-arrow.gif" class="arrow-detail" /> </div>
                </div>
                <div class="col-6 col-md-1">
                    <div class="top-header">Date dép.</div>
                    <div class="content-col"> {{expen.infos_suggest.datedepot | date: 'dd/MM/yyyy' }} </div> <!-- | date: 'dd/M/y' -->
                </div>
                <div class="col-6 col-md-2">
                    <div class="top-header">N° de suivi</div>
                    <div class="content-col numEnvoie"> {{expen.numtrans}} </div>
                </div>
                <div class="col-6 col-md-1">
                    <div class="top-header">Nature transaction</div>
                    <div class="content-col"> {{expen.nature_trans}} </div>
                </div>
                <div class="col-6 col-md-2">
                    <div class="top-header">Localisation</div>
                    <div class="content-col"> <span class=""> {{ expen.localisation }}  </span> </div>
                </div>
                <div class="col-6 col-md-1">
                    <div class="top-header">État</div>
                    <div class="content-col">
                        <span [ngClass]="expen.etattrans == 'En cours' ? 'etat-encours-class' : expen.etattrans == 'Terminée' ? 'etat-termine-class' : '' " > {{expen.etattrans}}  </span>
                    </div>
                </div>
                <div class="col-6 col-md-1">
                    <div class="top-header">€</div>
                    <div class="content-col"> <span class="etat-rouge-class"> {{expen.prixtotal}} € </span> </div>
                </div>
                <div class="col-6 col-md-1">
                    <div class="top-header">Note</div>
                    <div class="content-col cursor-point">
                        <span class="rate_review" (click)="ajouterUneNote($event, expen)" > <i class="material-icons">rate_review</i></span>
                    </div>
                </div>
                <div class="col-6 col-md-2">
                    <div class="top-header">Action</div>
                    <div class="content-col">
                        <button mat-icon-button *ngIf="isValidate(expen) && canValidate(expen)" class="btn_action" (click)="btnEncoursAction($event, expen, 'valider')" matTooltip="Valider la prise en charge"> <mat-icon>library_add_check</mat-icon> </button>
                        <button mat-icon-button class="btn_action" (click)="btnEncoursAction($event, expen, 'etat')" matTooltip="Modifier l'état de l'expédition"> <mat-icon>playlist_add_check</mat-icon> </button>
                        <button mat-icon-button class="btn_action" (click)="btnEncoursAction($event, expen, 'location')" matTooltip="Mettre à jour la localisation du colis"> <mat-icon>edit_location</mat-icon> </button>
                        <button mat-icon-button class="btn_action_delete" (click)="btnEncoursAction($event, expen, 'delete')" matTooltip="Annuler l'expédition"> <mat-icon>delete_forever</mat-icon> </button>
                    </div>
                </div>
            </div>

            <div class="example-element-detail" [@detailExpand]="expen === expandedElement ? 'expanded' : 'collapsed'">
    
    
                <!-- Timeline -->
                <div class="timeline">
            
                    <!-- Line component -->
                    <div class="line text-muted"></div>


                    <!-- Separator -->
                    <div class="separator text-muted">
                        <span class="text-muted">Le </span> : &nbsp;
                        <time>{{expen.datecreate | date: 'dd/MM/yyyy' }}</time>
                    </div>
                    <!-- /Separator -->


                    <!-- Date et aérport -->
                    <article class="panel" [ngClass]="expen.etattrans == 'En cours' ? 'panel-info' : expen.etattrans == 'Terminée' ? 'panel-success' : 'panel-primary' " >
                    
                        <!-- Icon -->
                        <div class="panel-heading icon">
                            <i class="material-icons">date_range</i>
                        </div>
                        <!-- /Icon -->
                
                        <!-- Heading -->
                        <div class="panel-heading">
                            <h5 class="panel-title"> Trajet : {{expen.infos_suggest.aerodep}} => {{expen.infos_suggest.aeroarr}}  du {{expen.infos_suggest.datedepot | date: 'dd-MM-yyyy' }} </h5>
                        </div>
                        <!-- /Heading -->
                
                    </article>
                    <!-- /Panel -->
                    <br />


                    <!-- Panel -->
                    <article class="panel" [ngClass]="expen.etattrans == 'En cours' ? 'panel-info' : expen.etattrans == 'Terminée' ? 'panel-success' : 'panel-primary' " >
                    
                        <!-- Icon -->
                        <div class="panel-heading icon">
                            <i class="material-icons">history</i>
                        </div>
                        <!-- /Icon -->
                
                        <!-- Heading -->
                        <div class="panel-heading">
                            <span [ngClass]="expen.etattrans == 'En cours' ? 'etat-encours-class' : expen.etattrans == 'Terminée' ? 'etat-termine-class' : 'etat-autre-class' " >
                                {{expen.etattrans}}
                            </span>

                        </div>
                        <!-- /Heading -->
                
                    </article>
                    <!-- /Panel -->

                    
                    <article class="panel">
                        <!-- Heading -->
                        <div class="panel-heading">
                            <h2 class="panel-title"> {{expen.nature_trans}} - Transaction N° {{expen.numtrans}} </h2>
                        </div>
                        <!-- /Heading -->
                    </article>


                    <!-- Panel -->
                    <article class="panel panel-primary" >
                    
                        <!-- Icon -->
                        <div class="panel-heading icon">
                            <i class="material-icons">location_on</i>
                        </div>
                        <!-- /Icon -->
                
                        <!-- Heading -->
                        <div class="panel-heading multi-etat">
                            <h2 class="panel-title"> </h2>
                            <div class="row spacing-col">
                                <div class="col-12 col-md-4"> <span class="localisation-class margin-bottom-8"> {{expen.localisation}} </span> </div>
                                <div class="col-12 col-md-2"> <span  class="etat-rouge-class margin-bottom-8"> {{expen.prixtotal}} € </span> </div>
                                <div class="col-12 col-md-2"> <span  class="etat-encours-class margin-bottom-8"> {{expen.poidsobj}} Kg </span> </div>
                                <div class="col-12 col-md-4"> <span  class="localisation-class margin-bottom-8"> {{ expen.typeobj[0].toUpperCase() }}{{ expen.typeobj.slice(1) }} </span> </div>
                            </div>

                            <div class="row spacing-col">
                                <div class="col-12 col-md-3"> <div class="title_spac_det">Prix</div> {{expen.prixtrans}} € </div>
                                <div class="col-12 col-md-3"> <div class="title_spac_det">Total à payer</div> {{expen.prixtotal}} € </div>
                                <div class="col-12 col-md-3"> <div class="title_spac_det">Garantie</div> {{ !!expen.garantie ? expen.garantie : 'Non'}} </div>
                                <div class="col-12 col-md-3"> <div class="title_spac_det">Valeur</div> {{ !!expen.valeur ? expen.valeur : '0'}} € </div>
                            </div>
                        </div>
                        <!-- /Heading -->
                
                    </article>
                    <!-- /Panel -->


                    <br /><br />
                    <!-- Panel -->
                    <article class="panel panel-info">

                        <!-- Icon -->
                        <div class="panel-heading icon">
                            <i class="material-icons">description</i>
                        </div>
                        <!-- /Icon -->

                        <!-- Body -->
                        <div class="panel-body">
                                
                            <span class="text-muted">Description du colis</span> : &nbsp;
                            <div class="body-message">
                                    {{expen.infosobj}} 
                            </div><br />

        
                        </div>
                        <!-- /Body -->


                        <!-- Body -->
                        <div class="panel-body" *ngIf="expen.user_sender.id == userConnected.id" >
                                
                            <span class="text-muted">Destinataire</span> : &nbsp;
                            <div class="body-message">
                                    {{expen.nomdest}} 
                            </div><br />

                            <span class="text-muted">Adresse de livraision</span> : &nbsp;
                            <div class="body-message">
                                    {{expen.addrliv}} 
                            </div><br />

                            <span class="text-muted">Tel. destinataire</span> : &nbsp;
                            <div class="body-message">
                                    {{expen.teldest}} 
                            </div><br />

                            <span class="text-muted">Type d'objet</span> : &nbsp;
                            <div class="body-message">
                                {{ expen.typeobj[0].toUpperCase() }}{{ expen.typeobj.slice(1) }} 
                            </div><br />

                            <span class="text-muted">Taille de l'objet</span> : &nbsp;
                            <div class="body-message">
                                    {{expen.tailobj}} 
                            </div><br />

                            <span class="text-muted">Poids</span> : &nbsp;
                            <div class="body-message">
                                    {{expen.poidsobj}} KG
                            </div><br />
        
                        </div>
                        <!-- /Body -->

                    </article>


                    <!-- Separator -->
                    <div class="separator text-muted margin-top-bottom">
                        Expéditeur - Transporteur
                    </div>
                    <!-- /Separator -->


                    <!-- Panel -->
                    <article class="panel panel-info" >
                    
                        <!-- Icon -->
                        <div class="panel-heading icon">
                            <i class="material-icons">local_airport</i>
                        </div>
                        <!-- /Icon -->
                
                        <!-- Heading -->
                        <div class="panel-heading">

                            <div class="row">
                                <div class="col-md-6">
                                    <span class="expediteur">Expéditeur : </span> <span class="nomexpediteur"> {{expen.user_sender.username}} </span>
                                </div>
                                <div class="col-md-6">
                                    <span class="transporteur">Transporteur : </span> <span class="nomtransporteur"> {{expen.infos_suggest.user_suggest.username}} </span>
                                </div>
                            </div>

                        </div>
                        <!-- /Heading -->
                
                    </article>
                    <!-- /Panel -->


                    <!-- Separator -->
                    <div class="separator text-muted margin-top-bottom">
                        Notes transaction
                    </div>
                    <!-- /Separator -->


                    <!-- Panel -->
                    <article class="panel panel-warning" >
                    
                        <!-- Icon -->
                        <div class="panel-heading icon">
                            <i class="material-icons">info</i>
                        </div>
                        <!-- /Icon -->
                
                        <!-- Heading -->
                        <div class="panel-heading">
            
                            <div class="body-message note-bubble" *ngFor="let note of expen.notestrans" >
                            
                                <div class="talk-bubble tri-right round btm-left">
                                    <div class="talktext">
                                        <p>{{note.note}}</p>
                                    </div>
                                </div>

                                <div class="talk-bubble-date">
                                    {{note.create_at}}
                                </div>

                            </div>

                        </div>
                        <!-- /Heading -->
                
                    </article>
                    <!-- /Panel -->


                    <div *ngIf="expen?.prep_remise_exped?.prep_remise_exped?.length> 0" >
                        <!-- Separator -->
                        <div class="separator text-muted margin-top-bottom">
                            Suivi de l'Expédition
                        </div>
                        <!-- /Separator -->

                        <!--  Panel Suivi récupération - livraison -->
                        <article class="panel panel-info" >
                            <!-- Icon -->
                            <div class="panel-heading icon">
                                <i class="material-icons">flight_takeoff</i>
                            </div>
                            <!-- /Icon -->


                            <div *ngIf="expen?.prep_remise_exped?.agent_recup" >
                                <div class="row margin-top-20px">
                                    <div class="col-md-12">
                                        <label>Récupération de colis</label>
                                    </div>
                                </div>
            
                                <div class="row" *ngIf="expen?.prep_remise_exped?.agent_recup" >
                                    <div class="col-md-12 inter-div">
                                        <span> Récupéré par </span><br />
                                        {{expen?.prep_remise_exped?.agent_recup?.username}}
                                    </div>
                                </div>
                                <div class="row " *ngIf="expen?.prep_remise_exped?.prep_remise_exped" >
                                    <div class="col-md-12 inter-div">
                                        <label>Commentaire sur la récupération de colis</label> <br />
                                        {{expen?.prep_remise_exped?.prep_remise_exped[0]?.comment_recup ? expen?.prep_remise_exped?.prep_remise_exped[0]?.comment_recup : 'RAS'}}
                                    </div>
                                </div>
                                <div class="row" *ngIf="expen?.prep_remise_exped?.prep_remise_exped && expen?.prep_remise_exped?.prep_remise_exped[0]?.date_recup" >
                                    <div class="col-md-6 inter-div">
                                        <span> Date de récupération </span><br />
                                        {{
                                            expen?.prep_remise_exped?.prep_remise_exped[0]?.date_recup ? (expen?.prep_remise_exped?.prep_remise_exped[0]?.date_recup | date: 'EE dd MMMM yyyy') : '-'
                                        }}
                                    </div>
                                    <div class="col-md-6 inter-div">
                                        <span> Expédition payé ?  </span><br />
                                        {{
                                            expen?.prep_remise_exped?.prep_remise_exped[0]?.exped_paye ? expen?.prep_remise_exped?.prep_remise_exped[0]?.exped_paye : 'Non'
                                        }}
                                    </div>
                                </div>
                                <div class="row" *ngIf="expen?.prep_remise_exped?.prep_remise_exped && expen?.prep_remise_exped?.prep_remise_exped[0]?.sign_recup" >
                                    <div class="col-md-6 inter-div" *ngIf="expen?.prep_remise_exped?.prep_remise_exped[0]?.sign_recup[0]" >
                                        <span> Signature Agent récupération </span><br />
                                        <img [src]="expen?.prep_remise_exped?.prep_remise_exped[0]?.sign_recup[0]?.signature | safeHtml" width="150"  />
                                    </div>
                                    <div class="col-md-6 inter-div" *ngIf="expen?.prep_remise_exped?.prep_remise_exped[0]?.sign_recup[1]" >
                                        <span> Signature Client récupération </span><br />
                                        <img [src]="expen?.prep_remise_exped?.prep_remise_exped[0]?.sign_recup[1]?.signature | safeHtml" width="150"  />
                                    </div>
                                </div>
                                <div class="row" *ngIf="expen?.prep_remise_exped?.prep_remise_exped && expen?.prep_remise_exped?.prep_remise_exped[0]?.image_recup">
                                    <div class="col-md-12 inter-div">
                                        <label>Image(s) récupération de colis</label>
                                    </div>
                                </div>
                                <div class="row" *ngIf="expen?.prep_remise_exped?.prep_remise_exped && expen?.prep_remise_exped?.prep_remise_exped[0]?.image_recup" >
                                    <div class="col-md-3 inter-div cursor div-image-prep" *ngFor="let imr of expen?.prep_remise_exped?.prep_remise_exped[0]?.image_recup" >
                                        <img [src]="imr.path | safeHtml" width="200" (click)="downloadFile( $event, imr.path , 'recuperation')" />
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="expen?.prep_remise_exped?.agent_livr" >
                                <div class="row margin-top-20px">
                                    <div class="col-md-12">
                                        <label>Livraison de colis</label>
                                    </div>
                                </div>

                                <div class="row" *ngIf="expen?.prep_remise_exped?.agent_livr" >
                                    <div class="col-md-12 inter-div">
                                        <span> Livré par </span><br />
                                        {{expen?.prep_remise_exped?.agent_livr?.username}}
                                    </div>
                                </div>
                                <div class="row " *ngIf="expen?.prep_remise_exped?.prep_remise_exped" >
                                    <div class="col-md-12 inter-div">
                                        <label>Commentaire sur la livraison de colis</label> <br />
                                        {{expen?.prep_remise_exped?.prep_remise_exped[0]?.comment_livr ? expen?.prep_remise_exped?.prep_remise_exped[0]?.comment_livr : 'RAS'}}
                                    </div>
                                </div>
                                <div class="row" *ngIf="expen?.prep_remise_exped?.prep_remise_exped" >
                                    <div class="col-md-6 inter-div">
                                        <span> Date de livraison </span><br />
                                        {{
                                            expen?.prep_remise_exped?.prep_remise_exped[0]?.date_livr ? (expen?.prep_remise_exped?.prep_remise_exped[0]?.date_livr | date: 'EE dd MMMM yyyy') : '-'
                                        }}
                                    </div>
                                    <div class="col-md-6 inter-div">
                                        <span> E-mail client  </span><br />
                                        {{
                                            expen?.prep_remise_exped?.prep_remise_exped[0]?.email_client ? expen?.prep_remise_exped?.prep_remise_exped[0]?.email_client : '-'
                                        }}
                                    </div>
                                </div>
                                <div class="row" *ngIf="expen?.prep_remise_exped?.prep_remise_exped && expen?.prep_remise_exped?.prep_remise_exped[0]?.sign_livr" >
                                    <div class="col-md-6 inter-div" *ngIf="expen?.prep_remise_exped?.prep_remise_exped[0]?.sign_livr[0]" >
                                        <span> Signature Agent livraison </span><br />
                                        <img [src]="expen?.prep_remise_exped?.prep_remise_exped[0]?.sign_livr[0]?.signature | safeHtml" width="150"  />
                                    </div>
                                    <div class="col-md-6 inter-div" *ngIf="expen?.prep_remise_exped?.prep_remise_exped[0]?.sign_livr[1]" >
                                        <span> Signature Client livraison </span><br />
                                        <img [src]="expen?.prep_remise_exped?.prep_remise_exped[0]?.sign_livr[1]?.signature | safeHtml" width="150"  />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 inter-div">
                                        <label>Image(s) livraison de colis</label>
                                    </div>
                                </div>
                                <div class="row" *ngIf="expen?.prep_remise_exped?.prep_remise_exped && expen?.prep_remise_exped?.prep_remise_exped[0]?.photo_livr" >
                                    <div class="col-md-3 inter-div cursor" *ngFor="let iml of expen?.prep_remise_exped?.prep_remise_exped[0]?.photo_livr" >
                                        <img [src]="iml.path | safeHtml" width="250" (click)="downloadFile( $event, iml.path , 'livraison')" />
                                    </div>
                                </div>
                            </div>

                        </article>
                        <!--  /Panel Suivi récupération - livraison -->
                    </div>


                </div>
    
            </div>
        </div>
        <!-- Fin parcours -->

        <div  class="lire-la-suite" >
            <span *ngIf="!notOther" class="voir-plus-span" (click)="nextAffiche()"  >
                <span class="material-icons check-list">expand_more</span>
                <span class="plus">Voir plus </span>
                <span class="material-icons check-list">expand_more</span>
            </span>
        </div>

        
 
    </div>



</div>
