<div class="content-app">

    <app-topheader></app-topheader>
 
    <app-header></app-header>


    <!-- Section bar de recherche -->
    <div class="search-bar-top">
        <img src="assets/imgs/support.svg" class="airport-img" /> Nous contacter
    </div>




    <section id="contact">
        <div class="container-fluid">
  

          <div class="row wow fadeInUp">
  
            <div class="d-none d-lg-block col-lg-5">
              <div class="map mb-4 mb-lg-0">
                <img src="assets/imgs/contact-us.svg" />
                <!--<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2632.8204352308544!2d2.508538515669112!3d48.708912979273194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e60a6b3b58ce53%3A0x42f7fa58550995ac!2s24%20Rue%20de%20Verdun%2C%2091800%20Brunoy!5e0!3m2!1sfr!2sfr!4v1582804343816!5m2!1sfr!2sfr" frameborder="0" style="border:0; width: 100%; height: 280px;" allowfullscreen="true"></iframe>-->
              </div>
            </div>
  
            <div class="col-12 col-lg-7">
              <div class="row">
                <div class="col-md-4 info">
                  <i class="material-icons">location_on</i>
                  <div [innerHTML]="footerInfos.adresse"></div>
                   <div class="text-center">Et</div>
                  <div [innerHTML]="footerInfos.adressecomores"></div>
                </div>
                <div class="col-md-4 info">
                  <i class="material-icons">email</i>
                  <p>{{footerInfos.email}}</p>
                </div>
                <div class="col-md-4 info">
                  <i class="material-icons">phone</i>
                  <div class="numtel" [innerHTML]="footerInfos.telephone" ></div> 
                  <div class="numtel" [innerHTML]="footerInfos.telcomores" ></div>
                </div>
              </div>
  
              <div class="form">
                <div id="sendmessage" *ngIf="retourMessage.ok" > {{retourMessage.ok}} </div>
                <div id="errormessage" *ngIf="retourMessage.ko"> {{retourMessage.ko}} </div>

                <form class="contactForm" [formGroup]="contactFormGroup" (ngSubmit)="onSubmit()" >

                  <div class="form-row">

                    <div class="form-group col-lg-6">
                      <input formControlName="nameCtrl" type="text" class="form-control" id="nameCtrl" placeholder="Votre nom et prénom" data-rule="minlen:4" data-msg="Veuillez saisir au moins 4 caractères" [ngClass]="{ 'is-invalid': submitted && f.nameCtrl.errors }" />
                      <div class="validation"></div>
                    </div>

                    <div class="form-group col-lg-6">
                      <input formControlName="emailCtrl" type="email" class="form-control" id="emailCtrl" placeholder="Votre Email" data-rule="email" data-msg="Veuillez saisir un e-mail valide" [ngClass]="{ 'is-invalid': submitted && f.emailCtrl.errors }"/>
                      <div class="validation"></div>
                    </div>

                  </div>

                  <div class="form-group">
                    <input formControlName="sujetCtrl" type="text" class="form-control" id="sujetCtrl" placeholder="Sujet de votre message" data-rule="minlen:4" data-msg="Veuillez saisir au moins 8 caractères du sujet" [ngClass]="{ 'is-invalid': submitted && f.sujetCtrl.errors }" />
                    <div class="validation"></div>
                  </div>

                  <div class="form-group">
                    <textarea formControlName="messageCtrl" class="form-control" id="messageCtrl" rows="5" data-rule="required" data-msg="Votre message ... " placeholder="Votre message " [ngClass]="{ 'is-invalid': submitted && f.messageCtrl.errors }" ></textarea>
                    <div class="validation"></div>
                  </div>

                  <div class="text-center"><button type="submit" title="Send Message">Envoyer un message</button></div>

                </form>


              </div>
            </div>
  
          </div>
  
        </div>
    </section>

    

    
    
    <app-whychoose-home></app-whychoose-home> 
     
    
    <app-footer></app-footer>

    
</div>
