<nav class="navbar navbar-expand-lg navbar-dark  static-top navbar-fixed-top">


    <div class="navbar-brand cursor " (click)="openPage('/home')"  > 
        <span class="nuOne">
            N° 1 
        </span> 
        <span class="brandTxt">
            de l'envoi de colis 
        </span>
        <span class="collabTxt">
            collaboratif
        </span> 
        <span class="brandTxt">
            dans l'
        </span>
        <span class="nuOne">
            Océan Indien 
        </span>
    </div>


    <button class="navbar-toggler btn-collapse" type="button" (click)="isCollapsed = !isCollapsed"
        aria-controls="navbarResponsive" [attr.aria-expanded]="!isCollapsed" aria-label="Toggle navigation">
        <i class="material-icons">menu</i>
    </button>


    <div [ngbCollapse]="!isCollapsed" class="collapse navbar-collapse" id="navbarResponsive">


        <ul class="navbar-nav ml-auto">


            <ul class="navbar-nav mr-auto">

                <li class="nav-item cursor">
                    <a class="nav-link waves-effect waves-light commentcamarche cursor" (click)="openPage('/comment-ca-marche')" >
                        Comment ça marche ?
                    </a>
                </li>

                <li class="nav-item " *ngIf="!userConnected"  >
                    <a class="nav-link waves-effect btn-connexion cursor" (click)="openPage('/login')" >
                        Connexion
                    </a>
                </li>
        
                <li class="nav-item " *ngIf="!userConnected" >
                    <a class="nav-link waves-effect btn-inscription cursor" (click)="openPage('/signup')">
                        Inscription
                    </a>
                </li>


                <li class="nav-item " *ngIf="userConnected" >
                    <a class="nav-link waves-effect profile profile-user cursor" (click)="openPage('/dashboard')">
                        <img [src]="userConnected.image64 | safeHtml" class="profile"  /> 
                        <span>Salut {{userConnected.firstname}} {{userConnected.lastname[0]}} <i class="material-icons">arrow_drop_down</i> </span>
                    </a>
                </li>


                <li class="nav-item">
                    <a class="nav-link waves-effect waves-light cursor" href="https://twitter.com/msahilisho" target="_blank" >
                      <i class="fa fa-twitter"></i>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link waves-effect waves-light cursor" href="https://www.facebook.com/msahilishoimportexport/" target="_blank" >
                        <i class="fa fa-facebook"></i>
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link waves-effect waves-light cursor" href="https://www.instagram.com/msahilishogroup/?hl=fr" target="_blank" >
                        <i class="fa fa-instagram"></i>
                    </a>
                </li>
                <!--<li class="nav-item">
                    <a class="nav-link waves-effect waves-light cursor">
                        <i class="fa fa-google-plus"></i>
                    </a>
                </li>-->

                
            </ul>


            <li class="nav-item " *ngIf="userConnected" >
                <a class="nav-link waves-effect btn-inscription cursor" (click)="logOut()">
                    Déconnexion
                </a>
            </li>


            <li class="nav-item admnistration">

                <button mat-button  aria-label="Administration menu">
                    <img src="assets/imgs/france.png" width="20px" /> &nbsp; FR
                </button>


                <button mat-button  aria-label="Administration menu" (click)="openPage('/help')" >
                    <img src="assets/imgs/info.svg" width="20px" />
                </button>

            <!--   [matMenuTriggerFor]="menu"
                <mat-menu #menu="matMenu">
                    <button mat-menu-item >
                        <img src="assets/imgs/france.png" width="20px" /> &nbsp;
                        <span class="countries">FR</span>
                    </button>
                    <button mat-menu-item >
                        <img src="assets/imgs/united-kingdom.png" width="20px" /> &nbsp;
                        <span>EN</span>
                    </button>

                    <button mat-menu-item >
                        <img src="assets/imgs/comoros.png" width="20px" /> &nbsp;
                        <span>KM</span>
                    </button>
                    
                </mat-menu>
            -->


            </li>

        </ul>

        
    </div>





  </nav>