import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { MaterialAngModule } from '../matmodule/materialang.module'

import { BackendService } from '../services/backend/backend.service'
import { GlobalService } from '../services/global/global.service'
import { EventsService } from '../services/events/events.service'



@NgModule({
  declarations: [
    HeaderComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    MaterialAngModule
  ],
  exports: [
    HeaderComponent
  ],
  providers: [
    BackendService,
    GlobalService,
    EventsService
  ]
})
export class HeaderModule { }
