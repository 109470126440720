 <!--==========================
      Services Section
    ============================-->
    <section id="services" class="section-bg" *ngIf="nextTravels.length > 0" >
        <div class="container-service">
  
          <header class="section-header">
            <h3> 
                <img src="assets/imgs/travel_loc_bl.svg" class="travel_loc_bl" />
                <!-- <svg viewBox="0 0 24 24" class="css-1jhmm81"><g><path d="M18.749 6.375a.817.817 0 0 1-.131-.011 1.125 1.125 0 0 1-.982-.983.698.698 0 0 1 0-.26c.06-.513.47-.923.982-.983a.717.717 0 0 1 .262 0c.513.06.922.47.983.983a.796.796 0 0 1 0 .26c-.061.513-.47.923-.983.983a.817.817 0 0 1-.131.011z"></path><path d="M18.748 13.426a1.489 1.489 0 0 1-1.182-.577c-1.855-2.373-4.067-5.618-4.067-7.598 0-2.895 2.355-5.25 5.25-5.25s5.25 2.355 5.25 5.25c0 1.983-2.212 5.227-4.068 7.6a1.493 1.493 0 0 1-1.183.575zM18.749 1.5a3.754 3.754 0 0 0-3.75 3.75c0 1.159 1.437 3.717 3.75 6.676 2.313-2.957 3.75-5.515 3.75-6.676a3.754 3.754 0 0 0-3.75-3.75zM.749 24a.74.74 0 0 1-.689-.455.747.747 0 0 1-.007-.574l6-15a.746.746 0 0 1 .976-.417.747.747 0 0 1 .417.975l-6 15A.747.747 0 0 1 .749 24zm21 0a.744.744 0 0 1-.696-.472l-3-7.5a.747.747 0 0 1 .418-.975.745.745 0 0 1 .974.419l3 7.5a.747.747 0 0 1-.418.975.75.75 0 0 1-.278.053zm-10.5 0a.75.75 0 0 1-.75-.75v-1.5a.75.75 0 0 1 1.5 0v1.5a.75.75 0 0 1-.75.75zm0-6a.75.75 0 0 1-.75-.75v-1.5a.75.75 0 0 1 1.5 0v1.5a.75.75 0 0 1-.75.75zm0-6a.75.75 0 0 1-.75-.75v-1.5a.75.75 0 0 1 1.5 0v1.5a.75.75 0 0 1-.75.75z"></path></g></svg>-->
                Les prochains départs 
            </h3>
            <p> Faites livrer vos colis et vos achats par des particuliers </p>
            <p> <a class="btn-showannonces cursor" (click)="openPage('/expedier-ou-recevoir-un-colis')" > <img src="assets/imgs/search.svg" class="airport-img" /> Voir plus d'annonces</a> </p>
            
          </header>

          <div class="row">
  
            <div *ngFor="let ntravl of nextTravels; let i = index" [ngClass]="i%2 == 0 ? 'offset-lg-1' : ''" class="col-md-6 col-lg-5 wow bounceInUp cursor" data-wow-duration="1.4s"  (click)="openAnnonce(ntravl)">
              <div class="box" [ngClass]="ntravl.kgdispo > 0 ? '' : 'ligh-bg-no-kilos' ">
                <div class="icon"><i> <img class="avatar" [src]="ntravl.user_suggest.image64 | safeHtml" /> </i></div>

                
                <div class="row">
                    <div class="col-md-8">
                        <h4 class="title">
                            <a >{{ntravl.user_suggest.username}} !</a>
                        </h4>
                    </div>
                    <div class="col-md-4 duree-voyage">
                        Durée prévue du trajet : {{ntravl.nombre_jours}} jour(s)
                    </div>
                </div>

                <div class="detail-user">
                    <div class="row">
                        <div class="col-12 col-lg-6 col-md-6 col-sm-12 date-dep">
                            <i class="fa fa-calendar" aria-hidden="true"></i> <span>{{ ntravl.datedepot | date: 'EE dd MMMM yyyy'}}</span>
                        </div>
                        <div class="col-6 col-lg-2 col-md-2 col-sm-6 icon-transport">
                            <img src="assets/imgs/plane.svg" class="travel-img" />
                        </div>
                        <div class="col-6 col-lg-4 col-md-4 col-sm-6 note-user">
                            <!--<ngb-rating [(rate)]="currentRate" max="5" [readonly]="true"></ngb-rating>-->
                            <span *ngFor="let strt of currentRate" class="" >
                                <span class="material-icons start-rate-icon">star_rate</span>
                              </span>
                        </div>
                    </div>
                </div>

                <div class="colis-user">

                    <!-- Airport -->
                    <div class="row">
                        <div class="col-md-6">
                            <div class="txt-mute-petit">Date de départ prévue</div>
                            <span class="text-colis"> <img src="assets/imgs/boarding.svg" class="airport-img" /> ({{ ntravl.datedepot | date: 'EE dd MMMM yyyy'}})</span><br /><br />
                            <img src="assets/imgs/airport.svg" class="airport-img" />
                            <span class="airport-text">{{ntravl.aerodep}}</span>
                        </div>
                        <div class="col-md-6">
                            <div class="txt-mute-petit">Date d’arrivée prévue</div>
                            <span class="text-colis"> <img src="assets/imgs/waiting-room.svg" class="airport-img" /> ({{ ntravl.daterecup | date: 'EE dd MMMM yyyy'}})</span><br /><br />
                            <img src="assets/imgs/airport.svg" class="airport-img" />
                            <span class="airport-text">{{ntravl.aeroarr}}</span>
                        </div>
                    </div>

                    <!-- Colis -->
                    <div class="row prix-aukilo">
                        <div class="col-6 col-md-6 ">
                            <span class="text-colis">Prix /kg</span><br />
                            <img src="assets/imgs/euro.svg" class="airport-img" />
                            <span class="airport-text">{{ntravl.prixkg}} {{ntravl.devise}}</span>
                        </div>
                        <div class="col-6 col-md-6">
                            <span class="text-colis">Nombre de kilos disponibles</span><br />
                            <img src="assets/imgs/box.svg" class="airport-img" />
                            <span class="airport-text">{{ntravl.kgdispo}} KG</span>
                            <span *ngIf="ntravl.kgdispo <= 0" class="plus-de-kg">COMPLET !</span>
                        </div>
                    </div>

                </div>


                <!-- <p class="description">Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>-->


                <div class="info-user">
                    <div class="row">
                        <div class="col-6 col-md-4 " [ngClass]="ntravl.user_suggest.email != '' ? 'check-icon' : 'uncheck-icon'" >
                            <i class="material-icons">check_circle</i><span>Email</span> 
                        </div>
                        <div class="col-6 col-md-4" [ngClass]="ntravl.user_suggest.telephone != '' ? 'check-icon' : 'uncheck-icon'">
                            <i class="material-icons">check_circle</i><span>Téléphone</span> 
                        </div>
                        <div class="col-6 col-md-4" [ngClass]="ntravl.user_suggest.address != '' ? 'check-icon' : 'uncheck-icon'">
                            <i class="material-icons">check_circle</i><span>Adresse</span> 
                        </div>
                        <!--<div class="col-6 col-md-3" [ngClass]="ntravl.user_suggest.email != '' ? 'check-icon' : 'uncheck-icon'">
                            <i class="material-icons">check_circle</i><span>P. identité</span> 
                        </div>-->
                    </div>
                </div>


              </div>

              <span class="slides" *ngIf="ntravl.msahilisho != '0'" ><img src="assets/imgs/mbanner.png" alt=""></span>

            </div>

  
          </div>
  
        </div>
    </section><!-- #services -->
