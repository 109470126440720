<div class="row dashbord-right">

    <label for="">
        Mes avis  <span>Votre historique d'avis envoyés ou reçus </span>
    </label>



    <div class="container">

        <div class="row">
            <div class="col-md-12">
                <div class="main-timeline2">


                    <div class="timeline" *ngFor="let avis of lesNotes" >
                        <span class="icon"> <img [src]="avis.user_transp.image64 | safeHtml" class="testimonial-img" alt=""> </span>
                        <a href="#" class="timeline-content">
                            <h3 class="title">
                                {{avis.user_transp.civilite}}. {{avis.user_transp.name}}

                                <h4><ngb-rating [(rate)]="avis.nbrstar" max="5"></ngb-rating></h4>
                            </h3> 
                            <p class="description">
                                {{ avis.note }}
                            </p>
                        </a>
                    </div>



                </div>
            </div>
        </div>

        <div class="row" *ngIf="lesNotes.length > 0 && !notOther " >
            <div class="col-12">
                <div class="text-center">
                    <a (click)="voirPlus()" class="btn-showannonces cursor"> <img src="assets/imgs/search.svg" class="airport-img" /> Voir plus d'avis (+10)</a>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="!avisYes" >
            <div class="col-12">
                <div class="text-center text-gray">
                    Vous n'avez ni envoyé ni reçu d'avis
                </div>
            </div>
        </div>


    </div>


    



</div>
