import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasswordComponent } from './password.component';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { HomeModule} from '../home/home.module'
import { TopheaderModule} from '../topheader/topheader.module'
import { HeaderModule} from '../header/header.module';
import { FooterModule } from '../footer/footer.module'
import { MaterialAngModule } from '../matmodule/materialang.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BackendService } from '../services/backend/backend.service'
import { GlobalService } from '../services/global/global.service'
import { EventsService } from '../services/events/events.service'
import { ValidatorrService } from '../services/validatorr/validatorr.service'

@NgModule({
  declarations: [PasswordComponent],
  imports: [
    CommonModule,

    NgbModule,
    HomeModule,
    TopheaderModule,
    HeaderModule,
    FooterModule,
    MaterialAngModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [BackendService , GlobalService , EventsService , ValidatorrService ]
})
export class PasswordModule { }
