<footer id="footer">
    <div class="footer-top">
      <div class="container-app">
        <div class="row">

          <div class="col-lg-3 col-md-6 footer-info">
            <!--<img src="assets/imgs/msahilisho.png" class="" width="200px" />-->
            <h4>Le group Msahilisho </h4>
            <p>
              L’équipe Msahilisho Group, ce sont de multiples compétences complémentaires formant une richesse d’expertises. Chaque membre de notre équipe est différent mais des valeurs communes nous réunissent : l’innovation, le service et la qualité ! Envie d’en savoir plus ? <a  class="cursor" (click)="openPage('/Qui-sommes-nous')" > <b>Qui sommes-nous?</b> </a>
            </p>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Liens utiles</h4>
            <ul>
              <li><a class="cursor" (click)="openPage('/home')" ><i class="material-icons">done</i> Acceuil</a></li>
              <li><a class="cursor" (click)="openPage('/expedier-ou-recevoir-un-colis')" ><i class="material-icons">done</i> Expédier un colis</a></li>
              <li><a class="cursor" (click)="openPage('/proposer-un-trajet')" ><i class="material-icons">done</i> Proposer un trajet</a></li>
              <!--<li><a  class="cursor" (click)="openPage('/expedier-ou-recevoir-un-colis')" ><i class="material-icons">done</i> Voir les annonces</a></li>-->
              <li><a  class="cursor" (click)="openPage('/Qui-sommes-nous')" ><i class="material-icons">done</i> Qui sommes-nous ?</a></li>
              <li><a class="cursor" (click)="openPage('/politique-de-confidentialite')"><i class="material-icons">done</i> Politique de confidentialité</a></li>
              <li><a class="cursor" (click)="openPage('/conditions-generales-utilisations')"><i class="material-icons">done</i> Conditions Générales d'Utilisation</a></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-contact">
            <h4>Nous contacter</h4>



            <div class="row">
              <div class="col-4">
                <img src="assets/imgs/travel_loc_bl.svg" width="25" /> &nbsp; <img src="assets/imgs/france.png" width="15" />
              </div>
              <div class="col-8">
                <div [innerHTML]="footerInfos?.adresse" ></div> 
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <img src="assets/imgs/travel_loc_bl.svg" width="25" /> &nbsp; <img src="assets/imgs/comoros.png" width="15" />
              </div>
              <div class="col-8">
                <div [innerHTML]="footerInfos?.adressecomores" ></div> 
              </div>
            </div>



            <div class="row">
              <div class="col-4">
                <img src="assets/imgs/customer.svg" width="25" /> &nbsp; <img src="assets/imgs/france.png" width="15" />
              </div>
              <div class="col-8">
                <div [innerHTML]="footerInfos?.telephone" ></div> 
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <img src="assets/imgs/customer.svg" width="25" /> &nbsp; <img src="assets/imgs/comoros.png" width="15" />
              </div>
              <div class="col-8">
                <div [innerHTML]="footerInfos?.telcomores" ></div> 
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <img src="assets/imgs/support.svg" width="25" /> &nbsp;
              </div>
              <div class="col-8">
                <div [innerHTML]="footerInfos?.email" ></div> 
              </div>
            </div>
            <p *ngIf="footerInfos">
              <img [src]="footerInfos?.image64 | safeHtml" class="" width="200px" /><br />
            </p>

            

          </div>

          <div class="col-lg-3 col-md-6 footer-newsletter form">
            <h4>Rester informé</h4>
            <p> Pour ne rien manquer de l'actualité et des promotions Msahilisho Group, inscrivez-vous à la Newsletter Quotidienne de Msahilisho Import & Export. </p>
            <form [formGroup]="contactFormGroup" (ngSubmit)="onSubmit()"  >
              <div id="sendmessage" *ngIf="retourMessage.ok" > {{retourMessage.ok}} </div>
              <div id="errormessage" *ngIf="retourMessage.ko"> {{retourMessage.ko}} </div>

              <input type="email" formControlName="emailCtrl" id="emailCtrl" required  ><input type="submit"  value="M'inscrire">
            </form>
            <br />
            <div class="social-links">
              <a href="https://twitter.com/msahilisho" target="_blank" class="twitter">
                <img src="assets/imgs/icons/twitter.svg" class="icons-logo" />
              </a>
              <a href="https://www.facebook.com/msahilishoimportexport/" target="_blank" class="facebook">
                <img src="assets/imgs/icons/facebook.svg" class="icons-logo" />
              </a>
              <a href="https://www.instagram.com/msahilishogroup/?hl=fr" target="_blank" class="instagram">
                <img src="assets/imgs/icons/instagram.svg" class="icons-logo" />
              </a>
              <!--<a href="#" class="google-plus"><i class="fa fa-google-plus"></i></a>
              <a href="#" class="linkedin"><i class="fa fa-linkedin"></i></a>-->
            </div>

          </div>

        </div>
      </div>
    </div>

    <div class="container">
      <div class="copyright">
        &copy; Copyright <strong>Msahilisho Group</strong> v. {{versionApplication}}. Tous droits réservés.
      </div>
      <div class="credits">
        Une création de <a href="{{urlApp}}"> Msahilisho Group <img src="assets/imgs/msahilishoImportExportWhite.png" width="100px" /> </a> et <a href="https://hachamco.fr/"> Hacham & Co <img src="assets/imgs/hachamco.png" width="120px" /> </a>
      </div>
    </div>
  </footer>