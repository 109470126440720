import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { HomeModule} from '../home/home.module'
import { TopheaderModule} from '../topheader/topheader.module'
import { HeaderModule} from '../header/header.module';
import { FooterModule } from '../footer/footer.module'
import { MaterialAngModule } from '../matmodule/materialang.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SidebarComponent } from './sidebar/sidebar.component';

import { ProfileModule } from '../profile/profile.module'


import { ExpeditionComponent } from './expedition/expedition.component';
import { NotesComponent } from './notes/notes.component'
import { NotesService} from './notes/notes.service'

import { GlobalService } from '../services/global/global.service'
import { BackendService } from '../services/backend/backend.service'
import { EventsService } from '../services/events/events.service'

import { NgxMaskModule } from 'ngx-mask';
import { EditSendboxComponent } from './edit/edit-sendbox/edit-sendbox.component';


import { ModalModule } from '../modal/modal.module';
import { TrajetsComponent } from './trajets/trajets.component';
import { MesavisComponent } from './mesavis/mesavis.component';
import { EditTrajetComponent } from './edit/edit-trajet/edit-trajet.component';
import { ExpeditionEncoursComponent } from './expedition-encours/expedition-encours.component';
import { ExpeditionTermineeComponent } from './expedition-terminee/expedition-terminee.component';

import { SharedModule } from '../shared/shared.module';
import { FideliteComponent } from './fidelite/fidelite.component';


@NgModule({
  declarations: [
    DashboardComponent, 
    SidebarComponent, 
    ExpeditionComponent, 
    NotesComponent, 
    EditSendboxComponent, 
    TrajetsComponent, 
    MesavisComponent, 
    EditTrajetComponent, 
    ExpeditionEncoursComponent, 
    ExpeditionTermineeComponent,
    FideliteComponent
  ],
  imports: [
    CommonModule,

    NgbModule,
    HomeModule,
    TopheaderModule,
    HeaderModule,
    FooterModule,
    MaterialAngModule,
    FormsModule,
    ReactiveFormsModule,

    ProfileModule,
    NgxMaskModule.forRoot(),
    ModalModule,
    SharedModule

  ],
  providers: [EventsService , NotesService , GlobalService , BackendService ],
  entryComponents: [NotesComponent]
})
export class DashboardModule { }
