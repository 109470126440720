import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { HomeComponent} from './home/home.component'
import { SendboxComponent } from './sendbox/sendbox.component';
import { SuggestrouteComponent } from './suggestroute/suggestroute.component';
import { ContactComponent } from './contact/contact.component'
import { CmtmarcheComponent } from './cmtmarche/cmtmarche.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { DetailsAdComponent } from './sendbox/details-ad/details-ad.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ChooseAdComponent } from './sendbox/choose-ad/choose-ad.component'
import { ActiveuserComponent } from './activeuser/activeuser.component';
import { ReinitpassComponent } from './reinitpass/reinitpass.component';
import { ConfidentialiteComponent } from './confidentialite/confidentialite.component'
import { CguComponent } from './cgu/cgu.component'
import { PasswordComponent } from './password/password.component'
import { TeamsComponent } from './teams/teams.component'
import { HelpComponent } from './help/help.component';
import { JeveuxvoyagerComponent } from './jeveuxvoyager/jeveuxvoyager.component';
import { SignercontratComponent } from './signercontrat/signercontrat.component';


import { VisitorGuard } from './auth-helper/visitor.guard'

const routes: Routes = [
  { path: 'home',      component: HomeComponent , canActivate: [VisitorGuard] },
  { path: 'expedier-ou-recevoir-un-colis',      component: SendboxComponent, canActivate: [VisitorGuard] },
  { path: 'proposer-un-trajet',      component: SuggestrouteComponent, canActivate: [VisitorGuard] },
  { path: 'contact',      component: ContactComponent, canActivate: [VisitorGuard] },
  { path: 'comment-ca-marche',      component: CmtmarcheComponent , canActivate: [VisitorGuard] },
  { path: 'login',      component: LoginComponent , canActivate: [VisitorGuard] },
  { path: 'signup',      component: SignupComponent , canActivate: [VisitorGuard] },
  { path: 'detail-annonces',      component: DetailsAdComponent , canActivate: [VisitorGuard] }, // /:id
  { path: 'choisir-annonce',      component: ChooseAdComponent , canActivate: [VisitorGuard] },
  { path: 'dashboard',      component: DashboardComponent , canActivate: [VisitorGuard] },
  { path: 'activer-mon-compte/:id',      component: ActiveuserComponent , canActivate: [VisitorGuard] },
  { path: 'reinitialiser-mon-mot-de-passe/:id',      component: ReinitpassComponent , canActivate: [VisitorGuard] },
  { path: 'politique-de-confidentialite',      component: ConfidentialiteComponent , canActivate: [VisitorGuard] },
  { path: 'conditions-generales-utilisations',      component: CguComponent , canActivate: [VisitorGuard] },
  { path: 'mot-de-passe-oublier',      component: PasswordComponent , canActivate: [VisitorGuard] },
  { path: 'Qui-sommes-nous',      component: TeamsComponent , canActivate: [VisitorGuard] },
  { path: '',   redirectTo: 'home', pathMatch: 'full' },

  { path: 'mes-expedition-ou-transports', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'mon-compte', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'mes-trajets', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'mes-avis', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'help',      component: HelpComponent , canActivate: [VisitorGuard] },
  { path: 'je-veux-voyager',      component: JeveuxvoyagerComponent , canActivate: [VisitorGuard] },

  { path: 'signer-mon-contrat-voyageur/:id',      component: SignercontratComponent , canActivate: [VisitorGuard] },

  { path: '**', component: HomeComponent }
];


@NgModule({
  imports: [RouterModule.forRoot(routes , { useHash: true, relativeLinkResolution: 'legacy' } )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
