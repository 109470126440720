import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';

import { GlobalService } from '../../../services/global/global.service'
import { BackendService } from '../../../services/backend/backend.service'
import { EventsService } from '../../../services/events/events.service'
import { ConfirmService } from '../../../modal/confirm/confirm.service';

import {FormControl, FormGroup, Validators, FormBuilder} from '@angular/forms';


@Component({
  selector: 'app-edit-trajet',
  templateUrl: './edit-trajet.component.html',
  styleUrls: ['./edit-trajet.component.scss']
})
export class EditTrajetComponent implements OnInit {

    /***** */
    isLinear = true;
    submitted = false
  
    infosFormGroup: FormGroup;
    offreFormGroup: FormGroup;
    paiementFormGroup: FormGroup;
    confiramtionFormGroup: FormGroup;
  
    aeroportList = []
    frequenceVoyage = []
    moyenPayment = []
    isPaiementReadOnly = true
  
    aeroportSelet = {depart: null, arrivee: null }
    @ViewChild('labelImport')
    labelImport: ElementRef;
  
    formImport: FormGroup;
    fileToUpload: File = null;
    urlSelectJustif = { url: '', msg: 'Justificatif de voyage' }
    progressMessage = {progress: null, ok: null, ko: null}
  
    maskTeldep = {mask: "(+00) 0 00 00 00 00" , value: "france.png"}
    masktelArr = {mask: "(+00) 0 00 00 00 00" , value: "france.png"}
  
    pourcentage
    msgPrice

    suggestSelect
    userConnected

    listTelephone = [];
    envoiEncours = false;
  
  constructor(
    private globalService: GlobalService,
    private backendService: BackendService,
    private _formBuilder: FormBuilder,
    private eventsService: EventsService ,
    private confirmService: ConfirmService,
    private router: Router
  ) { }


  ngOnInit() {

    this.listTelephone = this.globalService.getListeIndicatifTelephone();
    this.aeroportList = this.globalService.getAeroports()
    this.frequenceVoyage = this.globalService.getFrequenceVoyage()
    this.moyenPayment = this.globalService.getMoyenPaiement()

    if( !this.globalService.getConnectedUser() ) {
      this.router.navigate(['/home']);
      window.scroll(0,0);
    }

    if( !this.globalService.getAnnoneEnModification() ) {
      this.router.navigate(["/dashboard"]);
      window.scroll(0,0);
    }

    this.userConnected = this.globalService.getConnectedUser()
    this.suggestSelect = this.globalService.getAnnoneEnModification()

    /******** */
    this.pourcentage = this.globalService.getPourcentage()
    this.msgPrice = `Msahilisho Import & Export se réserve ${this.pourcentage}% de commission sur votre annonce.`

    this.infosFormGroup = this._formBuilder.group({
      aeroportDepCtrl: ['', Validators.required],
      aeroportArrCtrl: ['', Validators.required],
      adresseDepotCtrl: ['', Validators.required],
      adresseRecupCtrl: ['', Validators.required],
      dateDepartCtrl: ['', Validators.required],
      dateArriveeCtrl: ['', Validators.required],
      numDepartCtrl: ['', Validators.required],
      numArriveCtrl: ['', Validators.required]
    }, {validator: this.dateLessThan('dateDepartCtrl', 'dateArriveeCtrl')});

    this.offreFormGroup = this._formBuilder.group({
      deviseCtrl: [''],
      frequenceCtrl: ['', Validators.required],
      prixCtrl: ['', Validators.required],
      nbrKilosCtrl: ['', Validators.required]
    });

    this.paiementFormGroup = this._formBuilder.group({
      paiementCtrl: ['', Validators.required],
      ibanCtrl: ['']
    });

    this.confiramtionFormGroup = this._formBuilder.group({
      confirmationCtrl: ['', Validators.required],
      importFile: ['']
    });

  }


  ngAfterContentInit() {
    
    console.log( this.suggestSelect )

    this.maskTeldep =  this.globalService.maskSaisi( this.suggestSelect.teldepot );
    this.masktelArr = this.globalService.maskSaisi( this.suggestSelect.telrecup );

    this.infosFormGroup.patchValue( {
      aeroportDepCtrl: this.makeAeroSelect( this.suggestSelect.aerodep , "dep" ),
      aeroportArrCtrl: this.makeAeroSelect( this.suggestSelect.aeroarr , "arr"),
      adresseDepotCtrl: this.suggestSelect.adddepot,
      adresseRecupCtrl: this.suggestSelect.addrecup,
      dateDepartCtrl: this.unFormateDate( this.suggestSelect.datedepot ),
      dateArriveeCtrl: this.unFormateDate( this.suggestSelect.daterecup ),
      numDepartCtrl: this.suggestSelect.teldepot,
      numArriveCtrl: this.suggestSelect.telrecup,
    } )
    this.offreFormGroup.patchValue( {
      deviseCtrl: this.suggestSelect.devise,
      frequenceCtrl: this.suggestSelect.freqtrajet,
      prixCtrl: this.suggestSelect.prixkg,
      nbrKilosCtrl: this.suggestSelect.kgdispo,
    } )
    this.paiementFormGroup.patchValue( {
      paiementCtrl: this.suggestSelect.moyenpaie,
      ibanCtrl: this.suggestSelect.iban
    } )


  }


  makeAeroSelect( aero , type ) {
    let ppp = this.aeroportList.filter( ae => ae.name == aero )

    if( type == "dep" ) {
      this.aeroportSelet.depart = ppp[0]
    } else {
      this.aeroportSelet.arrivee = ppp[0]
    }
    
    return ppp[0]
  }

  get f1() { 
    return this.infosFormGroup.controls; 
  }
  get f2() { 
    return this.offreFormGroup.controls; 
  }
  get f3() { 
    return this.paiementFormGroup.controls; 
  }
  get f4() { 
    return this.confiramtionFormGroup.controls; 
  }



  onAeroDepSelection(event) {
    this.aeroportSelet.depart = this.infosFormGroup.controls['aeroportDepCtrl'].value 
  }

  onAeroArrSelection(event) {
    this.aeroportSelet.arrivee = this.infosFormGroup.controls['aeroportArrCtrl'].value 
  }


  onFreqSelection(event) {

  }

  onPaymentSelection(event) {
    if( this.paiementFormGroup.controls['paiementCtrl'].value == this.moyenPayment[0].value ) {
      this.isPaiementReadOnly = false
    } else {
      this.isPaiementReadOnly = true
    }
  }


  onFileChange( event ) {  //files: FileList   :::: $event.target.files 
    
   if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      this.urlSelectJustif.url = event.target.files[0]
      reader.readAsDataURL(event.target.files[0]);
      this.urlSelectJustif.msg = event.target.files[0].name
    }
    
  }


  formaterDate( la_date ){
    return `${la_date.substring(0, 2)}-${la_date.substring(2, 4)}-${la_date.substring(4, 8)}`
  }


  unFormateDate( la_date ){
    let la_date_ = la_date.split("-")
    return la_date_[2] + la_date_[1] + la_date_[0]
  }


  openCGU(){
    //this.router.navigate(["/conditions-generales-utilisations"]);
    window.open( `${this.globalService.getURLApp()}#/conditions-generales-utilisations`  , "_blank")
  }


  telFormat( ntl , type ) {
    console.log('ntel ' , ntl)
    if (type === 'dep') {
      this.maskTeldep.value = ntl.image;
      this.maskTeldep.mask = ntl.mask;
      this.infosFormGroup.patchValue( {
        numDepartCtrl: ntl.text
      } );
    } else {
      this.masktelArr.value = ntl.image;
      this.masktelArr.mask = ntl.mask;
      this.infosFormGroup.patchValue( {
        numArriveCtrl: ntl.text
      } );
    }
  }



  OnChangePrice(){
    
    let prc  = parseFloat( this.offreFormGroup.controls['prixCtrl'].value ) * ( parseFloat(this.pourcentage) / 100 );
    let rst = parseFloat( this.offreFormGroup.controls['prixCtrl'].value ) - prc
    this.msgPrice = `Msahilisho Import & Export se réserve ${this.pourcentage}% de commission sur votre annonce. Soit ${prc.toFixed(2)}€ pour Msahilisho Import & Export et ${rst.toFixed(2)}€ pour vous, pour chaque proposition de transport`
  }




  publier(){

    this.submitted = true
    this.progressMessage.ko = null;
    this.progressMessage.ok = null;
    this.progressMessage.progress = null;

    /*console.log( this.infosFormGroup )
    console.log( this.offreFormGroup )
    console.log( this.paiementFormGroup )
    console.log( this.confiramtionFormGroup )
    console.log( this.urlSelectJustif )*/

    if (this.infosFormGroup.invalid) {
      this.progressMessage.ko = "Une erreur a été détectée dans l'onglet [Informations du voyage] "
      return;
    }
    if (this.offreFormGroup.invalid) {
      this.progressMessage.ko = "Une erreur a été détectée dans l'onglet [Offre et service] "
      return;
    }
    if (this.paiementFormGroup.invalid) {
      this.progressMessage.ko = "Une erreur a été détectée dans l'onglet [Facturation et rémunération] "
      return;
    }

    if (this.confiramtionFormGroup.invalid) {
      this.progressMessage.ko = "Une erreur a été détectée dans l'onglet [Confirmation et validation des CGU]. Le champs CGU est obligatoire."
      return;
    }
    if ( this.urlSelectJustif.url === '' ) {
      this.progressMessage.ko = "Une erreur a été détectée dans l'onglet [Confirmation et validation des CGU]. Le justificatif de voyage est obligatoire."
      return;
    }
    if ( this.infosFormGroup.controls['numDepartCtrl'].value === '' || this.infosFormGroup.controls['numArriveCtrl'].value === '' ) {
      this.progressMessage.ko = "Une erreur a été détectée dans l'onglet [Informations du voyage]. Votre navigateur internet n'est peut être pas compatible avec cette version de Msahilisho Import & Export."
      return;
    }

    const formData = new FormData();
    formData.append('justificatif', this.urlSelectJustif.url);
    formData.append('iduser', this.globalService.getConnectedUser().id);
    formData.append('aerodep', this.aeroportSelet.depart.name );
    formData.append('aeroarr', this.aeroportSelet.arrivee.name );
    formData.append('adddepot', this.infosFormGroup.controls['adresseDepotCtrl'].value );
    formData.append('addrecup', this.infosFormGroup.controls['adresseRecupCtrl'].value );
    formData.append('datedepot', this.formaterDate( this.infosFormGroup.controls['dateDepartCtrl'].value ) );
    formData.append('daterecup', this.formaterDate(this.infosFormGroup.controls['dateArriveeCtrl'].value ) );
    formData.append('teldepot', `${this.infosFormGroup.controls['numDepartCtrl'].value}` );
    formData.append('telrecup', `${this.infosFormGroup.controls['numArriveCtrl'].value}` );
    formData.append('devise', this.offreFormGroup.controls['deviseCtrl'].value );
    formData.append('freqtrajet', this.offreFormGroup.controls['frequenceCtrl'].value );
    formData.append('prixkg', this.offreFormGroup.controls['prixCtrl'].value );
    formData.append('kgdispo', this.offreFormGroup.controls['nbrKilosCtrl'].value );
    formData.append('moyenpaie', this.paiementFormGroup.controls['paiementCtrl'].value );
    formData.append('iban', this.paiementFormGroup.controls['ibanCtrl'].value );
    formData.append('paysdep', this.aeroportSelet.depart.pays );
    formData.append('paysarr', this.aeroportSelet.arrivee.pays );
    formData.append('msahilisho', this.globalService.checkIsMsahilisho( this.globalService.getConnectedUser().email ) ? "1" : "0" );

    this.envoiEncours = true;
    this.backendService.upload( formData, `/suggest/edit/${this.suggestSelect.id}`)
    .subscribe (
      (res) => {
        let uploadResponse = res

        if( uploadResponse.status === 'error' ) {
          
          this.eventsService.errorsmsg( uploadResponse.message )
        } else {
          
          if( uploadResponse.status == 'progress' ) {
            this.progressMessage.progress = uploadResponse.message
          }

          if( uploadResponse.text ) {
            this.progressMessage.ok = uploadResponse.text
            this.envoiEncours = false;
            setTimeout(() => {
              this.goBack()
            }, 2000);
          }

        }
      },
      (err) => {
        this.envoiEncours = false;
        this.eventsService.errorsmsg( err )
      }
    )

  }


  goBack() {
    this.globalService.setAnnoneEnModification( null )
    this.eventsService.onPageSelected( "mes-trajets" )
  }



  deleteSuggest() {

    this.confirmService.confirm(`Suppression du trajet - ${this.suggestSelect.aerodep} => ${this.suggestSelect.aeroarr}`, 'Êtes-vous sûr de vouloir supprimer ce trajet ?', 'Supprimer', 'Annuler' )
    .then( (confirmed : any) => {

      if( confirmed.etat == "accept") {

        this.backendService.delete(`/suggest/route/${this.suggestSelect.id}`)
        .then( resultat => {

          if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
            // this.eventsService.errorsmsg( resultat.error.error.text )
            this.progressMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
          } else {

            this.eventsService.successmsg( resultat.text )
            this.progressMessage.ok = resultat.text
            setTimeout( () => {
              this.goBack()
            }, 2000)
          
          }

        })
        .catch( error => {
          
          this.progressMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
        })

      }
      
    })
    .catch( () => {

    })

  }


  checkMask( type , value ) {
    if( type == "date" ) {
      return value.length < 8 ? false: true
    }
  }

  
  changeBTN( action ) {

   this.submitted = false
   console.log( this.infosFormGroup )

   if( action == "infos" ) {

    if (this.infosFormGroup.invalid) {
      this.submitted = true
      return;
    }

   }

   if( action == "offre" ) {

    if (this.offreFormGroup.invalid) {
      this.submitted = true
      return;
    }

   }

   if( action == "facturation" ) {

    if (this.paiementFormGroup.invalid) {
      this.submitted = true
      return;
    }

   }


   if( action == "cgu" ) {

    if (this.confiramtionFormGroup.invalid) {
      this.submitted = true
      this.progressMessage.ko = null
      return;
    }

   }


  }


  dateLessThan(from: string, to: string) {
    return (group: FormGroup): {[key: string]: any} => {
      let f = group.controls[from];
      let t = group.controls[to];
      if (f.value > t.value) {
        return {
          dates: "La date de départ doit obligatoirement être postérieure à celle d'arrivée !"
        };
      }
      return {};
    }
  }


}
