<mat-list role="list">

    <mat-list-item role="listitem" class="cursor" [ngClass]="selectedPage == 'dashboard' ? 'menu-selected' : 'menu'" (click)="selectOption('dashboard')" >
        <img src="assets/imgs/dashboard.svg" class="svg-img" /> Tableau de bord
    </mat-list-item>

    <mat-list-item role="listitem" class="cursor menu" [ngClass]="selectedPage == 'mon-compte' ? 'menu-selected' : 'menu'" (click)="selectOption('mon-compte')" >
        <img src="assets/imgs/ui.svg" class="svg-img" /> Mon profil
    </mat-list-item>

    <!--<mat-list-item role="listitem" class="cursor menu" [ngClass]="selectedPage == 'mes-expedition-ou-transports' ? 'menu-selected' : 'menu'" (click)="selectOption('mes-expedition-ou-transports')" >
        <img src="assets/imgs/import.svg" class="svg-img" /> Mes Expéditions / Transports
    </mat-list-item>-->


    <mat-list-item role="listitem" class="cursor menu" [ngClass]="selectedPage == 'mes-expedition-ou-transports-encours' ? 'menu-selected' : 'menu'" (click)="selectOption('mes-expedition-ou-transports-encours')" >
        <img src="assets/imgs/import.svg" class="svg-img" /> Mes Expéditions / Transports En cours
    </mat-list-item>

    <mat-list-item role="listitem" class="cursor menu" [ngClass]="selectedPage == 'historique-expedition-ou-transports' ? 'menu-selected' : 'menu'" (click)="selectOption('historique-expedition-ou-transports')" >
        <img src="assets/imgs/import_histo.svg" class="svg-img" /> Historique Expéditions / Transports
    </mat-list-item>



    <mat-list-item role="listitem" class="cursor menu" [ngClass]="selectedPage == 'mes-trajets' ? 'menu-selected' : 'menu'" (click)="selectOption('mes-trajets')" >
        <img src="assets/imgs/tracking.svg" class="svg-img" /> Mes Trajets
    </mat-list-item>

   <!-- <mat-list-item role="listitem" class="cursor menu" [ngClass]="selectedPage == 'ma-messagerie' ? 'menu-selected' : 'menu'" (click)="selectOption('ma-messagerie')" >
        <img src="assets/imgs/dashboard.svg" class="svg-img" /> Ma Messagerie
    </mat-list-item>
    -->

    <mat-list-item role="listitem" class="cursor menu" [ngClass]="selectedPage == 'mes-avis' ? 'menu-selected' : 'menu'" (click)="selectOption('mes-avis')" >
        <img src="assets/imgs/dashboard.svg" class="svg-img" /> Mes avis
    </mat-list-item>

  </mat-list>
