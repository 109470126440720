<div class="content-app">

    <app-topheader></app-topheader>
 
    <app-header></app-header>


    <!-- Section bar de recherche -->
    <div class="search-bar-top">
        <img src="assets/imgs/sign-in.svg" class="airport-img" /> Authentification
    </div>

    <app-flashinfo-home *ngIf="!publish" ></app-flashinfo-home>



    <div class="login-page ">

        <div class="row logo-authentification">
            <div class="col-12 text-center">
                <img src="assets/imgs/sign-in.svg" class="img-login" /> 
            </div>
        </div>

        <div class="row " *ngIf="resultMessage != null ">
            <div class="col-12 div-error">
                {{resultMessage.text}}
            </div>
        </div>


        <form [formGroup]="loginFormGroup" (ngSubmit)="onSubmit()"  >

            <div class="form-group">
                <label for="emailCtrl">Votre e-mail</label>
                <div class="input-group mb-2 mr-sm-2">
                    <div class="input-group-prepend">
                      <div class="input-group-text"><i class="material-icons">email</i></div>
                    </div>
                    <input type="email" class="form-control" id="emailCtrl" placeholder="Votre e-mail" formControlName="emailCtrl" [ngClass]="{ 'is-invalid': submitted && f.emailCtrl.errors }" >
                  </div>
            </div>

            <div class="form-group">
                <label for="passwordCtrl">Mot de passe</label>
                <div class="input-group mb-2 mr-sm-2">
                    <div class="input-group-prepend">
                      <div class="input-group-text"><i class="material-icons">lock</i></div>
                    </div>
                    <input type="password" class="form-control" id="passwordCtrl" placeholder="Votre mot de passe" formControlName="passwordCtrl" [ngClass]="{ 'is-invalid': submitted && f.passwordCtrl.errors }" >
                  </div>
            </div>

            <div class="form-group checkboxx">
                <mat-checkbox formControlName="seSouvenirCtrl"  > </mat-checkbox> <span>Se souvenir de moi.</span>
            </div>


            <div class="row txt-connexion" *ngIf="publish" >
                <div class="col-md-6">
                    <button type="submit" class="btn-connexion" >Se connecter</button>
                </div>
                
                <div class="col-md-6">
                    <button (click)="passwordForget()" type="button" class="btn-password"  >Mot de passe oublié ?</button>
                </div>


            </div>

          </form>


    </div>

    <div class="marg-bot"></div>


    
 
    <app-whychoose-home></app-whychoose-home> 
     
    
    <app-footer></app-footer>

    
</div>
