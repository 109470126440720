<div class="content-app comment-ca">

    <app-topheader></app-topheader>
 
    <app-header></app-header>


    <div class="search-bar-top">
        <img src="assets/imgs/brainstorming.svg" class="airport-img" /> Mais comment ça marche ? 
    </div>


    <mat-tab-group mat-align-tabs="center" class="tabs-center">



        <mat-tab label="Je veux envoyer un colis">
            
            <div class="container">
            
                <div class="row">
                    <div class="col-md-12">
                        <div class="main-timeline2">
                            <div class="timeline">
                                <span class="icon material-icons">search</span>
                                <a href="#" class="timeline-content">
                                    <h3 class="title">Je recherche un voyageur</h3>
                                    <p class="description">
                                        Renseignez la ville de départ et la destination de votre colis. Les profils ayant enregistré leur 
                                        trajet apparaîtront et vous n'aurez plus qu'à faire votre choix ! Pour vous aider à choisir la bonne personne, consultez leurs profils.
                                    </p>
                                </a>
                            </div>
                            <div class="timeline">
                                <span class="icon material-icons">travel_explore</span>
                                <a href="#" class="timeline-content">
                                    <h3 class="title">Je fais une demande de livraison</h3>
                                    <p class="description">
                                        Je précise au voyageur l’objet à transporter, toutes les informations qui peuvent lui être utiles (taille, poids, coordonnées du destinataire).
                                    </p>
                                </a>
                            </div>
                            
                            <div class="timeline">
                                <span class="icon material-icons">inventory_2</span>
                                <a href="#" class="timeline-content">
                                    <h3 class="title">Je confie mon colis à un agent Msahilisho + paiement</h3>
                                    <p class="description">
                                        Je prépare correctement mon colis mais je le laisse ouvert avant la prise en charge pour que l'agent puisse en vérifier le contenu. Je n’oublie pas de fermer le colis ensuite :-)
                                    </p>
                                </a>
                            </div>
                            <div class="timeline">
                                <span class="icon material-icons">receipt</span>
                                <a href="#" class="timeline-content">
                                    <h3 class="title">Je signe la fiche de dépôt obligatoire</h3>
                                    <p class="description">
                                        Je remplis la fiche sur le nombre de kilo ou quantité, la valeur, la date d’émission du colis ou de l’objet. J'appose ma signature précédée de la mention lu et approuvé et bon pour accord en deux exemplaire l’une pour moi et l’autre pour l’agent Msahilisho qui les signent à son tour et en y apposant son cachet.
                                    </p>
                                </a>
                            </div>
                            <div class="timeline">
                                <span class="icon material-icons">emoji_emotions</span>
                                <a href="#" class="timeline-content">
                                    <h3 class="title">Mon colis est livré !</h3>
                                    <p class="description">
                                        Mon correspondant (le destinataire) m’a confirmé la bonne reception du colis !
                                    </p>
                                </a>
                            </div>
                            <div class="timeline">
                                <span class="icon material-icons">star_rate</span>
                                <a href="#" class="timeline-content">
                                    <h3 class="title">Je n’oublie pas de laisser un avis !</h3>
                                    <p class="description">
                                        Qu’il soit très positif ou un peu moins, je n’oublie pas de laisser un avis sur le profil du voyageur afin d’informer les autres utilisateurs.
                                    </p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </mat-tab>



        <mat-tab label="Je rentabilise mes trajets">
            
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="main-timeline5">
                            <div class="timeline">
                                <div class="timeline-icon"><span class="year"> <img src="assets/imgs/flight-route.svg" class="svg-img" /> </span></div>
                                <div class="timeline-content">
                                    <h3 class="title">Je poste mon trajet</h3>
                                    <p class="description">
                                        Je renseigne ma ville de départ, de destination, la date de mon voyage, le nombre de kilos que je peux transporter.
                                        J'indique aussi d’autres informations tel que le prix au kilo.
                                    </p>
                                </div>
                            </div>
                            <div class="timeline">
                                <div class="timeline-icon"><span class="year"> <img src="assets/imgs/people.svg" class="svg-img-2" /> </span></div>
                                <div class="timeline-content">
                                    <h3 class="title">J’ai reçu une demande de livraison</h3>
                                    <p class="description">
                                        Je prends connaissance de la demande, de l’objet à transporter et si tout me conviens je peux valider la demande.
                                    </p>
                                </div>
                            </div>
                            <div class="timeline">
                                <div class="timeline-icon"><span class="year"> <img src="assets/imgs/box.svg" class="svg-img-2" /> </span></div>
                                <div class="timeline-content">
                                    <h3 class="title">Je récupère le colis du demandeur à l'agent Msahilisho</h3>
                                    <p class="description">
                                        L'agent Msahilisho a pris des photos à la reception du colis avant la ferméture. Je prends connaissance du contenu avant la prise en charge.
                                         Une fois vérifié, je peux le récupérer :-)
                                    </p>
                                </div>
                            </div>
                            <div class="timeline">
                                <div class="timeline-icon"><span class="year"> <img src="assets/imgs/digital-signature.svg" class="svg-img-2" /> </span></div>
                                <div class="timeline-content">
                                    <h3 class="title">Je signe le contrat obligatoire LV-ind</h3>
                                    <p class="description">
                                        Je lis et signe en deux exemplaires avec l’agent Msahilisho le contrat LV-ind (Le voyageur indépendant) qui me permet de prendre connaissance des normes et conditions à respecter pour pouvoir bénéficier du service Msahilisho Import/Export.
                                    </p>
                                </div>
                            </div>
                            <div class="timeline">
                                <div class="timeline-icon"><span class="year"> <img src="assets/imgs/tracking.svg" class="svg-img-2" /> </span></div>
                                <div class="timeline-content">
                                    <h3 class="title">J’ai livré le colis !</h3>
                                    <p class="description">
                                        J’ai bien livré le colis à l'agent Msahilisho, qui a prévenu le demandeur de mon arrivée. Selon le moyen de paiement, l'agent Msahilisho débloque le paiement à la seconde (Cash, virement ...etc.)
                                    </p>
                                </div>
                            </div>
                            <div class="timeline">
                                <div class="timeline-icon"><span class="year"> <img src="assets/imgs/like.svg" class="svg-img-2" /> </span></div>
                                <div class="timeline-content">
                                    <h3 class="title">Je n’oublie pas de laisser un avis !</h3>
                                    <p class="description">
                                        Qu’il soit très positif ou un peu moins, je n’oublie pas de laisser un avis sur le fil d'actualité de livraison de Msahilisho afin d’informer les autres utilisateurs du service.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </mat-tab>


    </mat-tab-group>



    <app-whychoose-home></app-whychoose-home> 
     
    
    <app-footer></app-footer>

    
</div>