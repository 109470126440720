import { BrowserModule } from '@angular/platform-browser';
import { NgModule , APP_INITIALIZER } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
registerLocaleData(localeFr, 'fr-FR', localeFrExtra);
import { LOCALE_ID } from '@angular/core';

import { JwtInterceptor } from './auth-helper/jwt.interceptor';
import { ErrorInterceptor } from './auth-helper/error.interceptor';


import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
// import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { HttpClientModule , HTTP_INTERCEPTORS } from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import * as $ from 'jquery';
import { MaterialAngModule } from './matmodule/materialang.module'

import { FooterModule } from './footer/footer.module'
import { HomeModule} from './home/home.module'
import { HeaderModule} from './header/header.module'
import { TopheaderModule} from './topheader/topheader.module'
import { SendboxModule } from './sendbox/sendbox.module'
import { SuggestrouteModule } from './suggestroute/suggestroute.module'
import { ContactModule } from './contact/contact.module'
import { CmtmarcheModule } from './cmtmarche/cmtmarche.module'
import { LoginModule } from './login/login.module'
import { SignupModule } from './signup/signup.module'
import { ProfileModule } from './profile/profile.module'
import { DashboardModule } from './dashboard/dashboard.module'
import { ModalModule as MuduleNotre } from './modal/modal.module'
import { ActiveuserModule } from './activeuser/activeuser.module'
import { ReinitpassModule } from './reinitpass/reinitpass.module'
import { ConfidentialiteModule } from './confidentialite/confidentialite.module'
import { CguModule } from './cgu/cgu.module'
import { PasswordModule } from './password/password.module'
import { TeamsModule } from './teams/teams.module'
import { HelpModule } from './help/help.module'
import { JeveuxvoyagerModule } from './jeveuxvoyager/jeveuxvoyager.module';
import { SignercontratModule } from './signercontrat/signercontrat.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ToastrModule } from 'ngx-toastr'; 

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MomentModule } from 'angular2-moment';

import { BackendService } from './services/backend/backend.service';
import { LoaderService } from './services/loader/loader.service';
import { SharedModule } from './shared/shared.module';

export function loadConfigurations(backService: BackendService) {
  return () => backService.load();
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    // AngularFontAwesomeModule,
    MaterialAngModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FooterModule,
    HomeModule,
    HeaderModule,
    TopheaderModule,
    SendboxModule,
    SuggestrouteModule,
    ContactModule,
    CmtmarcheModule,
    LoginModule,
    SignupModule,
    ProfileModule,
    DashboardModule,
    MuduleNotre,
    ActiveuserModule,
    ReinitpassModule,
    ConfidentialiteModule,
    CguModule,
    PasswordModule,
    TeamsModule,
    HelpModule,
    ToastrModule.forRoot({
      timeOut: 4000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      closeButton: true,
    }),

    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    JeveuxvoyagerModule,
    SharedModule,
    SignercontratModule

  ],
  providers: [ 
    BackendService, 
    LoaderService,
    { provide: APP_INITIALIZER, useFactory: loadConfigurations, deps: [BackendService], multi: true },
    { provide: LOCALE_ID, useValue: 'fr-FR' } ,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
