import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { GlobalService } from '../../services/global/global.service'
import { BackendService } from '../../services/backend/backend.service'


@Component({
  selector: 'app-annonces-home',
  templateUrl: './annonces-home.component.html',
  styleUrls: ['./annonces-home.component.scss'],
  
})
export class AnnoncesHomeComponent implements OnInit {

  nextTravels = []
  urlImage
  currentRate


  constructor( 
    private router: Router,
    private globalService: GlobalService,
    private backendService: BackendService,
  ) { }


  ngOnInit() {
    this.currentRate = Array(5).fill(5)
    this.urlImage = this.globalService.urlImage
    this.getAnnonces()
  }


  getAnnonces(){

    let annonce = {
      'limite': 0,
      'trajet_de': '',
      'trajet_a': '',
      'limite_all': 4,
      'etat': 1
    }

    this.backendService.post('/suggest/list' , annonce )
    .then( resultat => {
      
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
          this.nextTravels = []
      } else {
        this.nextTravels = resultat
      }
      
    })
    .catch( error => {
      this.nextTravels = []
    })

  }



  openPage(page) {
    this.router.navigate([page]);
    window.scroll(0,0);
  }


  openAnnonce(ntravl) {
    this.globalService.setAnnoncdSelected( ntravl )
    this.router.navigate(['/detail-annonces' ]);
    window.scroll(0,0);
  }

}
