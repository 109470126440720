<nav class="navbar navbar-expand-lg navbar-dark  static-top navbar-fixed-top">


    <div class="navbar-brand cursor" (click)="openPage('/home')"> 
        <img src="assets/imgs/msahilisho.png" class="" width="200px" />
    </div>

    <button class="navbar-toggler btn-collapse" type="button" (click)="isCollapsed = !isCollapsed"
        aria-controls="navbarResponsive" [attr.aria-expanded]="!isCollapsed" aria-label="Toggle navigation">
        <i class="material-icons">menu</i>
    </button>


    <div [ngbCollapse]="!isCollapsed" class="collapse navbar-collapse" id="navbarResponsive">

        <ul class="navbar-nav ml-auto">

            <ul class="navbar-nav mr-auto">

                <li class="nav-item active">
                    <a class="nav-link waves-effect waves-light cursor" (click)="openPage('/expedier-ou-recevoir-un-colis')" >
                        <img src="assets/imgs/tracking.svg" class="airport-img" />
                        Expédier un colis <!-- ou recevoir -->
                    </a>
                </li>
        
                <li class="nav-item active">
                    <a class="nav-link waves-effect waves-light cursor" (click)="openPage('/proposer-un-trajet')" >
                        <img src="assets/imgs/flight-route.svg" class="airport-img" />
                        Proposer un trajet
                    </a>
                </li>

                <!--<li class="nav-item active">
                    <a class="nav-link waves-effect waves-light cursor" (click)="openPage('/expedier-ou-recevoir-un-colis')" >
                        <img src="assets/imgs/search.svg" class="airport-img" />
                        Voir les annonces
                    </a>
                </li>-->


                <li class="nav-item active">
                    <a class="nav-link waves-effect waves-light cursor" (click)="openPage('/je-veux-voyager')" >
                        <img src="assets/imgs/tourist.png" class="airport-img" />
                        Je veux voyager
                    </a>
                </li>

                <li class="nav-item active">
                    <a class="nav-link waves-effect waves-light cursor" (click)="openPage('/contact')" >
                        <img src="assets/imgs/support.svg" class="airport-img" />
                        Contact
                    </a>
                </li>

                <!--<li class="nav-item">
                    <a class="nav-link waves-effect waves-light cursor" (click)="openPage('/aide')">
                        <img src="assets/imgs/customer-service.svg" class="airport-img" />
                    </a>
                </li>-->
                
            </ul>

        </ul>

        
    </div>


  </nav>