<div class="content-app">

    <app-topheader></app-topheader>
 
    <app-header></app-header>


    <!-- Section bar de recherche -->
    <div class="search-bar-top">
        <img src="assets/imgs/msahilishoImportExportWhite.png" class="airport-img" />  « Nous », c'est qui exactement ?
    </div>


    <div class="py-5 team4">
        <div class="container">
          <div class="row justify-content-center mb-4">
            <div class="col-md-7 text-center">
              <h3 class="mb-3">Découvrez notre équipe de passionnés</h3>
              <h6 class="subtitle">
                  L’équipe Msahilisho Group, ce sont de multiples compétences complémentaires formant une richesse d’expertises.
                  Chaque membre de notre équipe est différent mais des valeurs communes nous réunissent : l’innovation, le service et la qualité ! Envie d’en savoir plus ? Vous êtes au bon endroit.
                </h6>
            </div>
          </div>

          <div class="row">

            <!-- column  -->
            <div class="col-lg-4 mb-4 d-none d-xl-block d-lg-block "> &nbsp; </div>
            <div class="col-lg-4 mb-4" *ngIf="president" >
              <!-- Row -->
              <div class="row">
                <div class="col-md-12">
                  <img [src]="president.image64 | safeHtml" alt="wrapkit" class="img-fluid rounded-circle" />
                </div>
                <div class="col-md-12 text-center">
                  <div class="pt-2">
                    <h5 class="mt-4 font-weight-medium nom-prenom mb-0">{{president.nom_prenom}}</h5>
                    <h6 class="subtitle mb-3">{{president.email}}</h6>
                    <p [innerHTML]="president.bio"></p>
                  </div>
                </div>
              </div>
              <!-- Row -->
            </div>
            <div class="col-lg-4 mb-4 d-none d-xl-block d-lg-block "> &nbsp; </div>
            <!-- column  -->

          </div>

          <div class="row">


            <!-- column  -->
            <div class="col-lg-3 mb-4" *ngFor="let team of equipes" >
              <!-- Row -->
              <div class="row">
                <div class="col-md-12">
                  <img [src]="team.image64 | safeHtml" alt="wrapkit" class="img-fluid rounded-circle" />
                </div>
                <div class="col-md-12 text-center">
                  <div class="pt-2">
                    <h5 class="mt-4 font-weight-medium nom-prenom mb-0">{{team.nom_prenom}}</h5>
                    <h6 class="subtitle mb-3">{{team.email}}</h6>
                    <p [innerHTML]="team.bio"></p>
                  </div>
                </div>
              </div>
              <!-- Row -->
            </div>
            <!-- column  -->
            
          </div>


          <div class="row"  *ngIf="directeurGeneral">
            <div class="col-lg-4 mb-4 d-none d-xl-block d-lg-block "> &nbsp; </div>
            <div class="col-lg-4 mb-4" *ngIf="directeurGeneral" >
              <div class="row">
                <div class="col-md-12">
                  <img [src]="directeurGeneral.image64 | safeHtml" alt="wrapkit" class="img-fluid rounded-circle" />
                </div>
                <div class="col-md-12 text-center">
                  <div class="pt-2">
                    <h5 class="mt-4 font-weight-medium nom-prenom mb-0">{{directeurGeneral.nom_prenom}}</h5>
                    <h6 class="subtitle mb-3">{{directeurGeneral.email}}</h6>
                    <p [innerHTML]="directeurGeneral.bio"></p>
                  </div>
                </div>
              </div>
  
            </div>
            <div class="col-lg-4 mb-4 d-none d-xl-block d-lg-block "> &nbsp; </div>
          </div>


        </div>
    </div>


    <div class="row content-cgu-term" *ngFor="let abas of aboutAs" >

      <div class=" teams-body col-12">
        <div [innerHTML]="abas.nous" ></div>
      </div>

      <div class="col-12 test-normal text-center">
          <img [src]="abas.image64 | safeHtml" class="" /><br />
      </div>

    </div>




    <div class="marg-bot"></div>



    
 
    <app-whychoose-home></app-whychoose-home> 
     
    
    <app-footer></app-footer>

    
</div>
