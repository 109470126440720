import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss']
})
export class ApplicationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


  openApp( app ){
    if ( app === 'ios' ) {
      window.open( `https://apps.apple.com/us/app/msahilisho-group/id1553768360`  , "_blank")
    } else {
      window.open( `https://play.google.com/store/apps/details?id=com.msahilishogroup.importexport&hl=fr&gl=US`  , "_blank")
    }
  }

}
