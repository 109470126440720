import { Component, OnInit , Input  } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-info-detail',
  templateUrl: './info-detail.component.html',
  styleUrls: ['./info-detail.component.scss']
})
export class InfoDetailComponent implements OnInit {

  @Input() infoDetail
  constructor(
    private activeModal: NgbActiveModal ,
  ) { }

  ngOnInit() {
  }

  public decline() {
    this.activeModal.close({status: false});
  }
  

}
