import { Component, OnInit , Input  } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() btnRefuserText: string;


  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {

  }

  public decline() {
    this.activeModal.close({etat: 'cancel'});
  }

  public accept() {
    this.activeModal.close({etat: 'accept'});
  }

  public dismiss() {
    this.decline();
  }

  public refuser() {
    this.activeModal.close({etat: 'refuse'});
  }

}
