<div class="content-app">

    <app-topheader></app-topheader>
 
    <app-header></app-header>


    <!-- Section bar de recherche -->
    <div class="search-bar-top">
        <img src="assets/imgs/dashboard.svg" class="airport-img" /> Votre tableau de bord utilisateur
    </div>

    
    <mat-drawer-container class="example-container" autosize>
        <mat-drawer #drawer class="example-sidenav" mode="side" [opened]="opened" >


          <app-sidebar></app-sidebar>

        </mat-drawer>
      
        <div class="example-sidenav-content">

          <button type="button" mat-button (click)="drawer.toggle() && eventToggle(drawer)" class="btn-menu-dash" >
            <i class="material-icons">menu_open</i> <span>{{txtBadge}}</span>
          </button> <br />



          <div class="text-center"  *ngIf="pageSelected == 'dashboard'">

            <div class="attention-compte-incomplet" *ngIf="userConnected.address === '' || userConnected.telephone === '' || userConnected.city === '' " >
              <div class="licone">
                <i class="material-icons">warning</i> 
              </div>
              <div class="info-message" >
                Attention ! Votre compte utilisateur est incomplet. <br />
                En finalisant votre profil, vous obtnez 10 pts de fidélité. <br />
                Veuillez renseigner votre adresse complète et un numéro de téléphone valide. <br /><br />
                Votre numéro de téléphone, votre adresse e-mail ainsi que l'adresse de votre lieu de résidence sont des informations confidentielles. <br />
                Rassurez-vous, elles ne seront ni affichées publiquement, ni communiquées aux autres utilisateurs.
              </div>
            </div>

              <app-fidelite *ngIf="userConnected.pointclient" ></app-fidelite>

              <div class="bonjour">
                  <img [src]="userConnected.image64 | safeHtml" class="profile"  /> <br />
                  Salut {{userConnected.firstname}} {{userConnected.lastname[0]}}!

                  <p>
                      Bienvenue dans votre espace personnel my Msahilisho Import & Export
                  </p>
              </div>

                
          </div>

          <app-profile *ngIf="pageSelected == 'mon-compte'"></app-profile>
          <app-expedition *ngIf="pageSelected == 'mes-expedition-ou-transports'"></app-expedition>
          <app-edit-sendbox *ngIf="pageSelected == 'edit-sendbox'" ></app-edit-sendbox>
          <app-mesavis *ngIf="pageSelected == 'mes-avis'"></app-mesavis>
          <app-trajets *ngIf="pageSelected == 'mes-trajets'"></app-trajets>
          <app-edit-trajet *ngIf="pageSelected == 'edit-trajet'" ></app-edit-trajet>

          <app-expedition-encours *ngIf="pageSelected == 'mes-expedition-ou-transports-encours'" ></app-expedition-encours>

          <app-expedition-terminee *ngIf="pageSelected == 'historique-expedition-ou-transports'" >  </app-expedition-terminee>


        </div>
      
      </mat-drawer-container>



    <div class="marg-bot"></div>

    <app-whychoose-home></app-whychoose-home> 
     
    
    <app-footer></app-footer>

    
</div>
