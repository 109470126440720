

<section class="tg-business-user tg-haslayout">
    <div class="container-app2">
        <div class="row">

            <div class="col-sm-6">
                <div class="tg-business" >
                    <div class="tg-section-head" >
                        <h2>Je suis expéditeur</h2>
                    <ul>
                        <li>Je bénéficie d'un faible coût de transport (prix négocié avec le voyageur)</li>
                        <li>Service fonctionnant tous les jours (dimanche et jours fériés compris)</li>
                        <li>J'ai une date et une heure de livraison choisis et non pas subis</li>
                        <li>Je sais que tout est sécurisé et le colis assuré</li>
                    </ul>
                    
                    </div>
                    <a *ngIf="!userConnected" class="btn-connexion cursor" (click)="openPage('/signup')" ><span>Je m'inscris !</span></a>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="tg-user" >
                    <div class="tg-section-head" >
                        <h2>Je suis voyageur</h2>
                    <ul>
                        <li>Je rentabilise mes trajets/voyages</li>
                        <li>France <img src="assets/imgs/double-arrow.svg" class="arrow_double" /> Océan Indien <img src="assets/imgs/double-arrow.svg" class="arrow_double" /> Sénégal </li>
                        <li>Je choisis la taille du colis que je peux prendre avec moi (du S au XXL) !</li>
                        <li>Je sais que tout est sécurisé et le colis assuré</li>
                    </ul>
                    </div>
                    <a *ngIf="!userConnected" class="btn-connexion cursor" (click)="openPage('/signup')" ><span>Je m'inscris !</span></a>
                </div>
            </div>
        </div>
    </div>
</section>