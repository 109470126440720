import { Component, OnInit, ViewChild } from '@angular/core';
//import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';


import { GlobalService } from '../../services/global/global.service'
import { BackendService } from '../../services/backend/backend.service'

import { EventsService } from '../../services/events/events.service'

@Component({
  selector: 'app-clients-home',
  templateUrl: './clients-home.component.html',
  styleUrls: ['./clients-home.component.scss']
})
export class ClientsHomeComponent implements OnInit {

  avisClient = []

  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;
  urlImage
  userConnected

  @ViewChild('carousel', {static : true}) carousel: NgbCarousel;

  constructor(
    private globalService: GlobalService,
    private backendService: BackendService,
    private router: Router,
    private eventsService: EventsService
  ) {
    this.eventsService.userConnectEmitter.subscribe( user => {
      this.isConnectedUser()
    })
  }



  ngOnInit() {

    this.isConnectedUser()
    this.urlImage = this.globalService.urlImage
    this.getNotes()
  }



  getNotes(){
    
    this.backendService.get('/notes/list' )
    .then( resultat => {

      
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
          this.avisClient = []
      } else {
        this.avisClient = resultat;
      }
      
    })
    .catch( error => {
      this.avisClient = []
    })

  }


  isConnectedUser() {
    this.userConnected = this.globalService.getConnectedUser()
  }


  openPage(page) {
    this.router.navigate([page]);
    window.scroll(0,0);
  }


  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }


  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }

}
