<div class="modal-header">
    <div class="modal-title"> 
        <img src="assets/imgs/{{infoDetail.img}}" class="img-cqnous" /> <span> {{infoDetail.title}}</span>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="decline()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>


<div class="modal-body">


    <div class="row">

        <div class="col-md-12">

            <div class="form-group" >
                <label for="locationCtrl"> Le concept de la « {{infoDetail.title}} » Msahilisho Group !<span> <img src="assets/imgs/correct.svg" class="img-check" /> </span></label>
                <div class="txt-body" [innerHTML]="infoDetail.text" ></div>
            </div>

        </div>

    </div>


</div>