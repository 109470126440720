import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators, FormBuilder} from '@angular/forms';
import { Router } from '@angular/router';

import { BackendService } from '../services/backend/backend.service'
import { GlobalService } from '../services/global/global.service'
import { EventsService } from '../services/events/events.service'
import { ValidatorrService } from '../services/validatorr/validatorr.service'

import { environment } from 'src/environments/environment';
import { version } from '../../../package.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  submitted = false
  contactFormGroup: FormGroup;
  retourMessage = { ok: null, ko: null}
  userConnected
  
  urlImage = environment.urlImage
  urlApp = environment.urlApp
  versionApplication = environment.environment === 'production' ? version : `${version}-${environment.environment}`

  footerInfos: any;

  constructor(
    private router: Router ,
    private _formBuilder: FormBuilder,
    private backendService: BackendService ,
    private globalService: GlobalService,
    private eventsService: EventsService
  ) { 

    this.footerInfos = {
      nom: "Msahilisho Group",
      email: "contact@msahilishogroup.com",
      telephone: "(+33) 06 58 74 50 24",
      adresse: "24 rue de Verdun, 91800 Brunoy",
      logo: "logo.png",
      telcomores: "",
      adressecomores: ""
    }
    this.eventsService.userConnectEmitter.subscribe( user => {
      this.isConnectedUser()

      /*this.footerInfos = {
        nom: "Msahilisho Group",
        email: "contact@msahilishogroup.com",
        telephone: "(+33) 06 58 74 50 24",
        adresse: "24 rue de Verdun, 91800 Brunoy",
        logo: "logo.png",
        telcomores: ""
      }*/
    })
  }


  ngOnInit() {

    this.getFooterContent()
    this.contactFormGroup = this._formBuilder.group({
      emailCtrl: ['', [Validators.required , ValidatorrService.emailValidator] ]
    });
    this.isConnectedUser()
    
  }


  getFooterContent() {

    this.backendService.get('/contact/footer' )
    .then( resultat => {

      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        
      } else {
        this.footerInfos = resultat[0]
        this.globalService.setRemisecentPoints( this.footerInfos.remisecentpoints )
        console.log('Result footer ', this.footerInfos)
      }

    })
    .catch( error => {
    })

  }


  get f() { 
    return this.contactFormGroup.controls; 
  }

  isConnectedUser() {
    this.userConnected = this.globalService.getConnectedUser()
  }


  onSubmit(){
    this.submitted = true

    if (this.contactFormGroup.invalid) {
      this.retourMessage.ko = "Adresse email incorrecte"
      return;
    }
    this.retourMessage.ko = null

    let contact = {
      email: this.f.emailCtrl.value,
      listId: environment.listeDiffusionGlobale
    }

    this.backendService.post('/contact/newsletter', contact )
    .then( resultat => {
      
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      } else {
        this.retourMessage.ok = resultat.text
        setTimeout( () => {
          this.contactFormGroup.patchValue( {
            emailCtrl: "",
          } )
        }, 2000)
      }

    })
    .catch( error => {
      
    })

  }


  openPage(page) {

    if( page == '/proposer-un-trajet' && !this.userConnected ) {
      this.router.navigate(['/login']);
    } else {
      this.router.navigate([page]);
    }

    window.scroll(0,0);

  }


}
