<div class="s01" [ngStyle]="backgroundImage" >
    <form [formGroup]="searchFormGroup" (ngSubmit)="onSubmit()" >
      <fieldset>
        <legend>Expédiez vos colis <span>en toute sérénité</span></legend>
      </fieldset>

      <div class="lengdsTitle">
            <!--Envoyer <span>un colis</span> dans l'<span>Océan Indien</span> en toute <span>sérénité</span> et une <span>qualité</span> absolue.-->
            <span>Recherchez</span>, <span>comparez</span>, <span>envoyez</span>, <span>économisez</span>. Il n'y a rien de plus simple.
      </div>

      <div class="inner-form">

        <div class="input-field text-one">
          <input class="form-control-plaintext" type="text" value="Je cherche un trajet de" />
        </div>

        <div class="input-field text-two">
            <input id="villeDepCtrl" type="text"class="form-control" placeholder="Indiquer ville ou pays" formControlName="villeDepCtrl" [ngClass]="{ 'is-invalid': submitted && f.villeDepCtrl.errors }"  />
        </div>

        <div class="input-field text-three">
            <!--<input class="form-control-plaintext" type="text" value="à" />-->
            <img src="assets/imgs/double-arrow.svg" class="arrow_double cursor" (click)="reverseInput()" />
        </div>

        <div class="input-field text-four">
            <input id="villeArrCtrl" type="text" class="form-control"  placeholder="Indiquer ville ou pays" formControlName="villeArrCtrl" [ngClass]="{ 'is-invalid': submitted && f.villeArrCtrl.errors }" />
        </div>

        <div class="input-field text-five">
          <button class="btn-search" type="submit">C'est parti !</button>
        </div>

      </div>

      <div class="lengdsTitle bottom-legend">
        Parce que la <span>qualité</span> et la <span>simplicité</span> doivent être au <span>centre</span> de toute <span>prestation</span>.
      </div>

    </form>
</div>


<div class="caParledenous">
    <div class="row">

        <div class="col-md-2 ilsParlent">
            Ils parlent de nous 
        </div>
        <div class="col-md-10 ">
    
            <div class="row">
                <div class="col"> <img src="assets/imgs/partenaires/hachamco.png" class=""  width="130px" /> </div>
                <div class="col"> <img src="assets/imgs/partenaires/NuktwaRadio.png" class=""  width="130px" /> </div>
 

                <div class="col"> <img src="assets/imgs/partenaires/le269.png" class="" width="130px" /> </div>
                <div class="col"> <img src="assets/imgs/partenaires/hStudio.png" class=""  width="80px"/> </div>
                <div class="col"> <img src="assets/imgs/partenaires/upissi.png" class="" width="40px" /> </div>
            </div>
            
        </div>

    </div>
   

</div>


<div class="nousCeQuoi">
  <div class="row">

      <div *ngFor="let matl of textToolTip " class="col-md-3 cursor" (click)="openDetail(matl)" matTooltip="Cliquez ici pour en savoir plus" >
        <img src="assets/imgs/{{matl.img}}" class="img-cqnous" /><br />
        <div class="txt-cqn">
          <img src="assets/imgs/settings.svg" class="img-check" />
          &nbsp;&nbsp; {{matl.title}} &nbsp;&nbsp;
          <img src="assets/imgs/correct.svg" class="img-check" />
        </div> 
      </div>

  </div>
 

</div>
