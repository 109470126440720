<div class="content-app">

    <app-topheader></app-topheader>
 
    <app-header></app-header>


    <!-- Section bar de recherche -->
    <!-- Section bar de recherche -->
    <div class="search-bar-top">
        <img src="assets/imgs/tracking.svg" class="airport-img" /> Expédier un colis
    </div>

    <div class="container-app">

        <div class="go-back">
            <button class="" mat-button (click)="goBack()" matTooltip="Retourner à la page de choix de trajet" > 
                <mat-icon>arrow_back_ios</mat-icon> Retourner à la page de choix de trajet
            </button>
        </div>

        <div class="row">

            <div class="col-md-4">

                <div class="box wow bounceInUp">
                    
                    <div class="detail-user">
                        <div class="row">
                            <div class="col-12 date-dep">
                                <i class="fa fa-calendar" aria-hidden="true"></i> <span>{{nextTravels.datedepot | date: 'EE dd MMMM yyyy'}}</span>
                            </div>
                            <div class="col-6 icon-transport">
                                <img src="assets/imgs/plane.svg" class="travel-img" />
                            </div>
                            <div class="col-6 note-user">
                                <!--<ngb-rating [(rate)]="currentRate" max="5" [readonly]="true"></ngb-rating>-->
                                <span *ngFor="let strt of currentRate" class="" >
                                    <span class="material-icons start-rate-icon">star_rate</span>
                                  </span>
                            </div>
                        </div>
                    </div>
    
                    <div class="colis-user">
    
                        
                        <div class="row">
                            <div class="col-md-12">
                                <div class="txt-mute-petit">Date de départ prévue</div>
                                <span class="text-colis"><img src="assets/imgs/boarding.svg" class="airport-img" /> ({{ nextTravels.datedepot | date: 'EE dd MMMM yyyy'}})</span><br /><br />
                                <img src="assets/imgs/airport.svg" class="airport-img" />
                                <span class="airport-text">{{nextTravels.aerodep}}</span>
                            </div>

                            <div class="col-md-12 margin-20">
                                <div class="txt-mute-petit">Date d’arrivée prévue</div>
                                <span class="text-colis"><img src="assets/imgs/waiting-room.svg" class="airport-img" /> ({{ nextTravels.daterecup | date: 'EE dd MMMM yyyy'}})</span><br /><br />
                                <img src="assets/imgs/airport.svg" class="airport-img" />
                                <span class="airport-text">{{nextTravels.aeroarr}}</span>
                            </div>
                        </div>

                        <div class="row margin-20"></div>
                    
                        <div class="row paiement-arrive">
                            <label>Le paiement s'effectuera à la récupération du colis par l'agent Msahilisho</label>
                            <div class="col-6 col-md-12 ">
                                <span class="text-colis">Prix /kg</span><br />
                                <img src="assets/imgs/euro.svg" class="airport-img" />
                                <span class="airport-text">{{nextTravels.prixkg}} {{nextTravels.devise}}</span><br />
                                
                            </div>
                            <div class="col-6 col-md-12 margin-20" >
                                <span class="text-colis">Kilos disponibles</span><br />
                                <img src="assets/imgs/box.svg" class="airport-img" />
                                <span class="airport-text">{{nextTravels.kgdispo}} KG</span>
                            </div>
                        </div>
    
                    </div>
    
    
    
                </div>

            </div>


            <div class="col-md-8">

                <form [formGroup]="infosFormGroup" (ngSubmit)="onSubmit()" >

                    <div class="info-colis">Informations colis </div>

                    <div class="row">
                        <div class="col-md-6">
    
                            <div class="form-group" >
                                <label for="nomPrenomExpediteurCtrl">Expéditeur  <span>(*)</span></label>
                                <legend>Votre nom et prénom tel que écrit sur votre pièce d’identité officiel </legend>
                                <input id="nomPrenomExpediteurCtrl" type="text" class="form-control" formControlName="nomPrenomExpediteurCtrl" placeholder="Votre nom et prénom" readonly="readonly" >
                            </div>
    
                        </div>
    
                        <div class="col-md-6">
    
                            <div class="form-group" >
                                <label for="telExpediteurCtrl">Téléphone expéditeur  <span>(*)</span></label>
                                <legend>Numéro de téléphone sur lequel vous êtes joignable.</legend>

                                <div class="input-group mb-3">

                                    <div class="input-group-prepend">
                                        <button class="btn btn-outline-secondary dropdown-toggle" type="button"  
                                            aria-haspopup="true" aria-expanded="false" [matMenuTriggerFor]="menuExp" > 
                                            <img src="assets/imgs/{{maskTelExp.value}}" width="20" >
                                        </button>
                                        <mat-menu #menuExp="matMenu" class="img-select-menu" >
                                          <button *ngFor="let ntl of listTelephone" mat-menu-item (click)="telFormat( ntl , 'exp' )" >
                                              <img src="assets/imgs/{{ntl.image}}" width="30" > &nbsp; {{ntl.text}}
                                          </button>
                                        </mat-menu>
                                    </div>

                                    <input [validation]="true" [showMaskTyped]="true" mask="{{maskTelExp.mask}}" id="telExpediteurCtrl" type="text" 
                                    class="form-control" formControlName="telExpediteurCtrl" placeholder="Téléphone expéditeur" required [ngClass]="{ 'is-invalid': submitted && f.telExpediteurCtrl.errors }" >
                                </div>

                            </div>
    
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group" >
                                <label for="adresseExpediteurCtrl">Adresse de résidence de l'expéditeur  <span>(*)</span></label>
                                <legend>Adresse précise de l'expéditeur. Ces informations seront utiles aux agents Msahilisho pour des fins de vérification. </legend>
                                <input id="adresseExpediteurCtrl" type="text" class="form-control" formControlName="adresseExpediteurCtrl" placeholder="Adresse de l'expéditeur" required [ngClass]="{ 'is-invalid': submitted && f.adresseExpediteurCtrl.errors }" >
                            </div>
                        </div>
                    </div>


                    <div class="row">
    
                        <div class="col-md-6">
                            <div class="form-group" >
                                <label for="nomPrenomDestinataireCtrl">Nom et prénom du destinataire  <span>(*)</span></label>
                                <legend>Attention! Nos agents vérifieront les noms et prénoms avant de remettre le colis</legend>
                                <input id="nomPrenomDestinataireCtrl" type="text" class="form-control" formControlName="nomPrenomDestinataireCtrl" placeholder="Nom et prénom du destinataire" required [ngClass]="{ 'is-invalid': submitted && f.nomPrenomDestinataireCtrl.errors }"  >
                            </div>
                        </div>

                        <div class="col-md-6">
    
                            <div class="form-group" >
                                <label for="telephoneDestinataireCtrl">Téléphone destinataire <span>(*)</span></label>
                                <legend>Numéro de téléphone sur lequel le destinataire est joignable. Au format {{masktelArr.mask}}</legend>

                                <div class="input-group mb-3">

                                    <div class="input-group-prepend">
                                        <button class="btn btn-outline-secondary dropdown-toggle" type="button"  
                                            aria-haspopup="true" aria-expanded="false" [matMenuTriggerFor]="menuDest" > 
                                            <img src="assets/imgs/{{masktelArr.value}}" width="20" >
                                        </button>
                                        <mat-menu #menuDest="matMenu" class="img-select-menu" >
                                          <button *ngFor="let ntl of listTelephone" mat-menu-item (click)="telFormat( ntl , 'dest' )" >
                                              <img src="assets/imgs/{{ntl.image}}" width="30" > &nbsp; {{ntl.text}}
                                          </button>
                                        </mat-menu>
                                    </div>

                                    <input [validation]="true" [showMaskTyped]="true" mask="{{masktelArr.mask}}" id="telephoneDestinataireCtrl" 
                                    type="text" class="form-control" formControlName="telephoneDestinataireCtrl" 
                                    placeholder="Veuillez renseigner le numéro de téléphone du destinataire" 
                                    required [ngClass]="{ 'is-invalid': submitted && f.telephoneDestinataireCtrl.errors }" >
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group" >
                                <label for="adresseLivraisonCtrl">Adresse de résidence du destinataire  <span>(*)</span></label>
                                <legend>Adresse précise du destinataire. Ces informations seront utiles aux agents Msahilisho pour des fins de vérification. </legend>
                                <input id="adresseLivraisonCtrl" type="text" class="form-control" formControlName="adresseLivraisonCtrl" placeholder="Adresse du destinataire" required [ngClass]="{ 'is-invalid': submitted && f.adresseLivraisonCtrl.errors }" >
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-12"> 
                            <div class="form-group" >
                                <label for="envoiConcerne">Votre envoi concerne <span>(*)</span></label>
                            </div>
                        </div>
                        <div class="col-md-12 div-info-produit-non-pesable">
                            <i class="material-icons">warning</i> Le coût d'expédition en tenant compte de facteur tel que la valeur du colis est estimé à 15% de la valeur déclarée. Prix = (15% v.).
                        </div>
                    </div>

                    <div class="row msahilisho-div">
                        <div *ngFor="let pdt of infosProducts" class="col-6 col-md-3 col-lg-3 col-sm-12 col-div" [ngClass]= "produiSelect && produiSelect.id === pdt.id ? 'select-div': ''"   (click)="chooseEnvoieMsahilisho(pdt)">
                            <div class="nom-produit">{{pdt.nomproduct}}</div>
                            <div class="icon-produit"> <img src="assets/imgs/icon-produit/{{pdt.image}}" ></div>
                            <div class="prix-produit" *ngIf="pdt.prixproduct != ''" > 
                                {{pdt.nomproduct === 'Argent' ? pdt.prixproduct + '€' : '15% v.'}}
                                <span *ngIf="pdt.partype != ''" class="parType" >/{{pdt.partype}} </span>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="produiSelect && produiSelect.id == 'autre'" >
                        <div class="col-md-6">
                            <div class="form-group" >
                                <label for="poidsCtrl">Poids  <span>(*)</span></label>
                                <legend>Poids en KG de votre objet. </legend>
                                <input id="poidsCtrl" step="0.01" type="number" class="form-control" formControlName="poidsCtrl" placeholder="Poid de votre objet" required [ngClass]="{ 'is-invalid': submitted && f.poidsCtrl.errors }" >
                            </div>
                        </div>
            
                        <div class="col-md-6">
                            <div class="form-group" >
                                <label for="telephoneDestinatairetrl">Type de colis<span>(*)</span></label>
                                <legend>Le type de colis que vous voulez expédier: Enveloppe ou Colis ? </legend>
                                <mat-select  formControlName="typeObjetCtrl" required class="form-control" placeholder="Type d'objet " (selectionChange)="onTypeSelection($event)">
                                    <mat-option *ngFor="let tpe of typeObjet" [value]="tpe.value">
                                    {{tpe.name}}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
    
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group" >
                                <label for="valeurCtrl">Valeur du colis  <span>(*)</span></label>
                                <legend>Estimer à peu près le prix de votre colis. Cette information est utile aux agents Msahilisho pour des fins de vérification et en vue de faire jouer la garantie Msahilisho Group. Dans certains cas, des factures ou justificatifs peuvent vous être demandés.</legend>
                                <input id="valeurCtrl" type="number" class="form-control" formControlName="valeurCtrl" placeholder="Valeur du colis" required [ngClass]="{ 'is-invalid': submitted && f.valeurCtrl.errors }" >
                                <div class="error-maximum" *ngIf="f.valeurCtrl.errors && f.valeurCtrl.errors.max" > Le montant maximum autorisé est de 3000€ par expédition. </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group" >
                                <label for="garantieColisCtrl">Garantie  <span>(*)</span></label>
                                <legend>Choisissez la garantie optionelle et Msahilisho Group s'engage à vous rembourser la totalité de la valeur de votre colis en cas de perte. Dans le cas contraire, vous serez rembourser à hauteur de 50%.</legend>
                                <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="garantieColisCtrl">
                                    <mat-button-toggle value="non" aria-label="Je ne veux pas la garantie">
                                        Je ne veux pas la garantie
                                      </mat-button-toggle>
                                    <mat-button-toggle value="oui" aria-label="Je veux la garantie">
                                      Je veux la garantie
                                    </mat-button-toggle>
                                </mat-button-toggle-group>
                            </div>
                        </div>
                    </div>


                    <div class="row" *ngIf="messageRetour" >
                        <div class="col-md-12 div-error" *ngIf="messageRetour.ko" >
                            {{messageRetour.ko}}
                        </div>
                        <div class="col-md-12 div-success" *ngIf="messageRetour.ok" >
                            {{messageRetour.ok}}
                        </div>
                    </div>


                    <div class="row" >
                        <div class="col-md-12">
                            <div class="form-group" >
                                <label for="infoObjetCtrl">Informations sur le colis  <span>(*)</span></label>
                                <legend>{{typeSelected == 'colis' ? infoPlaceholder.txtColi.infos : infoPlaceholder.txtEnv.infos }}</legend>
                                <textarea class="form-control" id="infoObjetCtrl" rows="3" formControlName="infoObjetCtrl" placeholder="Information sur le colis" [ngClass]="{ 'is-invalid': submitted && f.infoObjetCtrl.errors }"></textarea>
                            </div>
                        </div>
                    </div>
                    

                    <div class="condition">
                        <div>
                            <mat-checkbox formControlName="confirmationCtrl" [ngClass]="{ 'is-invalid': submitted && f.confirmationCtrl.errors }"  > </mat-checkbox>
                            <span>En cliquant sur le bouton Valider, vous indiquez avoir pris connaissance et accepté</span>
                            <a class="cursor" (click)="openCGU()">les Conditions Générales d'Utilisation.</a>
                        </div>
                    </div>
                
                    <div>
                        <br />
                        <button class="btn selectionner" type="submit" >Valider votre demande </button>
                    </div>

                    <div class="row" *ngIf="messageRetour" >
                        <div class="col-md-12 div-error" *ngIf="messageRetour.ko" >
                            {{messageRetour.ko}}
                        </div>
                        <div class="col-md-12 div-success" *ngIf="messageRetour.ok" >
                            {{messageRetour.ok}}
                        </div>
                        <br />
                    </div>
    
                </form>

            </div>



        </div>

   
    </div>


    <app-whychoose-home></app-whychoose-home> 
     
    
    <app-footer></app-footer>

    
</div>