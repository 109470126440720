import { Component, OnInit } from '@angular/core';
import { BackendService } from '../services/backend/backend.service'
import {FormControl, FormGroup, Validators, FormBuilder, ValidationErrors } from '@angular/forms';
import { ValidatorrService } from '../services/validatorr/validatorr.service'
import { GlobalService } from '../services/global/global.service'
import { Router } from '@angular/router';
import { EventsService } from '../services/events/events.service'

import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  signupFormGroup: FormGroup;
  submitted
  resultMessage = {ok: null, ko: null}
  passwordError = false
  emailError = false
  nameError = false
  publish = environment.publish

  constructor( 
    private backendService: BackendService ,
    private _formBuilder: FormBuilder,
    private globalService: GlobalService,
    private router: Router,
    private eventsService: EventsService
  ) { }

  ngOnInit() {

    if( this.globalService.getConnectedUser() ) {
      this.router.navigate(['/home']);
      window.scroll(0,0);
    }

    this.signupFormGroup = this._formBuilder.group({
      nomCtrl: ['', Validators.required],
      prenomCtrl: ['', Validators.required],
      emailCtrl: ['', [Validators.required , ValidatorrService.emailValidator] ],
      emailConfirmCtrl: ['', [Validators.required , ValidatorrService.emailValidator] ],
      passwordCtrl: ['', Validators.required ],
      passwordConfirmCtrl: ['', Validators.required ]
    });

  }

  get f() { 
    return this.signupFormGroup.controls; 
  }


  
  onSubmit(){

    this.submitted = true;
    this.passwordError = false
    this.emailError = false
    this.nameError = false

    // stop here if form is invalid
    if (this.signupFormGroup.invalid) {
      return;
    }

    if( this.f.passwordCtrl.value != this.f.passwordConfirmCtrl.value ) {
      this.resultMessage.ko = " Les mots de passe saisis ne sont pas identiques"
      this.passwordError = true
      return;
    }

    if( this.f.emailCtrl.value != this.f.emailConfirmCtrl.value ) {
      this.resultMessage.ko = " Les e-mails saisis ne sont pas identiques"
      this.emailError = true
      return;
    }

    if( this.globalService.checkInterdi( this.f.nomCtrl.value ) ) {
      this.resultMessage.ko = " Le nom utilisé n'est pas autorisé"
      this.nameError = true
      return;
    }

    let user = {
      lastname: this.f.nomCtrl.value,
      firstname: this.f.prenomCtrl.value,
      email: this.f.emailCtrl.value,
      password: this.f.passwordCtrl.value
    }

    this.backendService.post('/users/add', user )
    .then( resultat => {

      

      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.resultMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      } else {

        this.resultMessage.ok = resultat.text
        this.signupFormGroup.patchValue({
          nomCtrl: "",
          prenomCtrl: "",
          emailCtrl: "",
          passwordCtrl: "",
          passwordConfirmCtrl: "",
          emailConfirmCtrl: ""
        })
        this.submitted = false;
      
      }
      
    })
    .catch( error => {
      
    })

  }


  openCGU(){
    window.open( `${this.globalService.getURLApp()}#/conditions-generales-utilisations`  , "_blank")
  }

  formulaireChange(){
    this.passwordError = false
    this.emailError = false
  }


  onPaste(event) {
    
    this.eventsService.errorsmsg("Le copier-coller est interdit.")
    return false;
  }


}
