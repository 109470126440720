
<div class="input-group mb-3">
    <div class="input-group-prepend">
      <button class="btn btn-outline-secondary dropdown-toggle" type="button"  aria-haspopup="true" aria-expanded="false" [matMenuTriggerFor]="menu" > <img src="assets/imgs/{{maskTel.value}}" width="20" ></button>

      <mat-menu #menu="matMenu" class="img-select-menu" >
        <button *ngFor="let ntl of listTelephone" mat-menu-item (click)="telFormat( ntl )" >
            <img src="assets/imgs/{{ntl.image}}" width="30" > &nbsp; {{ntl.text}}
        </button>
    </mat-menu>

    </div>
    <input 
        [validation]="true" 
        [showMaskTyped]="true" 
        mask="{{maskTel.mask}}" 
        [id]="idTelephone" 
        type="text" 
        class="form-control" 
        [(ngModel)]="numTelCtrl" 
        [placeholder]="placeholderInput"  
        (ngModelChange)="numTelChange($event)"
        [ngClass]="{ 'is-invalid': errors }"
    >
</div>