<div class="content-app">

<app-topheader></app-topheader>

<app-header></app-header>


<app-flashinfo-home ></app-flashinfo-home> <!-- *ngIf="!publish" -->

<app-slider-home></app-slider-home>

<app-clients-home></app-clients-home>

<app-application></app-application>

<app-publicite></app-publicite>


<app-comment-home></app-comment-home>

<app-about-as-home></app-about-as-home>

<app-annonces-home></app-annonces-home>

<app-whychoose-home></app-whychoose-home> 


<app-footer></app-footer>



</div>