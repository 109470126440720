import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeamsComponent } from './teams.component';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { HomeModule} from '../home/home.module'
import { TopheaderModule} from '../topheader/topheader.module'
import { HeaderModule} from '../header/header.module';
import { FooterModule } from '../footer/footer.module'
import { MaterialAngModule } from '../matmodule/materialang.module'

import { BackendService } from '../services/backend/backend.service'
import { GlobalService } from '../services/global/global.service'


@NgModule({
  declarations: [TeamsComponent],
  imports: [
    CommonModule,
    NgbModule,
    HomeModule,
    TopheaderModule,
    HeaderModule,
    FooterModule,
    MaterialAngModule,
  ],
  providers: [BackendService , GlobalService ]
})
export class TeamsModule { }
