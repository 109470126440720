import { Component, OnInit } from '@angular/core';
import { BackendService } from '../services/backend/backend.service'
import { GlobalService } from '../services/global/global.service'
@Component({
  selector: 'app-cgu',
  templateUrl: './cgu.component.html',
  styleUrls: ['./cgu.component.scss']
})
export class CguComponent implements OnInit {

  cguInfos = []
  constructor(
    private backendService: BackendService ,
    private globalService: GlobalService,
  ) { }

  ngOnInit() {
    this.displayPage()
  }


  displayPage(){

    this.backendService.get('/cguteams/cgu/2' )
    .then( resultat => {
      
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.cguInfos = []
      } else {
        this.cguInfos = resultat
      }

    })
    .catch( error => {
      this.cguInfos = []
    })

  }

}
