<div class="content-app suggest-route">

    <app-topheader></app-topheader>
 
    <app-header></app-header>



<!-- Section bar de recherche -->
<div class="search-bar-top">
    <div class="container-app">
        <img src="assets/imgs/luggage.svg" class="airport-img" /> Proposer un trajet 
    </div>
</div>


    <div class="container-app">

        <div class="information">
            <i class="material-icons">warning</i> <i class="material-icons">warning</i> <i class="material-icons">warning</i>
            Pour offrir une meilleure qualité du service <span>Msahilsho Import & Export</span>, soyez le plus concis et le plus précis possible sur les informations renseignées.
            
            <div class="text-center" *ngIf="isIE() " >
                <b> Attention ! </b> Certains paramètres de votre navigateur internet ne sont pas compatible avec cette version de Msahilisho Import & Export.
            </div>
        </div>

        <mat-horizontal-stepper [linear]="isLinear" #stepper class="stepPage">


            <mat-step [stepControl]="infosFormGroup" errorMessage="(*) obligatoires." >
              <form [formGroup]="infosFormGroup">
                <ng-template matStepLabel>Informations sur le voyage </ng-template>


                <div class="row">
                    <div class="col-md-6">

                        <div class="form-group" >
                            <label for="aeroportDepCtrl">Départ à  <span>(*)</span></label>
                            <legend>Aéroport de départ</legend>
                            <mat-select id="aeroportDepCtrl" formControlName="aeroportDepCtrl" class="form-control" placeholder="Départ à " (selectionChange)="onAeroDepSelection($event)" [ngClass]="{ 'is-invalid': submitted && f1.aeroportDepCtrl.errors }" >
                                <mat-option *ngFor="let aero of aeroportList" [value]="aero" class="optionList">
                                {{aero.name}} <img src="assets/imgs/{{aero.drapeau}}" width="30"  >
                                </mat-option>
                            </mat-select>
                        </div>

                    </div>

                    <div class="col-md-6">

                        <div class="form-group" >
                            <label for="aeroportArrCtrl">Arrivée à  <span>(*)</span></label>
                            <legend>Destination</legend>
                            <mat-select id="aeroportArrCtrl" formControlName="aeroportArrCtrl" class="form-control" placeholder="Arrivée à " (selectionChange)="onAeroArrSelection($event)" [ngClass]="{ 'is-invalid': submitted && f1.aeroportArrCtrl.errors }" >
                                <mat-option *ngFor="let aero of aeroportList" [value]="aero" class="optionList">
                                {{aero.name}} <img src="assets/imgs/{{aero.drapeau}}" width="30"  >
                                </mat-option>
                            </mat-select>
                        </div>

                    </div>
                </div>


                <div class="row">
                    <div class="col-md-6">

                        <div class="form-group" >
                            <label for="adresseDepotCtrl">Adresse de dépot des colis  <span>(*)</span></label>
                            <legend>Adresse précise où l'agent Msahilisho doit vous déposer le colis avant votre départ. Rassurez-vous, elle ne sera pas affichée publiquement</legend>
                            <input id="adresseDepotCtrl" type="text" class="form-control" formControlName="adresseDepotCtrl" placeholder="Veuillez renseigner une adresse de départ valide" [ngClass]="{ 'is-invalid': submitted && f1.adresseDepotCtrl.errors }">
                        </div>

                    </div>

                    <div class="col-md-6">

                        <div class="form-group" >
                            <label for="adresseRecupCtrl">Adresse de récupération des colis  <span>(*)</span></label>
                            <legend>Adresse précise de domiciliation de votre lieu d'arrivée. Rassurez-vous, elle ne sera pas affichée publiquement</legend>
                            <input id="adresseRecupCtrl" type="text" class="form-control" formControlName="adresseRecupCtrl" placeholder="Veuillez renseigner une adresse de récupération valide" [ngClass]="{ 'is-invalid': submitted && f1.adresseRecupCtrl.errors }"  >
                        </div>

                    </div>
                </div>


                <div class="row">
                    <div class="col-md-6">

                        <div class="form-group" >

                            <div class="input-group">

                                <label for="dateDepartCtrl">Date de départ  <span>(*)</span></label>
                                <legend>
                                    La date de départ de votre voyage.
                                    <div *ngIf="submitted && infosFormGroup.errors" class="div-error" >
                                        {{ infosFormGroup.errors?.dates }}
                                    </div>
                                </legend>
                                <input [showMaskTyped]="true" mask="00/00/0000" id="dateDepartCtrl" type="text" class="form-control" formControlName="dateDepartCtrl" placeholder="dd/mm/yyyy"  [ngClass]="{ 'is-invalid': submitted && f1.dateDepartCtrl.errors }"  >
                                <!--<div class="input-group-append">  ngbDatepicker #d="ngbDatepicker"
                                    <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button"> <i class="fa fa-calendar" aria-hidden="true"></i> </button>
                                </div>-->

                            </div>
                            

                        </div>

                    </div>

                    <div class="col-md-6">

                        <div class="form-group" >

                            <div class="input-group">

                                <label for="dateArriveeCtrl">Date d'arrivée  <span>(*)</span></label>
                                <legend>
                                    La date de votre arrivée.
                                    <div *ngIf="submitted && infosFormGroup.errors" class="div-error" >
                                        {{ infosFormGroup.errors?.dates }}
                                    </div>
                                </legend>
                                <input [showMaskTyped]="true" mask="00/00/0000" id="dateArriveeCtrl" type="text" class="form-control" formControlName="dateArriveeCtrl" placeholder="dd/mm/yyyy"  [ngClass]="{ 'is-invalid': submitted && f1.dateArriveeCtrl.errors }"  >
                            </div>

                        </div>

                    </div>
                </div>


                <div class="row">
                    <div class="col-md-6">

                        <div class="form-group" >
                            <label for="numDepartCtrl">Téléphone du lieu de départ  <span>(*)</span></label>
                            <legend>Numéro de téléphone sur lequel vous êtes joignable jusqu'à votre départ. Au format {{maskTeldep.mask}} </legend>

                            <div class="input-group mb-3">

                                <div class="input-group-prepend">
                                    <button class="btn btn-outline-secondary dropdown-toggle" type="button"  
                                        aria-haspopup="true" aria-expanded="false" [matMenuTriggerFor]="menuDep" > 
                                        <img src="assets/imgs/{{maskTeldep.value}}" width="20" >
                                    </button>
                                    <mat-menu #menuDep="matMenu" class="img-select-menu" >
                                      <button *ngFor="let ntl of listTelephone" mat-menu-item (click)="telFormat( ntl , 'dep' )" >
                                          <img src="assets/imgs/{{ntl.image}}" width="30" > &nbsp; {{ntl.text}}
                                      </button>
                                    </mat-menu>
                                </div>

                                <input [validation]="true" [showMaskTyped]="true" mask="{{maskTeldep.mask}}" 
                                id="numDepartCtrl" type="text" class="form-control" formControlName="numDepartCtrl" 
                                placeholder="Téléphone du lieu de départ" [ngClass]="{ 'is-invalid': submitted && f1.numDepartCtrl.errors }" >
                            </div>

                        </div>

                    </div>

                    <div class="col-md-6">

                        <div class="form-group" >
                            <label for="numArriveCtrl">Téléphone du lieu d'arrivé  <span>(*)</span></label>
                            <legend>Numéro de téléphone sur lequel vous êtes joignable à votre pays d'arrivé. Au format {{masktelArr.mask}} </legend>

                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <button class="btn btn-outline-secondary dropdown-toggle" type="button"  
                                        aria-haspopup="true" aria-expanded="false" [matMenuTriggerFor]="menuArr" > 
                                        <img src="assets/imgs/{{masktelArr.value}}" width="20" >
                                    </button>
                                    <mat-menu #menuArr="matMenu" class="img-select-menu" >
                                      <button *ngFor="let ntl of listTelephone" mat-menu-item (click)="telFormat( ntl , 'arr' )" >
                                          <img src="assets/imgs/{{ntl.image}}" width="30" > &nbsp; {{ntl.text}}
                                      </button>
                                    </mat-menu>
                                </div>
                                <input [validation]="true" [showMaskTyped]="true" mask="{{masktelArr.mask}}" 
                                id="numArriveCtrl" type="text" class="form-control" formControlName="numArriveCtrl" 
                                placeholder="Téléphone du lieu d'arrivé" [ngClass]="{ 'is-invalid': submitted && f1.numArriveCtrl.errors }" >
                            </div>

                        </div>

                    </div>
                </div>


                <div>
                  <button (click)="changeBTN('infos')" class="nextLevel" mat-button matStepperNext>Étape suivante</button>
                  <br/><br /><span class="champObligatoire">Attention ! Les champs avec (*) sont obligatoires </span>
                </div>

              </form>
            </mat-step>


            <mat-step [stepControl]="offreFormGroup" errorMessage="(*) obligatoires."  >
              <form [formGroup]="offreFormGroup">
                <ng-template matStepLabel>Offre et service </ng-template>


                <div class="row">
                    <div class="col-md-6">

                        <div class="form-group" >
                            <label for="deviseCtrl">Devise de paiement  <span>(*)</span></label>
                            <legend>La devise pour le paiment </legend>
                            <input id="deviseCtrl" type="text" class="form-control" formControlName="deviseCtrl" placeholder="Devise actuelle" readonly >
                        </div>

                    </div>

                    <div class="col-md-6">

                        <div class="form-group" >
                            <label for="frequenceCtrl">Fréquence du trajet  <span>(*)</span></label>
                            <legend>Fréquence de voyage vers ce pays</legend>

                            <mat-select  formControlName="frequenceCtrl" required class="form-control" placeholder="Fréquence de voyage" (selectionChange)="onFreqSelection($event)" [ngClass]="{ 'is-invalid': submitted && f2.frequenceCtrl.errors }"  >
                                <mat-option *ngFor="let freq of frequenceVoyage" [value]="freq.value">
                                {{freq.name}}
                                </mat-option>
                            </mat-select>

                        </div>

                    </div>
                </div>


                <div class="row">
                    <div class="col-md-6">

                        <div class="form-group" >
                            <label for="prixCtrl">Prix au KG  <span>(*)</span></label>
                            <legend>Prix par kilogramme que vous proposer. les prix sont en Euro.</legend>
                            <input (change)="OnChangePrice()" step="0.01" id="prixCtrl" type="number" class="form-control" formControlName="prixCtrl" placeholder="Introduire un prix au KG" required [ngClass]="{ 'is-invalid': submitted && f2.prixCtrl.errors }">
                        </div>

                    </div>

                    <div class="col-md-6">

                        <div class="form-group" >
                            <label for="nbrKilosCtrl">Nombre de KG disponible  <span>(*)</span></label>
                            <legend>Le nombre de kilogramme que vous mettez à disposition.</legend>
                            <input step="0.01" id="nbrKilosCtrl" type="number" class="form-control" formControlName="nbrKilosCtrl" placeholder="Combien de KG disponible ?" required [ngClass]="{ 'is-invalid': submitted && f2.nbrKilosCtrl.errors }" >
                        </div>

                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 div-msg-price">
                        {{msgPrice}}
                    </div>
                </div>


                <div>
                  <button class="prevLevel" mat-button matStepperPrevious>Étape précédente</button>
                  <button (click)="changeBTN('offre')" class="nextLevel" mat-button matStepperNext>Étape suivante</button>
                </div>


              </form>
            </mat-step>


            <mat-step [stepControl]="paiementFormGroup" errorMessage="(*) obligatoires."  >
                <form [formGroup]="paiementFormGroup">
                  <ng-template matStepLabel>Facturation et rémunération </ng-template>

                    <div class="row">
                        <div class="col-md-6">

                            <div class="form-group" >
                                <label for="paiementCtrl">Moyen de paiement favoris <span>(*)</span></label>
                                <legend>Sélectionner le moyen qui vous convient pour recevoir votre paiement. </legend>
                                <mat-select  formControlName="paiementCtrl" required class="form-control" placeholder="Moyen de paiement" (selectionChange)="onPaymentSelection($event)" [ngClass]="{ 'is-invalid': submitted && f3.paiementCtrl.errors }" >
                                    <mat-option *ngFor="let mpa of moyenPayment" [value]="mpa.value">
                                        {{mpa.name}}
                                    </mat-option>
                                </mat-select>
                            </div>

                        </div>

                        <div class="col-md-6">
                            <div class="form-group" >
                                <label for="ibanCtrl">IBAN  <span>(*)</span></label>
                                <legend>Votre IBAN pour le virement.</legend>
                                <input id="ibanCtrl" type="text" class="form-control" formControlName="ibanCtrl" placeholder="IBAN présent dans votre RIB" [readonly]="isPaiementReadOnly" >
                            </div>

                        </div>
                    </div>


                  <div>
                    <button class="prevLevel" mat-button matStepperPrevious>Étape précédente</button>
                    <button (click)="changeBTN('facturation')" class="nextLevel" mat-button matStepperNext>Étape suivante</button>
                  </div>

                </form>
            </mat-step>


            <mat-step errorMessage="(*) obligatoires."  >
              <form [formGroup]="confiramtionFormGroup" >
                <ng-template matStepLabel>Confirmation et validation des CGU </ng-template>

                <div class="row">

                    <br />
                    <div class="col-md-10">
                        <div class="form-group" >
                            <label for="importFile" #labelImport class="cursor" > <i class="material-icons">  search </i> {{urlSelectJustif.msg}}  <span>Téléchargez votre billet d'avion</span></label>
                            <legend>Pour lutter contre la publication de fausses annonces, un justificatif prouvant votre voyage est nécessaire. 
                                Rassure-vous, ce document ne sera pas publié sur notre plateforme. Il permet la validation de votre annonce par notre équipe commerciale.
                                Vous pouvez rayer tout élément que vous estimez devoir être exclu tel que (N° de passeport, date et ville de naissance ...ect.)
                             </legend>
                            
                             <input type="file" class="custom-file-input" formControlName="importFile" id="importFile" (change)="onFileChange($event)" [ngClass]="{ 'is-invalid': submitted && f4.importFile.errors }">
                             
                        </div>
                    </div>
                </div>
    
                
                  
                  <div class="condition">
                    <div class="div-progress" *ngIf="progressMessage.progress">
                        <span> {{progressMessage.progress}} %</span> effectués
                    </div>

                    <div class="col-md-12 div-success" *ngIf="progressMessage.ok" >
                        {{progressMessage.ok}}
                        <p class="redirection_auto">
                            <br />
                            Vous allez être redirigé automatiquement dans quelques secondes.
                        </p>
                    </div>

                    <div class="col-md-12 div-error" *ngIf="progressMessage.ko" >
                        {{progressMessage.ko}}
                    </div>


                    <br /><br />

                    <mat-checkbox formControlName="confirmationCtrl" [ngClass]="{ 'is-invalid': submitted && f4.confirmationCtrl.errors }"  > </mat-checkbox> <span>En cliquant sur le bouton Valider, vous indiquez avoir pris connaissance et accepté</span>
                    <a class="cursor" (click)="openCGU()">les Conditions Générales d'Utilisation.</a>
    
                    <br />
                    <button *ngIf="!envoiEncours" class="validBtn" mat-button (click)="publier()" >Valider et publier votre annonce</button>
    
                  </div>
    
                  <div>
                    <button class="prevLevel" mat-button matStepperPrevious>Étape précédente</button>
                    <!--<button mat-button (click)="stepper.reset()">Reset</button>-->
                  </div>

              </form>
            </mat-step>
        </mat-horizontal-stepper>

          

    </div>



    <app-whychoose-home></app-whychoose-home> 
     
    
    <app-footer></app-footer>

    
</div>