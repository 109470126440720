import { Component, OnInit , EventEmitter , Output } from '@angular/core';
import { EventsService } from '../../services/events/events.service'


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  selectedPage = "dashboard"
  @Output() pageEmitter = new EventEmitter();


  constructor( private eventsService: EventsService) { }

  ngOnInit() {
  }

  selectOption( page ){
    this.selectedPage = page
    this.eventsService.onPageSelected( page )
  }



}
